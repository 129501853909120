<div class="field field-typeahead position-relative" [class.valid]="model">
    <span *ngIf="label" class="label">{{label}}</span>
    <ng-container *ngIf="multiple && model && model.length > 0">
        <ul class="app-list" [class.app-list--field]="true">
            <li *ngFor="let item of model; index as $i">
                <div style="display: flex; align-items: baseline;" [class.error-item]="isArray(error) && error[$i]">
                    <div>
                        {{item.value}}
                    </div>
                    <div style="margin-left: 10px;" *ngIf="item?.company?.id">
                        <span class="stamp mr-xxs stamp--gray">{{'crmctgdossiers_table_member' | translate}}</span>
                    </div>
                    <div>
                        <i (click)="deleteValue($i)" class="im-icon im-icon-x-light delete-icon"></i>
                    </div>
                    <div *ngIf="isArray(error) && error[$i]" class="error-field-display">
                        {{error[$i] | translate}}
                    </div>
                </div>
            </li>
        </ul>
    </ng-container>
    <div class="d-flex align-items-center">
        <input
        id="typeahead-prevent-manual-entry"  
        type="text"
        [(ngModel)]="inputValue"
        [class.error-field]="error"
        [placeholder]="placeholder"
        (blur)="onBlur()"
        (selectItem)="onSelect($event);"
        (change)="change($event)"
        (keyup.enter)="enter.emit()"
        [ngbTypeahead]="search"
        [inputFormatter]="formatter"
        [resultFormatter]="formatter"
        [resultTemplate]="template"
        [editable]="false" />
        <span *ngIf="error" class="error-field-display">{{error}}</span>
        
        <ng-template #template let-r="result" let-t="term">
            <ngb-highlight [result]="r.value" [term]="t"></ngb-highlight>
        </ng-template>
        <ng-container *ngIf="!multiple">
            <div style="padding-left: 16px; display: flex; flex: 0 0 auto;" *ngIf="inputValue?.company?.id">
                <span class="stamp stamp--gray" style="display: initial;">{{'pricedossier_table_member' | translate}} <ng-container *ngIf="!inputValue?.company?.company_status">({{'misc_inactive' | translate}})</ng-container></span>
            </div>
        </ng-container>
    </div>
</div>