import { Component, Input, OnInit, Output } from '@angular/core';
import { EventEmitter } from '@angular/core';

@Component({
    selector: 'app-field-checkboxes',
    templateUrl: './field-checkboxes.component.html',
    styleUrls: ['./field-checkboxes.component.scss']
})
export class FieldCheckboxesComponent implements OnInit {
    @Input() options: [{ title: string; value: any; tooltip?: string }];
    @Input() optionWidth: number;
    @Input() optionColumnWidth?: string;
    @Input() colSize?: string;
    @Input() fillWidth?: boolean;
    @Input() readOnly?: boolean;
    @Input() model: any[];
    @Input() returnChecked: boolean = false;
    @Input() label?: string;
    @Input() error?: string;
    @Input() optionalString?: string;
    @Output() modelChange = new EventEmitter();

    @Input() bool = false;

    constructor() {}

    ngOnInit(): void {}

    emit($event) {
        if (this.readOnly) {
            return;
        }
        this.modelChange.emit($event);
    }

    check(value: string) {
        if (this.bool) {
            let res = false;
            if (!this.model) res = true;
            this.emit(res);
            return;
        }
        if (this.returnChecked) {
            this.emit(value);
            return;
        }
        let newModel = [...this.model];
        const index = newModel.indexOf(value);
        if (index != -1) {
            newModel.splice(index, 1);
        } else newModel.push(value);
        this.emit(newModel);
    }

    isActive(value: string) {
        if (this.bool) return this.model;
        return this.model && this.model.indexOf(value) != -1;
    }
}
