<div class="field field-typeahead position-relative" [class.valid]="selected">
    <span *ngIf="label" class="label">{{label}}</span>

    <input
        id="typeahead-prevent-manual-entry"  
        type="text"
        [(ngModel)]="selected"
        [class.error-field]="error"
        [placeholder]="placeholder"
        (selectItem)="onSelect($event);"
        [ngbTypeahead]="search"
        [inputFormatter]="formatter"
        [resultFormatter]="formatter"
        [resultTemplate]="template"
        (blur)="onBlur()"
        [editable]="false"/>
    <span *ngIf="error" class="error-field-display">{{error}}</span>
    
    <ng-template #template let-r="result" let-t="term">
        <ngb-highlight [result]="r.title" [term]="t"></ngb-highlight>
        <span *ngIf="r.subtitle" class="typeahead-after">
            <ngb-highlight [result]="r.subtitle" [term]="t"></ngb-highlight>
            <span *ngFor="let stamp of r?.stamp" class="stamp mr-xxs ml-xxs" [class]="stamp.classList">
                {{stamp.value}}
            </span>
        </span>
    </ng-template>
</div>