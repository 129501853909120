<div class="field field-typeahead position-relative" [class.valid]="model">
    <span *ngIf="label" class="label">{{label}}</span>
    <ng-container *ngIf="model && model.length > 0">
        <ul class="app-list" [class.app-list--field]="true">
            <li *ngFor="let item of model; index as $i">
                <div style="display: inline;" [class.error-item]="isArray(error) && error[$i]">
                    {{item.label}}
                    <i (click)="deleteValue($i)" class="im-icon im-icon-x-light delete-icon"></i>
                    <div *ngIf="isArray(error) && error[$i]" class="error-field-display">
                        {{error[$i] | translate}}
                    </div>
                    <div
                        class="optional-input" 
                        contenteditable="true" 
                        (blur)="model[$i][optionalInputModelName] = $event.target.innerText" 
                        [textContent]="model[$i][optionalInputModelName]" 
                        [attr.data-placeholder]="optionalInputPlaceholder">
                    </div>
                </div>
            </li>
        </ul>
    </ng-container>
    <input
        id="typeahead-prevent-manual-entry"  
        type="text"
        [(ngModel)]="selected"
        [class.error-field]="error"
        [placeholder]="placeholder"
        (selectItem)="onSelect($event);"
        [ngbTypeahead]="search"
        [inputFormatter]="formatter"
        [resultFormatter]="formatter"
        [resultTemplate]="template"
        editable=false />
    <span *ngIf="error" class="error-field-display">{{error}}</span>

    <ng-template #template let-r="result" let-t="term">
        <ngb-highlight [result]="r.value" [term]="t"></ngb-highlight>
    </ng-template>
</div>