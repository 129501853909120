<ng-container *ngIf="mode != 'globalError'">
    <ng-container *ngIf="!ready">
        <app-spinner [centerVertically]="true"></app-spinner>
    </ng-container>
    <div *ngIf="ready" class="d-flex detail-modal">
        <!-- side panel -->
        <app-modal-side-panel *ngIf="mode != 'create'" [open]="true">
            <div class="d-flex flex-column w-100 flex-grow-1 scroll-y pb-5 saving--pre" [class.saving]="saving">
                <div class="p-l pb-s pr-s panel-head">
                    <button (click)="activeModal.dismiss()" class="mb-xl fontsize-xs"> <i class="im-icon im-icon-arrow-left mr-0" style="font-size: 18px;"></i> <span class="fontsize-s d-inline-block" style="margin-left: 11px;line-height: 21px;transform: translateY(-2px);">{{'contract_detail_back_btn' | translate}}</span></button>  
                    <div class="">
                        <div class="d-flex flex-column titles">
                            <span class="title">
                                <!-- {{getContractTitle() || 'Contract'}} -->
                                {{parentContract.version || 'Contract'}}
                            </span>
                            <span class="subtitle">
                                {{contract.number}}
                            </span>
                        </div>
                    </div>
                    <!-- <div class="mt-xs">
                        <app-contract-version 
                            [disabled]="editAmendment || mode == 'edit' || mode == 'createUpdate'" 
                            [model]="helper.version" 
                            (modelChange)="cancelEdit() && versionChange($event)"
                            (add)="createUpdateAmendment()"
                            [options]="contract.versions"
                        ></app-contract-version>
                    </div> -->
                </div>
                <div class="d-flex flex-grow-1 scroll-y opacity-hide" [class.opacity-show]="true">
                    <ng-container>
                        <app-side-navigation
                            class="d-block w-100"
                            [inactive]="editAmendment == 'new'"
                            [pageARR]="pages"
                            [model]="activePage"
                            (modelChange)="pageChange($event)">
                            <app-contract-version 
                                [disabled]="editAmendment || mode == 'edit' || mode == 'createUpdate'" 
                                [model]="helper.version" 
                                (modelChange)="cancelEdit() && versionChange($event)"
                                (add)="createUpdateAmendment()"
                                [options]="contract.versions"
                            ></app-contract-version>
                        </app-side-navigation>
                    </ng-container>
                </div>
            </div>
        </app-modal-side-panel>
    
        <div class="d-flex flex-column w-100 flex-grow-1 scroll-y pb-5">
            <div class="">
                <!-- header -->
                <div class="detail-modal-header d-flex flex-row justify-content-between align-items-center" style="min-height: 36px;">
                    <ng-container *ngIf="mode == 'create'">
                        <h1 style="min-height: 36px;">{{'contract_detail_create_title' | translate}}</h1>
                        <div class="h-100 d-flex align-items-center">
                            <button class="button tertiary cancel--" (click)="canExit(); activeModal.dismiss();" [class.disabled]="saving"><span>{{'contract_detail_cancel_btn' | translate}}</span></button>
                            <button class="button default-sm ml-xs" (click)="createUpdate()" [class.disabled]="saving">{{'contract_detail_save_btn' | translate}}</button>
                        </div>
                    </ng-container>
                    <ng-container *ngIf="mode != 'create'">
                        <h1 style="min-height: 36px;" class="d-flex align-items-center">
                            <ng-container *ngIf="editAmendment != 'new'">
                                <i class="active-page-icon" *ngIf="getActivePage()?.icon" class="im-icon im-icon-{{getActivePage().icon}}"></i>
                                <span>{{'contract_detail_menu_' + activePage | translate}}</span>
                            </ng-container>
                            <ng-container *ngIf="editAmendment == 'new'">
                                <span>{{'contract_detail_menu_add_admendment' | translate}}</span>
                            </ng-container>
                        </h1>
                        <!-- buttons overview -->
                        <ng-container *ngIf="activePage == 'overview'">
                            <div *ngIf="mode == 'show'" class="h-100 d-flex align-items-center">
                                <!-- edit -->
                                <button class="button tertiary ml-m" (click)="clickEdit();">
                                    <i class="im-icon im-icon-pencil icon-l mr-xxxs"></i><span>{{'contract_detail_edit_btn' | translate}}</span>
                                </button>
                            </div>
                            <div *ngIf="mode == 'edit'" class="h-100 d-flex align-items-center">
                                <button *ngIf="id" class="button tertiary cancel--" (click)="cancelEdit(); resetAmendmentProps();" [class.disabled]="saving"><span>{{'contract_detail_cancel_btn' | translate}}</span></button>
                                <button class="button default-sm ml-xs" (click)="createUpdate()" [class.disabled]="saving">{{'contract_detail_save_btn' | translate}}</button>
                            </div>
                        </ng-container>
                        <!-- buttons summary -->
                        <ng-container *ngIf="activePage == 'summary'">
                            <div *ngIf="mode == 'show'" class="h-100 d-flex align-items-center">
                                <!-- edit -->
                                <button class="button tertiary ml-m" (click)="initCreateUpdateSummary();">
                                    <i class="im-icon im-icon-pencil icon-l mr-xxxs"></i><span>{{'contract_detail_edit_btn' | translate}}</span>
                                </button>
                            </div>
                            <div *ngIf="mode == 'edit'" class="h-100 d-flex align-items-center">
                                <button *ngIf="id" class="button tertiary cancel--" (click)="cancelEdit();" [class.disabled]="saving"><span>{{'contract_detail_cancel_btn' | translate}}</span></button>
                                <button class="button default-sm ml-xs" (click)="createUpdate()" [class.disabled]="saving">{{'contract_detail_save_btn' | translate}}</button>
                            </div>
                        </ng-container>
                        <!-- buttons financial -->
                        <ng-container *ngIf="activePage == 'financial'">
                            <div *ngIf="mode == 'show'" class="h-100 d-flex align-items-center">
                                <!-- add -->
                                <button class="button tertiary ml-m" (click)="initCreateUpdateFinancialScheme()">
                                    <i class="im-icon im-icon-plus icon-l mr-xxxs"></i><span>{{'contractdetail_add_fin_scheme' | translate}}</span>
                                </button>
                            </div>
                            <div *ngIf="mode == 'view'" class="h-100 d-flex align-items-center">
                                <!-- view -->
                                <button class="button tertiary ml-m" (click)="mode = 'createUpdate'">
                                    <i class="im-icon im-icon-pencil icon-l mr-xxxs"></i><span>{{'contract_detail_edit_btn' | translate}}</span>
                                </button>
                            </div>
                            <div *ngIf="mode == 'createUpdate'" class="h-100 d-flex align-items-center">
                                <button class="button tertiary" (click)="helper.finDetail ? mode = 'view' : cancelEdit()" [class.disabled]="saving"><span>{{'contract_detail_cancel_btn' | translate}}</span></button>
                                <button class="button default-sm ml-xs" (click)="createUpdate()" [class.disabled]="saving">{{'contract_detail_save_btn' | translate}}</button>
                            </div>
                        </ng-container>
                        <!-- buttons payments -->
                        <ng-container *ngIf="activePage == 'payments'">
                            <div *ngIf="mode == 'show'" class="h-100 d-flex align-items-center">
                                <!-- add -->
                                <button class="button tertiary ml-m" (click)="initCreateUpdatePayment()">
                                    <i class="im-icon im-icon-plus icon-l mr-xxxs"></i><span>{{'contractdetail_add_payment' | translate}}</span>
                                </button>
                            </div>
                            <div *ngIf="mode == 'createUpdate'" class="h-100 d-flex align-items-center">
                                <button class="button tertiary" (click)="cancelEdit()" [class.disabled]="saving"><span>{{'contract_detail_cancel_btn' | translate}}</span></button>
                                <button class="button default-sm ml-xs" (click)="createUpdate()" [class.disabled]="saving">{{'contract_detail_save_btn' | translate}}</button>
                            </div>
                        </ng-container>
                        <ng-container *ngIf="activePage == 'estimated'">
                            <div *ngIf="mode == 'show'" class="h-100 d-flex align-items-center">
                                <!-- add -->
                                <button class="button tertiary ml-m" (click)="initCreateUpdateEstimated()">
                                    <i class="im-icon im-icon-plus icon-l mr-xxxs"></i><span>{{'contractdetail_add_estimated' | translate}}</span>
                                </button>
                            </div>
                            <div *ngIf="mode == 'createUpdate'" class="h-100 d-flex align-items-center">
                                <button class="button tertiary" (click)="cancelEdit()" [class.disabled]="saving"><span>{{'contract_detail_cancel_btn' | translate}}</span></button>
                                <button class="button default-sm ml-xs" (click)="createUpdate()" [class.disabled]="saving">{{'contract_detail_save_btn' | translate}}</button>
                            </div>
                        </ng-container>
                    </ng-container>
                </div>
    
                <div class="detail-modal-body saving--pre" [class.saving]="loadingVersion">
                    <!-- Overview -->
                    <ng-container *ngIf="activePage == 'overview'">
                        <ng-container *ngIf="mode == 'show'">
                            <!-- amendment start -->
                            <ng-container *ngIf="contract.lnk_contract_uuid">
                                <div class="row display-row">
                                    <div class="col-2">
                                        <div class="display-content">                            
                                            <div class="display-label">{{'amendment_name' | translate}}</div>
                                            <div class="display-value d-flex flex-row">
                                                <span>{{contract.name | display}}</span>
                                            </div>                     
                                        </div>
                                    </div>
                                    <div class="col-2">
                                        <div class="display-content">                            
                                            <div class="display-label">{{'amendment_type' | translate}}</div>
                                            <div class="display-value d-flex flex-row">
                                                <span>{{resourceService.getResourceTitleByTypeAndKey('amendment_type', contract.contract_type) | display}}</span>
                                            </div>                     
                                        </div>
                                    </div>
                                </div>
                                <div class="row display-row">
                                    <div class="col-4">
                                        <div class="display-content">
                                            <div class="display-label">{{'contractdetail_contracts' | translate}}</div>
                                            <div class="display-value d-flex flex-row">
                                                <ng-container *ngIf="contract?.related_contracts?.length">
                                                    <ul class="app-list">
                                                        <li *ngFor="let contract of contract.related_contracts; index as $i">
                                                            {{contract.title}}
                                                            <ng-container *ngIf="contract?.subtitle">
                                                                <div class="fontsize-s sub-contract" style="margin-top: 0px;">
                                                                    {{contract.subtitle | display}}
                                                                    <ng-container *ngIf="contract?.stamp">
                                                                        <div class="d-inline-block" [class.app-tooltip-wrap]="contract?.tooltip">
                                                                            <span *ngFor="let stamp of contract?.stamp" class="stamp mr-xxs" [class]="stamp.classList">
                                                                                {{stamp.value}}
                                                                            </span>
                                                                            <div *ngIf="contract?.tooltip" class="app-tooltip app-tooltip-default-centered">{{contract?.tooltip}}</div>
                                                                        </div>
                                                                    </ng-container>
                                                                </div>
                                                            </ng-container>
                                                        </li>
                                                    </ul>
                                                </ng-container>
                                                <ng-container *ngIf="!contract?.related_contracts?.length">
                                                    <span>-</span>
                                                </ng-container>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="row display-row"> 
                                    <div class="col-4">
                                        <div class="display-content">                            
                                            <div class="display-label">{{'contractdetail_procedure_article' | translate}}</div>
                                            <div class="display-value d-flex flex-row">
                                                <span>{{getProcedureArticleAmendment() | display}}</span>
                                            </div>                     
                                        </div>
                                    </div>
                                </div>
                                <div class="row display-row">
                                    <div class="col-8">
                                        <div class="display-content">                            
                                            <div class="display-label">{{'key_changes' | translate}}</div>
                                            <div class="display-value d-flex flex-row">
                                                <app-field-richtext
                                                    [model]="contract.key_changes"
                                                    [readOnly]="true"
                                                ></app-field-richtext>
                                            </div>                     
                                        </div>
                                    </div>
                                </div>
                            </ng-container>
                            <!-- amendm end -->
                            <div class="row display-row" *ngIf="!contract.lnk_contract_uuid">
                                <div class="col-6" *ngIf="!contract.lnk_contract_uuid">
                                    <div class="display-content">                            
                                        <div class="display-label">{{'contract_type' | translate}}</div>
                                        <div class="display-value d-flex flex-row">
                                            <span>{{parentContract.contract_type | resource : 'contract_type'}} <span *ngIf="parentContract.contract_type == 'renewal'">{{'contractdetail_renewal_afterstring' | translate}} <a class="format-link" (click)="id = parentContract.lnk_renewal_contract_uuid; getContract()" [routerLink]="['/contracts']" [queryParams]="{contractId: parentContract.lnk_renewal_contract_uuid, activePage: 'overview'}">{{parentContract.renewal_contract_number}}</a></span></span>
                                        </div>                     
                                    </div>
                                </div>
                                <div class="col-4" *ngIf="!contract.lnk_contract_uuid">
                                    <div class="display-content">                            
                                        <div class="display-label">{{'contractdetail_procedure_article' | translate}}</div>
                                        <div class="display-value d-flex flex-row">
                                            <span>{{getProcedureArticle() | display}}</span>
                                        </div>                     
                                    </div>
                                </div>
                            </div>

                            <div class="row display-row" *ngIf="!contract.lnk_contract_uuid">
                                <div class="col-4">
                                    <div class="display-content">
                                        <div class="display-label">{{'contractdetail_contracts' | translate}}</div>
                                        <div class="display-value d-flex flex-row">
                                            <ng-container *ngIf="contract?.related_contracts?.length">
                                                <ul class="app-list">
                                                    <li *ngFor="let contract of contract.related_contracts; index as $i">
                                                        {{contract.title}}
                                                        <ng-container *ngIf="contract?.subtitle">
                                                            <div class="fontsize-s sub-contract" style="margin-top: 0px;">
                                                                {{contract.subtitle | display}}
                                                                <ng-container *ngIf="contract?.stamp">
                                                                    <div class="d-inline-block" [class.app-tooltip-wrap]="contract?.tooltip">
                                                                        <span *ngFor="let stamp of contract?.stamp" class="stamp mr-xxs" [class]="stamp.classList">
                                                                            {{stamp.value}}
                                                                        </span>
                                                                        <div *ngIf="contract?.tooltip" class="app-tooltip app-tooltip-default-centered">{{contract?.tooltip}}</div>
                                                                    </div>
                                                                </ng-container>
                                                            </div>
                                                        </ng-container>
                                                    </li>
                                                </ul>
                                            </ng-container>
                                            <ng-container *ngIf="!contract?.related_contracts?.length">
                                                <span>-</span>
                                            </ng-container>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            
                            <div class="row display-row" *ngIf="!contract.lnk_linked_contract_uuid">
                                <div class="col-2">
                                    <div class="display-content">                            
                                        <div class="display-label">{{'contract_number' | translate}}</div>
                                        <div class="display-value d-flex flex-row">
                                            <span>{{contract.number | display}}</span>
                                        </div>                     
                                    </div>
                                </div>
                                <div class="col-4">
                                    <div class="display-content">                            
                                        <div class="display-label">{{'initial_contract_name' | translate}}</div>
                                        <div class="display-value d-flex flex-row" *ngIf="true">
                                            <span>{{parentContract.name | display}}</span>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-4">
                                    <div class="display-content">                            
                                        <div class="display-label">{{'contract_alias' | translate}}</div>
                                        <div class="display-value d-flex flex-row">
                                            <span>{{contract.alias | display}}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            
                            <div class="row display-row">
                                <div class="col-4">
                                    <div class="display-content">                            
                                        <div class="display-label">{{'contract_stage' | translate}}</div>
                                        <div class="display-value d-flex flex-row">
                                            <span>{{contract.stage | resource : 'stage'}}</span>
                                        </div>                     
                                    </div>
                                </div>
                                <div class="col-4" *ngIf="!contract.lnk_contract_uuid && ['contract_negotiation_failed', 'no_agreement', 'contract_negotiation_refused', 'contract_negotiation_initiated'].includes(contract.stage)">
                                    <div class="display-content">                            
                                        <div class="display-label">{{'contractdetail_year_of_implementation_or_failure' | translate}}</div>
                                        <div class="display-value d-flex flex-row">
                                            <span>{{contract.year_of_implementation_or_failure | display}}</span>
                                        </div>                     
                                    </div>
                                </div>
                            </div>
                            <div class="row display-row">
                                <div class="col-12">
                                    <app-contract-dossiers
                                        [model]="contract.dossiers"
                                        [readOnly]="true"
                                    ></app-contract-dossiers>
                                </div>
                            </div>
                            <div class="row display-row">
                                <div class="col-12">
                                    <app-contract-products
                                        [model]="contract.products"
                                        [readOnly]="true"
                                    ></app-contract-products>
                                </div>
                            </div>
                            <div class="row display-row" *ngIf="!contract.lnk_contract_uuid">
                                <div class="col-4">
                                    <div class="display-content">                            
                                        <div class="display-label">{{'contract_advisor' | translate}}</div>
                                        <div class="display-value d-flex flex-row">
                                            <span>{{getContractAdvisor(contract.lnk_advisor_id) | display}}</span>
                                        </div>                     
                                    </div>
                                </div>
                            </div>
                            <div class="row display-row" *ngIf="!contract.lnk_contract_uuid">
                                <div class="col-4">
                                    <div class="display-content">                            
                                        <div class="display-label">{{'contact_company' | translate}}</div>
                                        <div class="display-value d-flex flex-row">
                                            <span>{{contract.company_name | display}}</span>
                                            <ng-container *ngIf="contract.company_name">
                                                <div class="pl-xxs" *ngIf="contract.lnk_company_id">
                                                    <span class="stamp mr-xxs stamp--gray">{{'pricedossier_table_member' | translate}} <ng-container *ngIf="!contract.company_status">({{'misc_inactive' | translate}})</ng-container></span>
                                                </div>
                                            </ng-container>
                                        </div>                     
                                    </div>
                                </div>
                            </div>
                            <div class="row display-row">
                                <div class="col-4">
                                    <div class="display-content">                            
                                        <div class="display-label">{{'contract_indication' | translate}}</div>
                                        <div class="display-value d-flex flex-row">
                                            <ng-container *ngIf="contract.indications && contract.indications.length > 0">
                                                <ul class="app-list" [class.app-list--single]="contract.indications.length == 1">
                                                    <ng-container *ngFor="let indication of contract.indications">
                                                        <li>
                                                            {{indication.label}}
                                                            <div *ngIf="indication.comment" class="comment d-flex flex-row">{{indication.comment}}</div>
                                                        </li>
                                                    </ng-container>
                                                </ul>
                                            </ng-container>
                                            <ng-container *ngIf="!contract.indications || contract.indications.length == 0">
                                                <span>-</span>
                                            </ng-container>
                                        </div>                     
                                    </div>
                                </div>
                            </div>
                            <div class="row display-row">
                                <div class="col-8">
                                    <table class="table--detail readonly">
                                        <tr>
                                            <th>{{'contract_start_date' | translate}}</th>
                                            <th>{{'contract_end_date' | translate}}</th>
                                            <th>{{'contract_facial_price_decrease' | translate}}</th>
                                            <th>{{'contract_date_length' | translate}}</th>
                                            <th>{{'contract_date_type' | translate}}</th>
                                        </tr>
                                        <ng-container *ngFor="let version of contract?.versions">
                                            <tr>
                                                <td>{{version.start_date | formatTs:'DD-MM-YYYY'}}</td>
                                                <td>{{version.end_date | formatTs:'DD-MM-YYYY'}}</td>
                                                <td>{{version.facial_price_decrease | booleanDisplay}}</td>
                                                <td>{{getDateLength(version.start_date, version.end_date)}}</td>
                                                <td>{{version.lnk_contract_uuid ? (getAmendmentType(version.contract_type) | display) : ('contractdetail_initial_version' | translate)}}</td>
                                            </tr>
                                        </ng-container>
                                    </table>
                                </div>
                            </div>
                            <div class="row display-row">
                                <div class="col-6">
                                    <div class="display-content">                            
                                        <div class="display-label">{{'contract_totalduration' | translate}}</div>
                                        <div class="display-value d-flex flex-row">
                                            <span>{{getDateLengthSum()}}</span>
                                        </div>                     
                                    </div>
                                </div>
                            </div>
                            <div class="row display-row">
                                <div class="col-4">
                                    <div class="display-content">                            
                                        <div class="display-label">{{'contract_college_orphan_drugs' | translate}}</div>
                                        <div class="display-value d-flex flex-row">
                                            <span>{{contract.college_orphan_drugs | resource : 'college_orphan_drugs'}}</span>
                                        </div>                     
                                    </div>
                                </div>
                            </div>
                            <ng-container *ngIf="!contract.lnk_contract_uuid">  
                                <!-- <div class="row display-row">
                                    <div class="col-12">
                                        <div class="display-content">                            
                                            <div class="display-label">{{'contract_loss_of_exclusivity_date' | translate}}</div>
                                            <div class="display-value">{{contract.loss_of_exclusivity_date | formatTs : 'DD-MM-YYYY'}}</div>                     
                                        </div>
                                    </div>
                                </div> -->
                                <!-- <div class="row display-row">
                                    <div class="col-4">
                                        <div class="display-content">                            
                                            <div class="display-label">{{'contract_registry' | translate}}</div>
                                            <div class="display-value d-flex flex-row">
                                                <span>{{contract.registry | booleanDisplay}}</span>
                                            </div>                     
                                        </div>
                                    </div>
                                </div> -->
                            </ng-container>
                            <!-- start allow on amendment -->
                            <div class="row display-row">
                                <div class="col-4">
                                    <div class="display-content">                            
                                        <div class="display-label">{{'contract_convention_type' | translate}}</div>
                                        <div class="display-value d-flex flex-row">
                                            <ng-container *ngIf="contract.convention_type && contract.convention_type.length > 0">
                                                <ul class="app-list" [class.app-list--single]="contract.convention_type.length == 1">
                                                    <li *ngFor="let conv_type of contract.convention_type">
                                                        {{resourceService.getResourceTitleByTypeAndKey('convention_type', conv_type) | display}}
                                                    </li>
                                                </ul>
                                            </ng-container>
                                            <ng-container *ngIf="!contract.convention_type || contract.convention_type.length == 0">
                                                <span>-</span>
                                            </ng-container>
                                        </div>                
                                    </div>
                                </div>
                            </div>
                            <div class="row display-row">
                                <div class="col-8">
                                    <div class="display-content">                            
                                        <div class="display-label">{{'contract_rational_modalities' | translate}}</div>
                                        <div class="display-value d-flex flex-row">
                                            <app-field-richtext
                                                [model]="contract.rational_modalities"
                                                [readOnly] = "true"
                                            >
                                            </app-field-richtext>
                                        </div>                     
                                    </div>
                                </div>
                            </div>
                            <div class="row display-row">
                                <div class="col-8">
                                    <div class="display-content">                            
                                        <div class="display-label">{{'contract_financial_modalities' | translate}}</div>
                                        <div class="display-value d-flex flex-row">
                                            <app-field-richtext
                                                [model]="contract.financial_modalities"
                                                [readOnly] = "true"
                                            >
                                            </app-field-richtext>
                                        </div>                     
                                    </div>
                                </div>
                            </div>
                            <div class="row display-row">
                                <div class="col-4">
                                    <div class="display-content">                            
                                        <div class="display-label">{{'contract_type_of_art_15' | translate}}</div>
                                        <div class="display-value d-flex flex-column">
                                            <span>{{resourceService.getResourceTitleByTypeAndKey('type_of_art_15', contract.type_of_art_15) | display}}</span>
                                            <div class="freetext-comment" *ngIf="contract.type_of_art_15 == 'other' && contract.art_15_other"[innerHTML]="contract.art_15_other"></div>
                                        </div>                     
                                    </div>
                                </div>
                            </div>
                            <!-- end allow on amendment -->
                            <ng-container *ngIf="!contract.lnk_contract_uuid">
                                <!-- <div class="row display-row">
                                    <div class="col-6">
                                        <div class="display-content">                            
                                            <div class="display-label">{{'contract_questions' | translate}}</div>
                                            <div class="display-value d-flex flex-row">
                                                <app-field-richtext
                                                    [model]="contract.contract_questions"
                                                    [readOnly] = "true"
                                                ></app-field-richtext>
                                            </div>                     
                                        </div>
                                    </div>
                                </div> -->
                                <!-- extra fields PHARMA-1517 -->
                                <!-- <div class="row display-row">
                                    <div class="col-12">
                                        <div class="display-content">
                                            <div class="display-label">{{'contractdetail_prolongation' | translate}}</div>
                                            <div class="display-value d-flex flex-row">
                                                <span>{{contract.prolongation | booleanDisplay}}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row display-row">
                                    <div class="col-6">
                                        <div class="display-content">
                                            <div class="display-label">{{'contractdetail_transition_year' | translate}}</div>
                                            <div class="display-value d-flex flex-row">
                                                <span>{{contract.transition_year | booleanDisplay}}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div> -->
                            </ng-container>
                            <ng-container>
                                <div class="row display-row">
                                    <div class="col-4">
                                        <div class="display-content">
                                            <div class="display-label">{{'contractdetail_class_budget' | translate}}</div>
                                            <div class="display-value d-flex flex-row">
                                                <span>{{contract.class_budget | booleanDisplay}}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-4">
                                        <div class="display-content">
                                            <div class="display-label">{{'contractdetail_umbrella_contract' | translate}}</div>
                                            <div class="display-value d-flex flex-row">
                                                <span>{{resourceService.getResourceTitleByTypeAndKey('umbrella_contract', contract.umbrella_contract) | display}}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row display-row">
                                    <div class="col-6">
                                        <div class="display-content">
                                            <div class="display-label">{{'contractdetail_comment' | translate}}</div>
                                            <div class="display-value d-flex flex-row">
                                                <app-field-richtext
                                                    [model]="contract.comment"
                                                    [readOnly] = "true"
                                                ></app-field-richtext>
                                            </div>                     
                                        </div>
                                    </div>
                                </div>
                            </ng-container>
                            <ng-container *ngIf="!contract.lnk_contract_uuid">
                                <!-- <div class="row display-row">
                                    <div class="col-3">
                                        <div class="display-content">
                                            <div class="display-label">{{'contractdetail_post_contract' | translate}}</div>
                                            <div class="display-value d-flex flex-row">
                                                <span>{{resourceService.getResourceTitleByTypeAndKey('post_contract', contract.post_contract) | display}}</span>
                                            </div>   
                                        </div>
                                    </div>
                                    <div class="col-9">
                                        <div class="display-content">
                                            <div class="display-label">{{'contractdetail_facial_price_decrease_post_contract' | translate}}</div>
                                            <div class="display-value d-flex flex-row">
                                                <span>{{contract.facial_price_decrease_post_contract | customPercentage}}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div> -->
                                <!-- <div class="row display-row">
                                    <div class="col-6">
                                        <div class="display-content">
                                            <div class="display-label">{{'contractdetail_post_contract_comment' | translate}}</div>
                                            <div class="display-value d-flex flex-row">
                                                <app-field-richtext
                                                    [model]="contract.post_contract_comment"
                                                    [readOnly] = "true"
                                                ></app-field-richtext>
                                            </div>                     
                                        </div>
                                    </div>
                                </div> -->
                            </ng-container>
                            <!-- end PHARMA-1517 -->
                        </ng-container>
                        <!-- create | edit -->
                        <ng-container *ngIf="mode == 'create' || mode == 'edit'">
                            <div class="row display-row" *ngIf="!editAmendment">
                                <div class="{{!id ? 'col-4' : 'col-6'}}">
                                    <div class="d-flex align-items-end">
                                        <app-field-radios 
                                            class="d-block"
                                            label="{{'contract_type' | translate}}"
                                            [canUncheck]="true"
                                            [model]="formSTR.contract_type"
                                            [error]="validation.contract_type"
                                            (modelChange)="formSTR.contract_type = $event; formChange(); formSTR.contract_type != 'renewal' ? formSTR.lnk_renewal_contract_uuid = null : ''"
                                            [options]="contractTypeOptions"
                                            [optionWidth]="120">
                                        </app-field-radios>
                                        <app-field-contract 
                                            style="display: block; max-width: 340px; margin-left: 12px; width: 100%;"
                                            *ngIf="formSTR.contract_type == 'renewal'"
                                            [model]="typeaheadModels.renewal_contract" 
                                            (modelChange)="formSTR.lnk_renewal_contract_uuid = $event; formChange()"
                                            [error]="validation.lnk_renewal_contract_uuid"
                                            placeholder="{{'contractdetail_contract_placeholder' | translate}}">
                                        </app-field-contract>
                                    </div>
                                </div>
                                <div class="col-6">
                                    <div class="d-flex align-items-end">
                                        <app-field-radios 
                                            class="d-block"
                                            label="{{'contractdetail_procedure_article' | translate}}"
                                            [canUncheck]="true"
                                            [model]="formSTR.procedure_article"
                                            [error]="validation.procedure_article"
                                            (modelChange)="formSTR.procedure_article = $event; formChange();"
                                            [options]="procedureArticleOptions"
                                            [optionWidth]="120">
                                        </app-field-radios>
                                    </div>
                                </div>
                            </div>
                            <div class="row display-row" *ngIf="!editAmendment">
                                <div class="col-4">
                                    <app-field-contracts 
                                        [model]="formSTR.related_contracts" 
                                        (modelChange)="formSTR.related_contracts = $event; formChange();"
                                        label="{{'contractdetail_contracts' | translate}}"
                                        [error]="validation.related_contracts"
                                        placeholder="{{'contractdetail_contracts_placeholder' | translate}}">
                                    </app-field-contracts>
                                </div>
                            </div>
                            <div class="row display-row">
                                <ng-container *ngIf="mode == 'edit' && !editAmendment">
                                    <div class="col-2">
                                        <div class="display-content">                            
                                            <div class="display-label">{{'contract_number' | translate}}</div>
                                            <div class="display-value d-flex flex-row">
                                                <span>{{formSTR.number | display}}</span>
                                            </div>                     
                                        </div>
                                    </div>
                                </ng-container>
                                <div class="col-4">
                                    <app-field-text 
                                        [model]="formSTR.name" 
                                        [error]="validation.name"
                                        (modelChange)="formSTR.name = $event; formChange();" 
                                        label="{{(editAmendment ? 'contractdetail_amendment_name' : 'initial_contract_name') | translate}}"
                                    ></app-field-text>
                                </div>
                                <div class="col-3" *ngIf="editAmendment">
                                    <app-field-select
                                        label="{{'contractdetail_amendment_type' | translate}}" 
                                        [model]="formSTR.contract_type" 
                                        [options]="options.amendment_type"
                                        [error]="validation.contract_type"
                                        (modelChange)="formSTR.contract_type = $event; formChange();" 
                                        placeholder="{{'misc_select' | translate}}">
                                    </app-field-select>
                                </div>
                                <div class="col-3 d-flex align-items-end" *ngIf="editAmendment == 'new'">
                                    <div (click)="formSTR.copy_financial_scheme = !formSTR.copy_financial_scheme" [class.active]="formSTR.copy_financial_scheme" class="field-checkbox-wrap">
                                        <span class="fake-checkbox"></span> <span>{{'contractdetail_copy_fin_scheme' | translate}}</span>
                                    </div>
                                </div>
                                <!-- <div class="col-2" *ngIf="!editAmendment && !id">
                                </div> -->
                                <div class="col-4" *ngIf="!editAmendment">
                                    <app-field-text 
                                        [model]="formSTR.alias" 
                                        [error]="validation.alias"
                                        (modelChange)="formSTR.alias = $event; formChange();" 
                                        [label]="'contract_alias' | translate"
                                    ></app-field-text>
                                </div>
                            </div>
                            <div class="row display-row" *ngIf="editAmendment">
                                <div class="col-4">
                                    <app-field-contracts 
                                        [model]="formSTR.related_contracts" 
                                        (modelChange)="formSTR.related_contracts = $event; formChange();"
                                        label="{{'contractdetail_contracts' | translate}}"
                                        [error]="validation.related_contracts"
                                        placeholder="{{'contractdetail_contracts_placeholder' | translate}}">
                                    </app-field-contracts>
                                </div>
                            </div>
                            <div class="row display-row" *ngIf="editAmendment">
                                <div class="col-12">
                                    <app-field-radios 
                                        class="d-block"
                                        label="{{'contractdetail_procedure_article' | translate}}"
                                        [canUncheck]="true"
                                        [model]="formSTR.procedure_article"
                                        [error]="validation.procedure_article"
                                        (modelChange)="formSTR.procedure_article = $event; formChange();"
                                        [options]="procedureArticleAmendmentOptions"
                                        [optionWidth]="120">
                                    </app-field-radios>
                                </div>
                            </div>
                            <div class="row display-row" *ngIf="editAmendment">
                                <div class="col-8">
                                    <app-field-richtext 
                                        [model]="formSTR.key_changes" 
                                        [error]="validation.key_changes"
                                        (modelChange)="formSTR.key_changes = $event; formChange();" 
                                        [label]="'key_changes' | translate"
                                    ></app-field-richtext>
                                </div>
                            </div>
                            <div class="row display-row">
                                <div class="col-4">
                                    <app-field-select
                                        label="{{'contract_stage' | translate}}" 
                                        [model]="formSTR.stage" 
                                        [options]="stageOptions"
                                        [error]="validation.stage"
                                        (modelChange)="formSTR.stage = $event; formChange();" 
                                        placeholder="{{'misc_select' | translate}}">
                                    </app-field-select>
                                </div>
                                <div class="col-4" *ngIf="!editAmendment && ['contract_negotiation_failed', 'no_agreement', 'contract_negotiation_refused', 'contract_negotiation_initiated'].includes(formSTR.stage)">
                                    <app-field-text 
                                        style="max-width: 220px; display: block;"
                                        [model]="formSTR.year_of_implementation_or_failure" 
                                        [error]="validation.year_of_implementation_or_failure"
                                        (modelChange)="formSTR.year_of_implementation_or_failure = $event; formChange();" 
                                        [label]="'contractdetail_year_of_implementation_or_failure' | translate"
                                        [type]="'integer'"
                                    ></app-field-text>
                                </div>
                            </div>
                            <div class="row display-row">
                                <div class="col-12">
                                    <app-contract-dossiers
                                        [model]="formSTR.dossiers"
                                        [error]="validation.dossiers"
                                        (modelChange)="formSTR.dossiers = $event; setCompany(formSTR); setContractAdvisor(formSTR); setIndications(formSTR); formChange();" 
                                    ></app-contract-dossiers>
                                </div>
                            </div>
                            <div class="row display-row">
                                <div class="col-12">
                                    <app-contract-products
                                        [model]="formSTR.products"
                                        [error]="validation.products"
                                        (modelChange)="formSTR.products = $event; formChange();" 
                                    ></app-contract-products>
                                </div>
                            </div>
                            <div class="row display-row" *ngIf="!editAmendment">
                                <div class="col-4">
                                    <app-field-select
                                        label="{{'contract_advisor' | translate}}" 
                                        [model]="formSTR.lnk_advisor_id" 
                                        [options]="advisorOptions"
                                        [error]="validation.lnk_advisor_id"
                                        (modelChange)="formSTR.lnk_advisor_id = $event; formChange();" 
                                        placeholder="{{'contract_advisor_placeholder' | translate}}">
                                    </app-field-select>
                                </div>
                            </div>
                            <div class="row display-row" *ngIf="!editAmendment">
                                <div class="col-4">
                                    <div class="display-content">                            
                                        <div class="display-label">{{'contact_company' | translate}}</div>
                                        <div class="display-value d-flex flex-row">
                                            <span>{{formSTR.company_name | display}}</span>
                                            <ng-container *ngIf="formSTR.company_name">
                                                <div class="pl-xxs" *ngIf="formSTR.lnk_company_id">
                                                    <span class="stamp mr-xxs stamp--gray">{{'pricedossier_table_member' | translate}} <ng-container *ngIf="!contract.company_status">({{'misc_inactive' | translate}})</ng-container></span>
                                                </div>
                                            </ng-container>
                                        </div>                     
                                    </div>
                                </div>
                            </div>
                            <div class="row display-row">
                                <div class="col-4">
                                    <app-field-indications 
                                        [model]="formSTR.indications" 
                                        (modelChange)="formSTR.indications = $event; formChange()"
                                        optionalInputPlaceholder="{{'contract_indication_comment_placeholder' | translate}}"
                                        label="{{'contract_indication' | translate}}"
                                        [error]="validation.indications"
                                        placeholder="{{'contract_indication_placeholder' | translate}}">
                                    </app-field-indications>
                                </div>
                            </div>
                            <div class="row display-row">
                                <div class="col-8">
                                    <table class="table--detail">
                                        <tr>
                                            <th>{{'contract_start_date' | translate}}</th>
                                            <th>{{'contract_end_date' | translate}}</th>
                                            <th>{{'contract_facial_price_decrease' | translate}}</th>
                                            <th>{{'contract_date_length' | translate}}</th>
                                            <th>{{'contract_date_type' | translate}}</th>
                                        </tr>
                                        
                                        <ng-container *ngFor="let version of contract?.versions; index as $i">
                                            <tr *ngIf="version.id != formSTR.id">
                                                <td>{{version.start_date | formatTs:'DD-MM-YYYY'}}</td>
                                                <td>{{version.end_date | formatTs:'DD-MM-YYYY'}}</td>
                                                <td>{{version.facial_price_decrease | booleanDisplay}}</td>
                                                <td>{{getDateLength(version.start_date, version.end_date)}}</td>
                                                <td>{{version.lnk_contract_uuid ? (getAmendmentType(version.contract_type) | display) : ('contractdetail_initial_version' | translate)}}</td>
                                            </tr>
                                            <tr *ngIf="version.id == formSTR.id || (editAmendment == 'new' && $i == contract.versions.length - 1)">
                                                <td>
                                                    <app-field-date 
                                                        id="start_date"
                                                        [model]="formSTR.start_date" 
                                                        (modelChange)="formSTR.start_date = $event; setDateLength(formSTR); formChange();" 
                                                        placeholder="dd-mm-yyyy"
                                                        [error]="validation.start_date"
                                                        positionDateSelector="top"
                                                    ></app-field-date>
                                                </td>
                                                <td>
                                                    <app-field-date 
                                                        id="end_date"
                                                        [model]="formSTR.end_date" 
                                                        (modelChange)="formSTR.end_date = $event; setDateLength(formSTR); formChange();" 
                                                        placeholder="dd-mm-yyyy"
                                                        [error]="validation.end_date"
                                                        positionDateSelector="top"
                                                    ></app-field-date>
                                                </td>
                                                <td class="facial-price-decrease">
                                                <app-field-radios 
                                                    class="d-block"
                                                    label=""
                                                    [model]="formSTR.facial_price_decrease" 
                                                    [error]="validation.facial_price_decrease"
                                                    (modelChange)="formSTR.facial_price_decrease = $event; formChange();"
                                                    [options]="yesNoOptions"
                                                    canUncheck = true
                                                    [optionWidth]="120">
                                                </app-field-radios>
                                            </td>
                                                <td>{{formSTR.date_length | display}}</td>
                                                <td>{{editAmendment ? (getAmendmentType(formSTR.contract_type) | display) : ('contractdetail_initial_version' | translate)}}</td>
                                            </tr>
                                        </ng-container>
                                        <tr *ngIf="!contract?.versions?.length">
                                            <td>
                                                <app-field-date 
                                                    id="start_date"
                                                    [model]="formSTR.start_date" 
                                                    (modelChange)="formSTR.start_date = $event; setDateLength(formSTR); formChange();" 
                                                    placeholder="dd-mm-yyyy"
                                                    [error]="validation.start_date"
                                                    positionDateSelector="top"
                                                ></app-field-date>
                                            </td>
                                            <td>
                                                <app-field-date 
                                                    id="end_date"
                                                    [model]="formSTR.end_date" 
                                                    (modelChange)="formSTR.end_date = $event; setDateLength(formSTR); formChange();" 
                                                    placeholder="dd-mm-yyyy"
                                                    [error]="validation.end_date"
                                                    positionDateSelector="top"
                                                ></app-field-date>
                                            </td>
                                            <td>
                                                <app-field-radios 
                                                    class="d-block"
                                                    label=""
                                                    [model]="formSTR.facial_price_decrease" 
                                                    [error]="validation.facial_price_decrease"
                                                    (modelChange)="formSTR.facial_price_decrease = $event; formChange();"
                                                    [options]="yesNoOptions"
                                                    canUncheck = true
                                                    [optionWidth]="120">
                                                </app-field-radios>
                                            </td>
                                            <td>{{formSTR.date_length | display}}</td>
                                            <td>{{editAmendment ? (getAmendmentType(formSTR.contract_type) | display) : ('contractdetail_initial_version' | translate)}}</td>
                                        </tr>
                                    </table>
                                </div>
                            </div>
                            <div class="row display-row">
                                <div class="col-12">
                                    <app-field-radios 
                                        class="d-block"
                                        label="{{'contract_college_orphan_drugs' | translate}}"
                                        [model]="formSTR.college_orphan_drugs"
                                        [error]="validation.college_orphan_drugs"
                                        (modelChange)="formSTR.college_orphan_drugs = $event; formChange();"
                                        [options]="collegeOrphanDrugsOptions"
                                        canUncheck = true
                                        [optionWidth]="120">
                                    </app-field-radios>
                                </div>
                            </div>
                            <ng-container *ngIf="!editAmendment">
                                <!-- <div class="row display-row">
                                    <div class="col-12">
                                        <app-field-date 
                                            [model]="formSTR.loss_of_exclusivity_date" 
                                            (modelChange)="formSTR.loss_of_exclusivity_date = $event; formChange();" 
                                            [label]="'contract_loss_of_exclusivity_date' | translate"
                                            placeholder="dd-mm-yyyy"
                                            [error]="validation.loss_of_exclusivity_date"
                                            positionDateSelector="bottom">
                                        </app-field-date>
                                    </div>
                                </div> -->
                                <!-- <div class="row display-row">
                                    <div class="col-12">
                                        <app-field-radios 
                                            class="d-block"
                                            label="{{'contract_registry' | translate}}"
                                            [model]="formSTR.registry"
                                            [error]="validation.registry"
                                            (modelChange)="formSTR.registry = $event; formChange();"
                                            [options]="registryOptions"
                                            canUncheck = true
                                            [optionWidth]="120">
                                        </app-field-radios>
                                    </div>
                                </div> -->
                            </ng-container>
                            <!-- start allow on amendment -->
                            <div class="row display-row">
                                <div class="col-12">
                                    <app-field-checkboxes 
                                        class="d-block"
                                        label="{{'contract_convention_type' | translate}}"
                                        [error]="validation.convention_type"
                                        [model]="formSTR.convention_type" 
                                        (modelChange)="formSTR.convention_type = $event; formChange();"
                                        [options]="conventionTypeOptions"
                                        [optionWidth]="120">
                                    </app-field-checkboxes>
                                </div>
                            </div>
                            <div class="row display-row">
                                <div class="col-8">
                                    <app-field-richtext 
                                        [model]="formSTR.rational_modalities" 
                                        [error]="validation.rational_modalities"
                                        (modelChange)="formSTR.rational_modalities = $event; formChange();" 
                                        (changed) = "formChange();"
                                        label="{{'contract_rational_modalities' | translate}}"
                                    ></app-field-richtext>
                                </div>
                            </div>
                            <div class="row display-row">
                                <div class="col-8">
                                    <app-field-richtext 
                                        [model]="formSTR.financial_modalities" 
                                        [error]="validation.financial_modalities"
                                        (modelChange)="formSTR.financial_modalities = $event; formChange();" 
                                        (changed) = "formChange();"
                                        label="{{'contract_financial_modalities' | translate}}"
                                    ></app-field-richtext>
                                </div>
                            </div>
                            <div class="row display-row">
                                <div class="col-4">
                                    <app-field-select 
                                        [model]="formSTR.type_of_art_15" 
                                        [options]="typeOfArt15Options"
                                        [error]="validation.type_of_art_15"
                                        (modelChange)="formSTR.type_of_art_15 = $event; formChange(); formSTR.type_of_art_15 != 'other' ? formSTR.art_15_other = null : '';" 
                                        label="{{'contract_type_of_art_15' | translate}}"
                                        placeholder="{{'contract_type_of_art_15_placeholder' | translate}}">
                                    </app-field-select>
                                    <div
                                        *ngIf="formSTR.type_of_art_15 == 'other'"
                                        class="field-freetext" 
                                        contenteditable="true" 
                                        (blur)="formSTR.art_15_other = $event.target.innerHTML;" 
                                        [innerHtml]="formSTR.art_15_other" 
                                        [attr.data-placeholder]="'contractdetail_art_15_other_placeholder' | translate">
                                    </div>
                                </div>
                            </div>
                            <!-- end allow on amendment -->
                            <ng-container *ngIf="!editAmendment">
                                <!-- <div class="row display-row">
                                    <div class="col-6">
                                        <app-field-richtext 
                                            [model]="formSTR.contract_questions" 
                                            [error]="validation.contract_questions"
                                            (modelChange)="formSTR.contract_questions = $event; formChange();" 
                                            (changed) = "formChange();"
                                            label="{{'contract_questions' | translate}}"
                                        ></app-field-richtext>
                                    </div>
                                </div> -->
                                <!-- extra fields PHARMA-1517 -->
                                <!-- <div class="row display-row">
                                    <div class="col-12">
                                        <app-field-radios 
                                            class="d-block"
                                            label="{{'contractdetail_prolongation' | translate}}"
                                            [model]="formSTR.prolongation" 
                                            [error]="validation.prolongation"
                                            (modelChange)="formSTR.prolongation = $event; formChange();"
                                            [options]="yesNoOptions"
                                            [canUncheck]="true"
                                            [optionWidth]="120">
                                        </app-field-radios>
                                    </div>
                                </div> -->
                                <!-- <div class="row display-row">
                                    <div class="col-6">
                                        <app-field-radios 
                                            class="d-block"
                                            label="{{'contractdetail_transition_year' | translate}}"
                                            [model]="formSTR.transition_year" 
                                            [error]="validation.transition_year"
                                            (modelChange)="formSTR.transition_year = $event; formChange();"
                                            [options]="yesNoOptions"
                                            [canUncheck]="true"
                                            [optionWidth]="120">
                                        </app-field-radios>
                                    </div>
                                </div> -->
                            </ng-container>
                            <ng-container>
                                <div class="row display-row">
                                    <div class="col-4">
                                        <app-field-radios 
                                            class="d-block"
                                            label="{{'contractdetail_class_budget' | translate}}"
                                            [model]="formSTR.class_budget" 
                                            [error]="validation.class_budget"
                                            (modelChange)="formSTR.class_budget = $event; formChange();"
                                            [options]="yesNoOptions"
                                            [canUncheck]="true"
                                            [optionWidth]="120">
                                        </app-field-radios>
                                    </div>
                                    <div class="col-4">
                                        <app-field-select
                                            label="{{'contractdetail_umbrella_contract' | translate}}" 
                                            [model]="formSTR.umbrella_contract" 
                                            [options]="options.umbrella_contract"
                                            [error]="validation.umbrella_contract"
                                            (modelChange)="formSTR.umbrella_contract = $event; formChange(); UmbrellaContractChange($event);" 
                                            [placeholder]="'contractdetail_umbrella_contract_placeholder' | translate"
                                        ></app-field-select>
                                    </div>
                                </div>
                                <div class="row display-row">
                                    <div class="col-8">
                                        <app-field-richtext 
                                            [model]="formSTR.comment" 
                                            [error]="validation.comment"
                                            (modelChange)="formSTR.comment = $event; formChange();" 
                                            (changed)="formChange();"
                                            label="{{'contractdetail_comment' | translate}}"
                                        ></app-field-richtext>
                                    </div>
                                </div>
                            </ng-container>
                            <ng-container *ngIf="!editAmendment">
                                <!-- <div class="row display-row">
                                    <div class="col-3">
                                        <app-field-select
                                            label="{{'contractdetail_post_contract' | translate}}" 
                                            [model]="formSTR.post_contract" 
                                            [options]="options.post_contract"
                                            [error]="validation.post_contract"
                                            (modelChange)="formSTR.post_contract = $event; formChange();" 
                                            [placeholder]="'contractdetail_post_contract_placeholder' | translate"
                                        ></app-field-select>
                                    </div>
                                    <div class="col-9">
                                        <app-field-text 
                                            type="float"
                                            inputWidth="150px"
                                            afterString="%"
                                            [model]="formSTR.facial_price_decrease_post_contract" 
                                            [error]="validation.facial_price_decrease_post_contract"
                                            (modelChange)="formSTR.facial_price_decrease_post_contract = $event; formChange();" 
                                            [label]="'contractdetail_facial_price_decrease_post_contract' | translate"
                                        ></app-field-text>
                                    </div>
                                </div> -->
                                <!-- <div class="row display-row">
                                    <div class="col-4">
                                        <app-field-text 
                                            type="float"
                                            inputWidth="150px"
                                            afterString="%"
                                            [model]="formSTR.facial_price_decrease_post_contract" 
                                            [error]="validation.facial_price_decrease_post_contract"
                                            (modelChange)="formSTR.facial_price_decrease_post_contract = $event; formChange();" 
                                            [label]="'contractdetail_facial_price_decrease_post_contract' | translate"
                                        ></app-field-text>
                                    </div>
                                </div> -->
                                <!-- <div class="row display-row">
                                    <div class="col-6">
                                        <app-field-richtext 
                                            [model]="formSTR.post_contract_comment" 
                                            [error]="validation.post_contract_comment"
                                            (modelChange)="formSTR.post_contract_comment = $event; formChange();" 
                                            (changed)="formChange();"
                                            label="{{'contractdetail_post_contract_comment' | translate}}"
                                        ></app-field-richtext>
                                    </div>
                                </div> -->
                            </ng-container>
                            <!-- end PHARMA-1517 -->
                        </ng-container>
                    </ng-container>

                    <!-- Financial -->
                    <ng-container *ngIf="activePage == 'financial'">
                        <!-- overview -->
                        <ng-container *ngIf="mode == 'show'">
                            <table *ngIf="contract?.financial_schemes?.length" class="table--detail table--detail--clickable">
                                <tr>
                                    <th width="25%">{{'contractdetail_th_name' | translate}}</th>
                                    <th width="25%">{{'contractdetail_th_type' | translate}}</th>
                                    <th width="25%">{{'contractdetail_th_edit_user' | translate}}</th>
                                    <th width="25%">{{'contractdetail_th_edit_ts' | translate}}</th>
                                </tr>
                                <ng-container *ngFor="let scheme of contract.financial_schemes">
                                    <tr (click)="initCreateUpdateFinancialScheme(scheme)">
                                        <td>{{scheme.name | display}}</td>
                                        <td>{{scheme.displayType | display}}</td>
                                        <td>{{scheme.edit_username | display}}</td>
                                        <td>{{scheme.edit_ts | formatTs:'prettyDateTime'}}</td>
                                    </tr>
                                </ng-container>
                            </table>
                            <div *ngIf="!contract?.financial_schemes?.length" class="emptystate-wrap">
                                <app-empty-state value="{{'contractdetailfinancial_emptystate' | translate}}"></app-empty-state>
                            </div>
                        </ng-container>
                        <!-- create update -->
                        <ng-container *ngIf="mode == 'createUpdate'">
                            <div class="row display-row">
                                <div class="col-3">
                                    <app-field-text 
                                        [model]="financialSchemeFormSTR.name" 
                                        [error]="validation.name"
                                        (modelChange)="financialSchemeFormSTR.name = $event; formChange();" 
                                        [label]="'contractdetailfinancial_name' | translate"
                                    ></app-field-text>
                                </div>
                                <div class="col-3">
                                    <app-field-select
                                        label="{{'contractdetailfinancial_type' | translate}}" 
                                        [model]="financialSchemeFormSTR.type" 
                                        [options]="options.financial_scheme_type"
                                        [error]="validation.type"
                                        (modelChange)="financialSchemeFormSTR.type = $event; formChange();" 
                                        [placeholder]="'contractdetailfinancial_type_placeholder' | translate"
                                    ></app-field-select>
                                </div>
                            </div>
                            <div class="row display-row">
                                <div class="col-6">
                                    <app-field-contract 
                                        style="display: block; max-width: 340px; width: 100%;"
                                        [model]="financialSchemeFormSTR.lnkContractTypeaheadModel" 
                                        [initialContracts]="true"
                                        (modelChange)="financialSchemeFormSTR.lnk_linked_contract_uuid = $event; formChange()"
                                        [error]="validation.lnk_linked_contract_uuid"
                                        label="{{'contractdetail_linked_contract' | translate}}"
                                        placeholder="{{'contractdetail_linked_contract_placeholder' | translate}}">
                                    </app-field-contract>
                                </div>
                            </div>
                            <div class="row display-row">
                                <div class="col-6">
                                    <app-field-richtext 
                                        [model]="financialSchemeFormSTR.comments" 
                                        [error]="validation.comments"
                                        (modelChange)="financialSchemeFormSTR.comments = $event; formChange();" 
                                        (changed)="formChange();"
                                        label="{{'contractdetailfinancial_comments' | translate}}"
                                    ></app-field-richtext>
                                </div>
                            </div>
                            <ng-container *ngIf="financialSchemeFormSTR.type == 'pvc'">
                                <div class="form-sep"></div>
                                <div class="row display-row">
                                    <div class="col-md-12">
                                        <ng-container *ngFor="let period of financialSchemeFormSTR.periods; index as $i">
                                            <!-- period -->
                                            <div class="period-section">
                                                <h2><span>{{'contractdetail_th_period' | translate}} {{$i + 1}}</span> <i (click)="deletePeriod()" class="im-icon im-icon-x-light delete-icon" *ngIf="$i == financialSchemeFormSTR.periods.length - 1"></i></h2>
                                                <!-- <h3>{{period.start_date | formatTs: 'DD MMM YYYY' | lowercase}} - {{period.end_date | formatTs: 'DD MMM YYYY' | lowercase}}</h3> -->
                                                <div class="row display-row mw-1200-px">
                                                    <div class="col-md-12">
                                                        <div class="d-flex align-items-center">
                                                            <app-field-date 
                                                                [model]="period.start_date" 
                                                                (modelChange)="period.start_date = $event; formChange();" 
                                                                placeholder="dd-mm-yyyy"
                                                                [error]="false"
                                                                positionDateSelector="top"
                                                            ></app-field-date>
                                                            <span class="ml-xxs mr-xxs">-</span>
                                                            <app-field-date 
                                                                [model]="period.end_date" 
                                                                (modelChange)="period.end_date = $event; formChange();" 
                                                                placeholder="dd-mm-yyyy"
                                                                [error]="false"
                                                                positionDateSelector="top"
                                                            ></app-field-date>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="row display-row mw-1200-px">
                                                    <!-- estimated sales -->
                                                    <div class="col-4">
                                                        <app-field-text [label]="'contractdetailfinancial_td_estimated_sales' | translate" [model]="period.estimated_sales" [error]="false && getPeriodValidation($i, 'estimated_sales')" (modelChange)="period.estimated_sales = $event; formChange();" type="integer" showMaskTyped="false"
                                                        mask="separator" thousandSeparator="."></app-field-text>
                                                    </div>
                                                    <!-- caps -->
                                                    <div class="col-4">
                                                        <app-field-text [label]="'contractdetailfinancial_td_estimated_caps' | translate" [model]="period.caps" [error]="false && getPeriodValidation($i, 'caps')" (modelChange)="period.caps = $event; formChange();" type="integer" showMaskTyped="false"
                                                        mask="separator" thousandSeparator="."></app-field-text>
                                                    </div>
                                                    <!-- expected average refund -->
                                                    <div class="col-4">
                                                        <app-field-text [label]="'contractdetailfinancial_td_expected_average_refund' | translate" [model]="period.expected_average_refund" [error]="false && getPeriodValidation($i, 'expected_average_refund')" (modelChange)="period.expected_average_refund = $event; formChange();" type="integer" showMaskTyped="false" mask="separator" thousandSeparator="."></app-field-text>
                                                    </div>
                                                </div>
                                                <div class="row display-row">
                                                    <div class="col-6">
                                                        <app-field-richtext 
                                                            [model]="period.comment"
                                                            (modelChange)="period.comment = $event; formChange();" 
                                                            (changed)="formChange();"
                                                            label="{{'contractdetailfinancial_td_comment' | translate}}"
                                                        ></app-field-richtext>
                                                    </div>
                                                    <div class="col-6" *ngIf="contract.class_budget">
                                                        <app-field-richtext 
                                                            [model]="period.refund_mechanism_umbrella"
                                                            (modelChange)="period.refund_mechanism_umbrella = $event; formChange();" 
                                                            (changed)="formChange();"
                                                            label="{{'contractdetailfinancial_td_refund_mechanism_umbrella' | translate}}"
                                                        ></app-field-richtext>
                                                    </div>
                                                </div> 
                                                <!-- payment dates -->
                                                <ng-container *ngFor="let paymentDate of period.payment_dates; index as $j">
                                                    <div class="row display-row mw-1200-px">
                                                        <div class="col-5">
                                                            <div class="d-flex align-items-end position-relative payment-date-wrap">
                                                                <app-field-select
                                                                    label="{{'contractdetailfinancial_td_payment_date' | translate}} {{$j + 1}}"
                                                                    [model]="period.payment_dates[$j].type" 
                                                                    [options]="options.financial_scheme_payment_date"
                                                                    [error]="false && getPaymentDateValidation($i, $j, 'type')"
                                                                    (modelChange)="period.payment_dates[$j].type = $event; formChange();" 
                                                                    style="margin-right: 12px;"
                                                                    class="flex-grow-1"
                                                                ></app-field-select>
                                                                <app-field-date 
                                                                    positionDateSelector="top"
                                                                    [model]="period.payment_dates[$j].date" 
                                                                    (modelChange)="period.payment_dates[$j].date = $event; formChange();" 
                                                                    placeholder="dd-mm-yyyy"
                                                                    [error]="false && getPaymentDateValidation($i, $j, 'date')"
                                                                ></app-field-date>
                                                                <i (click)="deletePaymentDate($i, $j)" class="im-icon im-icon-x-light delete-icon"></i>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </ng-container>
                                                <!-- add payment date -->
                                                <div class="row display-row mw-1200-px add-payment-date">
                                                    <div class="col-12">
                                                        <button class="button-link h-auto" (click)="addPaymentDate($i)">+<span class="underline" style="margin-left: 4px;">{{'contractdetailfinancial_btn_add_payment_date' | translate}}</span></button>
                                                    </div>
                                                </div>
                                                <!-- refunds -->
                                                <div class="refund-section" [class.collapsed]="period.collapsed">
                                                    <div class="d-flex justify-content-between title-wrap" (click)="period.collapsed = !period.collapsed">
                                                        <h4>{{'contractdetailfinancial_th_refund_title' | translate}} {{$i + 1}}</h4>
                                                        <i class="im-icon" [class.im-icon-chev-down]="period.collapsed" [class.im-icon-chev-up]="!period.collapsed"></i>
                                                    </div>
                                                    <!-- perc eur of CAP -->
                                                    <div class="row display-row">
                                                        <div class="col-12">
                                                            <div class="d-flex align-items-end">
                                                                <app-field-radios 
                                                                    class="d-block"
                                                                    [canUncheck]="true"
                                                                    [model]="period.refund_type"
                                                                    [error]="validation.refund_type"
                                                                    (modelChange)="period.refund_type = $event; formChange();"
                                                                    [options]="options.financial_scheme_refund_type"
                                                                    [optionWidth]=""
                                                                    [optionWidth]="80">
                                                                </app-field-radios>
                                                                <div class="ml-xs d-flex align-items-center" style="min-height: 40px;color:#C3C7CC;font-size:14px">{{'contractdetailfinancial_th_refund_type' | translate}}</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <!-- refund -->
                                                    <ng-container *ngFor="let refund of period.refunds; index as $j">
                                                        <div class="row display-row">
                                                            <div class="col-6">
                                                                <!-- tier -->
                                                                <ng-container *ngIf="refund.type === 'tier'">
                                                                    <div class="d-flex align-items-center">
                                                                        <div class="d-flex flex-column">
                                                                            <div class="standalone-label">{{'contractdetailfinancial_th_' + refund.type | translate}} <ng-container *ngIf="refund.type === 'tier'">{{$j + 1}}</ng-container></div>
                                                                            <app-field-select
                                                                                style="min-width: 120px;"
                                                                                class="mr-xs"
                                                                                [model]="refund.range" 
                                                                                [options]="options.financial_scheme_tier_range"
                                                                                (modelChange)="refund.range = $event; formChange();" 
                                                                            ></app-field-select>
                                                                        </div>
                                                                        <div class="d-flex align-items-center align-self-end flex-grow-1" style="padding-right: 24px;">
                                                                            <app-field-text style="flex-grow:1;max-width: 200px;" [model]="refund.amount" [error]="false && getRefundValidation($i, $j, 'amount')" (modelChange)="refund.amount = $event; formChange();" showMaskTyped="false" [mask]="period.refund_type === 'percentage' ? '' : 'separator'" thousandSeparator="." [type]="period.refund_type === 'percentage' ? 'float' : 'integer'"></app-field-text>
                                                                            <ng-container *ngIf="refund.range === 'between'">
                                                                                <span class="ml-xs mr-xs" style="color:#C3C7CC;font-size:14px">{{'misc_and' | translate}}</span>
                                                                                <app-field-text style="flex-grow:1;max-width: 200px;" [model]="refund.amount_end" [error]="false && getRefundValidation($i, $j, 'amount_end')" (modelChange)="refund.amount_end = $event; formChange();" showMaskTyped="false" [mask]="period.refund_type === 'percentage' ? '' : 'separator'" thousandSeparator="." [type]="period.refund_type === 'percentage' ? 'float' : 'integer'"></app-field-text>
                                                                            </ng-container>
                                                                        </div>
                                                                    </div>
                                                                </ng-container>
                                                                <!-- fixed || extra -->
                                                                <ng-container *ngIf="refund.type === 'fixed' || refund.type === 'extra'">
                                                                    <div class="d-flex align-items-end">
                                                                        <app-field-text [label]="'contractdetailfinancial_th_' + refund.type | translate" style="width: 100%;max-width: 250px;" [model]="refund.amount" [error]="false && getRefundValidation($i, $j, 'amount')" (modelChange)="refund.amount = $event; formChange();" showMaskTyped="false" [mask]="period.refund_type === 'percentage' ? '' : 'separator'" thousandSeparator="." [type]="period.refund_type === 'percentage' ? 'float' : 'integer'"></app-field-text>
                                                                        <i (click)="deleteRefund($i, $j)" class="im-icon im-icon-x-light delete-icon" style="margin-left: 4px;margin-bottom: 5px;"></i>
                                                                    </div>
                                                                </ng-container>
                                                            </div>
                                                            <div class="col-6 pl-l">
                                                                <ng-container *ngIf="refund.type === 'tier'">
                                                                    <!-- refund percentage -->
                                                                    <div class="d-flex align-items-end position-relative refund-percentage-wrap">
                                                                        <app-field-text style="width: 100%;max-width: 100px;" [label]="'contractdetailfinancial_th_refund_percentage' | translate" [model]="refund.refund_percentage" [error]="false && getRefundValidation($i, $j, 'refund_percentage')" (modelChange)="refund.refund_percentage = $event; formChange();" type="float"></app-field-text>
                                                                        <i (click)="deleteRefund($i, $j)" class="im-icon im-icon-x-light delete-icon"></i>
                                                                    </div>
                                                                </ng-container>
                                                            </div>
                                                        </div>
                                                    </ng-container>
                                                    <div class="row display-row">
                                                        <div class="col-12">
                                                            <div class="d-flex">
                                                                <button class="button-link h-auto mr-s" (click)="addRefund($i, 'tier')">+<span class="underline" style="margin-left: 4px;">{{'contractdetailfinancial_btn_add_tier' | translate}}</span></button>
                                                                <button class="button-link h-auto mr-s" (click)="addRefund($i, 'fixed')">+<span class="underline" style="margin-left: 4px;">{{'contractdetailfinancial_btn_add_fixed' | translate}}</span></button>
                                                                <button class="button-link h-auto" (click)="addRefund($i, 'extra')">+<span class="underline" style="margin-left: 4px;">{{'contractdetailfinancial_btn_add_extra' | translate}}</span></button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="form-sep"></div>
                                            </div>
                                        </ng-container>
                                        <div class="d-flex align-items-center">
                                            <!-- add period -->
                                            <div class="add-period">
                                                <button class="button-link h-auto align-self-start" (click)="addPeriod()">+<span class="underline" style="margin-left: 4px;"> {{'contractdetailfinancial_btn_add_period' | translate}}</span></button>
                                            </div>
                                            <!-- check -->
                                            <div *ngIf="financialSchemeFormSTR.periods.length" (click)="helper.copyFinancialSchemeData = !helper.copyFinancialSchemeData" [class.active]="helper.copyFinancialSchemeData" class="field-checkbox-wrap ml-m">
                                                <span class="fake-checkbox"></span> <span>{{'contractdetail_copy_fin_scheme_data' | translate}}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </ng-container>
                        </ng-container>
                        <!-- view -->
                        <ng-container *ngIf="mode == 'view'">
                            <div class="row display-row">
                                <div class="col-3">
                                    <div class="display-content">                            
                                        <div class="display-label">{{'contractdetailfinancial_name' | translate}}</div>
                                        <div class="display-value d-flex flex-row">
                                            <span>{{financialScheme.name | display}}</span>
                                        </div>                     
                                    </div>
                                </div>
                                <div class="col-3">
                                    <div class="display-content">                            
                                        <div class="display-label">{{'contractdetailfinancial_type' | translate}}</div>
                                        <div class="display-value d-flex flex-row">
                                            <span>{{financialScheme.type | resource : 'financial_scheme_type'}}</span>
                                        </div>                     
                                    </div>
                                </div>
                            </div>
                            <div class="row display-row">
                                <div class="col-6">
                                    <div class="display-content">                            
                                        <div class="display-label">{{'contractdetail_linked_contract' | translate}}</div>
                                        <div class="display-value d-flex flex-row">
                                            <span>{{financialScheme.lnkContractTypeaheadModel?.value | display}}</span>
                                        </div>                     
                                    </div>
                                </div>
                            </div>
                            <div class="row display-row">
                                <div class="col-6">
                                    <div class="display-label">{{'contractdetailfinancial_comments' | translate}}</div>
                                    <div class="display-value d-flex flex-row">
                                        <app-field-richtext
                                            [model]="financialScheme.comments"
                                            [readOnly]="true"
                                        ></app-field-richtext>
                                    </div>
                                </div>
                            </div>
                            <ng-container *ngIf="financialScheme.type == 'pvc'">
                                <div class="form-sep"></div>
                                <div class="row display-row">
                                    <div class="col-md-12">
                                        <ng-container *ngFor="let period of financialScheme.periods; index as $i">
                                            <!-- period -->
                                            <div class="period-section">
                                                <h2><span>{{'contractdetail_th_period' | translate}} {{$i + 1}}</span></h2>
                                                <div class="display-value">
                                                    {{period.start_date | formatTs : 'DD-MM-YYYY'}} - {{period.end_date | formatTs : 'DD-MM-YYYY'}}
                                                </div>
                                                <div class="row display-row mw-1200-px">
                                                    <!-- estimated sales -->
                                                    <div class="col-4">
                                                        <div class="display-label">{{'contractdetailfinancial_td_estimated_sales' | translate}}</div>
                                                        <div class="display-value d-flex flex-row">
                                                            <span>{{period.estimated_sales | floatDisplay : 'thousandSeparator'}}</span>
                                                        </div>
                                                    </div>
                                                    <!-- caps -->
                                                    <div class="col-4">
                                                        <div class="display-label">{{'contractdetailfinancial_td_estimated_caps' | translate}}</div>
                                                        <div class="display-value d-flex flex-row">
                                                            <span>{{period.caps | floatDisplay : 'thousandSeparator'}}</span>
                                                        </div>
                                                    </div>
                                                    <!-- expected average refund -->
                                                    <div class="col-4">
                                                        <div class="display-label">{{'contractdetailfinancial_td_expected_average_refund' | translate}}</div>
                                                        <div class="display-value d-flex flex-row">
                                                            <span>{{period.expected_average_refund | floatDisplay : 'thousandSeparator'}}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="row display-row">
                                                    <div class="col-6">
                                                        <div class="display-label">{{'contractdetailfinancial_td_comment' | translate}}</div>
                                                        <div class="display-value d-flex flex-row">
                                                            <app-field-richtext
                                                                [model]="period.comment"
                                                                [readOnly]="true"
                                                            ></app-field-richtext>
                                                        </div>
                                                    </div>
                                                    <div class="col-6" *ngIf="contract.class_budget">
                                                        <div class="display-label">{{'contractdetailfinancial_td_refund_mechanism_umbrella' | translate}}</div>
                                                        <div class="display-value d-flex flex-row">
                                                            <app-field-richtext
                                                                [model]="period.refund_mechanism_umbrella"
                                                                [readOnly]="true"
                                                            ></app-field-richtext>
                                                        </div>
                                                    </div>
                                                </div> 
                                                <!-- payment dates -->
                                                <div class="row display-row mw-1200-px">
                                                    <div class="col-5">
                                                        <div class="display-label mb-0">Payment dates</div>
                                                        <ul class="display-value app-list">
                                                            <ng-container *ngFor="let paymentDate of period.payment_dates; index as $j">
                                                                <li class="d-flex align-items-center position-relative" style="gap: 5px;">
                                                                    <span>{{period.payment_dates[$j].type | resource : 'financial_scheme_payment_date'}}:</span>
                                                                    <span>{{period.payment_dates[$j].date | formatTs : 'DD-MM-YYYY'}}</span>
                                                                </li>
                                                            </ng-container>
                                                        </ul>
                                                    </div>
                                                </div>
                                                <!-- refunds -->
                                                <div class="refund-section" [class.collapsed]="period.collapsed">
                                                    <div class="d-flex justify-content-between title-wrap" (click)="period.collapsed = !period.collapsed">
                                                        <h4>{{'contractdetailfinancial_th_refund_title' | translate}} {{$i + 1}}</h4>
                                                        <i class="im-icon" [class.im-icon-chev-down]="period.collapsed" [class.im-icon-chev-up]="!period.collapsed"></i>
                                                    </div>
                                                    <!-- perc eur of CAP -->
                                                    <div class="row display-row">
                                                        <div class="col-12">
                                                            <div class="d-flex align-items-end">
                                                                <app-field-radios 
                                                                    class="d-block"
                                                                    [readOnly]="true"
                                                                    [canUncheck]="true"
                                                                    [model]="period.refund_type"
                                                                    [error]="validation.refund_type"
                                                                    (modelChange)="period.refund_type = $event; formChange();"
                                                                    [options]="options.financial_scheme_refund_type"
                                                                    [optionWidth]=""
                                                                    [optionWidth]="80">
                                                                </app-field-radios>
                                                                <div class="ml-xs d-flex align-items-center" style="min-height: 40px;color:#C3C7CC;font-size:14px">{{'contractdetailfinancial_th_refund_type' | translate}}</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <!-- refund -->
                                                    <ng-container *ngFor="let refund of period.refunds; index as $j">
                                                        <div class="row display-row">
                                                            <div class="col-6">
                                                                <!-- tier -->
                                                                <ng-container *ngIf="refund.type === 'tier'">
                                                                    <div class="d-flex align-items-center">
                                                                        <div class="d-flex flex-column">
                                                                            <div class="standalone-label">{{'contractdetailfinancial_th_' + refund.type | translate}} <ng-container *ngIf="refund.type === 'tier'">{{$j + 1}}</ng-container></div>
                                                                            <app-field-select
                                                                                style="min-width: 120px;"
                                                                                class="mr-xs"
                                                                                [disabled]="true"
                                                                                [model]="refund.range" 
                                                                                [options]="options.financial_scheme_tier_range"
                                                                                (modelChange)="refund.range = $event; formChange();" 
                                                                            ></app-field-select>
                                                                        </div>
                                                                        <div class="d-flex align-items-center align-self-end flex-grow-1" style="padding-right: 24px;">
                                                                            <app-field-text style="flex-grow:1;max-width: 200px;" [disabled]="true" [model]="refund.amount" [error]="false && getRefundValidation($i, $j, 'amount')" (modelChange)="refund.amount = $event; formChange();" showMaskTyped="false" [mask]="period.refund_type === 'percentage' ? '' : 'separator'" thousandSeparator="." [type]="period.refund_type === 'percentage' ? 'float' : 'integer'"></app-field-text>
                                                                            <ng-container *ngIf="refund.range === 'between'">
                                                                                <span class="ml-xs mr-xs" style="color:#C3C7CC;font-size:14px">{{'misc_and' | translate}}</span>
                                                                                <app-field-text style="flex-grow:1;max-width: 200px;" [disabled]="true" [model]="refund.amount_end" [error]="false && getRefundValidation($i, $j, 'amount_end')" (modelChange)="refund.amount_end = $event; formChange();" showMaskTyped="false" [mask]="period.refund_type === 'percentage' ? '' : 'separator'" thousandSeparator="." [type]="period.refund_type === 'percentage' ? 'float' : 'integer'"></app-field-text>
                                                                            </ng-container>
                                                                        </div>
                                                                    </div>
                                                                </ng-container>
                                                                <!-- fixed || extra -->
                                                                <ng-container *ngIf="refund.type === 'fixed' || refund.type === 'extra'">
                                                                    <div class="d-flex align-items-end">
                                                                        <app-field-text [disabled]="true" [label]="'contractdetailfinancial_th_' + refund.type | translate" style="width: 100%;max-width: 250px;" [model]="refund.amount" [error]="false && getRefundValidation($i, $j, 'amount')" (modelChange)="refund.amount = $event; formChange();" showMaskTyped="false" [mask]="period.refund_type === 'percentage' ? '' : 'separator'" thousandSeparator="." [type]="period.refund_type === 'percentage' ? 'float' : 'integer'"></app-field-text>
                                                                    </div>
                                                                </ng-container>
                                                            </div>
                                                            <div class="col-6 pl-l">
                                                                <ng-container *ngIf="refund.type === 'tier'">
                                                                    <!-- refund percentage -->
                                                                    <div class="d-flex align-items-end position-relative refund-percentage-wrap">
                                                                        <app-field-text style="width: 100%;max-width: 100px;" [disabled]="true" [label]="'contractdetailfinancial_th_refund_percentage' | translate" [model]="refund.refund_percentage" [error]="false && getRefundValidation($i, $j, 'refund_percentage')" (modelChange)="refund.refund_percentage = $event; formChange();" type="float"></app-field-text>
                                                                    </div>
                                                                </ng-container>
                                                            </div>
                                                        </div>
                                                    </ng-container>
                                                </div>
                                            </div>
                                        </ng-container>
                                    </div>
                                </div>
                            </ng-container>
                        </ng-container>
                    </ng-container>

                    <!-- Payments -->
                    <ng-container *ngIf="activePage == 'payments'">
                        <!-- overview -->
                        <ng-container *ngIf="mode == 'show'">
                            <ng-container *ngFor="let month of getPaymentMonths(); index as $j">
                                <div class="payment-month-wrap">
                                    <div class="d-flex justify-content-between">
                                        <h2>{{'contractdetailpayment_estimate' | translate}} {{month | formatTs: 'MMM YYYY' | lowercase}}</h2>
                                        <button class="button-link h-auto" (click)="openCopyPaymentsModal('',month)"><span class="underline" style="margin-left: 4px;">{{'contractdetailfinancial_btn_copy_payments' | translate}}</span></button>
                                    </div>
                                    
                                    <table *ngIf="contract?.payments?.length" class="table--detail table--detail--clickable">
                                        <tr>
                                            <th>{{'contractdetail_th_financial_scheme' | translate}}</th>
                                            <th width="10%">{{'contractdetail_th_period' | translate}}</th>
                                            <th width="14%">{{'contractdetail_th_payment_date' | translate}}</th>
                                            <th width="10%">{{'contractdetail_th_type' | translate}}</th>
                                            <th width="14%">{{'contractdetail_th_actual_estimate' | translate}}</th>
                                            <th width="10%">{{'contractdetail_th_sales' | translate}}</th>
                                            <th width="14%">{{'contractdetail_th_refund' | translate}}</th>
                                            <th width="1%"></th>
                                        </tr>
                                        <ng-container *ngFor="let payment of contract.payments | callback: filterPaymentMonth : month; index as $i">
                                            <tr (click)="initCreateUpdatePayment(payment)">
                                                <td>{{payment.name}}</td>
                                                <td>{{payment.period}}</td>
                                                <td>{{payment.date | formatTs:'DD-MM-YYYY'}}</td>
                                                <td>{{payment.type}}</td>
                                                <td>{{payment.display_actual_estimate}}</td>
                                                <td>{{payment.sales | customCurrency : true : true}}</td>
                                                <td>{{payment.refund | customCurrency : true : true}}</td>
                                                <td>
                                                    <div class="action-button d-flex align-items-center justify-content-end actions position-relative" [class.action-active]="helper.actionIndex == $i && helper.actionIndexJ == $j" (click)="helper.actionIndex == $i && helper.actionIndexJ == $j ? setActionIndex(null) : setActionIndex($i, $j); $event.stopPropagation();" appClickOutside (clickOutside)="setActionIndex(null); clickOutsideActions()">
                                                        <i class="im-icon im-icon-actions"></i>
                                                        <app-popover [open]="helper.actionIndex === $i && helper.actionIndexJ === $j" positionClass="left" (click)="setActionIndex(null); $event.stopPropagation();">
                                                            <ul (click)="setActionIndex(null)">
                                                                <li class="position-relative" *ngFor="let action of paymentActions" appClickOutside (clickOutside)="popoverHelper = null" (click)="action.confirm ? $event.stopPropagation() || popoverHelper = action.code + '_' + $i : actionClickPayments(payment, action.code);" [ngClass]="action.class || ''">
                                                                    <i *ngIf="action.icon" class="im-icon im-icon-{{action.icon}}"></i> 
                                                                    {{action.name}}
                                                                    <app-confirm-action *ngIf="action.confirm" [type]="action.confirm_type" (confirm)="actionClickPayments(payment, action.code); dismissPopover(); setActionIndex(null);" (cancel)="dismissPopover()" [open]="popoverHelper === action.code + '_' + $i" positionClass="left"></app-confirm-action>
                                                                </li>
                                                            </ul>
                                                        </app-popover>
                                                    </div>
                                                </td>
                                            </tr>
                                        </ng-container>
                                    </table>
                                </div>
                            </ng-container>
                            <div *ngIf="!contract?.payments?.length" class="emptystate-wrap">
                                <app-empty-state value="{{'contractdetail_payments_emptystate' | translate}}"></app-empty-state>
                            </div>
                        </ng-container>
                        <!-- create update -->
                        <ng-container *ngIf="mode == 'createUpdate'">
                            <div class="row display-row mw-800-px">
                                <div class="col-3">
                                    <app-field-select 
                                        [model]="paymentFormSTR.month" 
                                        [options]="optionsPayments.month"
                                        [error]="validation.month"
                                        (modelChange)="paymentFormSTR.month = $event; formChange();"
                                        label="{{'contract_payments_assessment_period' | translate}}"
                                        placeholder="{{'contract_payments_month_placeholder' | translate}}">
                                    </app-field-select>
                                </div>
                                <div class="col-3">
                                    <app-field-select 
                                        [model]="paymentFormSTR.year" 
                                        [options]="optionsPayments.year"
                                        [error]="validation.year"
                                        (modelChange)="paymentFormSTR.year = $event; formChange();"
                                        hideLabel="true"
                                        label="-"
                                        placeholder="{{'contract_payments_year_placeholder' | translate}}">
                                    </app-field-select>
                                </div>
                            </div>
                            <div class="row display-row mw-800-px">
                                <div class="col-3">
                                    <app-field-select 
                                        [model]="paymentFormSTR.lnk_financial_scheme_uuid" 
                                        [options]="optionsPayments.financial_schemes"
                                        [error]="validation.lnk_financial_scheme_uuid"
                                        (modelChange)="paymentFormSTR.lnk_financial_scheme_uuid = $event; setPeriodOptions(); resetLnkPeriodUuid(); resetPaymentDateUuid(); formChange();"
                                        label="{{'contract_payments_lnk_financial_scheme_uuid' | translate}}"
                                        placeholder="{{'contract_payments_lnk_financial_scheme_uuid_placeholder' | translate}}">
                                    </app-field-select>
                                </div>
                                <div class="col-3">
                                    <app-field-select 
                                        [model]="paymentFormSTR.lnk_period_uuid" 
                                        [disabled]="!paymentFormSTR.lnk_financial_scheme_uuid"
                                        [options]="optionsPayments.periods"
                                        [error]="validation.lnk_period_uuid"
                                        (modelChange)="paymentFormSTR.lnk_period_uuid = $event; setPaymentDateOptions(); setAdvancePrice(); resetPaymentDateUuid(); formChange();"
                                        label="{{'contract_payments_lnk_period_uuid' | translate}}"
                                        placeholder="{{'contract_payments_lnk_period_uuid_placeholder' | translate}}">
                                    </app-field-select>
                                </div>
                                <div class="col-3">
                                    <app-field-select 
                                        [model]="paymentFormSTR.lnk_payment_date_uuid" 
                                        [disabled]="!paymentFormSTR.lnk_period_uuid" 
                                        [options]="optionsPayments.payment_dates"
                                        [error]="validation.lnk_payment_date_uuid"
                                        (modelChange)="paymentFormSTR.lnk_payment_date_uuid = $event; formChange();"
                                        label="{{'contract_payments_lnk_payment_date_uuid' | translate}}"
                                        placeholder="{{'contract_payments_lnk_payment_date_uuid_placeholder' | translate}}">
                                    </app-field-select>
                                </div>
                            </div>
                            <div class="row display-row mw-800-px">
                                <div class="col-3">
                                    <app-field-radios 
                                        class="d-block"
                                        label="{{'contract_payments_actual_estimate' | translate}}"
                                        [canUncheck]="true"
                                        [model]="paymentFormSTR.actual_estimate"
                                        [error]="validation.actual_estimate"
                                        (modelChange)="paymentFormSTR.actual_estimate = $event; formChange();"
                                        [options]="optionsPayments.actual_estimate"
                                        [optionWidth]="120">
                                    </app-field-radios>
                                </div>
                            </div>
                            <div class="row display-row mw-800-px">
                                <div class="col-3">
                                    <app-field-text 
                                        [model]="paymentFormSTR.sales" 
                                        [error]="validation.sales"
                                        (modelChange)="paymentFormSTR.sales = $event; formChange();" 
                                        label="{{'contract_payments_sales' | translate}}"
                                        type="integer" 
                                        showMaskTyped="false"
                                        mask="separator"
                                        thousandSeparator="."
                                    ></app-field-text>
                                </div>
                                
                            </div>
                            <div class="row display-row mw-800-px">
                                <div class="col-3">
                                    <app-field-text 
                                        [model]="paymentFormSTR.refund" 
                                        [error]="validation.refund"
                                        (modelChange)="paymentFormSTR.refund = $event; formChange();" 
                                        label="{{'contract_payments_refund' | translate}}"
                                        placeholder=""
                                        type="integer" 
                                        showMaskTyped="false"
                                        mask="separator"
                                        thousandSeparator="."
                                    ></app-field-text>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-4">
                                    <div class="display-content">                            
                                        <div class="display-label">{{'price_dossier_advance_payment' | translate}}</div>
                                        <div class="display-value d-flex flex-row">
                                            <span>{{advancePayment | customCurrency: false}}</span>
                                        </div>                     
                                    </div>
                                </div>
                            </div>
                        </ng-container>
                    </ng-container>

                    <!-- Documents -->
                    <ng-container *ngIf="activePage == 'documents'">
                        <ng-container *ngIf="!documentsReady">
                            <app-spinner [centerVertically]="true"></app-spinner>
                        </ng-container>
                        <ng-container *ngIf="documentsReady">
                            <app-documents
                                *ngIf="contract?.dossiers?.length"
                                [foldersInput]="this.folders"
                                [contractId]="id"
                                [dossiers]="this.contract.dossiers"
                            ></app-documents>
                            <div *ngIf="!contract?.dossiers?.length" class="emptystate-wrap">
                                <app-empty-state value="{{'contractdetaildocuments_emptystate' | translate}}"></app-empty-state>
                            </div>
                        </ng-container>
                    </ng-container>

                    <!-- Estimated -->
                    <ng-container *ngIf="activePage == 'estimated'">

                        <ng-container *ngIf="loading">
                            <div class="spinner-wrap"><app-spinner></app-spinner></div>
                        </ng-container>

                        <ng-container *ngIf="!loading">
                            <!-- overview -->
                            <ng-container *ngIf="mode == 'show'">
                                <ng-container *ngFor="let month of getEstimatedMonths(); index as $j">
                                    <div class="payment-month-wrap">
                                        <div class="d-flex justify-content-between">
                                            <h2>{{month | formatTs: 'MMM YYYY' | lowercase}}</h2>
                                            <button class="button-link h-auto" (click)="openCopyEstimatedModal('',month)"><span class="underline" style="margin-left: 4px;">{{'contractdetailfinancial_btn_copy_estimated' | translate}}</span></button>
                                        </div>
                                        
                                        <table *ngIf="parentContract.estimated.length" class="table--detail table--detail--clickable">
                                            <tr>
                                                <th width="20%">{{'contractdetail_th_year' | translate}}</th>
                                                <th width="60%">{{'contractdetail_th_estimated_sales' | translate}}</th>
                                                <th width="auto"></th>
                                            </tr>
                                            <ng-container *ngFor="let estimated of parentContract.estimated | callback: filterPaymentMonth : month; index as $i">
                                                <tr (click)="initCreateUpdateEstimated(estimated)">
                                                    <td>{{estimated.estimated_sales_year}}</td>
                                                    <td>{{estimated.estimated_sales | customCurrency: true : true}}</td>
                                                    <td>
                                                        <div class="action-button d-flex align-items-center justify-content-end actions position-relative" [class.action-active]="helper.actionIndex == $i && helper.actionIndexJ == $j" (click)="helper.actionIndex == $i && helper.actionIndexJ == $j ? setActionIndex(null) : setActionIndex($i, $j); $event.stopPropagation();" appClickOutside (clickOutside)="setActionIndex(null); clickOutsideActions()">
                                                            <i class="im-icon im-icon-actions"></i>
                                                            <app-popover [open]="helper.actionIndex === $i && helper.actionIndexJ === $j" positionClass="left" (click)="setActionIndex(null); $event.stopPropagation();">
                                                                <ul (click)="setActionIndex(null)">
                                                                    <li class="position-relative" *ngFor="let action of estimatedActions" appClickOutside (clickOutside)="popoverHelper = null" (click)="action.confirm ? $event.stopPropagation() || popoverHelper = action.code + '_' + $i : actionClickEstimated(estimated, action.code);" [ngClass]="action.class || ''">
                                                                        <i *ngIf="action.icon" class="im-icon im-icon-{{action.icon}}"></i> 
                                                                        {{action.name}}
                                                                        <app-confirm-action *ngIf="action.confirm" [type]="action.confirm_type" (confirm)="actionClickEstimated(estimated, action.code); dismissPopover(); setActionIndex(null);" (cancel)="dismissPopover()" [open]="popoverHelper === action.code + '_' + $i" positionClass="left"></app-confirm-action>
                                                                    </li>
                                                                </ul>
                                                            </app-popover>
                                                        </div>
                                                    </td>
                                                </tr>
                                            </ng-container>
                                        </table>
                                    </div>
                                </ng-container>
                                <div *ngIf="!parentContract.estimated.length" class="emptystate-wrap">
                                    <app-empty-state value="{{'contractdetail_estimated_emptystate' | translate}}"></app-empty-state>
                                </div>
                            </ng-container>
                            <!-- create update -->
                            <ng-container *ngIf="mode == 'createUpdate'">
                                <div class="row display-row mw-800-px">
                                    <div class="col-4">
                                        <app-field-select 
                                            [model]="estimatedFormSTR.month" 
                                            [options]="optionsEstimated.month"
                                            [error]="validation.month"
                                            (modelChange)="estimatedFormSTR.month = $event; formChange();"
                                            label="{{'contract_estimated_assessment_period' | translate}}"
                                            placeholder="{{'contract_estimated_month_placeholder' | translate}}">
                                        </app-field-select>
                                    </div>
                                    <div class="col-4">
                                        <app-field-select 
                                            [model]="estimatedFormSTR.year" 
                                            [options]="optionsEstimated.year"
                                            [error]="validation.year"
                                            (modelChange)="estimatedFormSTR.year = $event; formChange();"
                                            hideLabel="true"
                                            label="-"
                                            placeholder="{{'contract_estimated_year_placeholder' | translate}}">
                                        </app-field-select>
                                    </div>
                                </div>
                                <div class="row display-row mw-800-px">
                                    <div class="col-4">
                                        <app-field-select 
                                            [model]="estimatedFormSTR.estimated_sales_year" 
                                            [options]="optionsEstimated.year"
                                            [error]="validation.estimated_sales_year"
                                            (modelChange)="estimatedFormSTR.estimated_sales_year = $event; formChange();"
                                            label="{{'contract_estimated_estimated_sales_year' | translate}}"
                                            placeholder="{{'contract_estimated_estimated_sales_year_placeholder' | translate}}">
                                        </app-field-select>
                                    </div>
                                    <div class="col-4">
                                        <app-field-text 
                                            [model]="estimatedFormSTR.estimated_sales" 
                                            [error]="validation.estimated_sales"
                                            (modelChange)="estimatedFormSTR.estimated_sales = $event; formChange();" 
                                            [label]="'contract_estimated_estimated_sales' | translate"
                                            type="integer" showMaskTyped="false" mask="separator" thousandSeparator="."
                                        ></app-field-text>
                                    </div>
                                </div>
                            </ng-container>
                        </ng-container>
                        
                    </ng-container>

                    <!-- summary -->
                    <ng-container *ngIf="activePage == 'summary'">

                        <ng-container *ngIf="loading">
                            <div class="spinner-wrap"><app-spinner></app-spinner></div>
                        </ng-container>

                        <div [class.saving]="saving" *ngIf="!loading" style="max-width: 1200px;">

                            <div class="row display-row">
                                <div class="col-3">
                                    <div class="display-content">                            
                                        <div class="display-label">{{'contract_alias' | translate}}</div>
                                        <div class="display-value d-flex flex-row">
                                            <span>{{contract.alias | display}}</span>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-3">
                                    <div class="display-content">                            
                                        <div class="display-label">{{'contract_type' | translate}}</div>
                                        <div class="display-value d-flex flex-row">
                                            <span>{{parentContract.contract_type | resource : 'contract_type'}} 
                                            <!-- <span *ngIf="parentContract.contract_type == 'renewal'">{{'contractdetail_renewal_afterstring' | translate}} <a class="format-link" (click)="id = parentContract.lnk_renewal_contract_uuid; getContract()" [routerLink]="['/contracts']" [queryParams]="{contractId: parentContract.lnk_renewal_contract_uuid, activePage: 'overview'}">{{parentContract.renewal_contract_number}}</a></span> -->
                                            </span>
                                        </div>                     
                                    </div>
                                </div>
                            </div>
                            <div class="row display-row">
                                <div class="col-12">
                                    <div class="display-content">
                                        <div class="display-label">{{'contractdetail_contracts' | translate}}</div>
                                        <div class="display-value d-flex flex-row">
                                            <ng-container *ngIf="latestAmendment.related_contracts?.length">
                                                <ul class="app-list">
                                                    <li *ngFor="let contract of latestAmendment.related_contracts; index as $i">
                                                        {{contract.title}}
                                                        <ng-container *ngIf="contract?.subtitle">
                                                            <div class="fontsize-s sub-contract" style="margin-top: 0px;">
                                                                {{contract.subtitle | display}}
                                                                <ng-container *ngIf="contract?.stamp">
                                                                    <div class="d-inline-block" [class.app-tooltip-wrap]="contract?.tooltip">
                                                                        <span *ngFor="let stamp of contract?.stamp" class="stamp mr-xxs" [class]="stamp.classList">
                                                                            {{stamp.value}}
                                                                        </span>
                                                                        <div *ngIf="contract?.tooltip" class="app-tooltip app-tooltip-default-centered">{{contract?.tooltip}}</div>
                                                                    </div>
                                                                </ng-container>
                                                            </div>
                                                        </ng-container>
                                                    </li>
                                                </ul>
                                            </ng-container>
                                            <ng-container *ngIf="!latestAmendment?.related_contracts?.length">
                                                <span>-</span>
                                            </ng-container>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row display-row">
                                <div class="col-12">
                                    <div class="display-content">                            
                                        <div class="display-label">{{'contract_stage' | translate}}</div>
                                        <div class="display-value d-flex flex-row">
                                            <span>{{latestAmendment.stage | resource : 'stage'}}</span>
                                        </div>                     
                                    </div>
                                </div>
                            </div>
                            <div class="row display-row">
                                <div class="col-6">
                                    <div class="display-content">
                                        <div class="display-label">{{'contractdetail_comment' | translate}}</div>
                                        <div class="display-value d-flex flex-row">
                                            <app-field-richtext
                                                [model]="latestAmendment.comment"
                                                [readOnly] = "true"
                                            ></app-field-richtext>
                                        </div>                     
                                    </div>
                                </div>
                            </div>
                            <div class="row display-row">
                                <div class="col-12">
                                    <table class="table--detail readonly">
                                        <tr>
                                            <th>{{'contract_start_date' | translate}}</th>
                                            <th>{{'contract_end_date' | translate}}</th>
                                            <th>{{'contract_facial_price_decrease' | translate}}</th>
                                            <th>{{'contract_date_length' | translate}}</th>
                                            <th>{{'contract_date_type' | translate}}</th>
                                            <th>{{'contract_college_orphan_drugs' | translate}}</th>
                                        </tr>
                                        <ng-container *ngFor="let version of parentContract?.versions">
                                            <tr>
                                                <td>{{version.start_date | formatTs:'DD-MM-YYYY'}}</td>
                                                <td>{{version.end_date | formatTs:'DD-MM-YYYY'}}</td>
                                                <td>{{version.facial_price_decrease | booleanDisplay}}</td>
                                                <td>{{getDateLength(version.start_date, version.end_date)}}</td>
                                                <td>{{version.lnk_contract_uuid ? (getAmendmentType(version.contract_type) | display) : ('contractdetail_initial_version' | translate)}}</td>
                                                <td>{{version.college_orphan_drugs | resource : 'college_orphan_drugs'}}</td>
                                            </tr>
                                        </ng-container>
                                    </table>
                                </div>
                            </div>
                            <div class="row display-row">
                                <div class="col-6">
                                    <div class="display-content">                            
                                        <div class="display-label">{{'contract_totalduration' | translate}}</div>
                                        <div class="display-value d-flex flex-row">
                                            <span>{{getDateLengthSum()}}</span>
                                        </div>                     
                                    </div>
                                </div>
                            </div>
                            <!-- <div class="row display-row">
                                <div class="col-12">
                                    <div class="display-content">                            
                                        <div class="display-label">{{'contract_loss_of_exclusivity_date' | translate}}</div>
                                        <div class="display-value">{{parentContract.loss_of_exclusivity_date | formatTs : 'DD-MM-YYYY'}}</div>                     
                                    </div>
                                </div>
                            </div> -->
                            <div class="row display-row">
                                <div class="col-4">
                                    <div class="display-content">                            
                                        <div class="display-label">{{'contract_advisor' | translate}}</div>
                                        <div class="display-value d-flex flex-row">
                                            <span>{{getContractAdvisor(parentContract.lnk_advisor_id) | display}}</span>
                                        </div>                     
                                    </div>
                                </div>
                            </div>
                            <div class="row display-row">
                                <div class="col-4">
                                    <div class="display-content">                            
                                        <div class="display-label">{{'contact_company' | translate}}</div>
                                        <div class="display-value d-flex flex-row">
                                            <span>{{parentContract.company_name | display}}</span>
                                            <ng-container *ngIf="parentContract.company_name">
                                                <div class="pl-xxs" *ngIf="parentContract.lnk_company_id">
                                                    <span class="stamp mr-xxs stamp--gray">{{'pricedossier_table_member' | translate}} <ng-container *ngIf="!parentContract.company_status">({{'misc_inactive' | translate}})</ng-container></span>
                                                </div>
                                            </ng-container>
                                        </div>                     
                                    </div>
                                </div>
                            </div>
                            <div class="row display-row">
                                <div class="col-6">
                                    <div class="display-content">                            
                                        <div class="display-label">{{'contract_rational_modalities' | translate}}</div>
                                        <div class="display-value d-flex flex-row">
                                            <app-field-richtext
                                                [model]="latestAmendment.rational_modalities"
                                                [readOnly] = "true"
                                            ></app-field-richtext>
                                        </div>                     
                                    </div>
                                </div>
                            </div>
                            <div class="row display-row">
                                <div class="col-6">
                                    <div class="display-content">                            
                                        <div class="display-label">{{'contract_financial_modalities' | translate}}</div>
                                        <div class="display-value d-flex flex-row">
                                            <app-field-richtext
                                                [model]="latestAmendment.financial_modalities"
                                                [readOnly] = "true"
                                            ></app-field-richtext>
                                        </div>                     
                                    </div>
                                </div>
                            </div>
                            <div class="row display-row">
                                <div class="col-12">
                                    <app-contract-products
                                        [model]="allContractProducts"
                                        [readOnly]="true"
                                    ></app-contract-products>
                                </div>
                            </div>

                            <!-- summary editable fields start -->

                            <!-- view -->
                            <ng-container *ngIf="mode == 'show'">
                                <div class="row display-row">
                                    <div class="col-6">
                                        <div class="display-content">                            
                                            <div class="display-label">{{'contract_questions' | translate}}</div>
                                            <div class="display-value d-flex flex-row">
                                                <app-field-richtext
                                                    [model]="parentContract.contract_questions"
                                                    [readOnly]="true"
                                                ></app-field-richtext>
                                            </div>                     
                                        </div>
                                    </div>
                                </div>
                                <div class="row display-row">
                                    <div class="col-12">
                                        <div class="display-content">
                                            <div class="display-label">{{'contractdetail_prolongation' | translate}}</div>
                                            <div class="display-value d-flex flex-row">
                                                <span>{{parentContract.prolongation | booleanDisplay}}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row display-row">
                                    <div class="col-6">
                                        <div class="display-content">
                                            <div class="display-label">{{'contractdetail_transition_year' | translate}}</div>
                                            <div class="display-value d-flex flex-row">
                                                <span>{{parentContract.transition_year | booleanDisplay}}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row display-row">
                                    <div class="col-3">
                                        <div class="display-content">
                                            <div class="display-label">{{'contractdetail_post_contract' | translate}}</div>
                                            <div class="display-value d-flex flex-row">
                                                <span>{{parentContract.post_contract | resource : 'post_contract'}}</span>
                                            </div>   
                                        </div>
                                    </div>
                                    <div class="col-9">
                                        <div class="display-content">
                                            <div class="display-label">{{'contractdetail_facial_price_decrease_post_contract' | translate}}</div>
                                            <div class="display-value d-flex flex-row">
                                                <span>{{parentContract.facial_price_decrease_post_contract | customPercentage}}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row display-row">
                                    <div class="col-6">
                                        <div class="display-content">
                                            <div class="display-label">{{'contractdetail_post_contract_comment' | translate}}</div>
                                            <div class="display-value d-flex flex-row">
                                                <app-field-richtext
                                                    [model]="parentContract.post_contract_comment"
                                                    [readOnly] = "true"
                                                ></app-field-richtext>
                                            </div>                     
                                        </div>
                                    </div>
                                </div>
                            </ng-container>
                            <!-- form -->
                            <ng-container *ngIf="mode == 'edit'">
                                <div class="row display-row">
                                    <div class="col-6">
                                        <app-field-richtext 
                                            [model]="summaryFormSTR.contract_questions" 
                                            [error]="validation.contract_questions"
                                            (modelChange)="summaryFormSTR.contract_questions = $event; formChange();" 
                                            (changed)="formChange();"
                                            label="{{'contract_questions' | translate}}"
                                        ></app-field-richtext>
                                    </div>
                                </div>
                                <div class="row display-row">
                                    <div class="col-12">
                                        <app-field-radios 
                                            class="d-block"
                                            label="{{'contractdetail_prolongation' | translate}}"
                                            [model]="summaryFormSTR.prolongation" 
                                            [error]="validation.prolongation"
                                            (modelChange)="summaryFormSTR.prolongation = $event; formChange();"
                                            [options]="yesNoOptions"
                                            [canUncheck]="true"
                                            [optionWidth]="120">
                                        </app-field-radios>
                                    </div>
                                </div>
                                <div class="row display-row">
                                    <div class="col-6">
                                        <app-field-radios 
                                            class="d-block"
                                            label="{{'contractdetail_transition_year' | translate}}"
                                            [model]="summaryFormSTR.transition_year" 
                                            [error]="validation.transition_year"
                                            (modelChange)="summaryFormSTR.transition_year = $event; formChange();"
                                            [options]="yesNoOptions"
                                            [canUncheck]="true"
                                            [optionWidth]="120">
                                        </app-field-radios>
                                    </div>
                                </div>
                                <div class="row display-row">
                                    <div class="col-3">
                                        <app-field-select
                                            label="{{'contractdetail_post_contract' | translate}}" 
                                            [model]="summaryFormSTR.post_contract" 
                                            [options]="options.post_contract"
                                            [error]="validation.post_contract"
                                            (modelChange)="summaryFormSTR.post_contract = $event; formChange();" 
                                            [placeholder]="'contractdetail_post_contract_placeholder' | translate"
                                        ></app-field-select>
                                    </div>
                                    <div class="col-9">
                                        <app-field-text 
                                            type="float"
                                            inputWidth="150px"
                                            afterString="%"
                                            [model]="summaryFormSTR.facial_price_decrease_post_contract" 
                                            [error]="validation.facial_price_decrease_post_contract"
                                            (modelChange)="summaryFormSTR.facial_price_decrease_post_contract = $event; formChange();" 
                                            [label]="'contractdetail_facial_price_decrease_post_contract' | translate"
                                        ></app-field-text>
                                    </div>
                                </div>
                                <div class="row display-row">
                                    <div class="col-6">
                                        <app-field-richtext 
                                            [model]="summaryFormSTR.post_contract_comment" 
                                            [error]="validation.post_contract_comment"
                                            (modelChange)="summaryFormSTR.post_contract_comment = $event; formChange();" 
                                            (changed)="formChange();"
                                            label="{{'contractdetail_post_contract_comment' | translate}}"
                                        ></app-field-richtext>
                                    </div>
                                </div>
                            </ng-container>

                            <!-- summary editable fields end -->

                        </div>
                        
                    </ng-container>


                </div>
            </div>
        </div>
    </div>
</ng-container>
