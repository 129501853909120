<div class="field field-radios d-flex flex-column" [class.hasOptionWidth]="!optionWidth || optionColumnWidth" [class.readOnly]="readOnly">
    <span *ngIf="label" class="label">{{label}} <span *ngIf="optionalString">({{optionalString}})</span> @if (tooltip) { <i [ngbTooltip]="tooltip" tooltipClass="tooltip--simple" class="im-icon im-icon-info-filled label-tooltip-icon"></i> }</span>
    <div class="{{!optionWidth && optionColumnWidth ? 'row' : 'options--flex'}} options">
        <div *ngFor="let option of options;" class="col-lg-{{(!optionWidth && optionColumnWidth) || ''}}" [class.flex-grow-1]="fillWidth">
            <div [class.error-field]="error" [ngStyle]="{'min-width': !optionColumnWidth && optionWidth ? (optionWidth + 'px') : 'auto'}" (click)="(canUncheck && option.value == model) ? emit(undefined) : emit(option.value)" [class.active]="option.value == model || (!canUncheck && !option.value && !model)" class="field-radio-option flex-grow-1 d-flex align-items-center">
                <span class="fake-radio"></span> <span>{{option.title}}</span>
            </div>
        </div>
        <ng-container *ngIf="localOptionalInput">
            <div class="pl-none col-lg-{{(optionalInputWidth) || ''}}">
                <!-- <input 
                    type="text"
                    class="flex-grow-1 d-inline-block"
                    [(ngModel)]="localOptionalInputModel" 
                    [placeholder]="optionalInputPlaceholder"
                    (ngModelChange)="optionalInputEmit()"
                    (blur)="onBlur($event)"
                /> -->
                <app-field-text 
                    class="flex-grow-1 d-inline-block"
                    [model]="localOptionalInputModel" 
                    (modelChange)="optionalInputEmit($event)" 
                    [type]="optionalInputType"
                    [placeholder]="optionalInputPlaceholder"
                    (blur)="onBlur($event)"
                ></app-field-text>


                <span class="passive-label">{{optionalInputLabel}}</span>
            </div>
        </ng-container>
        <ng-container *ngIf="!localOptionalInput && localOptionalValueReadOnly">
            <div class="d-flex align-items-center pl-none col-lg-{{(optionalInputWidth) || ''}}">
                {{optionalReadOnlyValue | customCurrency: false}}
            </div>
        </ng-container>
    </div>
    <span *ngIf="error" class="error-field-display">{{error}}</span>
</div>