import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { ResourceService } from 'src/app/services/resource.service';
import { TranslatePipe } from 'src/app/utils/pipes';

@Component({
    selector: 'app-field-multi-input',
    templateUrl: './field-multi-input.component.html',
    styleUrls: ['./field-multi-input.component.scss']
})
export class FieldMultiInputComponent implements OnInit, OnChanges {
    @Input() label?: string;
    @Input() error?: string | string[];
    @Input() placeholder?: string;
    @Input() length?: number;
    @Input() id?: string;
    @Input() positionDateSelector?: string;
    @Input() type?: 'text' | 'number' | 'selection' | 'typeahead' | 'date' = 'text';
    @Input() options: { value: string; key: any }[];
    @Input() optionsToExclude: string[] = [];
    @Input() errorMessage?: string;
    @Input() optionalInputPerValue: boolean = false;
    @Input() optionalInputPlaceholder: string;
    @Input() optionalInputModelName: string;
    @Input() model: any[] = [];
    @Output() modelChange = new EventEmitter();
    inputValue: string;

    constructor() {}

    ngOnInit(): void {
        if (this.model == null || this.model == undefined) {
            this.model = [];
        }
        this.initOptionsToExclude();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (this.model == null || this.model == undefined) {
            this.model = [];
        }
        this.initOptionsToExclude();
    }

    initOptionsToExclude() {
        this.optionsToExclude = [];
        for (let i = 0; i < this.model.length; i++) {
            if (this.type == 'selection') {
                if (this.model[i].key) {
                    this.optionsToExclude.push(this.model[i].key);
                }
            } else {
                if (this.model[i].code) {
                    this.optionsToExclude.push(this.model[i].code);
                }
            }
        }
    }

    addValueToModel($event) {
        this.inputValue = $event;
        if ($event) {
            this.optionsToExclude = [...this.optionsToExclude, $event];
            this.addInputValueToModel();
        }
    }

    addInputValueToModel() {
        if (this.inputValue) {
            if (this.type == 'text') {
                this.model.push(this.inputValue.trim());
            } else if (this.type == 'number') {
                this.model.push(this.inputValue.replace(/\s+/g, ''));
            } else if (this.type == 'selection') {
                this.model.push({ key: this.inputValue });
            } else if (this.type == 'date') {
                this.model.push(this.inputValue);
            } else {
                this.model.push({ code: this.inputValue });
            }
        }
        this.inputValue = undefined;
        this.modelChange.emit(this.model);
    }

    deleteValue(index) {
        if (this.type == 'selection') {
            this.removeOptionFromExclude(this.model[index].key);
        } else {
            this.removeOptionFromExclude(this.model[index].code);
        }
        this.model.splice(index, 1);
        if (this.error && this.error[index]) {
            (this.error as any).splice(index, 1);
        }
        this.modelChange.emit(this.model);
    }

    getTitleFromOptionKey(key) {
        for (const option of this.options) {
            if (option.key == key) {
                return option.value;
            }
        }
        return null;
    }

    getTitleFromSelectionOption(item) {
        for (const option of this.options) {
            if (option.key == item.key) {
                return option.value;
            }
        }
        if (item.value) {
            return item.value;
        } else {
            return item.key;
        }
    }

    isArray(val) {
        if (val && val instanceof Array) {
            return true;
        }
        return false;
    }

    removeOptionFromExclude(value) {
        const test = this.optionsToExclude;
        if (test && test.length > 0) {
            var index = test.indexOf(value);
            if (index != -1) test.splice(index, 1);
        }

        this.optionsToExclude = [...test];
    }
}
