<div class="field field-date {{classes}}" [class.field-date--open]="calendarIsOpen">
    <span *ngIf="label" class="label">{{label}} <span *ngIf="optionalString">({{optionalString}})</span></span>
    <div class="w-100 position-relative">
        <input
            [style.width]="{width}"
            #myinput 
            [class.error-field]="error || invalidDate"
            [class.invalid-field]="false"
            [placeholder]="placeholder" 
            (calendarToggle)="calendarToggle($event)"
            (blur)="onBlur();"
            (focus)="onFocus()"
            (dateSelect)="onDateChanged($event)"
            [(ngModel)]="localModel" 
            (ngModelChange)="changes()"
            ngbDatepicker
            (click)="d.toggle()"
            [minDate]="localDisableUntil"
            [maxDate]="localDisableSince"
            #d="ngbDatepicker"/>
    </div>
    <span *ngIf="error" class="error-field-display">{{error}}</span>
</div>