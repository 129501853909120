<div class="options-modal-head d-flex flex-row justify-content-between align-items-center">
    <h1>{{'add_contact_person_title' | translate}}</h1>
    <div class="h-100 d-flex align-items-center">
        <button (click)="cancel()" class="button tertiary"><span>{{'addContactPerson_cancel' | translate}}</span></button>
        <button (click)="submit()" class="button default-sm ml-xs">{{'addContactPerson_save' | translate}}</button>
    </div>
</div>
<div class="options-modal-body">
    <div class="row display-row">
        <div class="col-6">
            <app-field-text 
                [model]="formSTR.firstname" 
                [error]="validation.firstname"
                (modelChange)="formSTR.firstname = $event;" 
                [label]="'add_contact_person_firstname' | translate"
            ></app-field-text>
        </div>
        <div class="col-6">
            <app-field-text 
                [model]="formSTR.lastname" 
                [error]="validation.lastname"
                (modelChange)="formSTR.lastname = $event;" 
                [label]="'add_contact_person_lastname' | translate"
            ></app-field-text>
        </div>
    </div>
</div>