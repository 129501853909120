<div class="options-modal-head d-flex flex-row justify-content-between align-items-center">
    <h1 *ngIf="multiple">{{'contractdetail_copy_payments' | translate}}</h1>
    <h1 *ngIf="!multiple">{{'contractdetail_copy_payment' | translate}}</h1>
    <div class="h-100 d-flex align-items-center">
        <button (click)="cancel()" class="button tertiary"><span>{{'paymentCopycontractoptions_cancel' | translate}}</span></button>
        <button (click)="submit()" class="button default-sm ml-xs">{{'paymentCopyoptions_save' | translate}}</button>
    </div>
</div>
<div class="options-modal-body">
    <div class="row display-row">
        <div class="col-4">
            <app-field-select 
                [model]="formSTR.month" 
                [options]="options.month"
                [error]="validation.month"
                (modelChange)="formSTR.month = $event;"
                label="{{'contract_payments_assessment_period' | translate}}"
                placeholder="{{'contract_payments_month_placeholder' | translate}}">
            </app-field-select>
        </div>
        <div class="col-3">
            <app-field-select 
                [model]="formSTR.year" 
                [options]="options.year"
                [error]="validation.year"
                (modelChange)="formSTR.year = $event;"
                hideLabel="true"
                label="-"
                placeholder="{{'contract_payments_year_placeholder' | translate}}">
            </app-field-select>
        </div>
    </div>
</div>