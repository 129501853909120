import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { BehaviorSubject, forkJoin, Observable, of } from 'rxjs';
import { ConfigService } from './services/config.service';
import { Config } from './models/common';
import { AuthenticationService } from './services/authentication.service';
import { Router, RoutesRecognized } from '@angular/router';
import { ResourceService } from './services/resource.service';
import { TranslatorService } from './services/translator.service';
import { ToastrService } from 'ngx-toastr';
import { TranslatePipe } from './utils/pipes';
import { HelpersService } from './services/helpers.service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html'
})
export class AppComponent implements OnInit {
    ready$ = new BehaviorSubject<boolean>(false);
    fetchedInitialRouteData: boolean = false;
    isPublic: boolean;
    config: Config;

    constructor(
        public ConfigService: ConfigService,
        private TitleService: Title,
        private AuthenticationService: AuthenticationService,
        private ResourceService: ResourceService,
        private TranslatorService: TranslatorService,
        public Router: Router,
        private toastr: ToastrService,
        private TranslatePipe: TranslatePipe,
        private HelpersService: HelpersService
    ) {}

    ngOnInit(): void {
        this.config = this.ConfigService.getConfig();
        this.TitleService.setTitle(this.config.appName);

        // listen to router events
        this.Router.events.subscribe((event) => {
            if (this.fetchedInitialRouteData) {
                return;
            }
            if (event instanceof RoutesRecognized) {
                this.fetchedInitialRouteData = true;
                let route = event.state.root.firstChild;
                if (!route || !route.data.isPublic) {
                    this.AuthenticationService.fetchUser().subscribe((next) => {
                        this.initPrivateRoute(next.language);
                    });
                } else {
                    if (route && route.data && route.data.logout) {
                        this.AuthenticationService.softLogout();
                    }
                    const publicLanguage = this.TranslatorService.getPublicLanguage();
                    this.initPublicRoute(publicLanguage);
                    this.isPublic = true;
                }
            }
        });
    }

    public initPrivateRoute(language) {
        const observables$: Observable<any>[] = [];
        observables$.push(...this.ResourceService.init());
        observables$.push(this.TranslatorService.init(language || 'en'));
        forkJoin(observables$).subscribe((next) => {
            this.setReady(true);
        });
    }

    public initPublicRoute(language?) {
        const observables$: Observable<any>[] = [of(null)];
        observables$.push(...this.ResourceService.initPublic());
        observables$.push(this.TranslatorService.init(language || 'en'));
        forkJoin(observables$).subscribe((next) => {
            this.afterInit();
            this.setReady(true);
        });
    }

    public afterInit() {
        const msg = this.HelpersService.getParam('msg');
        switch (msg) {
            case 'invalidlink':
                setTimeout(() => {
                    this.toastr.error('', this.TranslatePipe.transform('toast_invalidlink_title'));
                }, 500);
                break;
            case 'passwordreset':
                setTimeout(() => {
                    this.toastr.success('', this.TranslatePipe.transform('toast_passwordreset_title'));
                }, 500);
                break;
            default:
                break;
        }
    }

    public getReady() {
        return this.ready$.asObservable();
    }

    private setReady(ready: boolean) {
        this.ready$.next(ready);
    }
}
