<div class="field field-typeahead position-relative" [class.valid]="model">
    <span *ngIf="label" class="label">{{label}}</span>
    <ng-container *ngIf="model && model.length > 0 && !hideList">
        <ul class="app-list" [class.app-list--field]="true">
            <li *ngFor="let contract of model; index as $i">
                {{contract.title}}
                <i (click)="deleteValue($i)" class="im-icon im-icon-x-light delete-icon"></i>
                <ng-container *ngIf="contract?.subtitle">
                    <div class="fontsize-s sub-contract" style="margin-top: 0px;">
                        {{contract.subtitle | display}}
                        <ng-container *ngIf="contract?.stamp">
                            <div class="d-inline-block" [class.app-tooltip-wrap]="contract?.tooltip">
                                <span *ngFor="let stamp of contract?.stamp" class="stamp mr-xxs" [class]="stamp.classList">
                                    {{stamp.value}}
                                </span>
                                <div *ngIf="contract?.tooltip" class="app-tooltip app-tooltip-default-centered">{{contract?.tooltip}}</div>
                            </div>
                        </ng-container>
                    </div>
                </ng-container>
            </li>
        </ul>
    </ng-container>
    <input
        (focus)="onFocus($event)"
        (click)="onFocus($event)"
        id="typeahead-prevent-manual-entry"  
        type="text"
        [(ngModel)]="selected"
        [class.error-field]="error"
        [placeholder]="placeholder"
        (selectItem)="onSelect($event);"
        [ngbTypeahead]="search"
        [inputFormatter]="formatter"
        [resultFormatter]="formatter"
        [resultTemplate]="template"
        editable=false />
    <span *ngIf="error" class="error-field-display">{{error}}</span>

    <ng-template #template let-r="result" let-t="term">
        <ngb-highlight [result]="r.title" [term]="t"></ngb-highlight>
        <span *ngIf="r.subtitle" class="typeahead-after">
            <ngb-highlight [result]="r.subtitle" [term]="t"></ngb-highlight>
            <span *ngFor="let stamp of r?.stamp" class="stamp mr-xxs ml-xxs" [class]="stamp.classList">
                {{stamp.value}}
            </span>
        </span>
    </ng-template>
</div>