import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges
} from '@angular/core';
import { LocalStorageService } from 'src/app/services/local-storage.service';
import { HelpersService } from '../../../services/helpers.service';

@Component({
    selector: 'app-table',
    templateUrl: './table.component.html',
    styleUrls: ['./table.component.scss']
})
export class TableComponent implements OnInit, OnChanges {
    @Input() id: string;
    @Input() class: string = 'table';
    @Input() heads: { name: string; code: string; sortable?: boolean; hide?: boolean }[];
    @Input() data: any;
    @Input() sort?: { code: string; dir: 'asc' | 'desc' };
    @Input() showTableOnEmptyState: boolean = true;
    @Input() showHideColums: boolean = false;
    @Input() emptyState?: string;
    @Input() RPP?: number = 20;
    @Input() startRow?: number = 0;
    @Input() totalRows?: number = null;
    @Input() shimmerTypes?: any[] = [];
    @Input() loading: boolean = true;
    @Input() exportLabel: string = '';
    @Input() exportBusy: boolean = false;
    @Input() exports: { title: string; code: string; busy: boolean }[] = [];
    @Input() componentIsAttached: boolean = true;
    @Output() sortChange = new EventEmitter();
    @Output() clickData = new EventEmitter();
    @Output() clickAction = new EventEmitter();
    @Output() startRowChange = new EventEmitter();
    @Output() clickExport = new EventEmitter();
    @Output() renderDone = new EventEmitter();

    clickable = false;
    helper: any = {};
    shimmerRows = [];
    popoverHelper: any = null;
    colums: any = {};

    constructor(
        public HelpersService: HelpersService,
        private localStorageService: LocalStorageService,
        private ChangeDetectorRef: ChangeDetectorRef
    ) {}

    ngOnInit(): void {
        this.clickable = this.clickData.observers.length > 0;
        this.shimmerRows = Array(this.RPP || 20);
        this.initShowHideColums();
    }

    detatch() {
        // console.log('detatch');
        this.ChangeDetectorRef.detach();
    }

    reattach() {
        // console.log('reattach');
        this.ChangeDetectorRef.reattach();
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.componentIsAttached) {
            changes.componentIsAttached.currentValue ? this.reattach() : this.detatch();
        }
    }

    clickOutsideActions() {}

    getActionIndex() {
        return this.helper.actionIndex;
    }
    setActionIndex(i) {
        this.helper.actionIndex = i;
    }

    dismissPopover() {
        setTimeout(() => {
            this.popoverHelper = null;
        }, 1);
    }

    getSortingClass(head: any) {
        if (head.sortable && this.sort.code === head.code) {
            return this.sort.dir;
        } else if (head.sortable) {
            return 'none';
        }
    }

    clickSort(head: any) {
        let result = this.sort;
        if (this.sort.code === head.code) {
            result.dir = this.sort.dir === 'asc' ? 'desc' : 'asc';
        } else {
            result = { code: head.code, dir: 'asc' };
        }
        this.sortChange.emit(result);
    }

    initShowHideColums() {
        this.colums = this.localStorageService.getItem('table_' + this.id);
        if (!this.colums) this.colums = {};
        for (const head of this.heads) {
            if (!this.colums[head.code]) {
                this.colums[head.code] = { display: !head.hide };
            }
        }
        this.localStorageService.setItem('table_' + this.id, this.colums);
    }

    isShown(head) {
        // console.log('table trigger test');
        if (!this.colums[head.code]) {
            this.colums[head.code] = { display: !head.hide };
            this.localStorageService.setItem('table_' + this.id, this.colums);
        }
        return this.colums[head.code]?.display;
    }

    setShowHide(columCode) {
        this.colums[columCode].display
            ? (this.colums[columCode].display = false)
            : (this.colums[columCode].display = true);
        this.localStorageService.setItem('table_' + this.id, this.colums);
    }

    drag(e) {
        e.dataTransfer.setData('id', e.target.id);
    }

    trackByMethod(index: number, el: any) {
        return index;
    }
}
