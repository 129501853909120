<div class="field field-select">
    <span *ngIf="label" class="label">{{label}}<span *ngIf="optionalString">({{optionalString}})</span></span>
    <div [class.disabled-wrap]="disabled">
        <div class="select-wrap d-flex" [class.open]="open" (click)="setOpen($event)" appClickOutside (clickOutside)="setClosed()">
            <div class="selected d-flex align-items-center justify-content-between w-100">
                <span class="ellipsis" *ngIf="model?.length">{{formattedModel}}</span>
                <span class="app-placeholder ellipsis" *ngIf="!model?.length">{{placeholder}}</span>
                <i class="icon icon-select"></i>
            </div>
            <div class="options" *ngIf="this.hasActiveControl ? this.isActive : this.open">
                <div class="options-inner">
                    <ng-container *ngFor="let option of localOptions">
                        <div class="option d-flex align-items-center" [class.active]="optionIsSelected(option.value)" (click)="selectOption(option);$event.preventDefault();$event.stopPropagation()">
                            <span class="fake-checkbox"></span>
                            <div class="ellipsis">{{option.title}}</div>
                        </div>
                    </ng-container>
                </div>
            </div>
        </div>
    </div>
    <span *ngIf="error" class="error-field-display">{{error}}</span>
</div>