<div class="container page">
    <div class="d-flex ">

        <!-- side panel -->
        <div class="side-panel-wrap">
            <div class="side-panel d-flex flex-column justify-content-between">
                <div>
                    <h2 class="search-title">{{'search_title' | translate}}</h2>
                    <div class="position-relative">
                        <div class="w-100">
                            <div class="row row-form">
                                <div class="col-md-12">
                                    <app-field-text
                                        [model]="searchSTR.term" 
                                        (modelChange)="searchSTR.term = $event" 
                                        (enter)="search()" 
                                        label="{{'notules_searchForm_term' | translate}}"
                                        placeholder="{{'notules_searchForm_term_placeholder' | translate}}">
                                    </app-field-text>
                                </div>
                            </div>
                            <div class="row row-form">
                                <div class="col-md-12">
                                    <div class="field"><span class="label">{{'notules_searchForm_date_crm_meeting' | translate}}</span></div>
                                    <div class="row">
                                        <div class="col-6" style="padding-right:7px">
                                            <app-field-date 
                                                [model]="searchSTR.startDate" 
                                                (modelChange)="searchSTR.startDate = $event;" 
                                                label="{{'notules_searchForm_startdate' | translate}}"
                                                placeholder="dd-mm-yyyy"
                                                positionDateSelector="bottom">
                                            </app-field-date>
                                        </div>
                                        <div class="col-6 align-datepicker" style="padding-left:7px">
                                            <app-field-date 
                                                [model]="searchSTR.endDate" 
                                                (modelChange)="searchSTR.endDate = $event;" 
                                                label="{{'notules_searchForm_enddate' | translate}}"
                                                placeholder="dd-mm-yyyy"
                                                positionDateSelector="bottom">
                                            </app-field-date>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="mt-s d-flex flex-column align-items-center search-button-container">
                                <button (click)="search()" class="button default dark w-100 text-center d-block">{{'notules_searchForm_search_btn' | translate}}</button>
                                <button (click)="search(true)" class="button-link underline mt-s fontsize-s underline"><span>{{'notules_searchForm_clear_btn' | translate}}</span></button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- main content -->
        <div class="flex-grow-1 side-panel-main pt-s pb-l">
            <div class="d-flex justify-content-between pl-xs pr-xs">
                <h1 class="mb-l">{{'notules_table_title' | translate}} {{((!tableLoading && totalRows) || '') && '(' + totalRows + ')'}}</h1>
            </div>
            <div class="pl-xs pr-xs">
                
                <app-table
                    id="notules"
                    [heads]="tableHeads" 
                    [data]="notules"
                    [sort]="tableSort"
                    [RPP]="RPP"
                    [startRow]="startRow"
                    [totalRows]="totalRows"
                    [loading]="tableLoading"
                    [shimmerTypes]="['text-m','text-m','actions']"
                    emptyState="{{'notules_table_empty' | translate}}"
                    (sortChange)="setSort($event.code, $event.dir)"
                    (clickAction)="actionClick($event.item, $event.action)"
                    (startRowChange)="changeStartRow($event)"
                    >
                </app-table>
            </div>
        </div>
    </div>
</div>