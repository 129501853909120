import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'app-add-umbrella-contract',
    templateUrl: './add-umbrella-contract.component.html',
    styleUrls: ['./add-umbrella-contract.component.scss']
})
export class AddUmbrellaContractComponent implements OnInit {
    @Output() confirmed = new EventEmitter();

    FORM: string;
    validation: any = {};

    constructor(private ActiveModal: NgbActiveModal) {}

    ngOnInit(): void {}

    submit() {
        this.confirmed.emit(this.FORM);

        this.ActiveModal.dismiss();
    }

    cancel() {
        this.ActiveModal.dismiss();
    }
}
