<div class="fade-in-shimmer">
    <div *ngIf="type !== 'avatar'" class="shimmer shine shimmer--text" [ngClass]="type || ''"></div>
    <div *ngIf="type === 'avatar'">
        <div class="d-flex flex-row align-items-center">
            <div class="avatar shine d-flex flex-row justify-content-center align-items-center"></div>
            <div class="d-flex flex-column titles">
                <div class="title shine"></div>
                <div class="sub shine"></div>
            </div>
        </div>
    </div>

</div>