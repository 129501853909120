import { Injectable } from '@angular/core';
import { DefaultService } from '../utils/api';
import { HelpersService } from './helpers.service';
import { tap } from 'rxjs/operators';
import * as moment from 'moment';

@Injectable({
    providedIn: 'root'
})
export class CompanyService {
    constructor(
        private defaultService: DefaultService,
        private helpersService: HelpersService
    ) {}

    public searchPharmaCompanies(term: string, excludeIds?: string[]) {
        return this.defaultService.companiesGetPharmaCompanies(0, 100, ['name asc'], term, excludeIds);
    }

    public searchLinkedCompanies(searchSTR) {
        return this.defaultService.companiesGetLinkedCompanies(
            searchSTR.startRow,
            searchSTR.RRP,
            searchSTR.orderBy,
            searchSTR.samCopanyName,
            searchSTR.companyName,
            searchSTR.status != 'all' ? searchSTR.status : null,
            searchSTR.company_id
        );
    }

    public searchSAMCompanies(term: string, selectedCompanies: any[], company_id, excludeIds: string[] = []) {
        if (!excludeIds?.length) {
            if (selectedCompanies && selectedCompanies.length > 0) {
                for (const p of selectedCompanies) {
                    excludeIds.push(p.uuid);
                }
            }
        }

        return this.defaultService.companiesGetSamCompanies(0, 50, null, term, excludeIds, company_id);
    }

    public linkCompanies(linkedCompany) {
        return this.defaultService.companiesLinkCompanies(linkedCompany);
    }

    public exportCompanies(searchSTR: any) {
        return this.defaultService
            .companiesExportLinkedCompanies(
                searchSTR.orderBy,
                searchSTR.samCopanyName,
                searchSTR.companyName,
                searchSTR.status != 'all' ? searchSTR.status : null,
                searchSTR.company_id
            )
            .pipe(
                tap((response) => {
                    this.helpersService.downloadBlob(
                        response,
                        `Companies Report ${moment().format('YYYY-MM-DD')}.xlsx`
                    );
                })
            );
    }
}
