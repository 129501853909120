<header class="d-flex">
    <div class="container d-flex flex-row align-items-center justify-content-between">
        <nav class="d-flex h-100 left">
            <ul class="d-flex flex-row m-0">
                <li>
                    <img src="/assets/img/misc/logo.svg" alt="{{appName}}">
                </li>
            </ul>
        </nav>
    </div>
</header>