<div class="field field-typeahead position-relative" [class.valid]="model">
    <span *ngIf="label" class="label">{{label}}</span>
    <input
        id="typeahead-prevent-manual-entry"  
        type="text"
        [(ngModel)]="model"
        [class.error-field]="error"
        [placeholder]="placeholder"
        (blur)="onBlur()"
        (selectItem)="onSelect($event);"
        (change)="change($event)"
        (keyup.enter)="enter.emit()"
        [ngbTypeahead]="search"
        [inputFormatter]="formatter"
        [resultFormatter]="formatter"
        [resultTemplate]="template"
        [editable]="false" />
    <span *ngIf="error" class="error-field-display">{{error}}</span>

    <ng-template #template let-r="result" let-t="term">
        <ngb-highlight [result]="r.value" [term]="t"></ngb-highlight>
    </ng-template>
    <!-- {{model | json}} -->
</div>