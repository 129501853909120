export type Product = {
    uuid: string;
    product?: string;
    productPackage?: string;
    dossage?: string;
    companyName?: string;
    company_id?: number;
    samCompanyUuid?: string;
    atc?: string;
    atc_chapter?: string;
    dci?: string;
    cti?: string;
    authorisationNr?: string;
};

export type Dossier = {
    id: number;
    companies: {
        lnk_company_id?: string;
        lnk_sam_company_id?: string;
        name: string;
        company_status: boolean;
    }[]
    specialty: string;
    name: string;
    alias: string;
    create_ts: Date;
    edit_ts: Date;
    dci: string;
    atc_code: string;
    custom_atc_chapter: string;
    custom_atc_code: string;
    custom_molecule: string;
    atc_chapter: string;
    application_type: string;
    marketing_authorization: string;
    external_appointed_by_applicant: string;
    orphan_drug_college: boolean;
    opinion_integrated_d60_d90: string;
    external_crm_expert: string;
    eta_cup_mnp: boolean;
    phase: string;
    budget_status: string;
    commision_decision_date: Date;
    day_0: Date;
    date_last_meeting: Date;
    hyp_reimbursement_date: Date;
    reimbursement_date: Date;
    added_value: string;
    price_decrease_percentage: number;
    reimbursement_category: string;
    hospital_retail: 'hospital' | 'retail';
    advice_crm: string;
    moh_approval: string;
    hearing: boolean;
    onco_product: boolean;
    population_submitted: string;
    population_reimbursed: string;
    initial_population_label: boolean;
    initial_population_percentage: number;
    final_population_label: boolean;
    final_population_percentage: number;
    comments: string;
    comments_general: string;
    init_budget_y1: number;
    init_budget_y2: number;
    init_budget_y3: number;
    actual_budget_y1: number;
    actual_budget_y2: number;
    actual_budget_y3: number;
    init_incremental_budget_y1: number;
    init_incremental_budget_y2: number;
    init_incremental_budget_y3: number;
    actual_incremental_budget_y1: number;
    actual_incremental_budget_y2: number;
    actual_incremental_budget_y3: number;
    init_patients_y1: number;
    init_patients_y2: number;
    init_patients_y3: number;
    actual_patients_y1: number;
    actual_patients_y2: number;
    actual_patients_y3: number;
    experts: {
        key: number;
        value: string;
        is_primary?: boolean;
    }[];
    dossier_managers: {
        key: number;
        value: string;
    }[];
    contract_manager: number;
    dossier_nrs: string[];
    indications: {
        id: string;
        comment: string;
        indication: string;
    }[];
    products: Product[];
    newDocument: boolean;
    expert_opinion_not_considered: boolean;
    patients_in_eta: number;
    total_patients_enrolled: number;
    medicine_in_guideline: boolean;
    lnk_asmr_value_appraisal_id: number;
    lnk_gba_value_appraisal_id: number;
    lnk_nice_value_appraisal_id: number;
    lnk_value_acknowledged_id?: number;
    loop_procedure?: boolean;
    combination_therapy?: boolean;
    submission?: number;
    date_first_submission?: Date;
};

export type DossierSTR = {
    rows: number;
    data: {
        status: string;
        id: number;
        phase: string;
        specialty: string;
        edit_ts: Date;
        date_last_meeting: Date;
        company_name: string;
        expert: string;
        dossier_nrs: string;
        expert_opinion_not_considered: boolean;
        patients_in_eta: number;
        total_patients_enrolled: number;
        medicine_in_guideline: boolean;
        asmr_value_appraisal: number;
        gba_value_appraisal: number;
        nice_value_appraisal: number;
        loop_procedure?: boolean;
        combination_therapy?: boolean;
        submission?: number;
        date_first_submission?: Date;
    }[];
};

export type DossierDocument = {
    fileName: string;
    size: number;
    folderId: number;
};

export const allowedExtensions = [
    'doc',
    'docx',
    'pdf',
    'ppt',
    'pptx',
    'key',
    'xls',
    'xlsx',
    'msg',
    'eml',
    'csv',
    'numbers',
    'png',
    'jpeg',
    'jpg',
    'msg',
    'eml'
];
