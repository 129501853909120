<div class="field field-select contract-version" [class.disabled-cursor]="disabled" *ngIf="options?.length">
    <div class="select-wrap d-flex saving--pre" [class.open]="open" [class.saving]="disabled" (click)="open = !open" appClickOutside (clickOutside)="open = false">
        <div class="selected d-flex align-items-center justify-content-between w-100" >
            <div class="d-flex align-items-center w-100 name-date-wrap">
                <!-- name -->
                <span [title]="getOption(model).displayName" class="ellipsis flex-grow-1" *ngIf="getOption(model)">{{getOption(model).displayName}}</span>
                <!-- date -->
                <span class="date">{{getOption(model).start_date | formatTs: 'DD MMM YYYY' | lowercase}} / {{getOption(model).end_date | formatTs: 'DD MMM YYYY' | lowercase}}</span>
            </div>
            <i class="icon icon-select-contract-version"></i>
        </div>
        <div class="options" *ngIf="open">
            <div class="options-inner">
                <ng-container *ngFor="let option of options"> <!-- PHARMA-1611 active => hidden -->
                    <div class="option option--version d-flex align-items-center justify-content-between" [class.hidden]="getOption(model) && getOption(model).id === option.id" (click)="selectOption(option);$event.preventDefault();$event.stopPropagation()">
                        <!-- name -->
                        <div [title]="option.displayName" class="ellipsis">{{option.displayName}}</div>
                        <!-- date -->
                        <span class="date">{{option.start_date | formatTs: 'DD MMM YYYY' | lowercase}} / {{option.end_date | formatTs: 'DD MMM YYYY' | lowercase}}</span>
                    </div>
                </ng-container>
                <!-- add -->
                <div class="option d-flex align-items-center justify-content-between" (click)="clickAdd();$event.preventDefault();$event.stopPropagation()">
                    <div class="ellipsis">{{'contractversion_add_btn' | translate}}</div>
                    <i class="im-icon im-icon-plus-round"></i>
                </div>
            </div>
        </div>
    </div>
</div>