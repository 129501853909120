import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { cloneWith } from 'lodash';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { DefaultService, Product } from '../utils/api';
import { HelpersService } from './helpers.service';
import { TranslatorService } from './translator.service';

@Injectable({
    providedIn: 'root'
})
export class BulkUploadService {
    constructor(private defaultService: DefaultService) {}

    public getDocuments() {
        return this.defaultService.bulkUploadGetBulkDocuments();
    }

    public upload(file: Blob, type: string) {
        if (type == 'notulen') {
            return this.defaultService.bulkUploadBulkDocumentUploadNotule(file);
        } else {
            return this.defaultService.bulkUploadBulkDocumentUpload(file);
        }
    }

    public confirmCRMDocument(documentIds: number[]) {
        return this.defaultService.bulkUploadConfirmCRMDocument(documentIds);
    }

    public deleteDocument(documentIds: number[]) {
        return this.defaultService.bulkUploadDeleteDocument(documentIds);
    }

    public updateDocumentIds(documentId: number, dossierId: number, folderId: number) {
        return this.defaultService.bulkUploadUpdateDocumentIds(
            { dossierId: dossierId, folderId: folderId },
            documentId
        );
    }
}
