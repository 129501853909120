import { Component, EventEmitter, Input, Output, OnInit, OnDestroy, HostListener } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subject } from 'rxjs';
import { finalize, takeUntil } from 'rxjs/operators';
import { ResourceService } from 'src/app/services/resource.service';
import { TranslatePipe } from 'src/app/utils/pipes';
import { ToastrService } from 'ngx-toastr';
import { HelpersService } from 'src/app/services/helpers.service';
import * as moment from 'moment';
import 'moment/locale/nl-be';
import { PriceDossiersService } from 'src/app/services/price-dossiers.service';
import { UsersService } from 'src/app/services/users.service';
import { AddContactPersonComponent } from '../add-contact-person/add-contact-person.component';

moment.locale('nl-be');

@Component({
    selector: 'app-price-dossier-detail',
    templateUrl: './price-dossier-detail.component.html',
    styleUrls: ['./price-dossier-detail.component.scss']
})
export class PriceDossierDetailComponent implements OnInit, OnDestroy {
    @Input() id?: string;
    @Input() mode?: string;
    @Input() activePage?: string;
    @Output() created = new EventEmitter();
    @Output() updated = new EventEmitter();

    dossier: any = {};

    formSTR: any = {};

    ready: boolean = false;
    saving: boolean = false;

    options: any = {};

    hideProductField: boolean = false;
    show_why_price_not_available_d90: boolean;
    dossierManagers: any[] = [];

    pages: { code: any; name: any; notify?: boolean; icon?: string }[];
    form: any;
    popoverHelper: any = null;
    validation: any = {};
    changes: boolean = false;
    helper: any = {
        version: null
    };
    documentsReady: boolean = false;
    folders: any = null;

    onDestroy$: Subject<void> = new Subject<void>();

    constructor(
        private activeModal: NgbActiveModal,
        private toastr: ToastrService,
        private priceDossiersService: PriceDossiersService,
        private resourceService: ResourceService,
        private translatePipe: TranslatePipe,
        private helpersService: HelpersService,
        private usersService: UsersService,
        private modalService: NgbModal
    ) {}

    ngOnInit(): void {
        this.initOptions();

        if (this.id) {
            this.getDossier();
        } else {
            this.dossier = {
                status_code: this.options.status_code.find((item) => {
                    return item.is_default;
                })?.value,
                products: [{}],
                year_of_decision: new Date().getFullYear()
            };
            this.initPages();
            this.resetForm();
            this.initProducts();
            this.ready = true;
        }

        this.initActivePage(this.activePage, this.mode);
    }

    ngOnDestroy(): void {
        this.onDestroy$.next();
    }

    initProducts() {
        if (this.formSTR.products?.length) {
            this.formSTR.products = this.formSTR.products.map((item) => {
                return {
                    ...item,
                    product: { key: item.uuid === item.uuid, value: item.productPackage }
                };
            });
        }
        this.formSTR.products = this.formSTR.products || [];
    }

    initPages() {
        this.pages = [
            {
                code: 'overview',
                name: this.translatePipe.transform('pricedossier_detail_menu_overview'),
                icon: 'document'
            },
            {
                code: 'documents',
                name: this.translatePipe.transform('pricedossier_detail_menu_documents'),
                icon: 'folder'
            }
        ];
    }

    initActivePage(activePage, mode?) {
        switch (activePage) {
            case 'overview':
                this.initOverview(mode);
                break;
            case 'documents':
                this.initDocuments();
                break;
            default:
                this.activePage = 'overview';
                this.helpersService.addParam('activePage', this.activePage);
                this.initOverview(mode);
                return;
        }
        this.helpersService.addParam('activePage', activePage);
    }

    initOverview(mode) {
        if (mode) {
            this.mode = mode;
        } else {
            if (this.id) {
                this.mode = 'show';
            } else {
                this.mode = 'create';
            }
        }

        this.activePage = 'overview';
        this.initOverviewResources();
    }

    initDocuments() {
        this.getDocuments();
    }

    initOptions() {
        this.options = {
            status_code: this.initOption(
                this.resourceService.getResourceByType('status_code').map((item) => {
                    return { title: item.value, value: item.key };
                })
            ),
            crp_cpsp: this.initOption(
                this.resourceService.getResourceByType('crp_cpsp').map((item) => {
                    return { title: item.value, value: item.key };
                })
            ),
            type: this.initOption(this.resourceService.getResourceByType('type')),
            reimbursement: this.initOption(this.resourceService.getResourceByType('reimbursement')),
            price_available_d90: this.initYesNoOptions(),
            why_price_not_available_d90: this.initOption(
                this.resourceService.getResourceByType('why_price_not_available_d90')
            ),
            supplementary_questions: this.initYesNoOptions(),
            recommended: this.initYesNoOptions(),
            minister: this.initYesNoOptions(),
            contact: [],
            final_decision: this.initOption(this.resourceService.getResourceByType('final_decision')),
            year_of_decision: [...Array(2050 - 2015 + 1).keys()]
                .map((x) => x + 2015)
                .map((x) => {
                    return { key: x, value: x };
                })
        };
    }

    initOption(arr) {
        if (
            arr &&
            !arr.find((item) => {
                return item.is_default;
            })
        ) {
            arr[0].is_default = true;
        }
        return arr;
    }

    initYesNoOptions() {
        return [
            { title: 'Yes', value: true },
            { title: 'No', value: false }
        ];
    }

    initOverviewResources() {}

    pageChange($event) {
        if (this.activePage === $event || !this.canExit()) return;
        this.changes = false;
        this.activePage = $event;
        this.initActivePage($event);
    }

    getActivePage() {
        if (this.activePage && this.pages) {
            return this.pages.find((item) => {
                return item.code == this.activePage;
            });
        }
    }

    changeYearOfTreatment($event) {
        this.dossier.year_of_treatment = $event ? new Date($event).getFullYear() : undefined;
    }

    getDossier() {
        this.priceDossiersService
            .getDossier(this.id)
            .pipe(takeUntil(this.onDestroy$))
            .subscribe({
                next: (next) => {
                    this.dossier = {
                        ...next,
                        year_of_treatment: next.date_meeting ? new Date(next.date_meeting).getFullYear() : undefined
                    };
                    if (this.dossier.lnk_company_id || this.dossier.lnk_sam_company_uuid) {
                        this.getDossierManagers(this.dossier.lnk_company_id, this.dossier.lnk_sam_company_uuid);
                    }
                    this.dossier.initial_company_name = {
                        name: next.company_name,
                        id: next.id,
                        company_id: next.lnk_company_id,
                        company_status: next.company_status
                    };

                    this.initPages();
                    this.resetForm();
                    this.initProducts();
                    this.ready = true;
                    this.saving = false;
                },
                error: (error) => this.handleError(error)
            });
    }

    createUpdate() {
        const FORM = { ...this.formSTR };
        this.saving = true;
        this.priceDossiersService
            .updateInsertDossier(FORM, this.activePage, this.id || null)
            .pipe(
                finalize(() => {
                    this.saving = false;
                })
            )
            .subscribe({
                next: (next) => {
                    this.validation = {};
                    if (!this.id) {
                        this.id = next['id'];
                        this.dossier.id = this.id;
                        this.formSTR.id = this.id;
                        this.created.emit(this.formSTR);
                    } else {
                        this.updated.emit(this.formSTR);
                    }
                    this.changes = false;
                    this.getDossier();
                    this.mode = 'show';
                },
                error: (error) => this.handleError(error)
            });
    }

    getDocuments() {
        this.documentsReady = false;
        this.priceDossiersService.getDocuments(this.id).subscribe((next) => {
            this.folders = next;
            this.documentsReady = true;
        });
    }

    isValidNumber(number: number): boolean {
        return isFinite(number) && !isNaN(number);
    }

    indexOfFirstDigit(input) {
        let i = 0;
        for (; input[i] < '0' || input[i] > '9'; i++);
        return i == input.length ? -1 : i;
    }

    formChange() {
        this.changes = true;
    }

    resetForm() {
        this.formSTR = structuredClone(this.dossier);
    }

    cancelEdit() {
        if (this.canExit()) {
            this.mode = 'show';
            this.resetForm();
        }
    }

    canExit(): boolean {
        if (!this.changes || confirm(this.translatePipe.transform('exit'))) {
            this.changes = false;
            return true;
        } else {
            return false;
        }
    }

    companyChange($event) {
        this.formSTR.lnk_contact_id = '';
        if ($event) {
            if ($event.item.company?.sam_id) {
                this.formSTR.lnk_sam_company_uuid = $event.item.key;
                this.formSTR.lnk_company_id = null;
                this.getDossierManagers(undefined, $event.item.key);
            } else {
                this.formSTR.lnk_company_id = $event.item.key;
                this.formSTR.lnk_sam_company_uuid = null;
                this.getDossierManagers($event.item.key, undefined);
            }
            this.formSTR.initial_company_name = {
                name: $event.item.company.name,
                id: $event.item.key,
                company_status: $event.item.company.company_status
            };
            this.formSTR.company_name = $event.item.company.name;
            this.formChange();
        } else {
            this.formSTR.lnk_company_id = null;
            this.formSTR.lnk_sam_company_uuid = null;
            this.formSTR.initial_company_name = null;
            this.formSTR.company_name = null;
        }
    }

    onSelectProduct($event, index) {
        if ($event?.product) {
            this.formSTR.products[index] = {
                ...this.formSTR.products[index],
                atc: $event.product.atc,
                authorisationNr: $event.product.authorisationNr,
                companyName: $event.product.companyName,
                cti: $event.product.cti,
                dci: $event.product.dci,
                product: $event,
                productPackage: $event.product.productPackage,
                samCompanyUuid: $event.product.samCompanyUuid,
                company_id: $event.product.company_id,
                uuid: $event.product.uuid,
                isUnknownProduct: 0
            };
        } else {
            this.formSTR.products[index] = {
                ...this.formSTR.products[index],
                atc: undefined,
                authorisationNr: undefined,
                companyName: undefined,
                cti: undefined,
                dci: undefined,
                product: undefined,
                productPackage: undefined,
                samCompanyUuid: undefined,
                company_id: undefined,
                uuid: undefined,
                isUnknownProduct: undefined
            };
        }
        this.onChangeProducts();
    }

    onChangeProducts() {
        let product = this.formSTR?.products.find((item) => {
            return item.uuid && !item.isUnknownProduct;
        });
        if (product) {
            if (!this.formSTR.lnk_company_id && !this.formSTR.lnk_sam_company_uuid) {
                // console.log('set company id and sam company uuid, because no ids set and valid product found');
                this.formSTR.lnk_company_id = product.company_id;
                this.formSTR.lnk_sam_company_uuid = product.samCompanyUuid;
                this.formSTR.company_name = product.companyName;
                this.formSTR.initial_company_name = {
                    name: product.companyName,
                    id: product.company_id ?? product.samCompanyUuid,
                    company_status: product.company_status
                };
                this.getDossierManagers(product.company_id, product.samCompanyUuid);
            }
        } else {
            // console.log('reset company, because there are no (valid) products');
            this.resetCompany();
        }
    }

    getDossierManagers(companyId: number, samCompanyUuid: string) {
        this.usersService
            .getUsers({
                startRow: 0,
                RPP: 99999,
                orderBy: null,
                dossierId: null,
                term: null,
                company_ids: [companyId] || [],
                sam_company_ids: [samCompanyUuid] || []
            })
            .pipe(takeUntil(this.onDestroy$))
            .subscribe((next) => {
                this.dossierManagers = next.data
                    .filter((item) => {
                        return item.code == 'user' || item.code == 'coordinator';
                    })
                    .map((item) => {
                        return { value: item.firstname + ' ' + item.lastname, key: item.id };
                    });
                this.dossierManagers.push({ value: '+ add new contact person', key: 'new' });
            });
    }

    addNewContactPerson($event) {
        if ($event == 'new') {
            const modalRef = this.modalService.open(AddContactPersonComponent, {
                windowClass: 'main-modal options-modal',
                beforeDismiss: () => {
                    return modalRef.componentInstance.canExit && modalRef.componentInstance.canExit();
                }
            });

            modalRef.componentInstance.confirmed.subscribe((next) => {
                const index = this.dossierManagers.findIndex((item) => {
                    return item.key == -1;
                });
                if (index !== -1) {
                    this.dossierManagers.splice(index, 1);
                }

                this.dossierManagers.splice(this.dossierManagers.length - 1, 0, {
                    value: next.formSTR.firstname + ' ' + next.formSTR.lastname,
                    key: -1
                });
                this.formSTR.lnk_contact_id = -1;
                this.dossierManagers.sort((a, b) => {
                    if (a.key == 'new') {
                        return 1;
                    }
                    return a.value.localeCompare(b.value);
                });

                this.dossierManagers = this.dossierManagers.slice(0);

                this.formSTR = {
                    ...this.formSTR,
                    newContact: {
                        ...next.formSTR
                    }
                };
            });
        }
    }

    setMinisterPrice(index) {
        if (this.formSTR.products[index].minister == true) {
            this.formSTR.products[index].minister_price = this.formSTR.products[index].price;
        }
    }

    showHideWhyPriceNotAvailableD90() {
        if (this.formSTR.price_available_d90 == true) {
            this.show_why_price_not_available_d90 = true;
        } else {
            this.show_why_price_not_available_d90 = false;
            this.formSTR.why_price_not_available_d90 = undefined;
        }
    }

    deleteProduct(index) {
        this.formSTR.products.splice(index, 1);
        this.onChangeProducts();
    }

    addProduct() {
        this.formSTR.products.push({});
    }

    resetCompany() {
        delete this.formSTR.company_name;
        delete this.formSTR.initial_company_name;
        delete this.formSTR.lnk_sam_company_uuid;
        delete this.formSTR.lnk_company_id;
        delete this.formSTR.lnk_contact_id;
        this.dossierManagers = [];
    }

    @HostListener('window:beforeunload', ['$event'])
    beforeUnloadHandler(event: Event) {
        if (this.changes) return false;
    }

    handleError(error) {
        if (error.status == 500 || error.status == 403 || error.status == 404) {
            this.mode = 'globalError';
            this.changes = false;
        }
        this.validation = error.error.details;
    }
}
