import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { ConfigService } from './config.service';

import { tap } from 'rxjs/operators';
import { Config } from '../models/common';
import { DefaultService } from '../utils/api';

@Injectable({
    providedIn: 'root'
})
export class AuthenticationService {
    private user$ = new BehaviorSubject<object>(null);

    constructor(private defaultService: DefaultService) {}

    public login(body) {
        return this.defaultService.userLogin(body).pipe(
            tap(
                (next) => {},
                (error) => {
                    this.logout();
                }
            )
        );
    }

    public fetchUser(): any {
        return this.defaultService.userMe().pipe(
            tap((next) => {
                this.setUser(next);
            })
        );
    }

    public userHasRole(code) {
        const user: any = this.getUserValue();
        return user && user.roles && user.roles.indexOf(code) != -1;
    }

    public logout() {
        window.location.href = '/api/user/logout';
        // this.defaultService.userLogout().subscribe((next) => {
        //     window.location.href = '/api/user/login';
        // });
    }

    public softLogout() {
        this.defaultService.userLogout().subscribe((next) => {
            this.setUser(null);
        });
    }

    public onUnauth() {
        window.location.href = '/api/user/login';
    }

    public getUserValue() {
        return this.user$.getValue() || null;
    }

    public getUser(): Observable<object> {
        return this.user$.asObservable();
    }

    private setUser(user: object) {
        this.user$.next(user);
    }

    public isCurrentUserId(id) {
        return this.user$.getValue() && this.user$.getValue()['id'] == id;
    }

    public hasAccessBulkUploadTool() {
        return (
            this.getUserValue() &&
            this.getUserValue()['roles'] &&
            this.getUserValue()['roles'].indexOf('tools.bu') != -1
        );
    }

    public hasAccessCompanyMapping() {
        return (
            this.getUserValue() &&
            this.getUserValue()['roles'] &&
            this.getUserValue()['roles'].indexOf('tools.cm') != -1
        );
    }

    public hasAccessContracts() {
        return (
            this.getUserValue() &&
            this.getUserValue()['roles'] &&
            this.getUserValue()['roles'].indexOf('contracts') != -1
        );
    }

    public hasAccessCTGDossiers() {
        return this.getUserValue() && this.getUserValue()['roles'] && this.getUserValue()['roles'].indexOf('ctg') != -1;
    }

    public hasAccessNotules() {
        return (
            this.getUserValue() && this.getUserValue()['roles'] && this.getUserValue()['roles'].indexOf('notules') != -1
        );
    }

    public hasAccessPriceDossiers() {
        return (
            this.getUserValue() && this.getUserValue()['roles'] && this.getUserValue()['roles'].indexOf('price') != -1
        );
    }

    public hasAccessDeleteCTGDossiers() {
        return (
            this.getUserValue() &&
            this.getUserValue()['roles'] &&
            this.getUserValue()['roles'].indexOf('ctg.delete') != -1
        );
    }

    public hasAccessDeletePriceDossiers() {
        return (
            this.getUserValue() &&
            this.getUserValue()['roles'] &&
            this.getUserValue()['roles'].indexOf('price.delete') != -1
        );
    }

    public hasAccessDeleteContracts() {
        return (
            this.getUserValue() &&
            this.getUserValue()['roles'] &&
            this.getUserValue()['roles'].indexOf('contracts.delete') != -1
        );
    }
}
