import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { DefaultService, Product } from '../utils/api';
import { HelpersService } from './helpers.service';
import { TranslatorService } from './translator.service';

@Injectable({
    providedIn: 'root'
})
export class ProductService {
    constructor(
        private DefaultService: DefaultService,
        private toastr: ToastrService,
        private http: HttpClient,
        public HelpersService: HelpersService,
        public TranslatorService: TranslatorService
    ) {}

    public getProducts(searchSTR: any) {
        return this.DefaultService.productGetProducts(
            searchSTR.startRow,
            searchSTR.rpp,
            searchSTR.orderBy,
            searchSTR.samCompanyUuid,
            searchSTR.company_id,
            searchSTR.atc_code,
            searchSTR.term,
            searchSTR.excludeUuids,
            searchSTR.dossierId
        );
    }

    public searchProducts(
        term: string,
        selectedProducts: Product[],
        companyId?: string,
        code?: string,
        samUuid?: string
    ) {
        let atc_code = null;
        let samCompanyUuid = null;
        let company_id = companyId;
        let excludeUuids: Array<string> = [];

        if (selectedProducts && selectedProducts.length > 0) {
            for (const p of selectedProducts) {
                excludeUuids.push(p.uuid);
                atc_code = p.atc;
                samCompanyUuid = p.samCompanyUuid;
            }
        } else {
            samCompanyUuid = samUuid;
        }

        if (code == 'contractProducts') {
            atc_code = null;
        }

        return this.DefaultService.productGetProducts(
            0,
            50,
            null,
            samCompanyUuid,
            company_id,
            atc_code,
            term,
            excludeUuids,
            null
        );
    }
}
