<div class="field field-text">
    <span *ngIf="label" class="label">{{label}} <span *ngIf="optionalString">({{optionalString}})</span></span>
    <ng-container *ngIf="model && model.length > 0">
        <ul class="app-list" [class.app-list--field]="true">
            <li *ngFor="let item of model; index as $i">
                <div style="display: inline;" [class.error-item]="isArray(error) && error[$i]">
                    <ng-container *ngIf="type == 'selection' ">
                        {{getTitleFromSelectionOption(item)}}
                    </ng-container>
                    <ng-container *ngIf="type == 'typeahead'">
                        {{getTitleFromOptionKey(item.code)}}
                    </ng-container>
                    <ng-container *ngIf="type == 'date'">
                        {{item | formatTs: 'DD-MM-YYYY'}}
                    </ng-container>
                    <ng-container *ngIf="type != 'selection' && type != 'typeahead' && type != 'date'">
                        {{item}}
                    </ng-container>
                    <i (click)="deleteValue($i)" class="im-icon im-icon-x-light delete-icon"></i>
                    <div *ngIf="isArray(error) && error[$i]" class="error-field-display">
                        {{error[$i] | translate}}
                    </div>
                    <ng-container *ngIf="optionalInputPerValue">
                        <div
                            class="optional-input" 
                            contenteditable="true" 
                            (blur)="model[$i][optionalInputModelName] = $event.target.innerText" 
                            [textContent]="model[$i][optionalInputModelName]" 
                            [attr.data-placeholder]="optionalInputPlaceholder">
                        </div>
                    </ng-container>
                </div>
            </li>
        </ul>
    </ng-container>
    <ng-container *ngIf="type == 'date'">
        <div class="d-flex align-items-center">
            <app-field-date 
                [id]="id"
                [model]="inputValue" 
                (modelChange)="inputValue = $event"
                [placeholder]="placeholder"
                [error]="error"
                [positionDateSelector]="positionDateSelector"
            ></app-field-date>
            <div class="col center-horizontally">
                <button class="button-link underline" (click)="addInputValueToModel()"><span>{{'generic_add_btn' | translate}}</span></button>
            </div>
        </div>        
    </ng-container>
    <ng-container *ngIf="type == 'selection'">
        <app-field-select 
            [model]="inputValue" 
            [options]="options"
            [optionsToExclude]="optionsToExclude"
            [error]="error && !isArray(error)"
            (modelChange)="addValueToModel($event);" 
            [placeholder]="placeholder">
        </app-field-select>
    </ng-container>
    <ng-container *ngIf="type == 'typeahead'">
        <app-field-typeahead
            [error]="error"
            [model]="inputValue"
            (modelChange)="addValueToModel($event);" 
            [options]="options"
            [optionsToExclude]="optionsToExclude"
            [searchKeyARR]="['value']"
            [editable]="false"
            [clearModelOnSelect]="true"
            [placeholder]="placeholder">
        </app-field-typeahead>
    </ng-container>
    <ng-container *ngIf="type != 'selection' && type != 'typeahead' && type != 'date'">
        <div class="container">
            <div class="row">
              <div class="col-8">
                <input 
                type="text"
                [class.error-field]="error && !isArray(error)"
                [(ngModel)]="inputValue" 
                [placeholder]="placeholder"
                (keyup.enter)="addInputValueToModel()"
            />
              </div>
              <div class="col center-horizontally">
                <button class="button-link underline" (click)="addInputValueToModel()"><span>{{'generic_add_btn' | translate}}</span></button>
              </div>
             
            </div>
          </div>
          <span *ngIf="error && !isArray(error)" class="error-field-display">{{error}}</span>
    </ng-container>
</div>