<div *ngFor="let doc of folders; trackBy: trackByDocument" [id]="'wrap_' + doc.id">
    <ngx-dropzone
        class="documents dropzone"
        [id]="'dropzone_' + doc.id"
        [class.error-field]="validation.documents && folderId == doc.id"
        (change)="saveDocumentsByUpload($event, doc.id)"
        [disableClick]="true"
        [maxFileSize]="maxFileSize"
        [disabled]="doc.folders"
        style="opacity: 1 !important; pointer-events: all !important;"
    >
        <span *ngIf="validation.documents && folderId == doc.id" class="error-field-display">{{validation.documents}}</span>
        <div class="row folder-head" (click)="openCloseDocument(doc)">
            <div class="col-5 d-flex align-items-center">
                <i class="icon icon-expand mr-xxxs" [id]= "'icon' + doc.id"></i>
                <span class="title">{{doc.label}}</span>
                <span style="padding-left: 8px;" *ngIf="doc.currentPhase">{{'crmCtgDossier_current_phase' | translate}}</span>
                <div *ngIf="doc.hasNew" class="d-inline-block">
                    <span class="stamp mr-xxs stamp--new">New</span>
                </div>
            </div>
            <ng-container *ngIf="doc.loading_documents">
                <div class="col-3 sub-data d-flex align-items-center">
                    <app-shimmer type="text-m"></app-shimmer>
                </div>
                <div class="col-2 sub-data d-flex align-items-center">
                    <app-shimmer type="text-m"></app-shimmer>
                </div>
            </ng-container>
            <ng-container *ngIf="!doc.loading_documents">
                <div class="col-3 sub-data d-flex align-items-center">
                    {{!doc.folders ? docsLength(doc.documents) : subfolderDocsLength(doc.folders)}}
                </div>
                <div class="col-2 sub-data d-flex align-items-center">
                    {{(!doc.folders ? doc.last_modified_ts : lastModifiedSubfolderTs(doc)) | formatTs: 'prettyDateTime'}}
                </div>
            </ng-container>
            <div class="col-2 d-flex align-items-center justify-content-end">
                <div class="">
                    <input [id]="'documentInput' + doc.id"
                        style="display: none"
                        type="file"
                        multiple
                        (change)="saveDocuments($event.target.files, doc.id)">
                    <button class="button" (click)="clickOnElement('documentInput' + doc.id)" *ngIf="!doc.folders">
                        <i class="im-icon im-icon-plus-round mr-0"></i>
                    </button>
                </div>
            </div>
        </div>

        <ng-container *ngIf="foldersHelper[doc.id].display || foldersHelper[doc.id].loading">
            <div class="row" [id]="doc.id" style="display: block;">
                <div class="content">
                    <div class="scroll-xx">
                        <table *ngIf="doc.documents && doc.documents.length" class="table tableSimple">
                            <thead>
                                <tr>
                                    <ng-container *ngFor="let head of documentsTableHeads">
                                        <th [ngStyle]="{'width' : head.width ? head.width : 'auto' }" 
                                            [class]="head.class || ''"
                                            [class.sortable]="head.sortable" 
                                            (click)="head.sortable && clickSortDocuments(head, doc)"
                                        ><div class="position-relative" [ngClass]="head.sortable?getSortingDocumentsClass(head, doc):''">{{head.name}}</div>
                                    </th>
                                    </ng-container>
                                </tr>
                            </thead>
                            <tbody *ngIf="foldersHelper[doc.id].loading">
                                <ng-container *ngFor="let item of ['','','','','']">
                                    <tr>
                                        <ng-container *ngFor="let type of ['actions','text-m','text-m','text-m','text-m','actions']">
                                            <td>
                                                <app-shimmer></app-shimmer>
                                            </td>
                                        </ng-container>
                                    </tr>
                                </ng-container>
                            </tbody>
                            <tbody *ngIf="foldersHelper[doc.id].display">
                                <ng-container *ngFor="let item of doc.documents; index as $i; trackBy: trackByDocument; let last = last">
                                    {{last && foldersHelper[doc.id].loading != false ? folderLoaded(doc.id) : ''}}
                                    <tr>
                                        <ng-container *ngFor="let head of documentsTableHeads">
                                            <td [class.td--icon]="item[head.code] && item[head.code].type === 'icon'" [class.td--error]="item[head.code] && item[head.code].type === 'error'" [class]="(item[head.code] && item[head.code].class) || ''" [class.shrink]="item[head.code] && item[head.code].type === 'actions'" [class.no-wrap]="item[head.code] && item[head.code].stamps && !item[head.code].wrap">

                                                <!-- error -->
                                                <ng-container *ngIf="item[head.code] && item[head.code].type === 'error'"><span class="red">{{item[head.code].value}}</span></ng-container>

                                                <!-- default -->
                                                <ng-container *ngIf="!item[head.code] || !item[head.code].type">{{item[head.code]}}</ng-container>
                    
                                                <!-- linked_dossiers -->
                                                <ng-container *ngIf="head.code == 'linked_dossiers'">
                                                    <div style="white-space: pre-wrap;" *ngIf="item.dossiers">{{linkedDossiers(item.dossiers)}}</div>
                                                </ng-container>
                    
                                                <!-- icon -->
                                                <ng-container *ngIf="item[head.code] && item[head.code].type === 'icon'">
                                                    <i *ngIf="item[head.code].iconclass" class="icon {{item[head.code].iconclass}}"></i>
                                                </ng-container>

                                                <!-- link-->
                                                <ng-container *ngIf="item[head.code] && item[head.code].type === 'link'">
                                                    <a (dragstart)="drag($event)" draggable="true" href="{{item[head.code].link}}" target="{{item[head.code].target}}" id="{{item[head.code].linkId}}">{{item[head.code].linkName}}</a>
                                                    <span *ngIf="item[head.code].new"  class="bullet--new"></span>
                                                </ng-container>
                    
                                                <!-- ts -->
                                                <ng-container *ngIf="item[head.code] && item[head.code].type === 'ts'"><span style="white-space: nowrap;">{{item[head.code].ts | formatTs: item[head.code].format}}</span></ng-container>
                    
                                                <!-- actions -->
                                                <ng-container *ngIf="item[head.code] && item[head.code].type === 'actions' && !contractId">
                                                    <i (click)="openConfirmAction('delete_' + doc.id + '_' + $i)" class="im-icon im-icon-trash"></i>
                                                    <app-confirm-action style="display: none;" [id]="'delete_' + doc.id + '_' + $i" [type]="'delete'" (confirm)="documentsActionClick(item, 'delete', doc.id); closeConfirmAction('delete_' + doc.id + '_' + $i)" (cancel)="closeConfirmAction('delete_' + doc.id + '_' + $i)" [open]="true" positionClass="left"></app-confirm-action>
                                                </ng-container>

                                                <!-- actions contractId -->
                                                <ng-container *ngIf="item[head.code] && item[head.code].type === 'actions' && contractId">
                                                    <div class="action-button d-flex align-items-center actions position-relative" [class.action-active]="helper.actionIndex == item.id" (click)="helper.actionIndex == item.id ? helper.actionIndex = null : helper.actionIndex = item.id; $event.stopPropagation();" appClickOutside (clickOutside)="helper.actionIndex = null;">
                                                        <i class="im-icon im-icon-actions"></i>
                                                        <app-popover [open]="helper.actionIndex === item.id" positionClass="left" (click)="helper.actionIndex = null; $event.stopPropagation();">
                                                            <ul (click)="helper.actionIndex = null">
                                                                <li class="position-relative" (click)="openContractOptionsModal(undefined, undefined, doc.id, undefined, item)"> <i class="im-icon im-icon-pencil"></i> {{'crmCtgDossiers_documents_table_edit_linked_documents' | translate}} </li>
                                                                <li class="position-relative delete-red" appClickOutside (clickOutside)="helper.deleteIndex = null" (click)="$event.stopPropagation() || helper.deleteIndex = item.id"> 
                                                                    <i class="im-icon im-icon-trash delete-red"></i>
                                                                    {{'crmCtgDossiers_documents_table_delete' | translate}}
                                                                    <app-confirm-action [type]="'delete'" (confirm)="documentsActionClick(item, 'delete', doc.id);helper.deleteIndex = null;" (cancel)="helper.deleteIndex = null;" *ngIf="helper.deleteIndex == item.id" [open]="helper.deleteIndex == item.id" positionClass="left"></app-confirm-action>
                                                                </li>
                                                            </ul>
                                                        </app-popover>
                                                    </div>
                                                </ng-container>
                                            </td>
                                        </ng-container>
                                    </tr>
                                </ng-container>
                            </tbody>
                        </table>
                        <div *ngIf="(!doc.documents || !doc.documents.length) && !doc.folders" class="mt-s emptystate-wrap">
                            <app-empty-state value="{{'crmCtgDossiers_documents_table_empty' | translate}}"></app-empty-state>
                        </div>
                    </div>

                    <div class="content-dropzone-wrap" *ngIf="!doc.folders">
                        <ngx-dropzone
                            class="content-dropzone"
                            [id]="'content_dropzone_' + doc.id"
                            [class.error-field]="validation.documents && folderId == doc.id"
                            (change)="saveDocumentsByUpload($event, doc.id)"
                            [disableClick]="false"
                            [maxFileSize]="maxFileSize">
                            <span>{{'crmCtgDossiers_documents_dropzone_descr' | translate}}</span>
                            <button class="button">{{'crmCtgDossiers_documents_dropzone_btn' | translate}}</button>
                        </ngx-dropzone>
                    </div>
                    <!-- subfolders -->
                    <div class="subfolders-wrap" *ngIf="doc.folders">
                        <ng-container *ngFor="let subfolder of doc.folders; trackBy: trackByDocument">
                            <ngx-dropzone
                                class="documents dropzone"
                                [id]="'dropzone_subfolder_' + subfolder.id"
                                [class.error-field]="validation.documents && folderId == subfolder.id"
                                (change)="saveDocumentsByUpload($event, doc.id, subfolder.id)"
                                [disableClick]="true"
                                [maxFileSize]="maxFileSize"
                                [disabled]="false"
                                style="opacity: 1 !important; pointer-events: all !important;"
                                class="subfolder" [class.open]="subfolder.open">
                                <!-- subfolder head -->
                                <div class="subfolder-head" (click)="subfolder.open = !subfolder.open; subfolder.open ? subfolderOpended(subfolder) : ''">
                                    <div class="row">
                                        <div class="col-5">
                                            <i class="icon icon-folder"></i>
                                            <span class="name">{{subfolder.label}}</span>
                                            <div *ngIf="subfolder.hasNew" class="d-inline-block">
                                                <span class="stamp mr-xxs stamp--new">New</span>
                                            </div>
                                        </div>
                                        <div class="col-3"><span class="amount">{{docsLength(subfolder.documents)}}</span></div>
                                        <div class="col-4 pr-0">
                                            <div class="d-flex align-items-center justify-content-between w-100">
                                                <span class="lastmodified">{{subfolder.last_modified_ts | formatTs: 'prettyDateTime'}}</span>
                                                <div>
                                                    <input [id]="'subfolderInput' + subfolder.id"
                                                        style="display: none"
                                                        type="file"
                                                        multiple
                                                        (change)="saveDocuments($event.target.files, subfolder.id)">
                                                    <button class="button" (click)="clickOnElement('subfolderInput' + subfolder.id)" *ngIf="!subfolder.folders">
                                                        <i class="im-icon im-icon-plus-round mr-0"></i>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!-- subfolder body -->
                                <div class="subfolder-body" *ngIf="subfolder.open">
                                    <!-- empty -->
                                    <div *ngIf="(!subfolder.documents || !subfolder.documents.length)" class="mt-s emptystate-wrap">
                                        <app-empty-state value="{{'crmCtgDossiers_documents_table_empty' | translate}}"></app-empty-state>
                                    </div>
                                    <!-- table -->
                                    <table *ngIf="subfolder.documents && subfolder.documents.length" class="table tableSimple">
                                        <!-- heads -->
                                        <thead>
                                            <tr>
                                                <th style="width: 1%;"></th>
                                                <th style="width: 40%; padding-left: 6px;" class="sortable" (click)="clickSortDocuments({sortable: true, code: 'file_name'}, subfolder)">
                                                    <div class="position-relative" [ngClass]="getSortingDocumentsClass({sortable: true, code: 'file_name'}, subfolder)">{{'crmCtgDossiers_documents_table_name' | translate}}</div>
                                                </th>
                                                <th style="width: 15%;">{{'crmCtgDossiers_documents_table_uploaded_by' | translate}}</th>
                                                <th style="width: 15%;" class="sortable" (click)="clickSortDocuments({sortable: true, code: 'create_ts'}, subfolder)">
                                                    <div class="position-relative" [ngClass]="getSortingDocumentsClass({sortable: true, code: 'create_ts'}, subfolder)">{{'crmCtgDossiers_documents_table_uploaded_on' | translate}}</div>
                                                </th>
                                                <th style="width: 15%;">{{'crmCtgDossiers_documents_table_size' | translate}}</th>
                                                <th style="width: 1%;"></th>
                                            </tr>
                                        </thead>
                                        <!-- body -->
                                        <tbody>
                                            <ng-container *ngFor="let document of subfolder.documents; index as $i; trackBy: trackByDocument;">
                                                <!-- error tr -->
                                                <tr *ngIf="document?.file_name?.type == 'error'">
                                                    <td colspan="6" style="padding-left: 59px !important"><span class="red">{{document.file_name.value}}</span></td>
                                                </tr>
                                                <!-- default tr -->
                                                <tr *ngIf="document.id && document?.file_name?.link">
                                                    <td class="td--icon pr-0" style="padding-left: 28px;">
                                                        <div class="d-flex align-items-start">
                                                            <i class="icon icon-arrow-down-right" style="margin-top: 1px;margin-right: 10px;"></i>
                                                            <i class="icon {{document.file_icon.iconclass}}"></i>
                                                        </div>
                                                    </td>
                                                    <td class="td-filename" style="padding-left: 6px;">
                                                        <a (dragstart)="drag($event)" draggable="true" href="{{document.file_name.link}}" target="{{document.file_name.target}}" id="{{document.file_name.linkId}}">{{document.file_name.linkName}}</a>
                                                        <span *ngIf="document.isNew"  class="bullet--new"></span>
                                                    </td>
                                                    <td>{{document.create_username}}</td>
                                                    <td>{{document.create_ts.ts | formatTs: 'prettyDateTime'}}</td>
                                                    <td>{{document.size}}</td>
                                                    <td>
                                                        <i (click)="activePopover = 'delete_' + document.id" class="im-icon im-icon-trash"></i>
                                                        <app-confirm-action *ngIf="activePopover == 'delete_' + document.id" [type]="'delete'" (confirm)="documentsActionClick(document, 'delete', doc.id, subfolder.id); activePopover = null" (cancel)="activePopover = null" [open]="true" positionClass="left"></app-confirm-action>
                                                    </td>
                                                </tr>
                                            </ng-container>
                                        </tbody>
                                    </table>
                                </div>
                            </ngx-dropzone>
                            <div class="content-dropzone-wrap content-dropzone-wrap--subfolder" *ngIf="subfolder.open">
                                <ngx-dropzone
                                    class="content-dropzone"
                                    [id]="'content_dropzone_' + subfolder.id"
                                    [class.error-field]="validation.documents && folderId == subfolder.id"
                                    (change)="saveDocumentsByUpload($event, doc.id, subfolder.id)"
                                    [maxFileSize]="maxFileSize">
                                    <span>{{'crmCtgDossiers_documents_dropzone_descr' | translate}}</span>
                                    <button class="button">{{'crmCtgDossiers_documents_dropzone_btn' | translate}}</button>
                                </ngx-dropzone>
                            </div>
                        </ng-container>
                    </div>
                </div>
            </div>
        </ng-container>
    </ngx-dropzone>
</div>