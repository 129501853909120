import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { BulkUploadComponent } from './pages/bulk-upload/bulk-upload.component';
import { CompaniesComponent } from './pages/companies/companies.component';
import { ContractsComponent } from './pages/contracts/contracts.component';
import { CrmCtgDossiersComponent } from './pages/crm-ctg-dossiers/crm-ctg-dossiers.component';
import { NotulesComponent } from './pages/notules/notules.component';
import { PriceDossiersComponent } from './pages/price-dossiers/price-dossiers.component';
import { LeaveGuardService } from './services/leave-guard.service';

const routes: Routes = [
    { path: '', redirectTo: 'dossiers', pathMatch: 'full' },
    {
        path: 'dossiers',
        component: CrmCtgDossiersComponent,
        canDeactivate: [LeaveGuardService],
        data: {}
    },
    {
        path: 'price-dossiers',
        component: PriceDossiersComponent,
        canDeactivate: [LeaveGuardService],
        data: {}
    },
    {
        path: 'notules',
        component: NotulesComponent,
        canDeactivate: [LeaveGuardService],
        data: {}
    },
    {
        path: 'contracts',
        component: ContractsComponent,
        canDeactivate: [LeaveGuardService],
        data: {}
    },
    {
        path: 'tools/bulk-upload-documents',
        component: BulkUploadComponent,
        canDeactivate: [LeaveGuardService],
        data: {}
    },
    {
        path: 'tools/companies',
        component: CompaniesComponent,
        canDeactivate: [LeaveGuardService],
        data: {}
    },
    // 404
    { path: '**', redirectTo: '/dossiers' }
];

@NgModule({
    imports: [RouterModule.forRoot(routes, { paramsInheritanceStrategy: 'always', anchorScrolling: 'enabled' })],
    exports: [RouterModule]
})
export class AppRoutingModule {}
