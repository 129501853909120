import {
    Component,
    EventEmitter,
    Input,
    Output,
    OnInit,
    OnDestroy,
    HostListener,
    SimpleChanges,
    OnChanges
} from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { lastValueFrom, Subject } from 'rxjs';
import { finalize, takeUntil } from 'rxjs/operators';
import { CrmCtgDossiersService } from 'src/app/services/crm-ctg-dossiers.service';
import { UsersService } from 'src/app/services/users.service';
import { ResourceService } from 'src/app/services/resource.service';
import { TranslatePipe } from 'src/app/utils/pipes';
import { DocumentService } from 'src/app/services/document.service';
import { Document, Folder } from 'backend/src/models/document-model';
import { ToastrService } from 'ngx-toastr';
import { HelpersService } from 'src/app/services/helpers.service';
import { Product } from 'src/app/utils/api';
import * as moment from 'moment';
import 'moment/locale/nl-be';
import * as filesize from 'filesize';
import { allowedExtensions } from 'backend/src/models/dossier-model';
import { HistoryService } from 'src/app/services/history.service';
import { ModalDocumentContractOptionsComponent } from '../../modals/modal-document-contract-options/modal-document-contract-options.component';

moment.locale('nl-be');

@Component({
    selector: 'app-documents',
    templateUrl: './documents.component.html',
    styleUrls: ['./documents.component.scss']
})
export class DocumentsComponent implements OnInit, OnChanges {
    @Input() foldersInput: any[] = [];
    @Input() dossierId?: any;
    @Input() contractId?: any;
    @Input() dossiers?: any[];
    @Input() priceDossierId?: string;

    folders: any[] = [];
    validation: any = {};

    documentsTableHeads: any;
    foldersHelper: any = {};
    folderId;
    maxFileSize = 152428800;
    documentAdded: boolean = false;

    helper: any = { actionIndex: null, deleteIndex: null };

    constructor(
        private ActiveModal: NgbActiveModal,
        private toastr: ToastrService,
        private crmCtgDossiersService: CrmCtgDossiersService,
        private documentService: DocumentService,
        private historyService: HistoryService,
        private usersService: UsersService,
        private resourceService: ResourceService,
        private translatePipe: TranslatePipe,
        private helpersService: HelpersService,
        private ModalService: NgbModal
    ) {}

    ngOnInit(): void {
        if (!this.contractId) {
            this.documentsTableHeads = [
                {
                    name: '',
                    code: 'file_icon',
                    width: '1%'
                },
                {
                    name: this.translatePipe.transform('crmCtgDossiers_documents_table_name'),
                    code: 'file_name',
                    sortable: true,
                    width: '40%',
                    class: 'td-filename'
                },
                {
                    name: this.translatePipe.transform('crmCtgDossiers_documents_table_uploaded_by'),
                    code: 'create_username',
                    sortable: false,
                    width: '15%'
                },
                {
                    name: this.translatePipe.transform('crmCtgDossiers_documents_table_uploaded_on'),
                    code: 'create_ts',
                    sortable: true,
                    width: '15%'
                },
                {
                    name: this.translatePipe.transform('crmCtgDossiers_documents_table_size'),
                    code: 'size',
                    sortable: false,
                    width: '15%'
                },
                { name: '', code: 'actions' }
            ];
        } else {
            // contract
            this.documentsTableHeads = [
                {
                    name: '',
                    code: 'file_icon',
                    width: '1%'
                },
                {
                    name: this.translatePipe.transform('crmCtgDossiers_documents_table_name'),
                    code: 'file_name',
                    sortable: true,
                    // width: '25%',
                    width: '30%',
                    class: 'td-filename'
                },

                // {
                //     name: this.translatePipe.transform('crmCtgDossiers_documents_table_uploaded_by'),
                //     code: 'create_username',
                //     sortable: false,
                //     width: '12%'
                // },
                {
                    name: this.translatePipe.transform('crmCtgDossiers_documents_table_uploaded_on'),
                    code: 'create_ts',
                    sortable: true,
                    width: '13%'
                    // width: '12%'
                },
                // {
                //     name: this.translatePipe.transform('crmCtgDossiers_documents_table_size'),
                //     code: 'size',
                //     sortable: false,
                //     width: '12%'
                // },
                {
                    name: this.translatePipe.transform('contractdetail_documents_table_linked_dossiers'),
                    code: 'linked_dossiers',
                    sortable: false,
                    width: '45%',
                    // width: '25%',
                    class: 'td-dossiers'
                },
                { name: '', code: 'actions' }
            ];
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.foldersInput && changes.foldersInput.currentValue) {
            const folders = changes.foldersInput.currentValue;
            if (folders && folders.length > 0) {
                // this.initFolders();
                for (const folder of folders) {
                    if (!folder?.folders) {
                        folder.documents = this.setDocumentsTableData(folder.documents);
                        folder.hasNew = this.documentsContainsNewDocuments(folder.documents);
                    } else {
                        folder.hasNew = false;
                        for (const subfolder of folder.folders) {
                            subfolder.documents = this.setDocumentsTableData(subfolder.documents);
                            subfolder.hasNew = this.documentsContainsNewDocuments(subfolder.documents);
                            if (subfolder.hasNew) {
                                folder.hasNew = true;
                            }
                        }
                    }
                }
            }
            this.folders = folders;
            this.initFolders();
        }
    }
    initFolders() {
        for (const folder of this.folders) {
            this.foldersHelper[folder.id] = { display: false, loading: false };
        }
    }

    lastModifiedSubfolderTs(folder) {
        let ts = '';
        for (let i = 0; i < folder.folders.length; i++) {
            const item = folder.folders[i];
            if (item.last_modified_ts && (!ts || moment(item.last_modified_ts).isAfter(moment(ts)))) {
                ts = item.last_modified_ts;
            }
        }
        return ts;
    }

    docsLength(docs) {
        if (!docs) return '0 documents';
        const count = docs.filter((item) => {
            return item.id;
        }).length;
        return count + ` document${count === 1 ? '' : 's'}`;
    }

    subfolderDocsLength(folders) {
        let count = 0;
        folders.forEach((item) => {
            count += item?.documents?.length || 0;
        });
        return count + ` document${count === 1 ? '' : 's'}`;
    }

    addDocumentToDocuments(folderId, newDoc, subfolderId?) {
        for (const doc of this.folders) {
            if (doc.id == folderId) {
                doc.last_modified_ts = newDoc.create_ts;
                doc.hasNew = this.documentsContainsNewDocuments(doc.documents);
                if (!subfolderId) {
                    doc.documents.unshift(this.getDocumentTableData(newDoc));
                } else {
                    const index = doc.folders
                        .map((item) => {
                            return item.id;
                        })
                        .indexOf(subfolderId);
                    if (index != -1) {
                        doc.folders[index].documents.unshift(this.getDocumentTableData(newDoc));
                        doc.folders[index].last_modified_ts = moment().format('YYYY-MM-DD HH:mm:ss');
                    }
                }
                break;
            }
        }
    }

    deleteDocumentInDocuments(folderId, docId, subfolderId?) {
        for (const doc of this.folders) {
            if (doc.id == folderId) {
                doc.last_modified_ts = moment().format('YYYY-MM-DD HH:mm:ss');
                if (!subfolderId) {
                    doc.documents = doc.documents.filter((item) => item.id != docId);
                } else {
                    const index = doc.folders
                        .map((item) => {
                            return item.id;
                        })
                        .indexOf(subfolderId);
                    if (index != -1) {
                        doc.folders[index].documents = doc.folders[index].documents.filter((item) => item.id != docId);
                        doc.folders[index].last_modified_ts = moment().format('YYYY-MM-DD HH:mm:ss');
                    }
                }
                break;
            }
        }
    }

    deleteDocument(folderId, documentId, subfolderId?, dossierIds?) {
        this.documentService.deleteDocument(dossierIds || [this.dossierId], documentId).subscribe((next) => {
            this.toastr.success(
                this.translatePipe.transform('document_deleted'),
                this.translatePipe.transform('document_deleted_title')
            );
            this.deleteDocumentInDocuments(folderId, documentId, subfolderId);
        });
    }

    deletePriceDossierDocument(folderId, documentId, priceDossierId) {
        this.documentService.priceDossierDeleteDocument(folderId, documentId, priceDossierId).subscribe((next) => {
            this.toastr.success(
                this.translatePipe.transform('document_deleted'),
                this.translatePipe.transform('document_deleted_title')
            );
            this.deleteDocumentInDocuments(folderId, documentId);
        });
    }

    moveDocumentToFolder(documentId, folderId, subfolderId?) {
        let dossierIds;
        let documentToMove;
        // remove
        for (const folder of this.folders) {
            if (folder.documents) {
                for (const document of folder.documents) {
                    if (document.id == documentId) {
                        documentToMove = document;
                        break;
                    }
                }
            }
            if (folder.folders) {
                for (const subfolder of folder.folders) {
                    for (const document of subfolder.documents) {
                        if (document.id == documentId) {
                            documentToMove = document;
                            break;
                        }
                    }
                }
            }
        }
        if (documentToMove.dossiers) {
            dossierIds = documentToMove.dossiers.map((item) => {
                return item.id;
            });
        }
        lastValueFrom(
            this.documentService.moveDocument(
                documentId,
                dossierIds || [this.dossierId],
                subfolderId || folderId,
                this.priceDossierId
            )
        ).then((result) => {
            let documentToMove;
            let subfolderfrom;
            // remove
            for (const folder of this.folders) {
                if (folder.documents) {
                    for (const document of folder.documents) {
                        if (document.id == documentId) {
                            documentToMove = document;
                            folder.documents = folder.documents.filter((item) => item.id != documentId);
                            folder.last_modified_ts = moment().format('YYYY-MM-DD HH:mm:ss');
                            break;
                        }
                    }
                }
                if (folder.folders) {
                    for (const subfolder of folder.folders) {
                        for (const document of subfolder.documents) {
                            if (document.id == documentId) {
                                subfolderfrom = subfolder;
                                documentToMove = document;
                                subfolderfrom.documents = subfolderfrom.documents.filter(
                                    (item) => item.id != documentId
                                );
                                subfolderfrom.last_modified_ts = moment().format('YYYY-MM-DD HH:mm:ss');
                                break;
                            }
                        }
                    }
                }
            }
            // add
            if (documentToMove) {
                for (const folder of this.folders) {
                    if (folder.id == folderId) {
                        if (!subfolderId) {
                            folder.documents.unshift(documentToMove);
                            folder.last_modified_ts = moment().format('YYYY-MM-DD HH:mm:ss');
                            break;
                        } else {
                            const subfolder = folder?.folders?.find((item) => {
                                return item.id == subfolderId;
                            });
                            subfolder.documents.unshift(documentToMove);
                            subfolder.last_modified_ts = moment().format('YYYY-MM-DD HH:mm:ss');
                            break;
                        }
                    }
                }
            }

            this.toastr.success(
                this.translatePipe.transform('document_moved'),
                this.translatePipe.transform('document_moved_title')
            );
        });
    }

    hasAllowedExtension(file) {
        const splitFileName = file.name.split('.').reverse();
        const fileExtention = splitFileName[0];
        if (!allowedExtensions.includes(fileExtention)) {
            return false;
        }
        return true;
    }

    getRejectedReason(file) {
        if (file.reason == 'size') {
            return `File can be maximum 50 MB in size`;
        } else {
            return `File can't be uploaded because of ${file.reason}`;
        }
    }

    openContractOptionsModal(files, rejected, folderId?, subfolderId?, file?) {
        if (!files?.length && rejected?.length) {
            rejected.forEach((err) => {
                this.toastr.error(this.getRejectedReason(err), err.name);
            });
        } else {
            if (this.dossiers?.length === 1) {
                this.saveDocumentsByUpload({ addedFiles: files }, folderId, subfolderId, [this.dossiers[0].id]);
                return;
            }
            const modalRef = this.ModalService.open(ModalDocumentContractOptionsComponent, {
                windowClass: 'main-modal options-modal',
                beforeDismiss: () => {
                    return modalRef.componentInstance.canExit && modalRef.componentInstance.canExit();
                }
            });
            modalRef.componentInstance.files = files;
            modalRef.componentInstance.rejected = rejected;
            modalRef.componentInstance.dossiers = this.dossiers;
            modalRef.componentInstance.file = file;
            modalRef.componentInstance.confirmed.subscribe((next) => {
                if (next.files) {
                    // new documents
                    this.saveDocumentsByUpload({ addedFiles: next.files }, folderId, subfolderId, next.dossierIds);
                } else if (next.file) {
                    // edit dossiers for existing documents
                    const FORM = [];
                    for (const dossier of this.dossiers) {
                        FORM.push({
                            dossierId: dossier.id,
                            link: next.dossierIds.indexOf(dossier.id) != -1 ? true : false
                        });
                    }
                    this.documentService.linkDocumentToDossiers(FORM, next.file.id, folderId).subscribe((item) => {
                        // edit document linked dossiers
                        const folder = this.folders.find((item) => {
                            return item.id == folderId;
                        });
                        folder.last_modified_ts = moment().format('YYYY-MM-DD HH:mm:ss');
                        const document = folder.documents.find((item) => {
                            return item.id == file.id;
                        });
                        document.dossiers = next.dossierIds.map((item) => {
                            return { id: item };
                        });
                        this.toastr.success(
                            this.translatePipe.transform('document_saved'),
                            this.translatePipe.transform('document_saved_title')
                        );
                    });
                }
            });
        }
    }

    // dropzones
    async saveDocumentsByUpload(event, folderId, subfolderId?, dossierIds?) {
        if (!event.id && !event.addedFiles && !event.rejectedFiles) {
            return;
        }
        this.documentAdded = false;
        if (event.id) {
            // @todo
            this.moveDocumentToFolder(event.id, folderId, subfolderId);
        } else {
            this.folderId = subfolderId || folderId;
            if (this.contractId && !dossierIds) {
                this.openContractOptionsModal(event.addedFiles, event.rejectedFiles, folderId, subfolderId);
                return;
            }
            if (event.rejectedFiles) {
                for (const file of event.rejectedFiles) {
                    if (file.reason == 'size') {
                        this.addErrorInFolder(file.name + ': Can be maximum 50 MB in size', folderId, subfolderId);
                    } else {
                        this.addErrorInFolder(
                            file.name + ": Can't be uploaded because of " + file.reason,
                            folderId,
                            subfolderId
                        );
                    }
                }
            }
            if (event.addedFiles) {
                for (const file of event.addedFiles) {
                    if (file.name.lastIndexOf('.') > 0) {
                        if (this.hasAllowedExtension(file)) {
                            await this.saveDocument(file, folderId, subfolderId, dossierIds);
                        } else {
                            this.addErrorInFolder(file.name + ': Extension is not allowed', folderId, subfolderId);
                        }
                    } else {
                        this.addErrorInFolder(file.name + ': Document is not allowed', folderId, subfolderId);
                    }
                }
                if (this.documentAdded) {
                    this.toastr.success(
                        this.translatePipe.transform('document_saved'),
                        this.translatePipe.transform('document_saved_title')
                    );
                }
            }
        }
    }

    async saveDocuments(files, folderId, subfolderId?, dossierIds?) {
        this.documentAdded = false;
        if (this.contractId && !dossierIds) {
            for (const file of files) {
                if (!this.hasAllowedExtension(file)) {
                    file.reason = 'extension';
                } else if (file.size > this.maxFileSize) {
                    file.reason = 'size';
                } else {
                    if (file.name.lastIndexOf('.') > 0) {
                        // ok
                    } else {
                        file.reason = 'extension';
                    }
                }
            }
            const addedFiles = [];
            const rejectedFiles = [];
            for (const file of files) {
                if (file.reason) {
                    rejectedFiles.push(file);
                } else addedFiles.push(file);
            }
            this.openContractOptionsModal(addedFiles, rejectedFiles, folderId, subfolderId);
            return;
        }
        for (const file of files) {
            if (!this.hasAllowedExtension(file)) {
                this.addErrorInFolder(file.name + ': Extension is not allowed', folderId, subfolderId);
            } else if (file.size > this.maxFileSize) {
                this.addErrorInFolder(file.name + ': Can be maximum 50 MB in size', folderId, subfolderId);
            } else {
                if (file.name.lastIndexOf('.') > 0) {
                    await this.saveDocument(file, folderId, subfolderId, dossierIds);
                } else {
                    this.addErrorInFolder(file.name + ': Document is not allowed', folderId, subfolderId);
                }
            }
        }
        if (this.documentAdded) {
            this.toastr.success(
                this.translatePipe.transform('document_saved'),
                this.translatePipe.transform('document_saved_title')
            );
        }
    }

    async saveDocument(file, folderId, subfolderId, dossierIds?) {
        if (!this.dossierId) {
            // contract
        } else dossierIds = [this.dossierId];
        this.addLoadingDocumentsInFolder(file.name, folderId, subfolderId);
        this.folderId = subfolderId || folderId;
        await lastValueFrom(this.documentService.saveDocument(file, this.folderId, dossierIds, this.priceDossierId))
            .then((doc: any) => {
                if (dossierIds) {
                    doc.dossiers = dossierIds.map((item) => {
                        return { id: item };
                    });
                }
                this.addDocumentToDocuments(folderId, doc, subfolderId);
                this.removeLoadingDocumentsInFolder(doc.file_name, folderId, subfolderId);
                this.documentAdded = true;
            })
            .catch((error) => {
                if (error.error.document) {
                    this.removeLoadingDocumentsInFolder(error.error.document.file_name, folderId, subfolderId);
                    this.addErrorInFolder(
                        error.error.document.file_name + ': ' + error.error.details.documents,
                        folderId,
                        subfolderId
                    );
                }
            });
    }

    setDocumentsAsRead(documents: Document[]) {
        console.log(documents);
        if (this.priceDossierId) {
            const body = { items: [] };
            for (const document of documents) {
                if (document.isNew) {
                    body.items.push({
                        documentId: document.id,
                        priceDossierUuid: this.priceDossierId
                    });
                }
            }
            this.documentService.setReadDocument(body).subscribe((next) => {});
            return;
        }
        let dossierIds = [this.dossierId];
        if (!this.dossierId) {
            dossierIds = this.dossiers.map((item) => {
                return item.id;
            });
        }
        if (documents && documents.length > 0) {
            const docIds = [];
            for (const document of documents) {
                if (document.isNew) {
                    docIds.push(document.id);
                }
            }
            if (docIds?.length) {
                dossierIds.forEach((id) => {
                    this.documentService.readDocuments(docIds, id).subscribe((next) => {});
                });
            }
        }
    }

    addErrorInFolder(error, folderId, subfolderId?) {
        const documentTableData = {
            file_name: {
                type: 'error',
                value: error
            },
            create_username: { type: 'error' },
            create_ts: { type: 'error' },
            size: { type: 'error' },
            actions: { type: 'error' }
        };
        for (const doc of this.folders) {
            if (doc.id == folderId) {
                if (!subfolderId) {
                    doc.documents.unshift(documentTableData);
                    this.openCloseDocument(doc, true);
                } else {
                    const index = doc.folders
                        .map((item) => {
                            return item.id;
                        })
                        .indexOf(subfolderId);
                    if (index != -1) {
                        doc.folders[index].documents.unshift(documentTableData);
                        this.openCloseDocument(doc, true, doc.folders[index]);
                    }
                }
                break;
            }
        }
    }

    addLoadingDocumentsInFolder(fileName, folderId, subfolderId?) {
        const documentTableData = {
            id: fileName,
            file_name: {
                type: 'default',
                value: fileName
            },
            create_username: { type: 'shimmer', shimmerType: 'text-m' },
            create_ts: { type: 'shimmer', shimmerType: 'text-m' },
            size: { type: 'shimmer', shimmerType: 'text-m' },
            actions: { type: 'shimmer', shimmerType: 'actions' }
        };

        for (const doc of this.folders) {
            if (doc.id == folderId) {
                doc.loading_documents = true;
                if (!subfolderId) {
                    doc.documents.unshift(documentTableData);
                } else {
                    const index = doc.folders
                        .map((item) => {
                            return item.id;
                        })
                        .indexOf(subfolderId);
                    if (index != -1) {
                        doc.folders[index].documents.unshift(documentTableData);
                    }
                }
                break;
            }
        }
    }

    removeLoadingDocumentsInFolder(fileName, folderId, subfolderId) {
        for (const doc of this.folders) {
            if (doc.id == folderId) {
                doc.loading_documents = false;
                if (!subfolderId) {
                    doc.documents = doc.documents.filter((item) => item.id != fileName);
                } else {
                    const index = doc.folders
                        .map((item) => {
                            return item.id;
                        })
                        .indexOf(subfolderId);
                    if (index != -1) {
                        doc.folders[index].documents = doc.folders[index].documents.filter(
                            (item) => item.id != fileName
                        );
                    }
                }
                break;
            }
        }
    }

    getDocumentsCount(document) {
        if (document.documents) {
            if (document.documents.length == 1) {
                return '1 document';
            } else {
                return document.documents.length + ' documents';
            }
        } else {
            return '0 documents';
        }
    }

    documentsContainsNewDocuments(documents: Document[]) {
        if (documents && documents.length > 0) {
            for (const document of documents) {
                if (document.isNew) {
                    return true;
                }
            }
        }
        return false;
    }

    getDocumentTableData(document) {
        const actions = [];
        const deleteAction = {
            name: this.translatePipe.transform('documents_table_delete'),
            code: 'delete',
            icon: 'trash',
            class: 'delete-red',
            confirm: true,
            confirm_type: 'delete'
        };
        actions.push(deleteAction);

        const size = filesize.partial({ base: 2, standard: 'jedec' });
        let ext = document.file_name.split('.').pop().toLowerCase();
        // switch (ext) {
        //     case 'pjeg':
        //         ext = 'jpg';
        //         break;
        // }
        if (
            [
                'docx',
                'eml',
                'xlsx',
                'key',
                'xls',
                'pptx',
                'jpg',
                'jpeg',
                'num',
                'png',
                'ppt',
                'csv',
                'msg',
                'doc',
                'pdf'
            ].indexOf(ext) == -1
        ) {
            ext = 'file';
        }
        const documentTableData = {
            ...document,
            org_file_name: document.file_name,
            org_create_username: document.create_username,
            org_create_ts: document.create_ts,
            org_size: document.size,
            file_icon: {
                type: 'icon',
                iconclass: 'icon-' + ext,
                class: 'pr-0'
            },
            file_name: {
                type: 'link',
                linkName: document.file_name,
                link: document.blobUrl,
                linkId: document.id,
                target: '_blank',
                new: document.isNew,
                stamps: document.pre_ctg ? [{ value: 'PRE-CRM', classList: 'stamp--primary' }] : null,
                wrap: true,
                class: 'td-filename'
            },
            create_ts: {
                type: 'ts',
                ts: document.create_ts,
                format: 'prettyDateTime'
            },
            size: size(document.size ? document.size : 0),
            actions: {
                type: 'actions',
                actions: actions
            }
        };
        return documentTableData;
    }

    linkedDossiers(dossiers) {
        let output = '';
        for (let i = 0; i < dossiers.length; i++) {
            if (i != 0) output += ', \n';
            const dossierId = dossiers[i].id;
            const dossier = this.dossiers.find((item) => {
                return item.id == dossierId;
            });
            if (dossier) {
                output += dossier.alias;
                if (dossier.dossier_nrs?.length) {
                    output += ` (${dossier.dossier_nrs.join(', ')})`;
                }
            } else {
                console.error('document dossier not found on contract');
            }
        }
        return output;
    }

    setDocumentsTableData(documents) {
        if (documents) {
            return documents.map((document) => this.getDocumentTableData(document));
        } else {
            return [];
        }
    }

    documentsActionClick(item: any, action: string, folderId: string, subfolderId?) {
        switch (action) {
            case 'delete':
                if (this.priceDossierId) {
                    this.deletePriceDossierDocument(folderId, item.id, this.priceDossierId);
                    break;
                }
                let dossierIds;
                if (item.dossiers) {
                    dossierIds = item.dossiers.map((item) => {
                        return item.id;
                    });
                }
                this.deleteDocument(folderId, item.id, subfolderId, dossierIds);
                break;
        }
    }

    documentsTableClick(item: any) {
        switch (item.type) {
            case 'actions':
                break;
            default:
                break;
        }
    }

    trackByDocument(index: number, document: any): number {
        return document.id;
    }

    folderLoaded(folderId) {
        setTimeout(() => {
            this.foldersHelper[folderId].loading = false;
        }, 100);
    }

    subfolderOpended(subfolder) {
        this.setDocumentsAsRead(subfolder.documents);
    }

    openCloseDocument(doc: Folder, open: boolean, subfolder?) {
        if (subfolder) {
            subfolder.open = true;
            return;
        }
        const elementId = doc.id;
        var folder = this.foldersHelper[elementId];
        var wrap = document.getElementById('wrap_' + elementId);
        var icon = document.getElementById('icon' + elementId);

        if (!folder.display || open) {
            icon.classList.remove('icon-up');
            icon.classList.add('icon-down');
            wrap.classList.remove('doc-closed');
            wrap.classList.add('doc-open');

            if (doc.documents && doc.documents.length > 50) {
                folder.loading = true;
                setTimeout(() => {
                    folder.display = true;
                }, 100);
            } else {
                folder.display = true;
            }

            this.setDocumentsAsRead(doc.documents);
        } else {
            folder.display = false;
            icon.classList.remove('icon-down');
            icon.classList.add('icon-up');
            wrap.classList.remove('doc-open');
            wrap.classList.add('doc-closed');
        }
    }

    clickSortDocuments(head, folder) {
        if (folder.documentsSort && folder.documentsSort.code === head.code) {
            folder.documentsSort.dir = folder.documentsSort.dir === 'asc' ? 'desc' : 'asc';
        } else {
            folder.documentsSort = { code: head.code, dir: 'asc' };
        }
        this.sortDocuments(folder);
    }

    sortDocuments(folder) {
        if (folder.documentsSort.code == 'size') {
            folder.documents.sort(function (x, y) {
                if (folder.documentsSort.dir == 'asc') {
                    return x.org_size - y.org_size;
                } else {
                    return y.org_size - x.org_size;
                }
            });
        } else if (folder.documentsSort.code == 'create_ts') {
            folder.documents.sort(function (x, y) {
                let a = new Date(x.org_create_ts).getTime(),
                    b = new Date(y.org_create_ts).getTime();
                if (folder.documentsSort.dir == 'asc') {
                    return a - b;
                } else {
                    return b - a;
                }
            });
        } else {
            folder.documents.sort(function (x, y) {
                let a = x['org_' + folder.documentsSort.code].toUpperCase(),
                    b = y['org_' + folder.documentsSort.code].toUpperCase();
                if (folder.documentsSort.dir == 'asc') {
                    return a == b ? 0 : a > b ? 1 : -1;
                } else {
                    return a == b ? 0 : a < b ? 1 : -1;
                }
            });
        }
    }

    getSortingDocumentsClass(head: any, folder) {
        if (head.sortable && folder.documentsSort && folder.documentsSort.code === head.code) {
            return folder.documentsSort.dir;
        } else if (head.sortable) {
            return 'none';
        }
    }

    drag(e) {
        e.dataTransfer.setData('id', e.target.id);
    }

    clickOnElement(elementId) {
        document.getElementById(elementId).click();
    }

    openConfirmAction(id) {
        document.getElementById(id).style.display = 'block';
    }

    closeConfirmAction(id) {
        document.getElementById(id).style.display = 'none';
    }
}
