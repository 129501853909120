<div class="container page">

    <h1 class="mb-s">{{'bulkupload_title' | translate}}</h1>

    <!-- upload zones -->
    <section class="upload-zones">
        <!-- crm -->
        <ngx-dropzone
            class="upload-zone crm"
            [id]="'dropzone_crm'"
            (change)="uploadDocuments($event, 'crm')"
        >
            <div class="icon-wrap">
                <i class="im-icon im-icon-upload"></i>
            </div>
            <div class="title">
                <span>{{'bulkupload_title_crm_1' | translate}}</span> <br>
                <span>{{'bulkupload_title_crm_3' | translate}}</span>
            </div>
            <div class="descr">
                <span>{{'bulkupload_descr_1' | translate}} </span> <span class="link">{{'bulkupload_descr_2' | translate}}</span> <span> {{'bulkupload_descr_3' | translate}}</span>
            </div>
        </ngx-dropzone>
        <!-- notulen -->
        <ngx-dropzone
            class="upload-zone other"
            [id]="'dropzone_other'"
            (change)="uploadDocuments($event, 'notulen')"
        >
            <div class="icon-wrap">
                <i class="im-icon im-icon-upload"></i>
            </div>
            <div class="title">
                <span>{{'bulkupload_title_other_1' | translate}}</span> <br>
                <span style="font-size: 18px">{{'bulkupload_title_other_3' | translate}}</span>
            </div>
            <div class="descr">
                <span>{{'bulkupload_descr_1' | translate}} </span> <span class="link">{{'bulkupload_descr_2' | translate}}</span> <span> {{'bulkupload_descr_3' | translate}}</span>
            </div>
        </ngx-dropzone>
    </section>

    <ng-container *ngIf="loadFiles">
        <app-spinner></app-spinner>
    </ng-container>

    <ng-container *ngIf="invalid_docs.length > 0 || attention_docexchange_docs.length > 0 || attention_docs.length > 0 || confirm_docs.length > 0 || processing_docs.length > 0">
        <h1 class="section-head">
            <ng-container *ngIf="processing_docs.length > 0">
                {{getProcessingFilesTitle()}} <span class="passive-gray">({{getNumberOfProcessedFiles()}}/{{getNumberOfProcessingFiles()}})</span>
            </ng-container>
            <ng-container *ngIf="processing_docs.length == 0">
                {{'bulkupload_processed_files_title' | translate}}
            </ng-container>
        </h1>

        <!-- processing section -->
        <section class="processing">
            <aside>
                <ng-container *ngIf="processing_docs.length > 0">
                    <div class="title">{{getProcessingTitle()}}</div>
                    <div class="sub">{{getNumberOfProcessedFiles()}} {{'bulkupload_processing_from' | translate}} {{getNumberOfProcessingFiles()}} {{'bulkupload_processing_files' | translate}} ({{getPerCentNumberOfProcessedFiles()}}%)</div>
                    <progress class="progress" [value]="getPerCentNumberOfProcessedFiles()" [max]="100"></progress>
                </ng-container>
                <ng-container *ngIf="processing_docs.length == 0">
                    <div class="title">{{'bulkupload_processed' | translate}}</div>
                    <progress class="progress" [value]="getPerCentNumberOfProcessedFiles()" [max]="100"></progress>
                </ng-container>
                <nav>
                    <a *ngIf="attention_docexchange_docs.length > 0 || attention_docs.length > 0" (click)="toAnchor('attention')"><span>{{'bulkupload_nav_attention' | translate}}</span><span class="amount">{{attention_docexchange_docs.length + attention_docs.length}}</span></a>
                    <a *ngIf="confirm_docs.length > 0" (click)="toAnchor('ready')"><span>{{'bulkupload_nav_ready' | translate}}</span><span class="amount">{{confirm_docs.length}}</span></a>
                    <a *ngIf="invalid_docs.length > 0" (click)="toAnchor('invalid')"><span>{{'bulkupload_nav_invalid' | translate}}</span><span class="amount">{{invalid_docs.length}}</span></a>
                    <a *ngIf="processing_docs.length > 0" (click)="toAnchor('processing')"><span>{{'bulkupload_nav_processed' | translate}}</span><span class="amount">{{processing_docs.length}}</span></a>
                </nav>
            </aside>
            <div>
                <!-- need attention -->
                <ng-container *ngIf="attention_docexchange_docs.length > 0 || attention_docs.length > 0">
                    <h2 id="attention" class="section-head mt-xxl">{{'bulkupload_processed_files_attention' | translate}} ({{attention_docexchange_docs.length + attention_docs.length}})</h2>
                    <div class="content">
                        <div class="table">
                            <tr>
                                <th width="30%" colspan="2">{{'bulkupload_th_filename' | translate}}</th>
                                <th width="20%">{{'bulkupload_th_dossier' | translate}}</th>
                                <th width="20%">{{'bulkupload_th_companies' | translate}}</th>
                                <th width="16%">{{'bulkupload_th_folder' | translate}}</th>
                                <th width="13%">{{'bulkupload_th_source' | translate}}</th>
                                <th width="1%"></th>
                            </tr>
                            <ng-container *ngFor="let item of attention_docexchange_docs; index as $i">
                                <tr>
                                    <td class="status-td" width="1%">
                                        <span class="status status--attention"></span>
                                    </td>
                                    <td class="name-td">
                                        <span class="text-color pr-xxs"><a href="{{item.blobUrl}}" target="_blank">{{item.fileName}}</a></span>
                                        <div class="d-inline-block">
                                            <span class="stamp stamp--crm stamp-bulk-upload stamp-bulk-upload--crm mr-xxs">doc-exchange </span>
                                        </div>
                                    </td>
                                    <td>
                                        <ng-container *ngIf="item.edit_dossier_id">
                                            <app-field-dossiers
                                                [model]="item.dossier" 
                                                (modelChange)="selectDossier(item, $event)"
                                                [selected]='item.selected'
                                                placeholder="{{'dossiers_placeholder' | translate}}">
                                            </app-field-dossiers>
                                        </ng-container>
                                        <ng-container *ngIf="!item.edit_dossier_id">
                                            <div class="d-flex flex-column">
                                                <span class="title">{{item.alias | display}}</span>
                                                <span class="fontsize-s" style="margin-top: 3px;">
                                                    {{getTitleDossierNrs(item.dossierNumbers)}}
                                                    <ng-container *ngIf="getStampDossierNrs(item.dossierNumbers)">
                                                        <div class="d-inline-block app-tooltip-wrap">
                                                            <span class="stamp mr-xxs ml-xxs stamp--small">
                                                                {{getStampDossierNrs(item.dossierNumbers)}}
                                                            </span>
                                                            <div class="app-tooltip app-tooltip-default-centered">{{getTooltipDossierNrs(item.dossierNumbers)}}</div>
                                                        </div>
                                                    </ng-container>
                                                </span>
                                            </div>
                                        </ng-container>
                                    </td>
                                    <td>
                                        <span>{{item.companies?.value | display}}</span>
                                        <div class="d-inline-block ml-xxs" [class.app-tooltip-wrap]="item.companies?.tooltip">
                                            <span *ngFor="let stamp of item.companies?.stamps" class="stamp mr-xxs" [class]="stamp.classList">
                                                {{stamp.value}}
                                            </span>
                                            <div *ngIf="item.companies?.tooltip" class="app-tooltip app-tooltip-default-centered">{{item.companies?.tooltip}}</div>
                                        </div>
                                    </td>
                                    <td>
                                        <ng-container *ngIf="item.edit_folder_id">
                                            <app-field-select 
                                                [model]="item.folderId" 
                                                [options]="folderOptions"
                                                (modelChange)="item.folderId = $event;" 
                                                placeholder="{{'folders_placeholder' | translate}}">
                                            </app-field-select>
                                        </ng-container>
                                        <ng-container *ngIf="!item.edit_folder_id">
                                            {{getFolderTitle(item.folderId) | display}}
                                        </ng-container>
                                        
                                    </td>
                                    <td>
                                        <div class="d-flex flex-column">
                                            <span>{{item.create_ts | formatTs: 'prettyDateTime'}}</span>
                                        </div>
                                    </td>
                                    <td>
                                        <ng-container *ngIf="item.dossierId && item.folderId">
                                            <div class="d-flex align-items-center">
                                                <button (click)="confirmAttention(item)" class="button default">{{'bulkupload_confirm_btn' | translate}}</button>
                                                <button class="button-link underline cancel" (click)="cancelAttention(item); item.selected = ''">{{'bulkupload_cancel_btn' | translate}}</button>
                                            </div>
                                        </ng-container>
                                        <ng-container *ngIf="!item.dossierId || !item.folderId">
                                            <div class="action-button d-flex align-items-center actions position-relative" (click)="helper.actionIndex == ('attention_docexchange_' + $i) ? helper.actionIndex = null : helper.actionIndex = ('attention_docexchange_' + $i); $event.stopPropagation();" appClickOutside (clickOutside)="helper.actionIndex = null;">
                                                <i class="im-icon im-icon-actions"></i>
                                                <app-popover [open]="helper.actionIndex === ('attention_docexchange_' + $i)" positionClass="left" (click)="helper.actionIndex = null; $event.stopPropagation();">
                                                    <ul (click)="helper.actionIndex = null">
                                                        <li class="position-relative delete-red" appClickOutside (clickOutside)="popoverHelper = null" (click)="$event.stopPropagation() || popoverHelper = 'delete_attention_docexchange_' + $i">
                                                            <i class="im-icon im-icon-trash"></i> 
                                                            {{'bulkupload_delete_btn' | translate}}
                                                            <app-confirm-action [type]="'delete'" (confirm)="deleteDocument(item, 'attention'); dismissPopover(); helper.actionIndex = null;" (cancel)="dismissPopover()" [open]="popoverHelper === 'delete_attention_docexchange_' + $i" positionClass="left"></app-confirm-action>
                                                        </li>
                                                    </ul>
                                                </app-popover>
                                            </div>
                                        </ng-container>
                                    </td>
                                </tr>
                            </ng-container>
                            <ng-container *ngFor="let item of attention_docs; index as $i">
                                <tr>
                                    <td class="status-td" width="1%">
                                        <span class="status status--attention"></span>
                                    </td>
                                    <td class="name-td">
                                        <span class="text-color"><a href="{{item.blobUrl}}" target="_blank">{{item.fileName}}</a></span>
                                    </td>
                                    <td>
                                        <ng-container *ngIf="item.edit_dossier_id">
                                            <app-field-dossiers
                                                [model]="item.dossier" 
                                                (modelChange)="selectDossier(item, $event);"
                                                [selected]='item.selected'
                                                placeholder="{{'dossiers_placeholder' | translate}}">
                                            </app-field-dossiers>
                                        </ng-container>
                                        <ng-container *ngIf="!item.edit_dossier_id">
                                            <div class="d-flex flex-column">
                                                <span class="title">{{item.alias | display}}</span>
                                                <span class="fontsize-s" style="margin-top: 3px;">
                                                    {{getTitleDossierNrs(item.dossierNumbers)}}
                                                    <ng-container *ngIf="getStampDossierNrs(item.dossierNumbers)">
                                                        <div class="d-inline-block app-tooltip-wrap">
                                                            <span class="stamp mr-xxs ml-xxs stamp--small">
                                                                {{getStampDossierNrs(item.dossierNumbers)}}
                                                            </span>
                                                            <div class="app-tooltip app-tooltip-default-centered">{{getTooltipDossierNrs(item.dossierNumbers)}}</div>
                                                        </div>
                                                    </ng-container>
                                                </span>
                                            </div>
                                        </ng-container>
                                    </td>
                                    <td>
                                        <span>{{item.companies?.value | display}}</span>
                                        <div class="d-inline-block ml-xxs" [class.app-tooltip-wrap]="item.companies?.tooltip">
                                            <span *ngFor="let stamp of item.companies?.stamps" class="stamp mr-xxs" [class]="stamp.classList">
                                                {{stamp.value}}
                                            </span>
                                            <div *ngIf="item.companies?.tooltip" class="app-tooltip app-tooltip-default-centered">{{item.companies?.tooltip}}</div>
                                        </div>
                                    </td>
                                    <td>
                                        <ng-container *ngIf="item.edit_folder_id">
                                            <app-field-select 
                                                [model]="item.folderId" 
                                                [options]="folderOptions"
                                                (modelChange)="item.folderId = $event;" 
                                                placeholder="{{'folders_placeholder' | translate}}">
                                            </app-field-select>
                                        </ng-container>
                                        <ng-container *ngIf="!item.edit_folder_id">
                                            {{getFolderTitle(item.folderId) | display}}
                                        </ng-container>
                                        
                                    </td>
                                    <td>
                                        <div class="d-flex flex-column">
                                            <span>{{item.create_ts | formatTs: 'prettyDateTime'}}</span>
                                        </div>
                                    </td>
                                    <td>
                                        <ng-container *ngIf="item.dossierId && (item.folderId || !item.edit_folder_id)">
                                            <div class="d-flex align-items-center">
                                                <button (click)="confirmAttention(item)" class="button default-xsm">{{'bulkupload_confirm_btn' | translate}}</button>
                                                <button class="button-link underline cancel" (click)="cancelAttention(item); item.selected = ''">{{'bulkupload_cancel_btn' | translate}}</button>
                                            </div>
                                        </ng-container>
                                        <ng-container *ngIf="!item.dossierId || (!item.folderId && item.edit_folder_id)">
                                            <div class="action-button d-flex align-items-center actions position-relative" (click)="helper.actionIndex == ('attention_' + $i) ? helper.actionIndex = null : helper.actionIndex = ('attention_' + $i); $event.stopPropagation();" appClickOutside (clickOutside)="helper.actionIndex = null;">
                                                <i class="im-icon im-icon-actions"></i>
                                                <app-popover [open]="helper.actionIndex === ('attention_' + $i)" positionClass="left" (click)="helper.actionIndex = null; $event.stopPropagation();">
                                                    <ul (click)="helper.actionIndex = null">
                                                        <li class="position-relative delete-red" appClickOutside (clickOutside)="popoverHelper = null" (click)="$event.stopPropagation() || popoverHelper = 'delete_attention_' + $i">
                                                            <i class="im-icon im-icon-trash"></i> 
                                                            {{'bulkupload_delete_btn' | translate}}
                                                            <app-confirm-action [type]="'delete'" (confirm)="deleteDocument(item, 'attention'); dismissPopover(); helper.actionIndex = null;" (cancel)="dismissPopover()" [open]="popoverHelper === 'delete_attention_' + $i" positionClass="left"></app-confirm-action>
                                                        </li>
                                                    </ul>
                                                </app-popover>
                                            </div>
                                        </ng-container>
                                    </td>
                                </tr>
                            </ng-container>   
                        </div>
                    </div>
                </ng-container>

                <!-- ready -->
                <ng-container *ngIf="confirm_docs.length > 0">
                    <h2 id="ready" class="section-head">{{'bulkupload_processed_files_ready' | translate}} ({{confirm_docs.length}})</h2>
                    <div class="content">
                        <div class="table">
                            <tr>
                                <th width="30%" colspan="2">{{'bulkupload_th_filename' | translate}}</th>
                                <th width="20%">{{'bulkupload_th_dossier' | translate}}</th>
                                <th width="20%">{{'bulkupload_th_companies' | translate}}</th>
                                <th width="16%">{{'bulkupload_th_folder' | translate}}</th>
                                <th width="13%">{{'bulkupload_th_source' | translate}}</th>
                                <th width="1%"></th>
                            </tr>
                            <ng-container *ngFor="let item of confirm_docs; index as $i">
                                <tr>
                                    <td class="status-td" width="1%">
                                        <span class="status status--ready"></span>
                                    </td>
                                    <td class="name-td">
                                        <span class="text-color pr-xxs"><a href="{{item.blobUrl}}" target="_blank">{{item.fileName}}</a></span>
                                        <div class="d-inline-block">
                                            <ng-container *ngIf="item.docExchangeFlag">
                                                <span class="stamp-bulk-upload stamp-bulk-upload--crm mr-xxs">doc-exchange </span>
                                            </ng-container>
                                        </div>
                                    </td>
                                    <td>
                                        <div class="d-flex flex-column">
                                            <span class="title">{{item.alias | display}}</span>
                                            <span class="fontsize-s" style="margin-top: 3px;">
                                                {{getTitleDossierNrs(item.dossierNumbers)}}
                                                <ng-container *ngIf="getStampDossierNrs(item.dossierNumbers)">
                                                    <div class="d-inline-block app-tooltip-wrap">
                                                        <span class="stamp mr-xxs ml-xxs stamp--small">
                                                            {{getStampDossierNrs(item.dossierNumbers)}}
                                                        </span>
                                                        <div class="app-tooltip app-tooltip-default-centered">{{getTooltipDossierNrs(item.dossierNumbers)}}</div>
                                                    </div>
                                                </ng-container>
                                            </span>
                                        </div>
                                    </td>
                                    <td>
                                        <span>{{item.companies?.value | display}}</span>
                                        <div class="d-inline-block ml-xxs" [class.app-tooltip-wrap]="item.companies?.tooltip">
                                            <span *ngFor="let stamp of item.companies?.stamps" class="stamp mr-xxs" [class]="stamp.classList">
                                                {{stamp.value}}
                                            </span>
                                            <div *ngIf="item.companies?.tooltip" class="app-tooltip app-tooltip-default-centered">{{item.companies?.tooltip}}</div>
                                        </div>
                                    </td>
                                    <td>
                                        {{getFolderTitle(item.folderId) | display}}
                                    </td>
                                    <td>
                                        <div class="d-flex flex-column">
                                            <span>{{item.create_ts | formatTs: 'prettyDateTime'}}</span>
                                        </div>
                                    </td>
                                    <td>
                                        <div class="action-button d-flex align-items-center actions position-relative" (click)="helper.actionIndex == ('ready_' + $i) ? helper.actionIndex = null : helper.actionIndex = ('ready_' + $i); $event.stopPropagation();" appClickOutside (clickOutside)="helper.actionIndex = null;">
                                            <i class="im-icon im-icon-actions"></i>
                                            <app-popover [open]="helper.actionIndex === ('ready_' + $i)" positionClass="left" (click)="helper.actionIndex = null; $event.stopPropagation();">
                                                <ul (click)="helper.actionIndex = null">
                                                    <li class="position-relative" appClickOutside (clickOutside)="helper.popover = null" (click)="confirmCRMDocument(item)">
                                                        <i class="im-icon im-icon-check"></i>
                                                        {{'bulkupload_confirm_btn' | translate}}
                                                    </li>
                                                    <li class="position-relative delete-red" appClickOutside (clickOutside)="popoverHelper = null" (click)="$event.stopPropagation() || popoverHelper = 'delete_ready_' + $i">
                                                        <i class="im-icon im-icon-trash"></i> 
                                                        {{'bulkupload_delete_btn' | translate}}
                                                        <app-confirm-action [type]="'delete'" (confirm)="deleteDocument(item, 'confirm'); dismissPopover(); helper.actionIndex = null;" (cancel)="dismissPopover()" [open]="popoverHelper === 'delete_ready_' + $i" positionClass="left"></app-confirm-action>
                                                    </li>
                                                </ul>
                                            </app-popover>
                                        </div>
                                    </td>
                                </tr>
                            </ng-container>
                        </div>
                        <div class="col-md-12">
                            <div class="position-relative d-inline-block">
                                <button [class.disabled]="getNumberOfProcessedFiles() != getNumberOfProcessingFiles()" (click)="$event.stopPropagation() || popoverHelper = 'confirm'" class="button default">
                                    <span>{{getConfirmCRMDocumentsLabel(confirm_docs)}}</span>
                                </button>
                                <app-confirm-action [type]="'confirms'" (confirm)="confirmCRMDocuments(confirm_docs); dismissPopover();" (cancel)="dismissPopover()" [open]="popoverHelper === 'confirm'" positionClass="center"></app-confirm-action>
                            </div>
                        </div>
                    </div>
                </ng-container>

                <!-- invalid -->
                <ng-container *ngIf="invalid_docs.length > 0">
                    <h2 id="invalid" class="section-head">{{'bulkupload_processed_files_invalid' | translate}} ({{invalid_docs.length}})</h2>
                    <div class="content">
                        <div class="table">
                            <tr>
                                <th width="30%" colspan="2">{{'bulkupload_th_filename' | translate}}</th>
                                <th width="56%">{{'bulkupload_th_error' | translate}}</th>
                                <th width="13%">{{'bulkupload_th_source' | translate}}</th>
                                <th width="1%"></th>
                            </tr>
                            <ng-container *ngFor="let item of invalid_docs; index as $i">
                                <tr>
                                    <td class="status-td" width="1%">
                                        <span class="status status--invalid"></span>
                                    </td>
                                    <td class="name-td">
                                        <span>{{item.fileName | display}}</span>
                                        <div class="d-inline-block ml-xxs">
                                            <ng-container *ngIf="item.docExchangeFlag">
                                                <span class="stamp-bulk-upload stamp-bulk-upload--crm mr-xxs">doc-exchange </span>
                                            </ng-container>
                                        </div>
                                    </td>
                                    <td>
                                        {{item.error | display}}
                                    </td>
                                    <td>
                                        <div class="d-flex flex-column">
                                            <span>{{item.create_ts | formatTs: 'prettyDateTime'}}</span>
                                        </div>
                                    </td>
                                    <td>
                                        <div class="action-button d-flex align-items-center actions position-relative" (click)="helper.actionIndex == ('invalid_' + $i) ? helper.actionIndex = null : helper.actionIndex = ('invalid_' + $i); $event.stopPropagation();" appClickOutside (clickOutside)="helper.actionIndex = null;">
                                            <i class="im-icon im-icon-actions"></i>
                                            <app-popover [open]="helper.actionIndex === ('invalid_' + $i)" positionClass="left" (click)="helper.actionIndex = null; $event.stopPropagation();">
                                                <ul (click)="helper.actionIndex = null">
                                                    <li class="position-relative delete-red" appClickOutside (clickOutside)="popoverHelper = null" (click)="$event.stopPropagation() || popoverHelper = 'delete_invalid_' + $i">
                                                        <i class="im-icon im-icon-trash"></i> 
                                                        {{'bulkupload_delete_btn' | translate}}
                                                        <app-confirm-action [type]="'delete'" (confirm)="deleteDocument(item, 'invalid'); dismissPopover(); helper.actionIndex = null;" (cancel)="dismissPopover()" [open]="popoverHelper === 'delete_invalid_' + $i" positionClass="left"></app-confirm-action>
                                                    </li>
                                                </ul>
                                            </app-popover>
                                        </div>
                                    </td>
                                </tr>
                            </ng-container>
                        </div>
                        <div class="col-md-12 no-padding">
                            <div class="d-flex justify-content-end">
                                <div class="position-relative d-inline-block ">
                                    <button [class.disabled]="getNumberOfProcessedFiles() != getNumberOfProcessingFiles()" (click)="$event.stopPropagation() || popoverHelper = 'delete'" class="button-link underline delete">
                                        <span>{{'bulkupload_delete_files_btn' | translate}}</span>
                                    </button>
                                    <app-confirm-action [type]="'deletes'" (confirm)="deleteDocuments(invalid_docs, 'invalid'); dismissPopover();" (cancel)="dismissPopover()" [open]="popoverHelper === 'delete'" positionClass="left"></app-confirm-action>
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-container>

                <!-- processing -->
                <ng-container *ngIf="processing_docs.length > 0">
                    <h2 id="processing" class="section-head">{{'bulkupload_processed_files_processed' | translate}} ({{processing_docs.length}})</h2>
                    <div class="content">
                        <div class="table">
                            <tr>
                                <th width="30%" colspan="2">{{'bulkupload_th_filename' | translate}}</th>
                                <th width="69%">{{'bulkupload_th_status' | translate}}</th>
                                <th width="1%"></th>
                            </tr>
                            <ng-container *ngFor="let item of processing_docs; index as $i">
                                <tr>
                                    <td class="status-td" width="1%">
                                        <span class="status status--processing"></span>
                                    </td>
                                    <td class="name-td">
                                        <span>{{item.fileName | display}}</span>
                                    </td>
                                    <td>
                                        {{getStatusTitle(item.status)}}
                                    </td>
                                    <td>
                                        <div class="action-button d-flex align-items-center actions position-relative" style="background:none">
                                            <i></i>
                                        </div>
                                    </td>
                                </tr>
                            </ng-container>
                        </div>
                    </div>
                </ng-container>
            </div>
        </section>
    </ng-container>
</div>