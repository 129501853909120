<ng-container *ngIf="mode != 'globalError'">
    <ng-container *ngIf="!ready">
        <app-spinner [centerVertically]="true"></app-spinner>
    </ng-container>
    <div *ngIf="ready" class="d-flex detail-modal">
        <!-- side panel -->
        <app-modal-side-panel *ngIf="mode != 'create'" [open]="true">
            <div class="d-flex flex-column w-100 flex-grow-1 scroll-y pb-5">
                <div class="p-l pb-s panel-head">
                    <button (click)="activeModal.dismiss()" [class.disabled]="saving" class="mb-xl fontsize-xs"> <i class="im-icon im-icon-arrow-left mr-0" style="font-size: 18px;"></i> <span class="fontsize-s d-inline-block" style="margin-left: 11px;line-height: 21px;transform: translateY(-2px);">{{'crmCtgDossier_detail_back_btn' | translate}}</span></button>  
                    <div class="">
                        <div class="d-flex flex-column titles">
                            <span class="title">
                                {{dossier.alias}}
                            </span>
                            <span class="subtitle">
                                {{getTitleDossierNrs(dossier.dossier_nrs)}}
                                <ng-container *ngIf="getStampDossierNrs(dossier.dossier_nrs)">
                                    <div class="d-inline-block app-tooltip-wrap" style="transform: translatey(-1px);">
                                        <span class="stamp mr-xxs ml-xxs stamp--small">
                                            {{getStampDossierNrs(dossier.dossier_nrs)}}
                                        </span>
                                        <div class="app-tooltip app-tooltip-default-centered" style="max-width: 140px;width: 140px;width: max-content;">{{getTooltipDossierNrs(dossier.dossier_nrs)}}</div>
                                    </div>
                                </ng-container>
                            </span>
                        </div>
                    </div>
                </div>
                <div class="d-flex flex-grow-1 scroll-y opacity-hide" [class.opacity-show]="true">
                    <ng-container>
                        <app-side-navigation
                            class="d-block w-100"
                            [pageARR]="pages"
                            [model]="activePage"
                            (modelChange)="pageChange($event)">
                        </app-side-navigation>
                    </ng-container>
                </div>
            </div>
        </app-modal-side-panel>
    
        <!-- General -->
        <div class="d-flex flex-column w-100 flex-grow-1 scroll-y pb-5">
            <div class="">
                <!-- header -->
                <div class="detail-modal-header d-flex flex-row justify-content-between align-items-center" style="min-height: 36px;">
                    <ng-container *ngIf="mode == 'create'">
                        <h1 style="min-height: 36px;">{{'crmCtgDossier_detail_create_title' | translate}}</h1>
                        <div class="h-100 d-flex align-items-center">
                            <button class="button tertiary cancel--" (click)="canExit(); activeModal.dismiss();" [class.disabled]="saving"><span>{{'crmCtgDossier_detail_cancel_btn' | translate}}</span></button>
                            <button class="button default-sm ml-xs" (click)="createUpdate()" [class.disabled]="saving">{{'crmCtgDossier_detail_save_btn' | translate}}</button>
                        </div>
                    </ng-container>
                    <ng-container *ngIf="mode != 'create'">
                        <h1 style="min-height: 36px;" class="d-flex align-items-center">
                            <i class="active-page-icon" *ngIf="getActivePage()?.icon" class="im-icon im-icon-{{getActivePage().icon}}"></i>
                            <span>{{'crmCtgDossier_detail_menu_' + activePage | translate}}</span>
                        </h1>
                        <ng-container *ngIf="activePage != 'documents' && activePage != 'history'">
                            <div *ngIf="mode == 'show'" class="h-100 d-flex align-items-center">
                                <!-- edit -->
                                <button class="button tertiary ml-m" (click)="mode='edit';">
                                    <i class="im-icon im-icon-pencil icon-l mr-xxxs"></i><span>{{'crmCtgDossier_detail_edit_btn' | translate}}</span>
                                </button>
                            </div>
                            <div *ngIf="mode == 'edit'" class="h-100 d-flex align-items-center">
                                <button *ngIf="id" class="button tertiary cancel--" (click)="cancelEdit()" [class.disabled]="saving"><span>{{'crmCtgDossier_detail_cancel_btn' | translate}}</span></button>
                                <button class="button default-sm ml-xs" (click)="createUpdate()" [class.disabled]="saving">{{'crmCtgDossier_detail_save_btn' | translate}}</button>
                            </div>
                        </ng-container>
                    </ng-container>
                </div>
    
                <div class="detail-modal-body">
                    <!-- General -->
                    <ng-container *ngIf="activePage == 'general'">
                        <ng-container *ngIf="mode == 'show'">
                            <div class="row display-row">
                                <div class="col-6">
                                    <div class="display-content">                            
                                        <div class="display-label">{{'crmCtgDossier_detail_product' | translate}}</div>
                                        <div class="display-value d-flex flex-row">
                                            <ng-container *ngIf="dossier.products && dossier.products.length > 0">
                                                <ul class="app-list" [class.app-list--single]="dossier.products.length == 1">
                                                    <li *ngFor="let product of dossier.products">
                                                        {{getProductInfo(product)}}
                                                        <span class="subProduct">{{product.authorisationNr}}</span>
                                                    </li>
                                                </ul>
                                            </ng-container>
                                            <ng-container *ngIf="!dossier.products || dossier.products.length == 0">
                                                <span>-</span>
                                            </ng-container>
                                        </div>                     
                                    </div>
                                </div>
                                <div class="col-6">
                                    <div class="display-content">                            
                                        <div class="display-label">{{'crmCtgDossier_detail_companies' | translate}}</div>
                                        <div class="display-value d-flex flex-row">
                                            <ng-container *ngIf="dossier.companies && dossier.companies.length > 0">
                                                <ul class="app-list" [class.app-list--single]="dossier.companies.length == 1">
                                                    <li *ngFor="let company of dossier.companies">
                                                        <div class="d-flex" *ngIf="company.name">
                                                            {{company.name}}
                                                            <div class="pl-xxs" *ngIf="company.lnk_company_id">
                                                                <span class="stamp mr-xxs stamp--gray">{{'crmctgdossiers_table_member' | translate}} <ng-container *ngIf="!company.status">({{'misc_inactive' | translate}})</ng-container></span>
                                                            </div>
                                                        </div>
                                                    </li>
                                                </ul>
                                            </ng-container>
                                            <ng-container *ngIf="!dossier.companies || dossier.companies.length == 0">
                                                <span>-</span>
                                            </ng-container>
                                        </div>                 
                                    </div>
                                </div>
                            </div>

                            <div class="row display-row">
                                <div class="col-3">
                                    <div class="display-content">                            
                                        <div class="display-label">{{'crmCtgDossier_detail_atcChapter' | translate}}</div>
                                        <div class="display-value d-flex flex-row">
                                            <span>{{getAtcChapter(dossier) | display}}</span>
                                        </div>                     
                                    </div>
                                </div>
                                <div class="col-3">
                                    <div class="display-content">                            
                                        <div class="display-label">{{'crmCtgDossier_detail_atcCode' | translate}}</div>
                                        <div class="display-value d-flex flex-row">
                                            <span>{{getAtcCode(dossier) | display}}</span>
                                        </div>                     
                                    </div>
                                </div>
                                <div class="col-3">
                                    <div class="display-content">                            
                                        <div class="display-label">{{'crmCtgDossier_detail_dci' | translate}}</div>
                                        <div class="display-value d-flex flex-row">
                                            <span>{{getDCI(dossier) | display}}</span>
                                        </div>                     
                                    </div>
                                </div>
                            </div>

                            <div class="row display-row">
                                <div class="col-6">
                                    <div class="display-content">                            
                                        <div class="display-label">{{'crmCtgDossier_detail_dossierName' | translate}}</div>
                                        <div class="display-value d-flex flex-row">
                                            <span>{{dossier.name | display}}</span>
                                        </div>                     
                                    </div>
                                </div>
                            </div>

                            <div class="row display-row">
                                <div class="col-6">
                                    <div class="display-content">                            
                                        <div class="display-label">{{'crmCtgDossier_detail_aliasName' | translate}}</div>
                                        <div class="display-value d-flex flex-row">
                                            <span>{{dossier.alias | display}}</span>
                                        </div>                     
                                    </div>
                                </div>
                            </div>

                            <div class="row display-row">
                                <div class="col-6">
                                    <div class="display-content">                            
                                        <div class="display-label">{{'crmCtgDossier_detail_referenceName' | translate}}</div>
                                        <div class="display-value d-flex flex-row">
                                            <span>{{dossier.specialty | display}}</span>
                                        </div>                     
                                    </div>
                                </div>
                            </div>

                            <div class="row display-row">
                                <div class="col-6">
                                    <div class="display-content">                            
                                        <div class="display-label">{{'crmCtgDossier_detail_dossierNumber' | translate}}</div>
                                        <div class="display-value d-flex flex-row">
                                            <ng-container *ngIf="dossier.dossier_nrs && dossier.dossier_nrs.length > 0">
                                                <ul class="app-list" [class.app-list--single]="dossier.dossier_nrs.length == 1">
                                                    <li *ngFor="let dossier_nr of dossier.dossier_nrs">{{dossier_nr}}</li>
                                                </ul>
                                            </ng-container>
                                            <ng-container *ngIf="!dossier.dossier_nrs || dossier.dossier_nrs.length == 0">
                                                <span>-</span>
                                            </ng-container>
                                        </div>                     
                                    </div>
                                </div>
                            </div>
                            <div class="row display-row">
                                <div class="col-6">
                                    <div class="display-content">                            
                                        <div class="display-label">{{'crmCtgDossier_detail_applicationType' | translate}}</div>
                                        <div class="display-value d-flex flex-row">
                                            <span>{{resourceService.getResourceTitleByTypeAndKey('application_type', dossier.application_type) | display}}</span>
                                        </div>                     
                                    </div>
                                </div>
                            </div>
                            <div class="row display-row">
                                <div class="col-6">
                                    <div class="display-content">                            
                                        <div class="display-label">{{'label_loop_procedure' | translate}}</div>
                                        <div class="display-value d-flex flex-row">
                                            <span>{{dossier.loop_procedure | booleanDisplay}}</span>
                                        </div>                     
                                    </div>
                                </div>
                                <div class="col-2">
                                    <div class="display-content">                            
                                        <div class="display-label">{{'label_submission' | translate}}</div>
                                        <div class="display-value d-flex flex-row">
                                            <span>{{dossier.submission | display}}</span>
                                        </div>                     
                                    </div>
                                </div>
                                <div class="col-3">
                                    <div class="display-content">                            
                                        <div class="display-label">{{'label_date_first_submission' | translate}}</div>
                                        <div class="display-value d-flex flex-row">
                                            <span>{{dossier.date_first_submission | formatTs : 'DD-MM-YYYY'}}</span>
                                        </div>                     
                                    </div>
                                </div>
                            </div>
                            <div class="row display-row">
                                <div class="col-6">
                                    <div class="display-content">                            
                                        <div class="display-label">{{'crmCtgDossier_detail_indication' | translate}}</div>
                                        <div class="display-value d-flex flex-row">
                                            <ng-container *ngIf="dossier.indications && dossier.indications.length > 0">
                                                <ul class="app-list" [class.app-list--single]="dossier.indications.length == 1">
                                                    <ng-container *ngFor="let indication of dossier.indications">
                                                        <li>
                                                            {{indication.label}}
                                                            <div *ngIf="indication.comment" class="comment d-flex flex-row">{{indication.comment}}</div>
                                                        </li>
                                                    </ng-container>
                                                </ul>
                                            </ng-container>
                                            <ng-container *ngIf="!dossier.indications || dossier.indications.length == 0">
                                                <span>-</span>
                                            </ng-container>
                                        </div>                     
                                    </div>
                                </div>
                            </div>
                            <div class="row display-row">
                                <div class="col-6">
                                    <div class="display-content">                            
                                        <div class="display-label">{{'label_combination_therapy' | translate}} <i [ngbTooltip]="'At least 2 molecules within the combination are in chapter IV'" tooltipClass="tooltip--simple" class="im-icon im-icon-info-filled label-tooltip-icon"></i></div>
                                        <div class="display-value d-flex flex-row">
                                            <span>{{dossier.combination_therapy | booleanDisplay}}</span>
                                        </div>                     
                                    </div>
                                </div>
                            </div>
                            <div class="row display-row">
                                <div class="col-6">
                                    <div class="display-content">                            
                                        <div class="display-label">{{'crmCtgDossier_detail_expert' | translate}}</div>
                                        <div class="display-value d-flex flex-row">
                                            <ng-container *ngIf="dossier.experts && dossier.experts.length > 0">
                                                <ul class="app-list" [class.app-list--single]="dossier.experts.length == 1">
                                                    <li *ngFor="let user of dossier.experts">
                                                        {{user.value}}
                                                        <ng-container *ngIf="user.is_primary">
                                                            <div class="d-inline-block ml-xxs">
                                                                <span class="stamp mr-xxs stamp--gray">
                                                                    primary
                                                                </span>
                                                            </div>
                                                        </ng-container>
                                                    </li>
                                                </ul>
                                            </ng-container>
                                            <ng-container *ngIf="!dossier.experts || dossier.experts.length == 0">
                                                <span>-</span>
                                            </ng-container>
                                        </div>                     
                                    </div>
                                </div>
                                <div class="col-3">
                                    <div class="display-content">                            
                                        <div class="display-label">{{'crmCtgDossier_detail_dossierManager' | translate}}</div>
                                        <div class="display-value d-flex flex-row">
                                            <ng-container *ngIf="dossier.dossier_managers && dossier.dossier_managers.length > 0">
                                                <ul class="app-list" [class.app-list--single]="dossier.dossier_managers.length == 1">
                                                    <li *ngFor="let user of dossier.dossier_managers">{{user.value}}</li>
                                                </ul>
                                            </ng-container>
                                            <ng-container *ngIf="!dossier.dossier_managers || dossier.dossier_managers.length == 0">
                                                <span>-</span>
                                            </ng-container>
                                        </div>                     
                                    </div>
                                </div>
                            </div>
                            <div class="row display-row">
                                <div class="col-6">
                                    <!-- <div class="display-content">                            
                                        <div class="display-label">{{'crmCtgDossier_detail_potentiallyInContract' | translate}}</div>
                                        <div class="display-value d-flex flex-row">
                                            <span>{{dossier.potentially_contract | booleanDisplay}}</span>
                                        </div>                     
                                    </div> -->
                                    <div class="display-content">                            
                                        <div class="display-label">{{'crmCtgDossier_detail_contractManager' | translate}}</div>
                                        <div class="display-value d-flex flex-row">
                                            <span *ngIf="dossier.contract_manager">{{getContractManager(dossier.contract_manager) | display}}</span>
                                            <span *ngIf="!dossier.contract_manager">-</span>
                                        </div>                     
                                    </div>
                                </div>
                                <div class="col-6">
                                    <!-- <div class="display-content">                            
                                        <div class="display-label">{{'crmCtgDossier_detail_contractManager' | translate}}</div>
                                        <div class="display-value d-flex flex-row">
                                            <span *ngIf="dossier.contract_manager">{{getContractManager(dossier.contract_manager) | display}}</span>
                                            <span *ngIf="!dossier.contract_manager">-</span>
                                        </div>                     
                                    </div> -->
                                </div>
                            </div>
                            <div class="row display-row">
                                <div class="col-12">
                                    <div class="display-content">                            
                                        <div class="display-label">{{'crmCtgDossier_detail_comments' | translate}}</div>
                                        <div class="display-value d-flex flex-row">
                                            <!-- <div class="pre-wrap">{{dossier.comments_general | display}}</div> -->
                                            <app-field-richtext
                                                [model]="dossier.comments_general"
                                                [readOnly] = "true"
                                            >
                                            </app-field-richtext>
                                        </div>                     
                                    </div>
                                </div>
                            </div>
                        </ng-container>
                        <!-- <br>initial_company_name: {{formSTR.initial_company_name | json}}
                        <br>company_id: {{formSTR.company_id | json}}
                        <br>lnk_sam_company_uuid: {{formSTR.lnk_sam_company_uuid | json}} -->
                        <ng-container *ngIf="mode == 'create' || mode == 'edit'">
                            <div class="row display-row">
                                <div class="col-5">
                                    <app-field-products 
                                        [model]="formSTR.products" 
                                        (modelChange)="setProducts($event); formChange(); setDossierNameFromProduct(); productsChange($event);"
                                        label="{{'crmCtgDossier_detail_product' | translate}}"
                                        [error]="validation.products"
                                        placeholder="{{'crmCtgDossier_detail_product_placeholder' | translate}}">
                                    </app-field-products>
                                </div>
                                <div class="col-1"></div>
                                <div class="col-4">
                                    <ng-container *ngIf="id">
                                        <div class="display-content">                            
                                            <div class="display-label">{{'crmCtgDossier_detail_companies' | translate}}</div>
                                            <div class="display-value d-flex flex-row">
                                                <ng-container *ngIf="dossier.companies && dossier.companies.length > 0">
                                                    <ul class="app-list" [class.app-list--single]="dossier.companies.length == 1">
                                                        <li *ngFor="let company of dossier.companies">
                                                            <div class="d-flex" *ngIf="company.name">
                                                                {{company.name}}
                                                                <div class="pl-xxs" *ngIf="company.lnk_company_id">
                                                                    <span class="stamp mr-xxs stamp--gray">{{'crmctgdossiers_table_member' | translate}} <ng-container *ngIf="!company.status">({{'misc_inactive' | translate}})</ng-container></span>
                                                                </div>
                                                            </div>
                                                        </li>
                                                    </ul>
                                                </ng-container>
                                                <ng-container *ngIf="!dossier.companies || dossier.companies.length == 0">
                                                    <span>-</span>
                                                </ng-container>
                                            </div>


                                            <!-- <div class="display-value d-flex flex-row">
                                                <span>{{dossier.company_name | display}}</span>
                                                <ng-container *ngIf="dossier.company_name">
                                                    <div class="pl-xxs" *ngIf="dossier.company_id">
                                                        <span class="stamp mr-xxs stamp--gray">{{'crmctgdossiers_table_member' | translate}} <ng-container *ngIf="!dossier.company_status">({{'misc_inactive' | translate}})</ng-container></span>
                                                    </div>
                                                </ng-container>
                                            </div>                      -->
                                        </div>
                                    </ng-container>
                                    <ng-container *ngIf="!formSTR.id">
                                        <div class="d-flex w-100 align-items-end">
                                            <!-- PHARMA-1603 -->
                                            <!-- <app-field-pharma-companies
                                                style="max-width: 400px;"
                                                class="w-100"
                                                [detectModelChange]="formSTR.company_name"
                                                [initialModel]="formSTR.initial_company_name" 
                                                (modelChange)="formSTR.company_id = $event ? $event.item.key : '';formSTR.initial_company_name = $event ? $event.item : ''; $event?.item ? formSTR.company_name = $event.item.value : ''; $event?.item ? getUsersFromProductCompany($event.item.key) : ''; formChange();"
                                                label="{{'crmCtgDossier_detail_company' | translate}}"
                                                [error]="validation.company_name"
                                                placeholder="{{'crmCtgDossier_detail_company_placeholder' | translate}}"
                                            ></app-field-pharma-companies> -->
                                            <app-field-company-all
                                                style="max-width: 400px;"
                                                class="w-100"
                                                [model]="this.formSTR.companies" 
                                                [multiple]="true"
                                                (modelChange)="companyChange($event)"
                                                label="{{'crmCtgDossier_detail_companies' | translate}}"
                                                [error]="validation.company"
                                                placeholder="{{'crmCtgDossier_detail_company_placeholder' | translate}}"
                                            ></app-field-company-all>
                                        </div>
                                    </ng-container>
                                </div>
                            </div>
                            <div class="row display-row">
                                <div class="col-3">
                                    <ng-container *ngIf="formSTR?.products?.length">
                                        <div class="display-content">
                                            <div class="display-label">{{'crmCtgDossier_detail_atcChapter' | translate}}</div>
                                            <div class="display-value d-flex flex-row">
                                                <span>{{getAtcChapter() | display}}</span>
                                            </div>                     
                                        </div>
                                    </ng-container>
                                    <ng-container *ngIf="!formSTR?.products?.length">
                                        <app-field-text 
                                            [model]="formSTR.atc_chapter" 
                                            [error]="validation.atc_chapter"
                                            (modelChange)="formSTR.atc_chapter = $event; formChange();" 
                                            label="{{'crmCtgDossier_detail_atcChapter' | translate}}"
                                        ></app-field-text>
                                    </ng-container>
                                </div>
                                <div class="col-3">
                                    <ng-container *ngIf="formSTR?.products?.length">
                                        <div class="display-content">
                                            <div class="display-label">{{'crmCtgDossier_detail_atcCode' | translate}}</div>
                                            <div class="display-value d-flex flex-row">
                                                <span>{{getAtcCode() | display}}</span>
                                            </div>                     
                                        </div>
                                    </ng-container>
                                    <ng-container *ngIf="!formSTR?.products?.length">
                                        <app-field-text 
                                            [model]="formSTR.atc_code" 
                                            [error]="validation.atc_code"
                                            (modelChange)="formSTR.atc_code = $event; formChange();" 
                                            label="{{'crmCtgDossier_detail_atcCode' | translate}}"
                                        ></app-field-text>
                                    </ng-container>
                                </div>
                                <div class="col-3">
                                    <ng-container *ngIf="formSTR?.products?.length">
                                        <div class="display-content">
                                            <div class="display-label">{{'crmCtgDossier_detail_dci' | translate}}</div>
                                            <div class="display-value d-flex flex-row">
                                                <span>{{getDCI() | display}}</span>
                                            </div>                     
                                        </div>
                                    </ng-container>
                                    <ng-container *ngIf="!formSTR?.products?.length">
                                        <app-field-text 
                                            [model]="formSTR.dci" 
                                            [error]="validation.dci"
                                            (modelChange)="formSTR.dci = $event; formChange();" 
                                            label="{{'crmCtgDossier_detail_dci' | translate}}"
                                        ></app-field-text>
                                    </ng-container>
                                </div>
                            </div>
                            <div class="row display-row">
                                <div class="col-5">
                                    <app-field-text 
                                        [model]="formSTR.name" 
                                        [error]="validation.name"
                                        (modelChange)="formSTR.name = $event; formChange();" 
                                        label="{{'crmCtgDossier_detail_dossierName' | translate}}"
                                    ></app-field-text>
                                </div>
                            </div>
                            <div class="row display-row">
                                <div class="col-5">
                                    <app-field-text 
                                        [model]="formSTR.alias" 
                                        [error]="validation.alias"
                                        (modelChange)="formSTR.alias = $event; formChange();" 
                                        label="{{'crmCtgDossier_detail_aliasName' | translate}}"
                                    >
                                    </app-field-text>
                                </div>
                            </div>
                            <div class="row display-row">
                                <div class="col-5">
                                    <app-field-text 
                                        [model]="formSTR.specialty" 
                                        [error]="validation.specialty"
                                        (modelChange)="formSTR.specialty = $event; formChange();" 
                                        label="{{'crmCtgDossier_detail_referenceName' | translate}}"
                                    >
                                    </app-field-text>
                                </div>
                            </div>
                            <div class="row display-row">
                                <div class="col-5">
                                    <app-field-multi-input
                                        type="number"
                                        [model]="formSTR.dossier_nrs" 
                                        (modelChange)="formSTR.dossier_nrs = $event; formChange();" 
                                        label="{{'crmCtgDossier_detail_dossierNumber' | translate}}"
                                        [error]="validation.dossier_nrs"
                                        placeholder="{{'crmCtgDossier_detail_dossierNumber_placeholder' | translate}}"
                                        >
                                    </app-field-multi-input>
                                </div>
                            </div>
                            <div class="row display-row">
                                <div class="col-5">
                                    <app-field-radios 
                                        class="d-block"
                                        label="{{'label_loop_procedure' | translate}}"
                                        [model]="formSTR.loop_procedure" 
                                        (modelChange)="formSTR.loop_procedure = $event; formChange();"
                                        [options]="yesNoOptions"
                                        canUncheck = true
                                        [optionColumnWidth]="6"
                                    ></app-field-radios>
                                </div>
                            </div>
                            <div class="row display-row">
                                <div class="col-5">
                                    <app-field-select 
                                        [model]="formSTR.application_type" 
                                        [options]="applicationTypes"
                                        [error]="validation.application_type"
                                        (modelChange)="formSTR.application_type = $event; formChange();" 
                                        label="{{'crmCtgDossier_detail_applicationType' | translate}}"
                                        placeholder="{{'crmCtgDossier_detail_applicationType_placeholder' | translate}}">
                                    </app-field-select>
                                </div>
                                <div class="col-1"></div>
                                <div class="col-2">
                                    <app-field-select 
                                        [model]="formSTR.submission" 
                                        [options]="[{value: '1', key: 1},{value: '2', key: 2},{value: '3', key: 3},{value: '4', key: 4},{value: '5', key: 5}]"
                                        [error]="validation.submission"
                                        (modelChange)="formSTR.submission = $event; formChange();" 
                                        label="{{'label_submission' | translate}}"
                                        placeholder="{{'misc_select' | translate}}">
                                    </app-field-select>
                                </div>
                                <div class="col-3">
                                    <app-field-date 
                                        id="date_first_submission"
                                        [model]="formSTR.date_first_submission" 
                                        (modelChange)="formSTR.date_first_submission = $event; formChange();" 
                                        label="{{'label_date_first_submission' | translate}}"
                                        placeholder="dd-mm-yyyy"
                                        [error]="validation.date_first_submission"
                                        positionDateSelector="bottom">
                                    </app-field-date>
                                </div>
                            </div>
                            <div class="row display-row">
                                <div class="col-5">
                                    <app-field-radios 
                                        class="d-block"
                                        label="{{'label_combination_therapy' | translate}}"
                                        [model]="formSTR.combination_therapy" 
                                        (modelChange)="formSTR.combination_therapy = $event; formChange();"
                                        [options]="yesNoOptions"
                                        canUncheck = true
                                        [optionColumnWidth]="6"
                                        tooltip="'At least 2 molecules within the combination are in chapter IV'"
                                    ></app-field-radios>
                                </div>
                            </div>
                            <div class="row display-row">
                                <div class="col-5">
                                    <app-field-indications 
                                        [model]="formSTR.indications" 
                                        (modelChange)="formSTR.indications = $event; formChange()"
                                        optionalInputPlaceholder="{{'crmCtgDossier_detail_indication_comment_placeholder' | translate}}"
                                        label="{{'crmCtgDossier_detail_indication' | translate}}"
                                        [error]="validation.indications"
                                        placeholder="{{'crmCtgDossier_detail_indication_placeholder' | translate}}">
                                    </app-field-indications>
                                </div>
                            </div>
                            <div class="row display-row">
                                <ng-container *ngIf="!formSTR.experts || formSTR.experts.length < 1">
                                    <div class="col-5">
                                        <app-field-select
                                            label="{{'crmCtgDossier_detail_expert' | translate}}" 
                                            [model]="formSTR.expert" 
                                            [options]="experts"
                                            [optionsToExclude]="getExpertsToExclude(formSTR.experts)"
                                            [error]="validation.experts"
                                            (modelChange)="addExpert($event); formSTR.expert = undefined; formChange();" 
                                            placeholder="{{'crmCtgDossier_detail_expert_placeholder' | translate}}">
                                        </app-field-select>
                                    </div>
                                </ng-container>
                                <ng-container *ngIf="formSTR.experts && formSTR.experts.length > 0">
                                    <div class="col-5">
                                        <table class="table--detail tableAdvisor">
                                            <tr>
                                                <th class="pt-0 pl-0">{{'crmCtgDossier_detail_expert' | translate}}</th>
                                                <th class="pt-0 pl-0">{{'crmCtgDossier_detail_primary' | translate}}</th>
                                                <th class="pt-0 pl-0">{{'crmCtgDossier_detail_delete' | translate}}</th>
                                            </tr>
                                            <ng-container *ngFor="let item of formSTR.experts; index as $i">
                                                <tr>
                                                    <td class="pl-0">
                                                        {{item.value}}
                                                    </td>
                                                    <td>
                                                        <div (click)="setPrimaryExpert(item.key)" [class.active]="item.is_primary" class="field-checkbox flex-grow-1 d-flex align-items-center">
                                                            <span class="fake-checkbox" style="margin-left: 14px;"></span>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <ng-container *ngIf="item.is_primary">
                                                            <i class="icon icon-lock" style="margin-left: 12px;"></i>
                                                        </ng-container>
                                                        <ng-container *ngIf="!item.is_primary">
                                                            <i (click)="deleteExpert($i)" class="im-icon im-icon-x-light delete-icon" style="position: initial;margin-left: 14px;"></i>
                                                        </ng-container>
                                                    </td>
                                                </tr>
                                            </ng-container>
                                        </table>
                                        <app-field-select 
                                            [model]="formSTR.expert" 
                                            [options]="experts"
                                            [optionsToExclude]="getExpertsToExclude(formSTR.experts)"
                                            [error]="validation.experts"
                                            (modelChange)="addExpert($event); formSTR.expert = undefined; formChange();" 
                                            placeholder="{{'crmCtgDossier_detail_expert_placeholder' | translate}}">
                                        </app-field-select>
                                    </div>
                                </ng-container>
                                <div class="col-1"></div>
                                <div class="col-5">
                                    <app-field-multi-input 
                                        [model]="formSTR.dossier_managers" 
                                        (modelChange)="formSTR.dossier_managers = $event;formChange();" 
                                        type="selection"
                                        [options]="dossierManagers"
                                        label="{{'crmCtgDossier_detail_dossierManager' | translate}}"
                                        [error]="validation.dossier_managers"
                                        placeholder="{{'crmCtgDossier_detail_dossierManager_placeholder' | translate}}">
                                    </app-field-multi-input>
                                </div>
                            </div>
                        </ng-container>
                        
                        <ng-container *ngIf="mode == 'create' || mode == 'edit'">
                            <div class="row display-row">
                                <div class="col-5">
                                    <!-- <app-field-radios 
                                        class="d-block"
                                        label="{{'crmCtgDossier_detail_potentiallyInContract' | translate}}"
                                        [model]="formSTR.potentially_contract" 
                                        (modelChange)="formSTR.potentially_contract = $event; formChange();"
                                        [options]="yesNoOptions"
                                        canUncheck = true
                                        [optionColumnWidth]="4">
                                    </app-field-radios> -->
                                    <app-field-select 
                                        [model]="formSTR.contract_manager" 
                                        [options]="contractManagers"
                                        [error]="validation.contract_manager"
                                        (modelChange)="formSTR.contract_manager = $event; formChange();" 
                                        label="{{'crmCtgDossier_detail_contractManager' | translate}}"
                                        placeholder="{{'crmCtgDossier_detail_contractManager_placeholder' | translate}}">
                                    </app-field-select>
                                </div>
                                <div class="col-1"></div>
                                <div class="col-5">
                                    <!-- <app-field-select 
                                        [model]="formSTR.contract_manager" 
                                        [options]="contractManagers"
                                        [error]="validation.contract_manager"
                                        (modelChange)="formSTR.contract_manager = $event; formChange();" 
                                        label="{{'crmCtgDossier_detail_contractManager' | translate}}"
                                        placeholder="{{'crmCtgDossier_detail_contractManager_placeholder' | translate}}">
                                    </app-field-select> -->
                                </div>
                            </div>
                        </ng-container>
                        <ng-container *ngIf="mode == 'create' || mode == 'edit'">
                            <div class="row display-row">
                                <div class="col-12">
                                    <app-field-richtext 
                                        [model]="formSTR.comments_general" 
                                        [error]="validation.comments_general"
                                        (modelChange)="formSTR.comments_general = $event; formChange();" 
                                        (changed) = "formChange();"
                                        label="{{'crmCtgDossier_detail_comments' | translate}}"
                                    >
                                    </app-field-richtext>
                                </div>
                            </div>
                        </ng-container>
                    </ng-container>

                    <!-- Stats -->
                    <ng-container *ngIf="activePage == 'stats'">
                        <ng-container *ngIf="mode == 'show'">
                            <div class="row display-row">
                                <div class="col-4">
                                    <div class="display-content">                            
                                        <div class="display-label">{{'crmCtgDossier_detail_marketingAuth' | translate}}</div>
                                        <div class="display-value d-flex flex-row">
                                            <span>{{resourceService.getResourceTitleByTypeAndKey('marketing_authorization', dossier.marketing_authorization) | display}}</span>
                                        </div>                     
                                    </div>
                                </div>
                                <div class="col-4">
                                    <div class="display-content">                            
                                        <div class="display-label">{{'label_expert_opinion_not_considered' | translate}}</div>
                                        <div class="display-value d-flex flex-row">
                                            <span>{{dossier.expert_opinion_not_considered | booleanDisplay}}</span>
                                        </div>                     
                                    </div>
                                </div>
                            </div>
                            <div class="row display-row">
                                <div class="col-4">
                                    <div class="display-content">                            
                                        <div class="display-label">{{'crmCtgDossier_detail_externalAppointed' | translate}}</div>
                                        <div class="display-value d-flex flex-row">
                                            <span>{{dossier.external_appointed_by_applicant | booleanDisplay}}</span>
                                        </div>                     
                                    </div>
                                </div>
                                <div class="col-4">
                                    <div class="display-content">                            
                                        <div class="display-label">{{'crmCtgDossier_detail_odCollege' | translate}}</div>
                                        <div class="display-value d-flex flex-row">
                                            <span>{{dossier.orphan_drug_college | booleanDisplay}}</span>
                                        </div>                     
                                    </div>
                                </div>
                            </div>
                            <div class="row display-row">
                                <div class="col-4">
                                    <div class="display-content">                            
                                        <div class="display-label">{{'crmCtgDossier_detail_opinion' | translate}}</div>
                                        <div class="display-value d-flex flex-row">
                                            <span>{{dossier.opinion_integrated_d60_d90 | booleanDisplay}}</span>
                                        </div>                     
                                    </div>
                                </div>
                                <div class="col-4">
                                    <div class="display-content">                            
                                        <div class="display-label">{{'crmCtgDossier_detail_eta' | translate}}</div>
                                        <div class="display-value d-flex flex-row">
                                            <span>{{dossier.eta_cup_mnp | booleanDisplay}}</span>
                                        </div>                     
                                    </div>
                                </div>
                            </div>
                            <div class="row display-row">
                                <div class="col-4">
                                    <div class="display-content">                            
                                        <div class="display-label">{{'crmCtgDossier_detail_externalExport' | translate}}</div>
                                        <div class="display-value d-flex flex-row">
                                            <span>{{dossier.external_crm_expert | booleanDisplay}}</span>
                                        </div>                     
                                    </div>
                                </div>
                                <div class="col-4">
                                    <div class="display-content">                            
                                        <div class="display-label">{{'label_patients_in_eta' | translate}}</div>
                                        <div class="display-value d-flex flex-row">
                                            <span>{{dossier.patients_in_eta | numberDisplay}}</span>
                                        </div>                     
                                    </div>
                                </div>
                                <div class="col-4">
                                    <div class="display-content">                            
                                        <div class="display-label">{{'label_total_patients_enrolled' | translate}}</div>
                                        <div class="display-value d-flex flex-row">
                                            <span>{{dossier.total_patients_enrolled | numberDisplay}}</span>
                                        </div>                     
                                    </div>
                                </div>
                            </div>
                            <div class="row display-row">
                                <div class="col-3">
                                    <div class="display-content">
                                        <div class="display-label">{{'label_lnk_asmr_value_appraisal_id' | translate}} <i [ngbTooltip]="'Please fill in appraisal in FR, DE, UK (or if not appraised yet: choose no appraisal)'" tooltipClass="tooltip--simple" class="im-icon im-icon-info-filled label-tooltip-icon"></i></div>
                                        <div class="display-value d-flex flex-row">
                                            <span>{{resourceService.getResourceTitleByTypeAndId('asmr_value_appraisal', dossier.lnk_asmr_value_appraisal_id) | display}}</span>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-3">
                                    <div class="display-content">
                                        <div class="display-label">{{'label_lnk_gba_value_appraisal_id' | translate}} <i [ngbTooltip]="'Please fill in appraisal in FR, DE, UK (or if not appraised yet: choose no appraisal)'" tooltipClass="tooltip--simple" class="im-icon im-icon-info-filled label-tooltip-icon"></i></div>
                                        <div class="display-value d-flex flex-row">
                                            <span>{{resourceService.getResourceTitleByTypeAndId('gba_value_appraisal', dossier.lnk_gba_value_appraisal_id) | display}}</span>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-3">
                                    <div class="display-content">
                                        <div class="display-label">{{'label_lnk_nice_value_appraisal_id' | translate}} <i [ngbTooltip]="'Please fill in appraisal in FR, DE, UK (or if not appraised yet: choose no appraisal)'" tooltipClass="tooltip--simple" class="im-icon im-icon-info-filled label-tooltip-icon"></i></div>
                                        <div class="display-value d-flex flex-row">
                                            <span>{{resourceService.getResourceTitleByTypeAndId('nice_value_appraisal', dossier.lnk_nice_value_appraisal_id) | display}}</span>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-3">
                                    <div class="display-content">                            
                                        <div class="display-label">{{'label_medicine_in_guideline' | translate}} <i [ngbTooltip]="'Only select yes if the medicine is positioned in international guidelines at the time of submission.'" tooltipClass="tooltip--simple" class="im-icon im-icon-info-filled label-tooltip-icon"></i></div>
                                        <div class="display-value">
                                            <span>{{dossier.medicine_in_guideline | booleanDisplay}}</span>
                                        </div>                     
                                    </div>
                                </div>
                            </div>
                        </ng-container>
                        <ng-container *ngIf="mode == 'edit'" class="stats">
                            <div class="row display-row">
                                <div class="col-4">
                                    <app-field-select
                                        [model]="formSTR.marketing_authorization" 
                                        [options]="marketingAuthOptions"
                                        [error]="validation.marketing_authorization"
                                        (modelChange)="formSTR.marketing_authorization = $event; formChange();" 
                                        label="{{'crmCtgDossier_detail_marketingAuth' | translate}}"
                                        placeholder="{{'crmCtgDossier_detail_marketingAuth_placeholder' | translate}}"
                                        maxWidth="">
                                    </app-field-select>
                                </div>
                                <div class="col-4">
                                    <app-field-radios 
                                        class="d-block"
                                        label="{{'label_expert_opinion_not_considered' | translate}}"
                                        [model]="formSTR.expert_opinion_not_considered" 
                                        (modelChange)="formSTR.expert_opinion_not_considered = $event; formChange();"
                                        [options]="yesNoOptions"
                                        canUncheck = true
                                        [optionColumnWidth]="6"
                                    ></app-field-radios>
                                </div>
                            </div>
                            <div class="row display-row">
                                <div class="col-4">
                                    <app-field-select 
                                        [model]="formSTR.external_appointed_by_applicant" 
                                        [options]="externalAppointedByApplicantOptions"
                                        [error]="validation.external_appointed_by_applicant"
                                        (modelChange)="formSTR.external_appointed_by_applicant = $event; formChange();" 
                                        label="{{'crmCtgDossier_detail_externalAppointed' | translate}}"
                                        placeholder="{{'crmCtgDossier_detail_externalAppointed_placeholder' | translate}}"
                                        maxWidth="">
                                    </app-field-select>
                                </div>
                                <div class="col-4">
                                    <app-field-select 
                                        [model]="formSTR.orphan_drug_college" 
                                        [options]="orphanDrugCollegeOptions"
                                        [error]="validation.orphan_drug_college"
                                        (modelChange)="formSTR.orphan_drug_college = $event; formChange();" 
                                        label="{{'crmCtgDossier_detail_odCollege' | translate}}"
                                        placeholder="{{'crmCtgDossier_detail_odCollege_placeholder' | translate}}"
                                        maxWidth="">
                                    </app-field-select>
                                    <!-- <app-field-radios 
                                        class="d-block"
                                        label="{{'crmCtgDossier_detail_odCollege' | translate}}"
                                        [model]="formSTR.orphan_drug_college" 
                                        (modelChange)="formSTR.orphan_drug_college = $event; formChange();"
                                        [options]="orphanDrugCollegeOptions"
                                        canUncheck = true
                                        [optionColumnWidth]="6">
                                    </app-field-radios> -->
                                </div>
                            </div>
                            <div class="row display-row">
                                <div class="col-4">
                                    <app-field-select 
                                        [model]="formSTR.opinion_integrated_d60_d90" 
                                        [options]="opinionIntegratedD60D90Options"
                                        [error]="validation.opinion_integrated_d60_d90"
                                        (modelChange)="formSTR.opinion_integrated_d60_d90 = $event; formChange();" 
                                        label="{{'crmCtgDossier_detail_opinion' | translate}}"
                                        placeholder="{{'crmCtgDossier_detail_opinion_placeholder' | translate}}"
                                        maxWidth="">
                                    </app-field-select>
                                </div>
                                <div class="col-4">
                                    <app-field-radios 
                                        class="d-block"
                                        label="{{'crmCtgDossier_detail_eta' | translate}}"
                                        [model]="formSTR.eta_cup_mnp" 
                                        (modelChange)="formSTR.eta_cup_mnp = $event; formChange();"
                                        [options]="etaCupMnpOptions"
                                        canUncheck = true
                                        [optionColumnWidth]="6">
                                    </app-field-radios>
                                </div>
                            </div>
                            <div class="row display-row">
                                <div class="col-4">
                                    <app-field-select 
                                        [model]="formSTR.external_crm_expert" 
                                        [options]="externalCrmExportOptions"
                                        [error]="validation.external_crm_expert"
                                        (modelChange)="formSTR.external_crm_expert = $event; formChange();" 
                                        label="{{'crmCtgDossier_detail_externalExport' | translate}}"
                                        placeholder="{{'crmCtgDossier_detail_externalExport_placeholder' | translate}}"
                                        maxWidth="">
                                    </app-field-select>
                                </div>
                                <div class="col-4">
                                    <app-field-text 
                                        [model]="formSTR.patients_in_eta" 
                                        (modelChange)="formSTR.patients_in_eta = $event; formChange();" 
                                        label="{{'label_patients_in_eta' | translate}}"
                                        type="integer"
                                        [error]="validation.patients_in_eta">
                                    </app-field-text>
                                </div>
                                <div class="col-4">
                                    <app-field-text 
                                        [model]="formSTR.total_patients_enrolled" 
                                        (modelChange)="formSTR.total_patients_enrolled = $event; formChange();" 
                                        label="{{'label_total_patients_enrolled' | translate}}"
                                        type="integer"
                                        [error]="validation.total_patients_enrolled">
                                    </app-field-text>
                                </div>
                            </div>
                            <div class="row display-row">
                                <div class="col-3">
                                    <app-field-select
                                        placeholder="{{'misc_select' | translate}}"
                                        [model]="formSTR.lnk_asmr_value_appraisal_id" 
                                        [options]="lnk_asmr_value_appraisal_id_options"
                                        [error]="validation.lnk_asmr_value_appraisal_id"
                                        (modelChange)="formSTR.lnk_asmr_value_appraisal_id = $event; formChange();" 
                                        label="{{'label_lnk_asmr_value_appraisal_id' | translate}}"
                                        tooltip="Please fill in appraisal in FR, DE, UK (or if not appraised yet: choose no appraisal)"
                                    ></app-field-select>
                                </div>
                                <div class="col-3">
                                    <app-field-select
                                        placeholder="{{'misc_select' | translate}}"
                                        [model]="formSTR.lnk_gba_value_appraisal_id" 
                                        [options]="lnk_gba_value_appraisal_id_options"
                                        [error]="validation.lnk_gba_value_appraisal_id"
                                        (modelChange)="formSTR.lnk_gba_value_appraisal_id = $event; formChange();" 
                                        label="{{'label_lnk_gba_value_appraisal_id' | translate}}"
                                        tooltip="Please fill in appraisal in FR, DE, UK (or if not appraised yet: choose no appraisal)"
                                    ></app-field-select>
                                </div>
                                <div class="col-3">
                                    <app-field-select
                                        placeholder="{{'misc_select' | translate}}"
                                        [model]="formSTR.lnk_nice_value_appraisal_id" 
                                        [options]="lnk_nice_value_appraisal_id_options"
                                        [error]="validation.lnk_nice_value_appraisal_id"
                                        (modelChange)="formSTR.lnk_nice_value_appraisal_id = $event; formChange();" 
                                        label="{{'label_lnk_nice_value_appraisal_id' | translate}}"
                                        tooltip="Please fill in appraisal in FR, DE, UK (or if not appraised yet: choose no appraisal)"
                                    ></app-field-select>
                                </div>
                                <div class="col-3">
                                    <app-field-radios 
                                        class="d-block"
                                        label="{{'label_medicine_in_guideline' | translate}}"
                                        [model]="formSTR.medicine_in_guideline" 
                                        (modelChange)="formSTR.medicine_in_guideline = $event; formChange();"
                                        [options]="yesNoOptions"
                                        canUncheck = true
                                        [optionColumnWidth]="6"
                                        tooltip="Only select yes if the medicine is positioned in international guidelines at the time of submission."
                                    ></app-field-radios>
                                </div>
                            </div>
                        </ng-container>
                    </ng-container>

                    <!-- Budget -->
                    <ng-container *ngIf="activePage == 'budget'">
                        <div class="row display-row">
                            <div class="col-3">
                                <!-- phase -->
                                <div class="display-content">
                                    <ng-container *ngIf="mode == 'show'">
                                        <div class="display-label">{{'crmCtgDossier_detail_phase' | translate}}</div>
                                        <div class="display-value d-flex flex-row">
                                            <span>{{resourceService.getResourceTitleByTypeAndKey('phase', dossier.phase) | display}}</span>
                                        </div>
                                    </ng-container>
                                    <ng-container *ngIf="mode == 'edit'">
                                        <app-field-select 
                                            id="phase"
                                            [model]="formSTR.phase" 
                                            [options]="phaseOptions"
                                            (modelChange)="formSTR.phase = $event; formChange();" 
                                            label="{{'crmCtgDossier_detail_phase' | translate}}"
                                            [error]="validation.phase"
                                            placeholder="{{'crmCtgDossier_detail_selecteer' | translate}}...">
                                        </app-field-select>
                                    </ng-container>                            
                                </div>
                            </div>
                            <div class="col-3">
                                <div class="display-content">
                                    <ng-container *ngIf="mode == 'show'">
                                        <div class="display-label">{{'crmCtgDossier_detail_budget_status' | translate}}</div>
                                        <div class="display-value d-flex flex-row">
                                            <span>{{resourceService.getResourceTitleByTypeAndKey('budget_status', dossier.budget_status) | display}}</span>
                                        </div>
                                    </ng-container>
                                    <ng-container *ngIf="mode == 'edit'">
                                        <app-field-select 
                                            id="budget_status"
                                            [model]="formSTR.budget_status" 
                                            [options]="budgetStatusOptions"
                                            (modelChange)="formSTR.budget_status = $event; formChange();" 
                                            label="{{'crmCtgDossier_detail_budget_status' | translate}}"
                                            [error]="validation.budget_status"
                                            placeholder="{{'crmCtgDossier_detail_selecteer' | translate}}...">
                                        </app-field-select>
                                    </ng-container>                            
                                </div>
                            </div>
                        </div>
                        <div class="row display-row">
                            <div class="col-3">
                                <div class="display-content">
                                    <ng-container *ngIf="mode == 'show'">
                                        <div class="display-label">{{'crmCtgDossier_detail_commision_decision_date' | translate}}</div>
                                        <div class="display-value d-flex flex-row">
                                            <span>{{dossier.commision_decision_date | formatTs: 'DD-MM-YYYY'}}</span>
                                        </div>
                                    </ng-container>
                                    <ng-container *ngIf="mode == 'edit'">
                                        <app-field-date 
                                            id="commision_decision_date"
                                            [model]="formSTR.commision_decision_date" 
                                            (modelChange)="formSTR.commision_decision_date = $event; formChange();" 
                                            label="{{'crmCtgDossier_detail_commision_decision_date' | translate}}"
                                            placeholder="dd-mm-yyyy"
                                            [error]="validation.commision_decision_date"
                                            positionDateSelector="bottom">
                                        </app-field-date>
                                    </ng-container>                            
                                </div>
                            </div>
                            <div class="col-3">
                                <div class="display-content">
                                    <ng-container *ngIf="mode == 'show'">
                                        <div class="display-label">{{'crmCtgDossier_detail_day_0' | translate}}</div>
                                        <div class="display-value d-flex flex-row">
                                            <span>{{dossier.day_0 | formatTs: 'DD-MM-YYYY'}}</span>
                                        </div>
                                    </ng-container>
                                    <ng-container *ngIf="mode == 'edit'">
                                        <app-field-date 
                                            id="day_0"
                                            [model]="formSTR.day_0" 
                                            (modelChange)="formSTR.day_0 = $event; formChange();" 
                                            label="{{'crmCtgDossier_detail_day_0' | translate}}"
                                            placeholder="dd-mm-yyyy"
                                            [error]="validation.day_0"
                                            positionDateSelector="bottom">
                                        </app-field-date>
                                    </ng-container>                            
                                </div>
                            </div>
                            <div class="col-3">
                                <div class="display-content">
                                    <ng-container *ngIf="mode == 'show'">
                                        <div class="display-label">{{'crmCtgDossier_detail_date_last_meeting' | translate}}</div>
                                        <div class="display-value d-flex flex-row">
                                            <span>{{dossier.date_last_meeting | formatTs: 'DD-MM-YYYY'}}</span>
                                        </div>
                                    </ng-container>
                                    <ng-container *ngIf="mode == 'edit'">
                                        <app-field-date 
                                            id="date_last_meeting"
                                            [model]="formSTR.date_last_meeting" 
                                            (modelChange)="formSTR.date_last_meeting = $event; formChange();" 
                                            label="{{'crmCtgDossier_detail_date_last_meeting' | translate}}"
                                            placeholder="dd-mm-yyyy"
                                            [error]="validation.date_last_meeting"
                                            positionDateSelector="bottom">
                                        </app-field-date>
                                    </ng-container>                            
                                </div>
                            </div>
                        </div>
                        <div class="row display-row">
                            <div class="col-3">
                                <div class="display-content">
                                    <ng-container *ngIf="mode == 'show'">
                                        <div class="display-label">{{'crmCtgDossier_detail_hyp_reimbursement_date' | translate}}</div>
                                        <div class="display-value d-flex flex-row">
                                            <span>{{dossier.hyp_reimbursement_date | formatTs: 'DD-MM-YYYY'}}</span>
                                        </div>
                                    </ng-container>
                                    <ng-container *ngIf="mode == 'edit'">
                                        <app-field-date 
                                            id="hyp_reimbursement_date"
                                            [model]="formSTR.hyp_reimbursement_date" 
                                            (modelChange)="formSTR.hyp_reimbursement_date = $event; formChange();" 
                                            label="{{'crmCtgDossier_detail_hyp_reimbursement_date' | translate}}"
                                            placeholder="dd-mm-yyyy"
                                            [error]="validation.hyp_reimbursement_date"
                                            positionDateSelector="bottom">
                                        </app-field-date>
                                    </ng-container>                            
                                </div>
                            </div>
                            <div class="col-3">
                                <div class="display-content">
                                    <ng-container *ngIf="mode == 'show'">
                                        <div class="display-label">{{'crmCtgDossier_detail_reimbursement_date' | translate}}</div>
                                        <div class="display-value d-flex flex-row">
                                            <span>{{dossier.reimbursement_date | formatTs: 'DD-MM-YYYY'}}</span>
                                        </div>
                                    </ng-container>
                                    <ng-container *ngIf="mode == 'edit'">
                                        <app-field-date 
                                            id="reimbursement_date"
                                            [model]="formSTR.reimbursement_date" 
                                            (modelChange)="formSTR.reimbursement_date = $event; formChange();" 
                                            label="{{'crmCtgDossier_detail_reimbursement_date' | translate}}"
                                            placeholder="dd-mm-yyyy"
                                            [error]="validation.reimbursement_date"
                                            positionDateSelector="bottom">
                                        </app-field-date>
                                    </ng-container>                            
                                </div>
                            </div>
                        </div>
                        <div class="row display-row bordergray-t" style="margin-bottom: 28px;">
                            <div class="col-12 mt-s">
                                <div class="display-label" style="font-size: 15px;">{{'crmCtgDossier_detail_extension_of_indication' | translate}}</div>
                            </div>
                        </div>
                        <div class="row display-row">
                            <div class="col-3">
                                <div class="display-content">
                                    <ng-container *ngIf="mode == 'show'">
                                        <div class="display-label">{{'crmCtgDossier_detail_added_value' | translate}}</div>
                                        <div class="display-value d-flex flex-row">
                                            <span>{{resourceService.getResourceTitleByTypeAndKey('added_value', dossier.added_value) | display}}</span>
                                        </div>
                                    </ng-container>
                                    <ng-container *ngIf="mode == 'edit'">
                                        <app-field-select 
                                            id="added_value"
                                            [model]="formSTR.added_value" 
                                            [options]="addedValueOptions"
                                            (modelChange)="formSTR.added_value = $event; formChange();" 
                                            label="{{'crmCtgDossier_detail_added_value' | translate}}"
                                            [error]="validation.added_value"
                                            placeholder="{{'crmCtgDossier_detail_selecteer' | translate}}...">
                                        </app-field-select>
                                    </ng-container>                            
                                </div>
                            </div>
                            <!-- <div class="col-3">
                                <div class="display-content">
                                    <ng-container *ngIf="mode == 'show'">
                                        <div class="display-label">{{'crmCtgDossier_detail_final_situation' | translate}}</div>
                                        <div class="display-value d-flex flex-row">
                                            <span>{{resourceService.getResourceTitleByTypeAndKey('final_situation', dossier.final_situation) | display}}</span>
                                        </div>
                                    </ng-container>
                                    <ng-container *ngIf="mode == 'edit'">
                                        <app-field-select 
                                            id="final_situation"
                                            [model]="formSTR.final_situation" 
                                            [options]="finalSituationOptions"
                                            (modelChange)="formSTR.final_situation = $event; formChange();" 
                                            label="{{'crmCtgDossier_detail_final_situation' | translate}}"
                                            [error]="validation.final_situation"
                                            placeholder="{{'crmCtgDossier_detail_selecteer' | translate}}...">
                                        </app-field-select>
                                    </ng-container>                            
                                </div>
                            </div> -->
                            <div class="col-3">
                                <div class="display-content">
                                    <ng-container *ngIf="mode == 'show'">
                                        <div class="display-label">{{'crmCtgDossier_detail_price_decrease_percentage' | translate}}</div>
                                        <div class="display-value d-flex flex-row">
                                            <span>{{dossier.price_decrease_percentage | floatDisplay}}</span>
                                        </div>
                                    </ng-container>
                                    <ng-container *ngIf="mode == 'edit'">
                                        <app-field-text 
                                            id="price_decrease_percentage"
                                            [model]="formSTR.price_decrease_percentage" 
                                            (modelChange)="formSTR.price_decrease_percentage = $event; formChange();" 
                                            label="{{'crmCtgDossier_detail_price_decrease_percentage' | translate}}"
                                            placeholder="0.00"
                                            type="float"
                                            [error]="validation.price_decrease_percentage">
                                        </app-field-text>
                                    </ng-container>                            
                                </div>
                            </div>
                            <!-- <div class="col-3">
                                <div class="display-content">
                                    <ng-container *ngIf="mode == 'show'">
                                        <div class="display-label">{{'crmCtgDossier_detail_rule_percentage' | translate}}</div>
                                        <div class="display-value d-flex flex-row">
                                            <span>{{dossier.rule_percentage | floatDisplay}}</span>
                                        </div>
                                    </ng-container>
                                    <ng-container *ngIf="mode == 'edit'">
                                        <app-field-text 
                                            id="rule_percentage"
                                            [model]="formSTR.rule_percentage" 
                                            (modelChange)="formSTR.rule_percentage = $event; formChange();" 
                                            label="{{'crmCtgDossier_detail_rule_percentage' | translate}}"
                                            placeholder="0.00"
                                            type="float"
                                            [error]="validation.rule_percentage">
                                        </app-field-text>
                                    </ng-container>                            
                                </div>
                            </div> -->
                        </div>
                        <div class="row display-row">
                            <div class="col-12 bordergray-b">
                            </div>
                        </div>
                        <div class="row display-row">
                            <div class="col-3">
                                <div class="display-content">
                                    <ng-container *ngIf="mode == 'show'">
                                        <div class="display-label">{{'crmCtgDossier_detail_reimbursement_category' | translate}}</div>
                                        <div class="display-value d-flex flex-row">
                                            <span>{{resourceService.getResourceTitleByTypeAndKey('reimbursement_category', dossier.reimbursement_category) | display}}</span>
                                        </div>
                                    </ng-container>
                                    <ng-container *ngIf="mode == 'edit'">
                                        <app-field-select 
                                            id="reimbursement_category"
                                            [model]="formSTR.reimbursement_category" 
                                            [options]="reimbursementCategoryOptions"
                                            (modelChange)="formSTR.reimbursement_category = $event; formChange();" 
                                            label="{{'crmCtgDossier_detail_reimbursement_category' | translate}}"
                                            [error]="validation.reimbursement_category"
                                            placeholder="{{'crmCtgDossier_detail_selecteer' | translate}}...">
                                        </app-field-select>
                                    </ng-container>                            
                                </div>
                            </div>
                            <div class="col-3">
                                <div class="display-content">
                                    <ng-container *ngIf="mode == 'show'">
                                        <div class="display-label">{{'crmCtgDossier_detail_hospital_retail' | translate}}</div>
                                        <div class="display-value d-flex flex-row">
                                            <span>{{resourceService.getResourceTitleByTypeAndKey('hospital_retail', dossier.hospital_retail) | display}}</span>
                                        </div>
                                    </ng-container>
                                    <ng-container *ngIf="mode == 'edit'">
                                        <app-field-select 
                                            id="hospital_retail"
                                            [model]="formSTR.hospital_retail" 
                                            [options]="hospitalRetailOptions"
                                            (modelChange)="formSTR.hospital_retail = $event; formChange();" 
                                            label="{{'crmCtgDossier_detail_hospital_retail' | translate}}"
                                            [error]="validation.hospital_retail"
                                            placeholder="{{'crmCtgDossier_detail_selecteer' | translate}}...">
                                        </app-field-select>
                                    </ng-container>                            
                                </div>
                            </div>
                            <!-- <div class="col-3">
                                <div class="display-content">
                                    <ng-container *ngIf="mode == 'show'">
                                        <div class="display-label">{{'crmCtgDossier_detail_icer_initial' | translate}}</div>
                                        <div class="display-value d-flex flex-row">
                                            <span>{{dossier.icer_initial | display}}</span>
                                        </div>
                                    </ng-container>
                                    <ng-container *ngIf="mode == 'edit'">
                                        <app-field-text 
                                            id="icer_initial"
                                            [model]="formSTR.icer_initial" 
                                            (modelChange)="formSTR.icer_initial = $event; formChange();" 
                                            label="{{'crmCtgDossier_detail_icer_initial' | translate}}"
                                            placeholder=""
                                            [error]="validation.icer_initial">
                                        </app-field-text>
                                    </ng-container>                            
                                </div>
                            </div> -->
                            <!-- <div class="col-3">
                                <div class="display-content">
                                    <ng-container *ngIf="mode == 'show'">
                                        <div class="display-label">{{'crmCtgDossier_detail_icer_final' | translate}}</div>
                                        <div class="display-value d-flex flex-row">
                                            <span>{{dossier.icer_final | display}}</span>
                                        </div>
                                    </ng-container>
                                    <ng-container *ngIf="mode == 'edit'">
                                        <app-field-text 
                                            id="icer_final"
                                            [model]="formSTR.icer_final" 
                                            (modelChange)="formSTR.icer_final = $event; formChange();" 
                                            label="{{'crmCtgDossier_detail_icer_final' | translate}}"
                                            placeholder=""
                                            [error]="validation.icer_final">
                                        </app-field-text>
                                    </ng-container>                            
                                </div>
                            </div> -->
                        </div>
                        <div class="row display-row">
                            <div class="col-3">
                                <div class="display-content">
                                    <ng-container *ngIf="mode == 'show'">
                                        <div class="display-label">{{'crmCtgDossier_detail_advice_crm' | translate}}</div>
                                        <div class="display-value d-flex flex-row">
                                            <span>{{resourceService.getResourceTitleByTypeAndKey('advice_crm', dossier.advice_crm) | display}}</span>
                                        </div>
                                    </ng-container>
                                    <ng-container *ngIf="mode == 'edit'">
                                        <app-field-select 
                                            id="advice_crm"
                                            [model]="formSTR.advice_crm" 
                                            [options]="adviceCrmOptions"
                                            (modelChange)="formSTR.advice_crm = $event; formChange();" 
                                            label="{{'crmCtgDossier_detail_advice_crm' | translate}}"
                                            [error]="validation.advice_crm"
                                            placeholder="{{'crmCtgDossier_detail_selecteer' | translate}}...">
                                        </app-field-select>
                                    </ng-container>                            
                                </div>
                            </div>
                            <div class="col-3">
                                <div class="display-content">
                                    <ng-container *ngIf="mode == 'show'">
                                        <div class="display-label">{{'crmCtgDossier_detail_moh_approval' | translate}}</div>
                                        <div class="display-value d-flex flex-row">
                                            <span>{{resourceService.getResourceTitleByTypeAndKey('moh_approval', dossier.moh_approval) | display}}</span>
                                        </div>
                                    </ng-container>
                                    <ng-container *ngIf="mode == 'edit'">
                                        <app-field-select 
                                            id="moh_approval"
                                            [model]="formSTR.moh_approval" 
                                            [options]="mohApprovalOptions"
                                            (modelChange)="formSTR.moh_approval = $event; formChange();" 
                                            label="{{'crmCtgDossier_detail_moh_approval' | translate}}"
                                            [error]="validation.moh_approval"
                                            placeholder="{{'crmCtgDossier_detail_selecteer' | translate}}...">
                                        </app-field-select>
                                    </ng-container>                            
                                </div>
                            </div>
                            <!-- <div class="col-3">
                                <div class="display-content">
                                    <ng-container *ngIf="mode == 'show'">
                                        <div class="display-label">{{'crmCtgDossier_detail_final_class' | translate}}</div>
                                        <div class="display-value d-flex flex-row">
                                            <span>{{resourceService.getResourceTitleByTypeAndKey('final_class', dossier.final_class) | display}}</span>
                                        </div>
                                    </ng-container>
                                    <ng-container *ngIf="mode == 'edit'">
                                        <app-field-select 
                                            id="final_class"
                                            [model]="formSTR.final_class" 
                                            [options]="finalClassOptions"
                                            (modelChange)="formSTR.final_class = $event; formChange();" 
                                            label="{{'crmCtgDossier_detail_final_class' | translate}}"
                                            [error]="validation.final_class"
                                            placeholder="{{'crmCtgDossier_detail_selecteer' | translate}}...">
                                        </app-field-select>
                                    </ng-container>                            
                                </div>
                            </div> -->
                            <div class="col-3">
                                <div class="display-content">
                                    <ng-container *ngIf="mode == 'show'">
                                        <div class="display-label">{{'crmCtgDossier_detail_value_acknowledged' | translate}}</div>
                                        <div class="display-value d-flex flex-row">
                                            <span>{{resourceService.getResourceTitleByTypeAndId('value_acknowledged', dossier.lnk_value_acknowledged_id) | display}}</span>
                                        </div>
                                    </ng-container>
                                    <ng-container *ngIf="mode == 'edit'">
                                        <app-field-select 
                                            id="value_acknowledged"
                                            [model]="formSTR.lnk_value_acknowledged_id" 
                                            [options]="valueAcknowledgedOptions"
                                            (modelChange)="formSTR.lnk_value_acknowledged_id = $event; formChange();" 
                                            label="{{'crmCtgDossier_detail_value_acknowledged' | translate}}"
                                            [error]="validation.lnk_value_acknowledged_id"
                                            placeholder="{{'crmCtgDossier_detail_selecteer' | translate}}...">
                                        </app-field-select>
                                    </ng-container>                            
                                </div>
                            </div>
                        </div>
                        <div class="row display-row">
                            <div class="col-3">
                                <div class="display-content">
                                    <ng-container *ngIf="mode == 'show'">
                                        <div class="display-label">{{'crmCtgDossier_detail_hearing' | translate}}</div>
                                        <div class="display-value d-flex flex-row">
                                            <span>{{dossier.hearing | booleanDisplay}}</span>
                                        </div>
                                    </ng-container>
                                    <ng-container *ngIf="mode == 'edit'">
                                        <app-field-select 
                                            id="hearing"
                                            [model]="formSTR.hearing" 
                                            [options]="hearingOptions"
                                            (modelChange)="formSTR.hearing = $event; formChange();" 
                                            label="{{'crmCtgDossier_detail_hearing' | translate}}"
                                            [error]="validation.hearing"
                                            placeholder="{{'crmCtgDossier_detail_selecteer' | translate}}...">
                                        </app-field-select>
                                    </ng-container>                            
                                </div>
                            </div>
                            <div class="col-3">
                                <div class="display-content">
                                    <ng-container *ngIf="mode == 'show'">
                                        <div class="display-label">{{'crmCtgDossier_detail_onco_product' | translate}}</div>
                                        <div class="display-value d-flex flex-row">
                                            <span>{{dossier.onco_product | booleanDisplay}}</span>
                                        </div>
                                    </ng-container>
                                    <ng-container *ngIf="mode == 'edit'">
                                        <app-field-select 
                                            id="onco_product"
                                            [model]="formSTR.onco_product" 
                                            [options]="oncoProductOptions"
                                            (modelChange)="formSTR.onco_product = $event; formChange();" 
                                            label="{{'crmCtgDossier_detail_onco_product' | translate}}"
                                            [error]="validation.onco_product"
                                            placeholder="{{'crmCtgDossier_detail_selecteer' | translate}}...">
                                        </app-field-select>
                                    </ng-container>                            
                                </div>
                            </div>
                        </div>
                        <div class="row display-row">
                            <div class="col-3">
                                <div class="display-content">
                                    <ng-container *ngIf="mode == 'show'">
                                        <div class="display-label">{{'crmCtgDossier_detail_population_submitted' | translate}}</div>
                                        <div class="display-value d-flex flex-row">
                                            <span>{{resourceService.getResourceTitleByTypeAndKey('population_submitted', dossier.population_submitted) | display}}</span>
                                        </div>
                                    </ng-container>
                                    <ng-container *ngIf="mode == 'edit'">
                                        <app-field-select 
                                            id="population_submitted"
                                            [model]="formSTR.population_submitted" 
                                            [options]="populationSubmittedOptions"
                                            (modelChange)="formSTR.population_submitted = $event; formChange();" 
                                            label="{{'crmCtgDossier_detail_population_submitted' | translate}}"
                                            [error]="validation.population_submitted"
                                            placeholder="{{'crmCtgDossier_detail_selecteer' | translate}}...">
                                        </app-field-select>
                                    </ng-container>                            
                                </div>
                            </div>
                            <div class="col-3">
                                <div class="display-content">
                                    <ng-container *ngIf="mode == 'show'">
                                        <div class="display-label">{{'crmCtgDossier_detail_population_reimbursed' | translate}}</div>
                                        <div class="display-value d-flex flex-row">
                                            <span>{{resourceService.getResourceTitleByTypeAndKey('population_reimbursed', dossier.population_reimbursed) | display}}</span>
                                        </div>
                                    </ng-container>
                                    <ng-container *ngIf="mode == 'edit'">
                                        <app-field-select 
                                            id="population_reimbursed"
                                            [model]="formSTR.population_reimbursed" 
                                            [options]="populationReimbursedOptions"
                                            (modelChange)="formSTR.population_reimbursed = $event; formChange();" 
                                            label="{{'crmCtgDossier_detail_population_reimbursed' | translate}}"
                                            [error]="validation.population_reimbursed"
                                            placeholder="{{'crmCtgDossier_detail_selecteer' | translate}}...">
                                        </app-field-select>
                                    </ng-container>                            
                                </div>
                            </div>
                        </div>
                        <div class="row display-row">
                            <div class="col-12">
                                <div class="display-content">
                                    <ng-container *ngIf="mode == 'show'">
                                        <div class="display-label">{{'crmCtgDossier_detail_comments' | translate}}</div>
                                        <div class="display-value d-flex flex-row">
                                            <!-- <div class="pre-wrap">{{dossier.comments | display}}</div> -->
                                            <app-field-richtext
                                                [model]="dossier.comments"
                                                [readOnly] = "true"
                                            >
                                        </app-field-richtext>
                                        </div>
                                    </ng-container>
                                    <ng-container *ngIf="mode == 'edit'">
                                        <app-field-richtext 
                                            id="comments"
                                            label="{{'crmCtgDossier_detail_comments' | translate}}" 
                                            [error]="validation.comments" 
                                            [placeholder]="''" 
                                            (modelChange)="formSTR.comments = $event; formChange();" 
                                            [model]="formSTR.comments || ''">
                                        </app-field-richtext>
                                    </ng-container>                            
                                </div>
                            </div>
                        </div>
                        <div class="row display-row" style="padding-right: 24px;">
                            <div class="col-md-12">
                                <table class="table tableYears">
                                    <thead>
                                        <th></th>
                                        <th [class.display-label]="mode == 'show'" [class.label]="mode == 'edit'">{{'crmCtgDossier_detail_year_1' | translate}}</th>
                                        <th [class.display-label]="mode == 'show'" [class.label]="mode == 'edit'">{{'crmCtgDossier_detail_year_2' | translate}}</th>
                                        <th [class.display-label]="mode == 'show'" [class.label]="mode == 'edit'">{{'crmCtgDossier_detail_year_3' | translate}}</th>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let row of tableYears">
                                            <td [class.display-label]="mode == 'show'" [class.label]="mode == 'edit'">{{row.label}}</td>
                                            <td>
                                                <ng-container *ngIf="mode == 'show'">
                                                    <ng-container *ngIf="row.type != 'float'">
                                                        <span>{{dossier[row.code_year_1] | numberDisplay:'thousandSeparator'}}</span>
                                                    </ng-container>
                                                    <ng-container *ngIf="row.type == 'float'">
                                                        <span>{{dossier[row.code_year_1] | floatDisplay:'thousandSeparator'}}</span>
                                                    </ng-container>
                                                </ng-container>
                                                <ng-container *ngIf="mode == 'edit'">
                                                    <ng-container *ngIf="row.readOnly">
                                                        <ng-container *ngIf="row.type != 'float'">
                                                            <span>{{formSTR[row.code_year_1] | numberDisplay:'thousandSeparator'}}</span>
                                                        </ng-container>
                                                        <ng-container *ngIf="row.type == 'float'">
                                                            <span>{{formSTR[row.code_year_1] | floatDisplay:'thousandSeparator'}}</span>
                                                        </ng-container>
                                                    </ng-container>
                                                    <ng-container *ngIf="!row.readOnly">
                                                        <app-field-text 
                                                            [model]="formSTR[row.code_year_1]" 
                                                            (modelChange)="setTableYear(row.code_year_1, $event); formChange();" 
                                                            placeholder=""
                                                            [type]="row.type? row.type : 'integer'"
                                                            mask="separator"
                                                            thousandSeparator="."
                                                            showMaskTyped="false"
                                                            [error]="validation[row.code_year_1]">
                                                        </app-field-text>
                                                    </ng-container>
                                                </ng-container>
                                            </td>
                                            <td>
                                                <ng-container *ngIf="mode == 'show'">
                                                    <ng-container *ngIf="row.type != 'float'">
                                                        <span>{{dossier[row.code_year_2] | numberDisplay:'thousandSeparator'}}</span>
                                                    </ng-container>
                                                    <ng-container *ngIf="row.type == 'float'">
                                                        <span>{{dossier[row.code_year_2] | floatDisplay:'thousandSeparator'}}</span>
                                                    </ng-container>
                                                </ng-container>
                                                <ng-container *ngIf="mode == 'edit'">
                                                    <ng-container *ngIf="row.readOnly">
                                                        <ng-container *ngIf="row.type != 'float'">
                                                            <span>{{formSTR[row.code_year_2] | numberDisplay:'thousandSeparator'}}</span>
                                                        </ng-container>
                                                        <ng-container *ngIf="row.type == 'float'">
                                                            <span>{{formSTR[row.code_year_2] | floatDisplay:'thousandSeparator'}}</span>
                                                        </ng-container>
                                                    </ng-container>
                                                    <ng-container *ngIf="!row.readOnly">
                                                        <app-field-text 
                                                            [model]="formSTR[row.code_year_2]" 
                                                            (modelChange)="setTableYear(row.code_year_2, $event); formChange();" 
                                                            placeholder=""
                                                            [type]="row.type? row.type : 'integer'"
                                                            mask="separator"
                                                            thousandSeparator="."
                                                            showMaskTyped="false"
                                                            [error]="validation[row.code_year_2]">
                                                        </app-field-text>
                                                    </ng-container>
                                                </ng-container>
                                            </td>
                                            <td>
                                                <ng-container *ngIf="mode == 'show'">
                                                    <ng-container *ngIf="row.type != 'float'">
                                                        <span>{{dossier[row.code_year_3] | numberDisplay:'thousandSeparator'}}</span>
                                                    </ng-container>
                                                    <ng-container *ngIf="row.type == 'float'">
                                                        <span>{{dossier[row.code_year_3] | floatDisplay:'thousandSeparator'}}</span>
                                                    </ng-container>
                                                </ng-container>
                                                <ng-container *ngIf="mode == 'edit'">
                                                    <ng-container *ngIf="row.readOnly">
                                                        <ng-container *ngIf="row.type != 'float'">
                                                            <span>{{formSTR[row.code_year_3] | numberDisplay:'thousandSeparator'}}</span>
                                                        </ng-container>
                                                        <ng-container *ngIf="row.type == 'float'">
                                                            <span>{{formSTR[row.code_year_3] | floatDisplay:'thousandSeparator'}}</span>
                                                        </ng-container>
                                                    </ng-container>
                                                    <ng-container *ngIf="!row.readOnly">
                                                        <app-field-text 
                                                            [model]="formSTR[row.code_year_3]" 
                                                            (modelChange)="setTableYear(row.code_year_3, $event); formChange();" 
                                                            placeholder=""
                                                            [type]="row.type? row.type : 'integer'"
                                                            mask="separator"
                                                            thousandSeparator="."
                                                            showMaskTyped="false"
                                                            [error]="validation[row.code_year_3]">
                                                        </app-field-text>
                                                    </ng-container>
                                                </ng-container>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </ng-container>

                    <!-- Documents -->
                    <ng-container *ngIf="activePage == 'documents'">
                        <ng-container *ngIf="!documentsReady">
                            <app-spinner [centerVertically]="true"></app-spinner>
                        </ng-container>
                        <ng-container *ngIf="documentsReady">
                            <app-documents
                                [foldersInput]="this.documents"
                                [dossierId]="this.id"
                            ></app-documents>
                        </ng-container>
                    </ng-container>

                    <!-- History -->
                    <ng-container *ngIf="activePage == 'history'">
                        <ng-container *ngIf="!historyReady">
                            <app-spinner [centerVertically]="true"></app-spinner>
                        </ng-container>
                        <ng-container *ngIf="historyReady">
                            <ng-container *ngIf="history && history.length > 0">
                                <div *ngFor="let his of history" [id]="'history_' + his.timestamp" class="history">
                                    <div class="row" (click)="openCloseHistoryItem(his)">
                                        <div class="col-4 d-flex align-items-center">
                                            <i class="icon icon-expand mr-xxxs" [id]= "'history_icon' + his.timestamp"></i>
                                            <span class="title">{{his.timestamp | formatTs: 'prettyDateTime'}} by {{his.user}}</span>
                                        </div>
                                    </div>
                                    <div class="row" style="display: block;" *ngIf="hisFolders[his.timestamp].display" >
                                        <div class="content">
                                            <div class="scroll-xx">
                                                <table class="table tableSimple">
                                                    <thead>
                                                        <tr>
                                                            <ng-container *ngFor="let head of historyTableHeads">
                                                                <th [ngStyle]="{'width' : head.width ? head.width : 'auto' }" [class]="head.class || ''"><div class="position-relative">{{head.name}}</div></th>
                                                            </ng-container>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <ng-container *ngFor="let item of his.changes; index as $i;">
                                                            <tr>
                                                                <ng-container *ngFor="let head of historyTableHeads">
                                                                    <td [class]="(item[head.code] && item[head.code].class) || ''" style="padding-left: 14px;">
                                                                        <ng-container *ngIf="item[head.code].type === 'richtext'">
                                                                            <app-field-richtext [model]="item[head.code].value | display" [readOnly] = "true"></app-field-richtext>
                                                                        </ng-container>
                                                                        <ng-container *ngIf="item[head.code].type === 'text'">
                                                                            <span [innerText]="item[head.code].value | display"></span>
                                                                        </ng-container>
                                                                        <ng-container *ngIf="item[head.code] && item[head.code].type === 'field'">
                                                                            <div class="d-flex flex-column">
                                                                                <span class="page">{{item[head.code].page}}</span>
                                                                                <span class="label">{{item[head.code].label}}</span>
                                                                            </div>
                                                                        </ng-container>
                                                                    </td>
                                                                </ng-container>
                                                            </tr>
                                                        </ng-container>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </ng-container>
                            <ng-container *ngIf="!history || history.length < 1">
                                <div class="pl-xs pr-xs mt-s emptystate-wrap">
                                    <app-empty-state value="{{'crmCtgDossiers_history_empty' | translate}}"></app-empty-state>
                                </div>
                            </ng-container> 
                        </ng-container>
                    </ng-container>
                </div>
            </div>
        </div>
    </div>
</ng-container>
