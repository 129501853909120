import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationStart } from '@angular/router';
import { Router } from '@angular/router';
import { Event as NavigationEvent } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { FieldSwitchOptionsModel } from 'src/app/components/fields/field-switch/field-switch.component';
import { CrmCtgDossierDetailComponent } from 'src/app/components/modals/crm-ctg-dossier-detail/crm-ctg-dossier-detail.component';
import { CrmCtgDossiersService } from 'src/app/services/crm-ctg-dossiers.service';
import { HelpersService } from 'src/app/services/helpers.service';
import { ResourceService } from 'src/app/services/resource.service';
import { TranslatePipe } from 'src/app/utils/pipes';
import { HttpParams } from '@angular/common/http';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { LocalStorageService } from 'src/app/services/local-storage.service';
import { UsersService } from 'src/app/services/users.service';

@Component({
    selector: 'app-crm-ctg-dossiers',
    templateUrl: './crm-ctg-dossiers.component.html',
    styleUrls: ['./crm-ctg-dossiers.component.scss']
})
export class CrmCtgDossiersComponent implements OnInit, OnDestroy {
    onDestroy$: Subject<void> = new Subject<void>();
    popoverHelper: any = null;
    searchSTR: any = {};
    exportBusy: boolean = false;
    switchOptions: FieldSwitchOptionsModel;
    statusOptions;
    phaseOptions: any[] = null;
    contractAdvisorOptions: any[] = null;
    modalOpen: boolean = false;

    applicationTypes: any[];

    // table
    tableHeads: any;
    tableSort: { code: string; dir: string };
    tableSortName = 'CTGDossiers_tableSort';

    dossiers: any[] = null;
    startRow: number = 0;
    RPP: number = 20;
    totalRows: number = null;
    tableLoading: boolean = true;
    searchType: string = 'advanced';

    helper: any = {};

    exports: { title: string; code: string; busy: boolean }[];

    constructor(
        private router: Router,
        private ModalService: NgbModal,
        private crmCtgDossiersService: CrmCtgDossiersService,
        private helpersService: HelpersService,
        private resourceService: ResourceService,
        private translatePipe: TranslatePipe,
        private authenticationService: AuthenticationService,
        private localStorageService: LocalStorageService,
        private route: ActivatedRoute,
        private usersService: UsersService
    ) {}

    ngOnInit(): void {
        // this.initSearchSTR();
        const activeDossierId = this.helpersService.getParam('dossierId');
        const mode = this.helpersService.getParam('mode');
        const activePage = this.helpersService.getParam('activePage');
        if (activeDossierId) this.openCreateUpdateModal(activeDossierId, mode, activePage);

        this.exports = [
            {
                title: this.translatePipe.transform('crmCtgDossiers_table_export_dossiers'),
                code: 'dossiers',
                busy: false
            },
            {
                title: this.translatePipe.transform('crmCtgDossiers_table_export'),
                code: 'budget_report',
                busy: false
            }
        ];

        this.switchOptions = [
            {
                title: this.translatePipe.transform('crmCtgDossiers_searchForm_title_'),
                value: 'advanced'
            }
        ];

        this.statusOptions = [
            { title: this.translatePipe.transform('crmctgdossier_searchForm_status_active'), value: 'active' },
            { title: this.translatePipe.transform('crmctgdossier_searchForm_published_archived'), value: 'archived' }
        ];

        this.phaseOptions = this.phaseOptions = this.resourceService.getResourceByType('phase').map((item) => {
            return {
                ...item,
                title: item.label,
                value: item.key
            };
        });

        this.initContractAdvisorOptions();

        this.tableHeads = [
            {
                name: this.translatePipe.transform('crmCtgDossiers_table_dossier'),
                code: 'alias',
                sortable: true,
                width: '20%'
            },
            {
                name: this.translatePipe.transform('crmCtgDossiers_table_dossier_name'),
                code: 'name',
                sortable: true,
                hideable: true,
                hide: true,
                width: '20%'
            },
            {
                name: this.translatePipe.transform('crmCtgDossiers_table_phase'),
                code: 'phase_sequence',
                sortable: true,
                hideable: true,
                width: '10%'
            },
            {
                name: this.translatePipe.transform('crmCtgDossiers_table_expert'),
                code: 'expert',
                sortable: false,
                hideable: true,
                width: '10%'
            },
            {
                name: this.translatePipe.transform('crmCtgDossiers_table_company'),
                code: 'companies',
                sortable: true,
                hideable: true,
                width: '20%'
            },
            {
                name: this.translatePipe.transform('crmCtgDossiers_table_dateLastMeeting'),
                code: 'date_last_meeting',
                sortable: true,
                hideable: true,
                width: '10%'
            },
            {
                name: this.translatePipe.transform('crmCtgDossiers_table_lastModifiedTS'),
                code: 'lastModifiedTS',
                sortable: true,
                hideable: true,
                width: '10%'
            },
            {
                name: this.translatePipe.transform('crmCtgDossiers_table_day0'),
                code: 'day_0',
                sortable: true,
                hideable: true,
                hide: true,
                width: '10%'
            },
            {
                name: this.translatePipe.transform('crmCtgDossiers_table_reimbursementDate'),
                code: 'reimbursement_date',
                sortable: true,
                hideable: true,
                hide: true,
                width: '10%'
            },
            {
                name: '',
                code: 'actions',
                settings: true
            }
        ];

        this.tableSort = this.getTableSort();
        if (!this.tableSort) {
            this.tableSort = { code: 'lastModifiedTS', dir: 'desc' };
        }

        this.router.events
            .pipe(
                filter((event: NavigationEvent) => {
                    return event instanceof NavigationStart;
                })
            )
            .subscribe((event: NavigationStart) => {
                if (event.restoredState) {
                    window.location.href = event.url;
                }
            });

        this.route.queryParams.subscribe((params) => {
            this.startRow = (params.page || 1) * this.RPP - this.RPP;
            this.searchSTR = params.query ? JSON.parse(params.query) : this.getDefaultSearchSTR();
            this.getDossiers();
        });

        if (!this.authenticationService.hasAccessCTGDossiers()) {
            if (this.authenticationService.hasAccessPriceDossiers) {
                this.router.navigate(['price-dossiers']);
            } else if (this.authenticationService.hasAccessNotules()) {
                this.router.navigate(['notules']);
            } else if (this.authenticationService.hasAccessContracts()) {
                this.router.navigate(['contracts']);
            } else if (this.authenticationService.hasAccessCompanyMapping()) {
                this.router.navigate(['tools/companies']);
            } else if (this.authenticationService.hasAccessBulkUploadTool()) {
                this.router.navigate(['tools/bulk-upload-documents']);
            }
        }

        this.getApplicationTypes();

        // this.getDossiers();
    }

    private getTableSort() {
        const tableSortLS = this.localStorageService.getItem(this.tableSortName);
        if (
            tableSortLS &&
            this.tableHeads.filter((item) => item.code == tableSortLS.code && item.sortable == true).length == 1
        ) {
            return tableSortLS;
        }

        return null;
    }

    private getApplicationTypes() {
        this.applicationTypes = this.resourceService.getResourceByType('application_type');
    }

    dismissPopover() {
        setTimeout(() => {
            this.popoverHelper = null;
        }, 1);
    }

    // initSearchSTR() {
    //     this.searchSTR = {};
    // }

    getDefaultSearchSTR() {
        return {
            phase: [],
            contractAdvisor: []
        };
    }

    getSearchQuery() {
        return {
            ...this.searchSTR,
            startRow: this.startRow,
            RRP: this.RPP,
            orderBy: this.getSortARR()
        };
    }

    getSortARR() {
        if (!this.tableSort || !this.tableSort.code) {
            return [];
        }
        return [`${this.tableSort.code} ${this.tableSort.dir}`];
    }

    search(reset?: boolean) {
        this.startRow = 0;
        if (reset) {
            this.router.navigate([], {
                queryParams: {
                    query: null,
                    page: null
                },
                queryParamsHandling: 'merge'
            });
            return;
        }
        if (this.helpersService.objectIsEmpty(this.searchSTR)) {
            this.router.navigate([], {
                queryParams: {
                    query: null,
                    page: null
                },
                queryParamsHandling: 'merge'
            });
        } else {
            this.router.navigate([], {
                queryParams: {
                    query: JSON.stringify(this.searchSTR),
                    page: null
                },
                queryParamsHandling: 'merge'
            });
        }
        // this.getDossiers();
    }

    export(code: 'dossiers' | 'budget_report') {
        const searchSTR = this.getSearchQuery();
        let params = new HttpParams();
        for (const key in searchSTR) {
            params = params.append(key, searchSTR[key]);
        }
        const exportItem = this.exports.find((x) => x.code === code);
        exportItem.busy = true;
        switch (code) {
            case 'dossiers':
                this.crmCtgDossiersService.exportDossiersExtra(searchSTR).subscribe((data) => {
                    setTimeout(() => {
                        exportItem.busy = false;
                    }, 400);
                });
                break;
            case 'budget_report':
                this.crmCtgDossiersService.exportDossiers(searchSTR).subscribe((data) => {
                    setTimeout(() => {
                        exportItem.busy = false;
                    }, 400);
                });
                break;
        }
    }

    getDossiers() {
        const searchSTR = this.getSearchQuery();
        this.tableLoading = true;
        this.crmCtgDossiersService
            .getDossiers(searchSTR)
            .pipe(takeUntil(this.onDestroy$))
            .subscribe((next) => {
                if (this.startRow && this.startRow >= next.rows) {
                    this.resetStartRow();
                    return;
                }
                this.dossiers = next.data.map((item: any) => {
                    item.companies = item.companies ?? [];
                    const companies = (item.companies ?? []).map((company) => company.name);
                    const dossier = {
                        ...item,
                        phase_sequence: {
                            type: 'default',
                            value: this.resourceService.getResourceTitleByTypeAndKey('phase', item.phase)
                        },
                        alias: {
                            type: 'titleSubtitle',
                            title: item.alias,
                            stamps: this.getStampForDossier(item.status),
                            subtitle: this.getTitleMultiValues(item.dossier_nrs, 1),
                            tooltipSubtitle: this.getTooltipValue(item.dossier_nrs, 1),
                            stampsSubtitle: this.getStampForTootltip(item.dossier_nrs, 1)
                        },
                        expert: {
                            type: 'default',
                            value: this.getTitleMultiValues(item.expert, 1),
                            tooltip: this.getTooltipValue(item.expert, 1),
                            stamps: this.getStampForTootltip(item.expert, 1)
                        },
                        companies: {
                            type: 'default',
                            value: this.getTitleMultiValues(companies, 1),
                            tooltip: this.getTooltipValue(companies, 1),
                            stamps: [
                                ...this.getStampForCompany(item.companies[0]),
                                ...this.getStampForTootltip(companies, 1)
                            ]
                        },
                        date_last_meeting: {
                            type: 'ts',
                            ts: item.date_last_meeting,
                            format: 'prettyTs'
                        },
                        lastModifiedTS: {
                            type: 'ts',
                            ts: item.lastModifiedTS,
                            format: 'prettyDateTime'
                        },
                        day_0: {
                            type: 'ts',
                            ts: item.day_0,
                            format: 'prettyTs'
                        },
                        reimbursement_date: {
                            type: 'ts',
                            ts: item.reimbursement_date,
                            format: 'prettyTs'
                        },
                        actions: {
                            type: 'actions',
                            actions: this.getActions(item)
                        }
                    };
                    return dossier;
                });
                this.tableLoading = false;
                this.totalRows = next.rows;
                document.body.scrollTop = 0;
                document.documentElement.scrollTop = 0;
            });
    }

    getActions(item) {
        const actions = [];
        const editAction = {
            name: this.translatePipe.transform('crmCtgDossiers_table_edit'),
            code: 'edit',
            icon: 'pencil'
        };
        actions.push(editAction);
        if (item.status == '1') {
            const archiveAction = {
                name: this.translatePipe.transform('crmCtgDossiers_table_archive'),
                code: 'archive',
                icon: 'folder',
                confirm: true,
                confirm_type: 'archive'
            };
            actions.push(archiveAction);
        }
        if (item.status == '3') {
            const unarchiveAction = {
                name: this.translatePipe.transform('crmCtgDossiers_table_unarchive'),
                code: 'unarchive',
                icon: 'folder',
                confirm: true,
                confirm_type: 'unarchive'
            };
            actions.push(unarchiveAction);
        }
        if (this.authenticationService.hasAccessDeleteCTGDossiers()) {
            const deleteAction = {
                name: this.translatePipe.transform('crmCtgDossiers_table_delete'),
                code: 'delete',
                icon: 'trash',
                class: 'delete-red',
                confirm: true,
                confirm_type: 'delete'
            };
            actions.push(deleteAction);
        }
        return actions;
    }

    getStampForDossier(status) {
        if (status == '3') {
            return [{ value: this.translatePipe.transform('crmCtgDossiers_table_archived'), classList: 'stamp--gray' }];
        }
    }

    getTitleMultiValues(values: any[], numberValues) {
        if (values && values.length > numberValues) {
            return values.slice(0, numberValues).join(', ');
        } else {
            return values ? values.join(', ') : '';
        }
    }

    getTooltipValue(values: any[], numberValues) {
        if (values && values.length > numberValues) {
            return values.slice(numberValues).join(', ');
        }
    }

    getStampForTootltip(values: any[], numberValues) {
        if (values && values.length > numberValues) {
            return [{ value: '+' + (values.length - numberValues), classList: 'stamp--small' }];
        }
        return [];
    }

    getStampForCompany(company) {
        if (company?.name && company?.id) {
            return [{ value: this.translatePipe.transform('crmCtgDossiers_table_member'), classList: 'stamp--gray' }];
        }
        return [];
    }

    archiveDossier(id: number) {
        this.dismissPopover();
        this.crmCtgDossiersService.archiveDossier(id).subscribe((next) => {
            if (this.searchSTR.status) {
                this.dossiers = this.dossiers.filter((item) => item.id != id);
            } else {
                for (let dossier of this.dossiers) {
                    if (dossier.id == id) {
                        dossier.status = 3;
                        dossier.dossier.stamps = this.getStampForDossier(dossier.status);
                        dossier.actions.actions = this.getActions(dossier);
                    }
                }
            }
        });
    }

    unarchiveDossier(id: number) {
        this.dismissPopover();
        this.crmCtgDossiersService.unarchiveDossier(id).subscribe((next) => {
            if (this.searchSTR.status) {
                this.dossiers = this.dossiers.filter((item) => item.id != id);
            } else {
                for (let dossier of this.dossiers) {
                    if (dossier.id == id) {
                        dossier.status = 1;
                        dossier.dossier.stamps = this.getStampForDossier(dossier.status);
                        dossier.actions.actions = this.getActions(dossier);
                    }
                }
            }
        });
    }

    deleteDossier(id: number) {
        this.dismissPopover();
        this.crmCtgDossiersService.deleteDossier(id).subscribe((next) => {
            this.dossiers = this.dossiers.filter((item) => item.id != id);
        });
    }

    resetStartRow() {
        this.router.navigate([], {
            queryParams: {
                page: 1
            },
            queryParamsHandling: 'merge'
        });
    }

    changeStartRow(startRow: number) {
        this.router.navigate([], {
            queryParams: {
                page: Math.ceil(startRow / this.RPP) + 1
            },
            queryParamsHandling: 'merge'
        });
    }

    tableClick(item: any) {
        switch (item.type) {
            case 'actions':
                break;
            default:
                this.openCreateUpdateModal(item.id, 'show');
                break;
        }
    }

    actionClick(item: any, action: string) {
        switch (action) {
            case 'edit':
                this.openCreateUpdateModal(item.id, 'edit');
                break;
            case 'archive':
                this.archiveDossier(item.id);
                break;
            case 'unarchive':
                this.unarchiveDossier(item.id);
                break;
            case 'delete':
                this.deleteDossier(item.id);
                break;
        }
    }

    setSort(code: string, dir: string) {
        this.tableSort = { code: code, dir: dir };
        this.localStorageService.setItem(this.tableSortName, this.tableSort);
        this.getDossiers();
    }

    openCloseMoreOptions() {
        if (this.searchSTR.displayMoreOptions) {
            this.searchSTR.displayMoreOptions = false;
        } else {
            this.searchSTR.displayMoreOptions = true;
        }
    }

    openCreateUpdateModal(id?: number, mode?: string, activePage?: string) {
        if (!activePage) {
            activePage = 'general';
        }
        let windowClass = 'main-modal detail-modal ';
        if (!id) windowClass += 'detail-modal--create';
        const modalRef = this.ModalService.open(CrmCtgDossierDetailComponent, {
            windowClass: windowClass,
            beforeDismiss: () => {
                return modalRef.componentInstance.canExit && modalRef.componentInstance.canExit();
            }
        });

        this.router.navigate([], {
            queryParams: {
                dossierId: id,
                activePage: activePage
            },
            queryParamsHandling: 'merge'
        });

        this.modalOpen = true;
        modalRef.componentInstance.id = id;
        modalRef.componentInstance.mode = mode;
        modalRef.componentInstance.activePage = activePage;
        modalRef.result
            .then((returnValue) => {
                switch (
                    returnValue
                    // case 'delete':
                    //     this.getDossiers();
                    //     break;
                ) {
                }
            })
            .finally(() => {
                this.modalOpen = false;
                this.router.navigate([], {
                    queryParams: {
                        dossierId: null,
                        activePage: null
                    },
                    queryParamsHandling: 'merge'
                });
            });
        // on create
        modalRef.componentInstance.created.subscribe((dossier: any) => {
            setTimeout(() => {
                this.router.navigate([], {
                    queryParams: {
                        dossierId: dossier.id,
                        activePage: activePage
                    },
                    queryParamsHandling: 'merge'
                });
            }, 1);
            this.search(true);
        });
        // on update
        modalRef.componentInstance.updated.subscribe((dossier: any) => {
            this.getDossiers();
        });
    }

    initContractAdvisorOptions() {
        this.usersService
            .getUsers({
                startRow: 0,
                RPP: 99999,
                orderBy: null,
                dossierId: null,
                term: null,
                company_ids: null,
                sam_company_ids: null
            })
            .pipe(takeUntil(this.onDestroy$))
            .subscribe((next) => {
                this.contractAdvisorOptions = next.data
                    .filter((item) => {
                        return item.code === 'contract_manager';
                    })
                    .map((item) => {
                        return {
                            title: item.firstname + ' ' + item.lastname,
                            value: item.id
                        };
                    });
            });
    }

    ngOnDestroy(): void {
        this.onDestroy$.next();
    }
}
