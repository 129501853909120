import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { tap } from 'rxjs/operators';
import * as moment from 'moment';
import { DefaultService } from '../utils/api';
import { HelpersService } from './helpers.service';
import { TranslatorService } from './translator.service';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class PriceDossiersService {
    constructor(
        private defaultService: DefaultService,
        private toastr: ToastrService,
        public helpersService: HelpersService,
        public translatorService: TranslatorService
    ) {}

    public getDossiers(searchSTR: any) {
        return this.defaultService.priceDossiersGetAll(
            searchSTR.startRow,
            searchSTR.rpp,
            searchSTR.orderBy,
            searchSTR.dos_number,
            searchSTR.term,
            searchSTR.company_name,
            searchSTR.status_code,
            searchSTR.date_meeting_from,
            searchSTR.date_meeting_to,
            searchSTR.day0,
            searchSTR.date_end_price_procedure_from,
            searchSTR.date_end_price_procedure_to,
            searchSTR.interuption_date,
            searchSTR.year_of_decision,
            searchSTR.crp_cpsp,
            searchSTR.type
        );
    }

    public exportDossiers(searchSTR: any) {
        return this.defaultService
            .priceDossiersExportPriceDossiers(
                searchSTR.startRow,
                searchSTR.rpp,
                searchSTR.orderBy,
                searchSTR.dos_number,
                searchSTR.term,
                searchSTR.company_name,
                searchSTR.status_code,
                searchSTR.date_meeting_from,
                searchSTR.date_meeting_to,
                searchSTR.day0,
                searchSTR.date_end_price_procedure_from,
                searchSTR.date_end_price_procedure_to,
                searchSTR.interuption_date,
                searchSTR.year_of_decision,
                searchSTR.crp_cpsp,
                searchSTR.type
            )
            .pipe(
                tap((response) => {
                    this.helpersService.downloadBlob(response, `Price_dossiers_${moment().format('YYYY-MM-DD')}.csv`);
                })
            );
    }

    public getDossier(id: string) {
        return this.defaultService.priceDossierGetById(id);
    }

    public getDocuments(uuid: string) {
        return this.defaultService.priceDossierGetDocuments(uuid);
    }

    unarchiveDossier(id: string) {
        //TODO: impl me!!
        return new Observable().pipe(
            tap((next) => {
                this.toastr.success(
                    this.translatorService.getTranslation('price_dossier_toastr_unarchived'),
                    this.translatorService.getTranslation('price_dossier_toastr_unarchived_title')
                );
            })
        );
    }

    deleteDossier(id: string) {
        return this.defaultService.priceDossierDelete(id).pipe(
            tap((next) => {
                this.toastr.success(
                    this.translatorService.getTranslation('price_dossier_toastr_delete'),
                    this.translatorService.getTranslation('price_dossier_toastr_delete_title')
                );
            })
        );
    }

    archiveDossier(id: string) {
        //TODO: impl me!!
        return new Observable().pipe(
            tap((next) => {
                this.toastr.success(
                    this.translatorService.getTranslation('price_dossier_toastr_archived'),
                    this.translatorService.getTranslation('price_dossier_toastr_archived_title')
                );
            })
        );
    }

    public updateInsertDossier(body: any, section: string, id?: string) {
        let result;
        switch (section) {
            case 'overview':
                result = this.defaultService.priceDossierUpdateOrCreate(body).pipe(
                    tap((next) => {
                        this.showCreateUpdateToast(id);
                    })
                );
                break;
        }
        return result;
    }

    showCreateUpdateToast(id: string) {
        if (id) {
            this.toastr.success(
                this.translatorService.getTranslation('pricedossier_toastr_saved'),
                this.translatorService.getTranslation('pricedossier_toastr_saved_title')
            );
        } else {
            this.toastr.success(
                this.translatorService.getTranslation('pricedossier_toastr_created'),
                this.translatorService.getTranslation('pricedossier_toastr_created_title')
            );
        }
    }
}
