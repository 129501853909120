import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class LocalStorageService {
    constructor() {}

    getItem(itemId) {
        return JSON.parse(localStorage.getItem(itemId));
    }

    setItem(itemId, item) {
        localStorage.setItem(itemId, JSON.stringify(item));
    }
}
