import { Inject, Injectable } from '@angular/core';
import { AuthenticationService } from './authentication.service';
import { DefaultService } from '../utils/api';
import { ConfigService } from './config.service';

@Injectable({
    providedIn: 'root'
})
export class InitService {
    constructor(
        public AuthenticationService: AuthenticationService,
        private DefaultService: DefaultService,
        private ConfigService: ConfigService
    ) {}

    initialize(): Promise<any> {
        let that = this;
        return new Promise<void>((resolve, reject) => {
            that.DefaultService.configGetConfig().subscribe((next) => {
                that.ConfigService.setConfig(next);
                resolve();
            });
        });
    }
}
