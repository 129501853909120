import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, input } from '@angular/core';
import { forkJoin, Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, switchMap, map } from 'rxjs/operators';
import { CompanyService } from 'src/app/services/company.service';

@Component({
    selector: 'app-field-company-all',
    templateUrl: './field-company-all.component.html',
    styleUrls: ['./field-company-all.component.scss']
})
export class FieldCompanyAllComponent implements OnInit, OnChanges {
    @Input() label?: string;
    @Input() error?: string | string[];
    @Input() placeholder?: string;
    @Input() model: any;
    @Input() detectModelChange?: any;
    @Input() multiple: boolean = false;
    @Output() modelChange = new EventEmitter();
    @Output() enter = new EventEmitter();
    inputValue: { company?: { name: string; id: string; company_status?: boolean }; key: string; value: string };
    companyOptionsToExclude: string[] = [];
    samCompanyOptionsToExclude: string[] = [];

    constructor(private companyService: CompanyService) {}

    ngOnInit(): void {
        if (this.multiple) {
            this.updateModel();
        } else {
            this.inputValue = { key: this.model?.id, value: this.model?.name };
            if (this.model?.company_id) {
                this.inputValue.company = {
                    name: this.model.name,
                    id: this.model.company_id,
                    company_status: this.model.company_status
                };
            }
        }
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.model) {
            if (this.multiple) {
                this.updateModel();
            } else {
                this.inputValue = { key: this.model?.id, value: this.model?.name };
                if (this.model?.company_id) {
                    this.inputValue.company = { name: this.model.name, id: this.model.company_id };
                }
            }
        }
    }

    updateModel() {
        if (this.model == null || this.model == undefined) {
            this.model = [];
        }
    }

    formatter = (item: { key: any; value: string }) => item.value;

    getCalls(term) {
        const observables$ = [
            this.companyService.searchPharmaCompanies(term, this.companyOptionsToExclude),
            this.companyService.searchSAMCompanies(term, [], undefined, this.samCompanyOptionsToExclude)
        ];
        return forkJoin(observables$);
    }

    search = (text$: Observable<string>) =>
        text$.pipe(
            debounceTime(300),
            distinctUntilChanged(),
            filter((term) => {
                return term.length > 1;
            }),
            switchMap((term) => this.getCalls(term)),
            // switchMap((term) => this.companyService.searchPharmaCompanies(term)),
            map((val: any) => {
                val = { data: [...val[0]?.data, ...val[1]?.data], rows: val[0]?.rows + val[1]?.rows };

                let companies = [];
                for (const company of val.data) {
                    console.log(company);
                    companies.push({
                        key: company.sam_id ? company.uuid : company.id,
                        value: company.name,
                        company: company,
                        company_status: company.company_status
                    });
                }

                if (companies.length == 0) {
                    companies.push({
                        key: '',
                        value: 'No companies found.',
                        company: null
                    });
                }
                return companies;
            })
        );

    onSelect($event) {
        this.inputValue = $event?.item;
        if ($event) {
            if ($event.item.company?.sam_id) {
                this.samCompanyOptionsToExclude = [...this.samCompanyOptionsToExclude, $event?.item?.key];
            } else {
                this.companyOptionsToExclude = [...this.companyOptionsToExclude, $event?.item?.key];
            }
            if (this.multiple) {
                this.addInputValueToModel();
            } else {
                this.modelChange.emit($event);
            }
        }
    }

    deleteValue(index) {
        this.removeOptionFromExclude(this.model[index]);
        this.model.splice(index, 1);
        if (this.error && this.error[index]) {
            (this.error as any).splice(index, 1);
        }
        this.modelChange.emit(this.model);
    }

    removeOptionFromExclude(value) {
        if (value?.company?.sam_id) {
            const index = this.samCompanyOptionsToExclude.findIndex((i) => i === value.key);
            this.samCompanyOptionsToExclude.splice(index, 1);
        } else {
            const index = this.companyOptionsToExclude.findIndex((i) => i === value.key);
            this.companyOptionsToExclude.splice(index, 1);
        }
    }

    change($event) {
        if (!this.model) {
            this.modelChange.emit(undefined);
        }
    }

    onBlur($event?) {
        if (!this.model) {
            this.model = undefined;
        }
    }

    isArray(val) {
        if (val && val instanceof Array) {
            return true;
        }
        return false;
    }

    addInputValueToModel() {
        if (this.inputValue) {
            this.model.push(this.inputValue);
        }
        setTimeout(() => {
            this.inputValue = undefined;
        }, 1);
        this.modelChange.emit(this.model);
    }
}
