<table class="table--detail" [class.readonly]="readOnly">
    <tr>
        <th style="width: 27%;">{{'contract_product' | translate}}</th>
        <th style="width: 20%;">{{'contract_product_impact' | translate}}</th>
        <th style="width: 22%;"></th>
        <th style="width: 30%;">{{'contract_comment' | translate}}</th>
        <th style="width: 1%;"></th>
        <!-- <th style="width: auto"></th> -->
    </tr>
    <ng-container *ngIf="model">
        <tr *ngFor="let product of model; index as $i;">
            <td>
                {{product.productPackage || product.product}}
                <span class="subProduct">{{product.authorisationNr}}</span>
            </td>
            <td>
                <ng-container *ngIf="!readOnly">
                    <app-field-select
                        [model]="product.impact" 
                        [error]="error && HelpersService.isObj(error) && error[$i]?error[$i].impact:''"
                        [options]="impactOptions"
                        (modelChange)="product.impact = $event; ($event === 'interpretative_rule' || $event === 'na' ? product.percentage = null : '')" 
                        placeholder="{{'contract_product_impact_placeholder' | translate}}">
                    </app-field-select>
                </ng-container>
                <ng-container *ngIf="readOnly">
                    {{resourceService.getResourceTitleByTypeAndKey('contract_product_impact', product.impact) | display}}
                </ng-container>
            </td>
            <td>
                <ng-container *ngIf="!readOnly && product.impact && (product.impact !== 'interpretative_rule' && product.impact !== 'na')">
                    <app-field-text
                        [model]="product.percentage"
                        [error]="error && HelpersService.isObj(error) && error[$i]?error[$i].percentage:''"
                        (modelChange)="product.percentage = $event; " 
                        placeholder="0.00"
                        type="float"
                        [afterString]="getPercentageLabel(product.impact)" 
                        [typeClass] = "'afterString'"
                    >
                    </app-field-text>
                </ng-container>
                <ng-container *ngIf="readOnly">
                    {{product.percentage | customPercentage}}
                </ng-container>
            </td>
            <td>
                <ng-container *ngIf="!readOnly">
                    <app-field-richtext 
                        [model]="product.comment" 
                        (modelChange)="product.comment = $event;" 
                        (changed) = "formChange();"
                    >
                    </app-field-richtext>
                </ng-container>
                <ng-container *ngIf="readOnly">
                    <app-field-richtext
                        [model]="product.comment"
                        [readOnly]="true"
                    ></app-field-richtext>
                </ng-container>
            </td>
            <td class="shrink" style="text-align: right;">
                <i *ngIf="!readOnly" (click)="deleteValue($i); error = null" class="im-icon im-icon-x-light delete-icon"></i>
            </td>
        </tr>
        <tr *ngIf="!model.length && readOnly" class="table-empty-line">
            <td>-</td>
            <td>-</td>
            <td></td>
            <td>-</td>
            <td></td>
        </tr>
    </ng-container>
    <ng-container *ngIf="!readOnly">
        <tr>
            <td colspan="4" style="border-bottom: none;">
                <div class="field field-typeahead position-relative">
                    <input
                        style="max-width: 340px"
                        [class.error-field]="error && !HelpersService.isObj(error)"
                        (focus)="onFocus($event)"
                        (click)="onFocus($event)"
                        id="typeahead-prevent-manual-entry"  
                        type="text"
                        [(ngModel)]="selected"
                        placeholder="{{'products_placeholder' | translate}}"
                        (selectItem)="onSelect($event); error = null"
                        [ngbTypeahead]="search"
                        [inputFormatter]="formatter"
                        [resultFormatter]="formatter"
                        [resultTemplate]="template"
                        editable=false />

                    <ng-template #template let-r="result" let-t="term">
                        <ngb-highlight [result]="r.value" [term]="t"></ngb-highlight>
                        <span *ngIf="r.product" class="typeahead-after">{{r.product.authorisationNr}}</span>
                    </ng-template>
                    <span *ngIf="error && !HelpersService.isObj(error)" class="error-field-display">{{error}}</span>
                </div>
            </td>
        </tr>
    </ng-container>
</table>