<div class="container page">
    <div class="d-flex">

        <!-- side panel -->
        <div class="side-panel-wrap">
            <div class="side-panel d-flex flex-column justify-content-between">
                <div>
                    <!-- switch html -->
                    <!-- <app-field-switch 
                        class="d-block"
                        style="margin-bottom: 25px; margin-top: 2px;"
                        [isTitle]="true"
                        [model]="searchType" 
                        (modelChange)="searchType = $event"
                        [options]="switchOptions">
                    </app-field-switch> -->
                    <h2 class="search-title">{{'search_title' | translate}}</h2>
                    <div class="position-relative">
                        <!-- ADVANCED -->
                        <div class="w-100" *ngIf="searchType === 'advanced'">
                            <div class="row row-form">
                                <div class="col-md-12">
                                    <app-field-text
                                        [model]="searchSTR.keyword" 
                                        (modelChange)="searchSTR.keyword = $event" 
                                        (enter)="search()" 
                                        label="{{'crmCtgDossiers_searchForm_keyword' | translate}}"
                                        placeholder="{{'crmCtgDossiers_searchForm_keyword_placeholder' | translate}}">
                                    </app-field-text>
                                </div>
                            </div>
                            <div class="row row-form">
                                <div class="col-md-12">
                                    <app-field-text
                                        [model]="searchSTR.dossierNumber" 
                                        (modelChange)="searchSTR.dossierNumber = $event" 
                                        (enter)="search()" 
                                        label="{{'crmCtgDossiers_searchForm_dossierNumber' | translate}}"
                                        placeholder="{{'crmCtgDossiers_searchForm_dossierNumber_placeholder' | translate}}">
                                    </app-field-text>
                                </div>
                            </div>
                            <div class="row row-form">
                                <div class="col-md-12">
                                    <app-field-select 
                                        [model]="searchSTR.applicationType" 
                                        [options]="applicationTypes"
                                        (modelChange)="searchSTR.applicationType = $event;" 
                                        label="{{'crmctgdossier_searchForm_applicationType' | translate}}"
                                        placeholder="{{'crmctgdossier_searchForm_applicationType_placeholder' | translate}}">
                                    </app-field-select>
                                </div>
                            </div>
                            <div class="row row-form">
                                <div class="col-md-12">
                                    <app-field-radios 
                                        class="d-block"
                                        label="{{'crmctgdossier_searchForm_status' | translate}}"
                                        [canUncheck]="true"
                                        [model]="searchSTR.status" 
                                        (modelChange)="searchSTR.status = $event"
                                        [options]="statusOptions"
                                        [optionWidth]=""
                                        [optionColumnWidth]="6">
                                    </app-field-radios>
                                </div>
                            </div>
                            <div class="row row-form">
                                <div class="col-md-12">
                                    <app-field-text
                                        [model]="searchSTR.atcCode" 
                                        (modelChange)="searchSTR.atcCode = $event" 
                                        (enter)="search()" 
                                        label="{{'crmCtgDossiers_searchForm_atcCode' | translate}}"
                                        placeholder="{{'crmCtgDossiers_searchForm_atcCode_placeholder' | translate}}">
                                    </app-field-text>
                                </div>
                            </div>
                            <div class="row row-form" style="margin-bottom:0px;">
                                <div class="col-md-12">
                                    <app-field-text
                                        [model]="searchSTR.indication" 
                                        (modelChange)="searchSTR.indication = $event" 
                                        (enter)="search()" 
                                        label="{{'crmCtgDossiers_searchForm_indication' | translate}}"
                                        placeholder="{{'crmCtgDossiers_searchForm_indication_placeholder' | translate}}">
                                    </app-field-text>
                                </div>
                                <div class="col-md-12">
                                    <ng-container *ngIf="searchSTR.displayMoreOptions">
                                        <button (click)="openCloseMoreOptions()" class="button-link underline mt-s fontsize-s float-right">
                                            <span id="text_options">{{'crmCtgDossiers_searchForm_less_options' | translate}}</span>
                                            <i class="icon icon-up icon-expand_blue mr-xxxs link" [id]= "'icon_options'"></i>
                                        </button>
                                    </ng-container>
                                    <ng-container *ngIf="!searchSTR.displayMoreOptions">
                                        <button (click)="openCloseMoreOptions()" class="button-link underline mt-s fontsize-s float-right">
                                            <span id="text_options">{{'crmCtgDossiers_searchForm_more_options' | translate}}</span>
                                            <i class="icon icon-down icon-expand_blue mr-xxxs link" [id]= "'icon_options'"></i>
                                        </button>
                                    </ng-container>
                                </div>
                            </div>

                            <ng-container *ngIf="searchSTR.displayMoreOptions">
                                <div class="row row-form">
                                    <div class="col-md-12">
                                        <app-field-multiselect
                                            [label]="'crmCtgDossier_phase' | translate" 
                                            [model]="searchSTR.phase" 
                                            [placeholder]="'misc_select' | translate"
                                            [options]="phaseOptions"
                                            (modelChange)="searchSTR.phase = $event;"
                                            (setActive)="helper.activeSelect = 'phase'"
                                            (setInactive)="helper.activeSelect = null"
                                            [isActive]="helper.activeSelect === 'phase'"
                                            [hasActiveControl]="true"
                                        ></app-field-multiselect>
                                    </div>
                                </div>
                                <div class="row row-form" *ngIf="contractAdvisorOptions">
                                    <div class="col-md-12">
                                        <app-field-multiselect
                                            [label]="'crmCtgDossier_contract_advisor' | translate" 
                                            [model]="searchSTR.contractAdvisor" 
                                            [placeholder]="'misc_select' | translate"
                                            [options]="contractAdvisorOptions"
                                            (modelChange)="searchSTR.contractAdvisorOptions = $event;"
                                            (setActive)="helper.activeSelect = 'contractAdvisorOptions'"
                                            (setInactive)="helper.activeSelect = null"
                                            [isActive]="helper.activeSelect === 'contractAdvisorOptions'"
                                            [hasActiveControl]="true"
                                        ></app-field-multiselect>
                                    </div>
                                </div>
                                <div class="row row-form">
                                    <div class="col-md-12">
                                        <div class="field"><span class="label">{{'crmCtgDossier_searchForm_last_modified' | translate}}</span></div>
                                        <div class="row">
                                            <div class="col-6" style="padding-right:7px">
                                                <app-field-date 
                                                    [model]="searchSTR.startDate" 
                                                    (modelChange)="searchSTR.startDate = $event;" 
                                                    label="{{'crmCtgDossier_searchForm_startdate' | translate}}"
                                                    placeholder="dd-mm-yyyy">
                                                </app-field-date>
                                            </div>
                                            <div class="col-6 align-datepicker" style="padding-left:7px">
                                                <app-field-date 
                                                    [model]="searchSTR.endDate" 
                                                    (modelChange)="searchSTR.endDate = $event;" 
                                                    label="{{'crmCtgDossier_searchForm_enddate' | translate}}"
                                                    placeholder="dd-mm-yyyy">
                                                </app-field-date>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row row-form">
                                    <div class="col-md-12">
                                        <div class="field"><span class="label">{{'crmCtgDossier_searchForm_day0' | translate}}</span></div>
                                        <div class="row">
                                            <div class="col-6" style="padding-right:7px">
                                                <app-field-date 
                                                    [model]="searchSTR.day0StartDate" 
                                                    (modelChange)="searchSTR.day0StartDate = $event;" 
                                                    label="{{'crmCtgDossier_searchForm_startdate' | translate}}"
                                                    placeholder="dd-mm-yyyy">
                                                </app-field-date>
                                            </div>
                                            <div class="col-6 align-datepicker" style="padding-left:7px">
                                                <app-field-date 
                                                    [model]="searchSTR.day0EndDate" 
                                                    (modelChange)="searchSTR.day0EndDate = $event;" 
                                                    label="{{'crmCtgDossier_searchForm_enddate' | translate}}"
                                                    placeholder="dd-mm-yyyy">
                                                </app-field-date>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row row-form">
                                    <div class="col-md-12">
                                        <div class="field"><span class="label">{{'crmCtgDossier_searchForm_reimb' | translate}}</span></div>
                                        <div class="row">
                                            <div class="col-6" style="padding-right:7px">
                                                <app-field-date 
                                                    [model]="searchSTR.reimbStartDate" 
                                                    (modelChange)="searchSTR.reimbStartDate = $event;" 
                                                    label="{{'crmCtgDossier_searchForm_startdate' | translate}}"
                                                    placeholder="dd-mm-yyyy">
                                                </app-field-date>
                                            </div>
                                            <div class="col-6 align-datepicker" style="padding-left:7px">
                                                <app-field-date 
                                                    [model]="searchSTR.reimbEndDate" 
                                                    (modelChange)="searchSTR.reimbEndDate = $event;" 
                                                    label="{{'crmCtgDossier_searchForm_enddate' | translate}}"
                                                    placeholder="dd-mm-yyyy">
                                                </app-field-date>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row row-form">
                                    <div class="col-md-12">
                                        <div class="field"><span class="label">{{'crmCtgDossier_searchForm_lastMeeting' | translate}}</span></div>
                                        <div class="row">
                                            <div class="col-6" style="padding-right:7px">
                                                <app-field-date 
                                                    [model]="searchSTR.lastMeetingStartDate" 
                                                    (modelChange)="searchSTR.lastMeetingStartDate = $event;" 
                                                    label="{{'crmCtgDossier_searchForm_startdate' | translate}}"
                                                    placeholder="dd-mm-yyyy">
                                                </app-field-date>
                                            </div>
                                            <div class="col-6 align-datepicker" style="padding-left:7px">
                                                <app-field-date 
                                                    [model]="searchSTR.lastMeetingEndDate" 
                                                    (modelChange)="searchSTR.lastMeetingEndDate = $event;" 
                                                    label="{{'crmCtgDossier_searchForm_enddate' | translate}}"
                                                    placeholder="dd-mm-yyyy">
                                                </app-field-date>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </ng-container>

                            <div class="mt-s d-flex flex-column align-items-center search-button-container">
                                <button (click)="search()" class="button default dark w-100 text-center d-block">{{'crmCtgDossiers_searchForm_search_btn' | translate}}</button>
                                <button (click)="search(true)" class="button-link underline mt-s fontsize-s"><span>{{'crmCtgDossiers_searchForm_clear_btn' | translate}}</span></button>
                            </div>
                        </div>
                        
                    </div>
                </div>
            </div>
        </div>

        <!-- main content -->
        <div class="flex-grow-1 side-panel-main pt-s pb-l">
            <div class="d-flex justify-content-between pl-xs pr-xs">
                <h1 class="mb-l">{{'crmCtgDossiers_table_title' | translate}} {{((!tableLoading && totalRows) || '') && '(' + totalRows + ')'}}</h1>
                <button (click)="openCreateUpdateModal()" class="button default"><i class="im-icon im-icon-plus-round"></i>{{'crmCtgDossiers_new_btn' | translate}}</button>
            </div>
            <div class="pl-xs pr-xs">
                
                <app-table
                    id="CTGDossiers"
                    [showHideColums]="true"
                    [heads]="tableHeads" 
                    [data]="dossiers"
                    [sort]="tableSort"
                    [RPP]="RPP"
                    [startRow]="startRow"
                    [totalRows]="totalRows"
                    [loading]="tableLoading"
                    [componentIsAttached]="!modalOpen"
                    [shimmerTypes]="['text-m','text-m','text-m','text-m','text-m','text-m','actions']"
                    emptyState="{{'crmCtgDossiers_table_empty' | translate}}"
                    (sortChange)="setSort($event.code, $event.dir)"
                    (clickData)="tableClick($event.item)"
                    (clickAction)="actionClick($event.item, $event.action)"
                    (startRowChange)="changeStartRow($event)"
                    (clickExport)="export($event)"
                    exportLabel=""
                    [exportBusy]=""
                    [exports]="exports"
                ></app-table>
            </div>
        </div>
    </div>
</div>