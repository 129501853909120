import { Injectable } from '@angular/core';
import { DefaultService } from '../utils/api';

@Injectable({
    providedIn: 'root'
})
export class HistoryService {
    constructor(private defaultService: DefaultService) {}

    public getHistory(dossierId: number) {
        return this.defaultService.dossierGetHistory(dossierId);
    }
}
