import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { tap } from 'rxjs/operators';
import { DefaultService, Contract } from '../utils/api';
import { HelpersService } from './helpers.service';
import { TranslatorService } from './translator.service';
import * as moment from 'moment';
import { TranslatePipe } from '../utils/pipes';

@Injectable({
    providedIn: 'root'
})
export class ContractsService {
    constructor(
        private DefaultService: DefaultService,
        private toastr: ToastrService,
        public helpersService: HelpersService,
        public translatorService: TranslatorService,
        public HelpersService: HelpersService,
        public TranslatePipe: TranslatePipe
    ) {}

    public getContracts(searchSTR: any) {
        return this.DefaultService.contractsGetContracts(
            searchSTR.startRow,
            searchSTR.RRP,
            searchSTR.orderBy,
            searchSTR.contractNumber,
            searchSTR.dossierNumber,
            searchSTR.contractType,
            searchSTR.stage,
            searchSTR.status,
            searchSTR.term,
            searchSTR.initialContracts,
            searchSTR.excludeIds
        );
    }

    public getContract(id: string) {
        return this.DefaultService.contractGetContract(id);
    }

    unarchiveContract(id: string) {
        return this.DefaultService.contractArchive(id, false).pipe(
            tap((next) => {
                this.toastr.success(
                    this.translatorService.getTranslation('contract_toastr_unarchived'),
                    this.translatorService.getTranslation('contract_toastr_unarchived_title')
                );
            })
        );
    }

    deleteContract(id: string) {
        return this.DefaultService.contractDelete(id).pipe(
            tap((next) => {
                this.toastr.success(
                    this.translatorService.getTranslation('contract_toastr_delete'),
                    this.translatorService.getTranslation('contract_toastr_delete_title')
                );
            })
        );
    }

    deletePayment(uuid: string) {
        return this.DefaultService.contractDeletePayment(uuid).pipe(
            tap((next) => {
                this.toastr.success(
                    this.translatorService.getTranslation('contract_payment_toastr_delete'),
                    this.translatorService.getTranslation('contract_payment_toastr_delete_title')
                );
            })
        );
    }

    copyPayments(body: any) {
        return this.DefaultService.contractCopyPayment(body).pipe(
            tap((next) => {
                if (body.copyFromMonthYear) {
                    this.toastr.success(
                        this.translatorService.getTranslation('contract_payments_copied_toastr_delete'),
                        this.translatorService.getTranslation('contract_payments_copied_toastr_delete_title')
                    );
                } else {
                    this.toastr.success(
                        this.translatorService.getTranslation('contract_payment_copied_toastr_delete'),
                        this.translatorService.getTranslation('contract_payment_copied_toastr_delete_title')
                    );
                }
            })
        );
    }

    copyEstimated(body: any) {
        return this.DefaultService.contractCopyEstimated(body).pipe(
            tap((next) => {
                if (body.copyFromMonthYear) {
                    this.toastr.success(
                        this.translatorService.getTranslation('contract_estimated_turnovers_copied_toastr_delete'),
                        this.translatorService.getTranslation('contract_estimated_turnovers_copied_toastr_delete_title')
                    );
                } else {
                    this.toastr.success(
                        this.translatorService.getTranslation('contract_estimated_copied_toastr_delete'),
                        this.translatorService.getTranslation('contract_estimated_copied_toastr_delete_title')
                    );
                }
            })
        );
    }

    deleteEstimated(uuid: string) {
        return this.DefaultService.contractDeleteEstimated(uuid).pipe(
            tap((next) => {
                this.toastr.success(
                    this.translatorService.getTranslation('contract_estimated_toastr_delete'),
                    this.translatorService.getTranslation('contract_estimated_toastr_delete_title')
                );
            })
        );
    }

    archiveContract(id: string) {
        return this.DefaultService.contractArchive(id, true).pipe(
            tap((next) => {
                this.toastr.success(
                    this.translatorService.getTranslation('contract_toastr_archived'),
                    this.translatorService.getTranslation('contract_toastr_archived_title')
                );
            })
        );
    }

    public updateInsertContract(body: any, section: string, id?: string) {
        let result;
        switch (section) {
            case 'overview':
                result = this.DefaultService.contractCreateOrUpdateGeneral(body).pipe(
                    tap((next) => {
                        this.showCreateUpdateToast(id);
                    })
                );
                break;
            case 'financial':
                result = this.DefaultService.contractCreateOrUpdateFinancial(body).pipe(
                    tap((next) => {
                        this.showCreateUpdateToast(id);
                    })
                );
                break;
            case 'payments':
                result = this.DefaultService.contractCreateOrUpdatePayment(body).pipe(
                    tap((next) => {
                        this.showCreateUpdateToast(id);
                    })
                );
                break;
            case 'estimated':
                result = this.DefaultService.contractCreateOrUpdateEstimated(body).pipe(
                    tap((next) => {
                        this.showCreateUpdateToast(id);
                    })
                );
                break;
            case 'summary':
                result = this.DefaultService.contractCreateOrUpdateSummary(body).pipe(
                    tap((next) => {
                        this.showCreateUpdateToast(id);
                    })
                );
                break;
        }
        return result;
    }

    showCreateUpdateToast(id: string) {
        if (id) {
            this.toastr.success(
                this.translatorService.getTranslation('contract_toastr_saved'),
                this.translatorService.getTranslation('contract_toastr_saved_title')
            );
        } else {
            this.toastr.success(
                this.translatorService.getTranslation('contract_toastr_created'),
                this.translatorService.getTranslation('contract_toastr_created_title')
            );
        }
    }

    public searchDossiers(term: string, selectedDossiers: any[]) {
        let companyId = null;
        let excludeIds: string[] = [];

        if (selectedDossiers && selectedDossiers.length > 0) {
            for (const d of selectedDossiers) {
                excludeIds.push(d.id);
                companyId = d.company_id;
            }
        }

        return this.DefaultService.contractGetContractDossiers(companyId, term, excludeIds);
    }

    public exportContracts(searchSTR: any) {
        return this.DefaultService.contractsExportContracts(
            searchSTR.orderBy,
            searchSTR.contractNumber,
            searchSTR.dossierNumber,
            searchSTR.contractType,
            searchSTR.stage,
            searchSTR.status,
            searchSTR.term,
            searchSTR.initialContracts,
            searchSTR.excludeIds
        ).pipe(
            tap((response) => {
                this.HelpersService.downloadBlob(
                    response,
                    `${this.TranslatePipe.transform('contracts_export_filename')} ${moment().format('YYYY-MM-DD')}.xlsx`
                );
            })
        );
    }

    public searchContracts(term: string, selectedContracts: Contract[]) {
        let excludeUuids: Array<string> = [];

        if (selectedContracts && selectedContracts.length > 0) {
            for (const c of selectedContracts) {
                excludeUuids.push(c.contract.id);
            }
        }

        return this.DefaultService.contractsGetContracts(
            0,
            50,
            null,
            null,
            null,
            null,
            null,
            null,
            term,
            false,
            excludeUuids
        );
    }
}
