<!-- head -->
<div class="options-modal-head d-flex flex-row justify-content-between align-items-center">
    <h1>{{('indications_add_title') | translate}}</h1>
    <div class="h-100 d-flex align-items-center">
        <button (click)="cancel()" class="button tertiary"><span>{{'documentcontractoptions_cancel' | translate}}</span></button>
        <button [class.disabled]="!FORM.label || saving" (click)="!saving && FORM.label && submit()" class="button default-sm ml-xs">{{'documentcontractoptions_save' | translate}}</button>
    </div>
</div>

<!-- body -->
<div class="options-modal-body saving--pre" [class.saving]="saving">

    <app-field-text 
        [model]="FORM.label" 
        [error]="validation.label"
        [instant]="true"
        (modelChange)="FORM.label = $event;" 
        label="{{'indications_indication_description' | translate}}"
    ></app-field-text>

</div>