<div class="field field-richtext" >
    <span *ngIf="label" class="label">{{label}}</span>
    <ng-container *ngIf="readOnly">
        <quill-view *ngIf="model" class="read-only" [content]="model"></quill-view>
        <ng-container *ngIf="!model">-</ng-container>
    </ng-container>
    <quill-editor
        *ngIf="!readOnly"
        [class.error-field]="error"
        [(ngModel)]="model"
        (onContentChanged)="valueChanged($event);"
        [modules]="modules"
        [placeholder]="placeholder"
    ></quill-editor>
    <span *ngIf="afterString" class="after-textarea passive-gray">{{afterString | display}}</span>
    <span *ngIf="error" class="error-field-display">{{error}}</span>
</div>