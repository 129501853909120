import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
    selector: 'app-confirm-action',
    templateUrl: './confirm-action.component.html',
    styleUrls: ['./confirm-action.component.scss']
})
export class ConfirmActionComponent implements OnInit {
    @Input() open?: boolean;
    @Input() positionClass?: 'left' | 'right' | 'center' = 'center';
    @Input() positionClassV?: 'top' | 'bottom' | 'middle' = 'middle';
    @Input() type: string;
    @Output() cancel = new EventEmitter();
    @Output() confirm = new EventEmitter();

    constructor() {}

    ngOnInit(): void {}
}
