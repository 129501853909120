import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, switchMap, map } from 'rxjs/operators';
import { CompanyService } from 'src/app/services/company.service';

@Component({
    selector: 'app-field-sam-companies',
    templateUrl: './field-sam-companies.component.html',
    styleUrls: ['./field-sam-companies.component.scss']
})
export class FieldSamCompaniesComponent implements OnInit {
    @Input() label?: string;
    @Input() error?: string | string[];
    @Input() placeholder?: string;
    @Input() model: any[] = [];
    @Input() company_id: string;
    @Output() modelChange = new EventEmitter();

    selected = '';

    constructor(private companyService: CompanyService) {}

    ngOnInit(): void {
        if (this.model == null || this.model == undefined) {
            this.model = [];
        }
    }

    formatter = (item: { key: any; value: string }) => item.value;

    public onFocus(e: Event): void {
        e.stopPropagation();
        setTimeout(() => {
            const inputEvent: Event = new Event('input');
            e.target.dispatchEvent(inputEvent);
        }, 0);
    }

    search = (text$: Observable<string>) =>
        text$.pipe(
            debounceTime(200),
            distinctUntilChanged(),
            filter((term) => {
                return term.length > 2;
            }),
            switchMap((term) => this.companyService.searchSAMCompanies(term, this.model, this.company_id)),
            map((val) => {
                let companies = [];
                for (const company of val.data) {
                    companies.push({
                        key: company.uuid,
                        value: company.name,
                        company: company
                    });
                }

                if (companies.length == 0) {
                    companies.push({
                        key: '',
                        value: 'No companies found.',
                        company: null
                    });
                }
                return companies;
            })
        );

    onSelect($event) {
        if ($event.item.company) {
            this.model.push($event.item.company);
            this.modelChange.emit(this.model);
        }

        $event.preventDefault();

        setTimeout(() => {
            this.selected = '';
        }, 200);
    }

    deleteValue(index) {
        this.model.splice(index, 1);
        this.modelChange.emit(this.model);
    }
}
