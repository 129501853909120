import { Injectable, Pipe, PipeTransform } from '@angular/core';
import { BehaviorSubject, Observable, forkJoin, of } from 'rxjs';
import { tap } from 'rxjs/operators';
import { DefaultService } from '../utils/api';

@Injectable({
    providedIn: 'root'
})
export class ResourceService {
    constructor(private defaultService: DefaultService) {}
    private resources$ = new BehaviorSubject<any>(null);
    private folders$ = new BehaviorSubject<any>(null);

    public init(): Observable<any>[] {
        const observables$: Observable<any>[] = [];

        const resources$ = this.defaultService.resourceGetResources().pipe(
            tap((next) => {
                this.resources$.next(next);
            })
        );
        observables$.push(resources$);

        const folders$ = this.defaultService.resourceGetFolders().pipe(
            tap((next) => {
                this.folders$.next(
                    next.map((item: any) => {
                        return { value: item.label, key: item.id, regex: item.regex };
                    })
                );
            })
        );
        observables$.push(folders$);

        return observables$;
    }

    public initPublic(): Observable<any>[] {
        const observables$: Observable<any>[] = [of(null)];
        return observables$;
    }

    public reload(): void {
        forkJoin(this.init()).subscribe((next) => {});
    }

    public getLocale() {
        return 'en';
    }

    public getResources() {
        return this.resources$.getValue();
    }

    public getFolders() {
        return this.folders$.getValue();
    }

    public getResourceByType(type: string, allowStatusFalse?: boolean): any[] {
        if (this.getResources()[type]) {
            return this.getResources()
                [type].filter((item) => {
                    return allowStatusFalse || item.status;
                })
                .map((item) => {
                    return { ...item, value: item.label, key: item.value };
                });
        }
        return undefined;
    }

    public getResourceTitleByTypeAndKey(type, key) {
        if (key && this.getResourceByType(type, true)) {
            const value = this.getResourceByType(type, true).filter((item) => item.key == key);
            if (value && value.length > 0) {
                return value[0].value;
            } else {
                return undefined;
            }
        }
        return undefined;
    }

    public getResourceTitleByTypeAndId(type, id) {
        if (id && this.getResourceByType(type, true)) {
            const value = this.getResourceByType(type, true).filter((item) => item.id == id);
            if (value && value.length > 0) {
                return value[0].value;
            } else {
                return undefined;
            }
        }
        return undefined;
    }
}

@Pipe({ name: 'resource' })
export class resourcePipe implements PipeTransform {
    constructor(private ResourceService: ResourceService) {}
    transform(value: any, type: string): any {
        if (value) {
            return this.ResourceService.getResourceTitleByTypeAndKey(type, value);
        }
        return '-';
    }
}
