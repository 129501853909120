<div class="field field-typeahead position-relative" [class.valid]="model">
    <span *ngIf="label" class="label">{{label}}</span>
    <ng-container *ngIf="model && model.length > 0">
        <ul class="app-list" [class.app-list--field]="true">
            <li *ngFor="let company of model; index as $i">
                {{company.name}} <span class="sam-id" *ngIf="company.sam_id">#{{company.sam_id}}</span>
                <i (click)="deleteValue($i)" class="im-icon im-icon-x-light delete-icon"></i>
            </li>
        </ul>
    </ng-container>
    <input
        (focus)="onFocus($event)"
        (click)="onFocus($event)"
        id="typeahead-prevent-manual-entry"  
        type="text"
        [(ngModel)]="selected"
        [class.error-field]="error"
        [placeholder]="placeholder"
        (selectItem)="onSelect($event);"
        [ngbTypeahead]="search"
        [inputFormatter]="formatter"
        [resultFormatter]="formatter"
        [resultTemplate]="template"
        editable=false />
    <span *ngIf="error" class="error-field-display">{{error}}</span>

    <ng-template #template let-r="result" let-t="term">
        <ngb-highlight [result]="r.value" [term]="t"></ngb-highlight>
        <span class="sam-id" *ngIf="r?.company?.sam_id"> #{{r.company.sam_id}}</span>
    </ng-template>
</div>