import { Injectable } from '@angular/core';
import { DefaultService } from '../utils/api';

@Injectable({
    providedIn: 'root'
})
export class DocumentService {
    constructor(private defaultService: DefaultService) {}

    public getDocuments(dossierId: number) {
        return this.defaultService.documentGetDocumentsForDossier(dossierId);
    }

    public deleteDocument(dossierIds: number[], documentId: number) {
        const items = dossierIds.map((item) => {
            return {
                dossierId: item,
                link: false
            };
        });
        return this.defaultService.documentLinkDocumentToDossiers({ items: items }, documentId);
    }

    public priceDossierDeleteDocument(folderId: number, documentId: number, priceDossierId: string) {
        return this.defaultService.priceDossierDeleteDocument(priceDossierId, folderId, documentId);
    }

    public linkDocumentToDossiers(links: any[], documentId: number, folderId?: number) {
        return this.defaultService.documentLinkDocumentToDossiers({ items: links }, documentId, folderId);
    }

    public saveDocument(file: Blob, folderId: number, dossierIds: number[], priceDossierId: string) {
        if (priceDossierId) {
            return this.defaultService.priceDossierSaveDocument(file, folderId, priceDossierId);
        } else {
            return this.defaultService.documentSaveDocument(file, folderId, dossierIds);
        }
    }

    public moveDocument(documentId: number, dossierIds: number[], folderId: number, priceDossierId: string) {
        if (priceDossierId) {
            return this.defaultService.priceDossierMoveDocument(documentId, priceDossierId, folderId);
        } else {
            return this.defaultService.documentMoveDocument(documentId, dossierIds, folderId);
        }
    }

    public readDocuments(documentIds, dossierId: number) {
        const body = { items: [] };
        for (const documentId of documentIds) {
            body.items.push({
                dossierId,
                documentId
            });
        }
        return this.defaultService.documentFileRead(body);
    }

    public setReadDocument(body) {
        return this.defaultService.priceDossierFileRead(body);
    }

    // contracts
    public getContractDocuments(contractId: string) {
        return this.defaultService.documentGetDocumentsForContract(contractId);
    }
}
