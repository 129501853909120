import { Injectable } from '@angular/core';
import { DefaultService } from '../utils/api';
import { tap } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { TranslatorService } from './translator.service';

@Injectable({
    providedIn: 'root'
})
export class NotulenService {
    constructor(
        private defaultService: DefaultService,
        private toastr: ToastrService,
        public translatorService: TranslatorService
    ) {}

    public search(searchSTR) {
        return this.defaultService.notulesGetAll(
            searchSTR.startRow,
            searchSTR.RRP,
            searchSTR.orderBy,
            searchSTR.term,
            searchSTR.startDate,
            searchSTR.endDate
        );
    }

    public delete(notuleId: number) {
        return this.defaultService.notulesDelete(notuleId).pipe(
            tap((next) => {
                this.toastr.success(
                    this.translatorService.getTranslation('notules_toastr_delete'),
                    this.translatorService.getTranslation('notules_toastr_delete_title')
                );
            })
        );
    }
}
