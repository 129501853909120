import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, switchMap, map } from 'rxjs/operators';
import { ProductService } from 'src/app/services/product.service';
import { Product } from 'src/app/utils/api';

@Component({
    selector: 'app-field-product',
    templateUrl: './field-product.component.html',
    styleUrls: ['./field-product.component.scss']
})
export class FieldProductComponent implements OnInit {
    @Input() label?: string;
    @Input() samCompanyUuid?: string;
    @Input() companyId?: string;
    @Input() error?: string | string[];
    @Input() placeholder?: string;
    @Input() model: any = null;
    @Input() hideList: boolean = false;
    @Output() modelChange = new EventEmitter();

    selected = '';

    constructor(private productService: ProductService) {}

    ngOnInit(): void {}

    formatter = (item: { key: any; value: string }) => item.value;

    public onFocus(e: Event): void {
        e.stopPropagation();
        setTimeout(() => {
            const inputEvent: Event = new Event('input');
            e.target.dispatchEvent(inputEvent);
        }, 0);
    }

    search = (text$: Observable<string>) =>
        text$.pipe(
            debounceTime(200),
            distinctUntilChanged(),
            filter((term) => {
                if (this.model && this.model.length > 0) {
                    return true;
                } else {
                    return term.length > 2;
                }
            }),
            switchMap((term) =>
                this.productService.searchProducts(term, undefined, this.companyId, undefined, this.samCompanyUuid)
            ),
            map((val) => {
                let producten = [];
                for (const product of val.data) {
                    producten.push({
                        key: product.uuid,
                        value: this.getProductInfo(product),
                        product: product
                    });
                }

                if (producten.length == 0) {
                    producten.push({
                        key: '',
                        value: 'No package found.',
                        product: null
                    });
                }
                return producten;
            })
        );

    onSelect($event) {
        this.modelChange.emit($event.item);
    }

    change($event) {
        if (!this.model) {
            this.modelChange.emit(undefined);
        }
    }

    onBlur($event?) {
        if (!this.model) {
            this.modelChange.emit(undefined);
        }
    }

    getProductInfo(product: Product) {
        return product.productPackage;
    }
}
