<ng-container *ngIf="showTableOnEmptyState==true || (data && data.length)">
    <div class="scroll-xx">
        <table [class]="class">
            <thead>
                <tr>
                    <ng-container *ngFor="let head of heads">
                        <ng-container *ngIf="isShown(head)">
                            <ng-container *ngIf="showHideColums && head.settings">
                                <th [ngStyle]="{'width' : head.width ? head.width : 'auto' }" class="settings">
                                    <div class="position-relative">
                                        <i 
                                            class="im-icon im-icon-settings-sliders" 
                                            (click)="helper.actionIndex == -1 ? setActionIndex(null) : setActionIndex(-1); $event.stopPropagation();" 
                                        >
                                        </i>
                                        <ng-container *ngIf="helper.actionIndex === -1">
                                            <app-popover [open]="true" positionClass="left" class="extra-width" style="transform: translateY(8px); display: block;">
                                                <ul>
                                                    <li class="title">{{'table_settings' | translate}}</li>
                                                    <ng-container *ngFor="let h of heads">
                                                        <li *ngIf="h.hideable" class="position-relative" (click)="setShowHide(h.code); setActionIndex(-1);$event.stopPropagation();">
                                                            <div class="field-checkbox flex-grow-1 d-flex align-items-center" [class.active]="isShown(h)">
                                                                <span class="fake-checkbox"></span>
                                                                <span>{{h.name}}</span>           
                                                            </div>
                                                        </li>
                                                    </ng-container>
                                                </ul>
                                            </app-popover>
                                        </ng-container>
                                    </div>
                                </th>
                            </ng-container>
                            <ng-container *ngIf="!showHideColums || !head.settings">
                                <th [ngStyle]="{'width' : head.width ? head.width : 'auto' }" 
                                    [class]="head.class || ''" 
                                    [class.sortable]="head.sortable" 
                                    (click)="head.sortable && clickSort(head)"
                                >
                                <div class="position-relative" [ngClass]="getSortingClass(head)">{{head.name}}</div>
                            </th>
                            </ng-container>
                        </ng-container>
                    </ng-container>
                </tr>
            </thead>
            <tbody  *ngIf="!loading">
                <ng-container *ngFor="let item of data; index as $i; trackBy: trackByMethod; let last = last">
                    {{last ? renderDone.emit() : ''}}
                    <tr [class.active]="item.trActive" [class.clickable]="clickable">
                        <ng-container *ngFor="let head of heads">
                            <ng-container *ngIf="isShown(head)">
                                <td 
                                    (click)="clickData.emit({item: item, head: head.code})" 
                                    [class]="(item[head.code] && item[head.code].class) || ''" 
                                    [class.shrink]="item[head.code] && item[head.code].type === 'actions'" 
                                    [class.no-wrap]="item[head.code] && item[head.code].stamps && !item[head.code].wrap"
                                >
                                    <!-- error -->
                                    <ng-container *ngIf="item[head.code] && item[head.code].type === 'error'"><span class="red">{{item[head.code].value}}</span></ng-container>
        
                                    <!-- default -->
                                    <ng-container *ngIf="!item[head.code] || !item[head.code].type">{{item[head.code] | display}}</ng-container>
        
                                    <!-- icon -->
                                    <ng-container *ngIf="item[head.code] && item[head.code].type === 'icon'">
                                        <i *ngIf="item[head.code].iconclass" class="icon {{item[head.code].iconclass}}"></i>
                                    </ng-container>
        
                                    <!-- default value -->
                                    <ng-container *ngIf="item[head.code] && item[head.code].type === 'default'">
                                        <span [class.mr-xxs]="item[head.code].value && !item[head.code].stamps">{{item[head.code].value | display}}</span>
                                    </ng-container>
    
                                    <!-- link-->
                                    <ng-container *ngIf="item[head.code] && item[head.code].type === 'link'">
                                        <a (dragstart)="drag($event)" draggable="true" href="{{item[head.code].link}}" target="{{item[head.code].target}}" id="{{item[head.code].linkId}}">{{item[head.code].linkName}}</a>
                                        <span *ngIf="item[head.code].new"  class="bullet--new"></span>
                                    </ng-container>
        
                                    <!-- image -->
                                    <ng-container *ngIf="item[head.code] && item[head.code].type === 'image'">
                                        <img *ngIf="item[head.code].src"  src="{{item[head.code].src}}" alt="{{item[head.code].alt}}" width="{{item[head.code].width}}">
                                        <img *ngIf="!item[head.code].src"  src="/assets/img/misc/no-image.jpeg" alt="no image" width="{{item[head.code].width}}">
                                    </ng-container>
        
                                    <!-- video -->
                                    <ng-container *ngIf="item[head.code] && item[head.code].type === 'video'" style="float: left">
                                        <embedded-media video='{{item[head.code].videoId}}' provider='{{item[head.code].videoType}}' attributes='{{item[head.code].videoAttributes}}' query='{{item[head.code].videoQuery}}'></embedded-media>
                                    </ng-container>
        
                                    <!-- default black -->
                                    <ng-container *ngIf="item[head.code] && item[head.code].type === 'black'"><span class="text-color">{{item[head.code].value | display}}</span></ng-container>
    
                                    <!-- add stamps -->
                                    <ng-container *ngIf="item[head.code] && item[head.code].type != 'titleSubtitle' && item[head.code].stamps">
                                        <div class="d-inline-block ml-xxs" [class.app-tooltip-wrap]="item[head.code].tooltip">
                                            <span *ngFor="let stamp of item[head.code].stamps" class="stamp mr-xxs" [class]="stamp.classList">
                                                {{stamp.value}}
                                            </span>
                                            <div *ngIf="item[head.code].tooltip" class="app-tooltip app-tooltip-default-centered">{{item[head.code].tooltip}}</div>
                                        </div>
                                    </ng-container>
        
                                    <!-- titleSubtitle -->
                                    <ng-container *ngIf="item[head.code] && item[head.code].type === 'titleSubtitle'">
                                        <div class="d-flex flex-column">
                                            <span class="text-color title">
                                                {{item[head.code].title | display}}
                                                <ng-container *ngIf="item[head.code] && item[head.code].stamps">
                                                    <div class="d-inline-block" [class.app-tooltip-wrap]="item[head.code].tooltip">
                                                        <span *ngFor="let stamp of item[head.code].stamps" class="stamp mr-xxs" [class]="stamp.classList">
                                                            {{stamp.value}}
                                                        </span>
                                                        <div *ngIf="item[head.code].tooltip" class="app-tooltip app-tooltip-default-centered">{{item[head.code].tooltip}}</div>
                                                    </div>
                                                </ng-container>
                                            </span>
                                            <span class="fontsize-s" style="margin-top: 0px;">
                                                {{item[head.code].subtitle | display}}
                                                <ng-container *ngIf="item[head.code] && item[head.code].stampsSubtitle">
                                                    <div class="d-inline-block" [class.app-tooltip-wrap]="item[head.code].tooltipSubtitle">
                                                        <span *ngFor="let stamp of item[head.code].stampsSubtitle" class="stamp mr-xxs" [class]="stamp.classList">
                                                            {{stamp.value}}
                                                        </span>
                                                        <div *ngIf="item[head.code].tooltipSubtitle" class="app-tooltip app-tooltip-default-centered">{{item[head.code].tooltipSubtitle}}</div>
                                                    </div>
                                                </ng-container>
                                            </span>
                                        </div>
                                    </ng-container>
        
                                    <!-- avatar -->
                                    <ng-container *ngIf="item[head.code] && item[head.code].type === 'avatar'"><app-avatar [user]="item[head.code].user" [image]="item[head.code].image" [title]="item[head.code].title" [subtitle]="item[head.code].subtitle" size="40" fontSize="14"></app-avatar></ng-container>
                                    
                                    <!-- ts -->
                                    <ng-container *ngIf="item[head.code] && item[head.code].type === 'ts'"><span style="white-space: nowrap;">{{item[head.code].ts | formatTs: item[head.code].format}}</span></ng-container>
        
                                    <!-- actions -->
                                    <ng-container *ngIf="item[head.code] && item[head.code].type === 'actions'">
                                        <div *ngIf="item[head.code].actions && item[head.code].actions.length" class="action-button d-flex align-items-center actions position-relative" [class.action-active]="helper.actionIndex == $i" (click)="helper.actionIndex == $i ? setActionIndex(null) : setActionIndex($i); $event.stopPropagation();" appClickOutside (clickOutside)="setActionIndex(null); clickOutsideActions()">
                                            <i class="im-icon im-icon-actions"></i>
                                            <app-popover [open]="helper.actionIndex === $i" positionClass="left" (click)="setActionIndex(null); $event.stopPropagation();">
                                                <ul (click)="setActionIndex(null)">
                                                    <li class="position-relative" *ngFor="let action of item[head.code].actions" appClickOutside (clickOutside)="popoverHelper = null" (click)="action.confirm ? $event.stopPropagation() || popoverHelper = action.code + '_' + $i : clickAction.emit({item: item, action: action.code});" [ngClass]="action.class || ''">
                                                        <i *ngIf="action.icon" class="im-icon im-icon-{{action.icon}}"></i> 
                                                        {{action.name}}
                                                        <app-confirm-action *ngIf="action.confirm" [type]="action.confirm_type" (confirm)="clickAction.emit({item: item, action: action.code}); dismissPopover(); setActionIndex(null);" (cancel)="dismissPopover()" [open]="popoverHelper === action.code + '_' + $i" positionClass="left"></app-confirm-action>
                                                    </li>
                                                </ul>
                                            </app-popover>
                                        </div>
                                    </ng-container>
    
                                    <!-- shimmer -->
                                    <ng-container *ngIf="item[head.code] && item[head.code].type === 'shimmer'">
                                        <app-shimmer [type]="item[head.code].shimmerType"></app-shimmer>
                                    </ng-container>
                                </td>
                            </ng-container>
                        </ng-container>
                    </tr>
                </ng-container>
            </tbody>
            <!-- shimmer -->
            <tbody *ngIf="loading && shimmerTypes">
                <ng-container *ngFor="let item of shimmerRows">
                    <tr [class.clickable]="clickable">
                        <ng-container *ngFor="let type of shimmerTypes">
                            <td [class.shrink]="type === 'actions'">
    
                                <app-shimmer [type]="type"></app-shimmer>
                                
                            </td>
                        </ng-container>
                    </tr>
                </ng-container>
            </tbody>
        </table>
    </div>
</ng-container>

<!-- spinner -->
<div *ngIf="loading && !shimmerTypes" class="mt-m">
    <app-spinner></app-spinner>
</div>
<!-- emptystate -->
<div *ngIf="!loading && data && !data.length && emptyState" class="pl-xs pr-xs mt-s emptystate-wrap">
    <app-empty-state [value]="emptyState"></app-empty-state>
</div>
<!-- pagination / export -->
<div *ngIf="data && data.length" class="row row-pagination mt-l">
    <div class="col-md-4">
        <div class="d-flex justify-content-end pl-s">
            <!--  -->
        </div>
    </div>
    <div class="col-md-4">
        <div class="d-flex justify-content-center" *ngIf="totalRows > RPP">
            <app-pagination 
                [RPP]="RPP"
                [startRow]="startRow"
                [totalRows]="totalRows"
                (startRowChange)="startRowChange.emit($event)">
            </app-pagination>
        </div>
    </div>
    <div class="col-md-4 d-flex flex-row align-items-center">
        <div *ngIf="exportLabel || exports?.length" class="d-flex w-100 justify-content-end pr-s">
            <div class="d-flex flex-column align-items-end pt-xxs" style="gap: 8px;">
                <!-- export -->
                <button *ngIf="exportLabel" (click)="clickExport.emit()" [class.disabled]="exportBusy" class="button-link fontsize-s export-btn" style="">
                    <i *ngIf="!exportBusy" class="im-icon im-icon-download"></i>
                    <app-inline-spinner *ngIf="exportBusy" class="mr-xxxs"></app-inline-spinner>
                    <span>{{exportLabel}}</span>
                </button>
                <!-- exports -->
                <ng-container *ngFor="let export of exports">
                    <button (click)="clickExport.emit(export.code)" [class.disabled]="export.busy" class="button-link fontsize-s export-btn" style="height: auto; line-height: 20px;">
                        <i *ngIf="!export.busy" class="im-icon im-icon-download"></i>
                        <app-inline-spinner *ngIf="export.busy" class="mr-xxxs"></app-inline-spinner>
                        <span>{{export.title}}</span>
                    </button>
                </ng-container>
            </div>
        </div>
    </div>
</div>