import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { DefaultService } from '../utils/api';
import { HelpersService } from './helpers.service';
import { TranslatorService } from './translator.service';

@Injectable({
    providedIn: 'root'
})
export class CrmCtgDossiersService {
    constructor(
        private DefaultService: DefaultService,
        private toastr: ToastrService,
        private http: HttpClient,
        public HelpersService: HelpersService,
        public TranslatorService: TranslatorService
    ) {}

    public searchDossiers(term: string) {
        return this.DefaultService.dossiersGetDossiers(0, 100, ['alias asc'], null, null, null, 'active', term);
    }

    public getDossiers(searchSTR: any) {
        return this.DefaultService.dossiersGetDossiers(
            searchSTR.startRow,
            searchSTR.RRP,
            searchSTR.orderBy,
            searchSTR.dossierNumber,
            searchSTR.applicationType,
            searchSTR.atcCode,
            searchSTR.status,
            searchSTR.keyword,
            searchSTR.startDate,
            searchSTR.endDate,
            searchSTR.day0StartDate,
            searchSTR.day0EndDate,
            searchSTR.reimbStartDate,
            searchSTR.reimbEndDate,
            searchSTR.lastMeetingStartDate,
            searchSTR.lastMeetingEndDate,
            searchSTR.indication,
            null,
            null,
            searchSTR.phase,
            searchSTR.contractAdvisor
        );
    }

    public exportDossiers(searchSTR: any) {
        return this.DefaultService.dossiersExportDossiers(
            searchSTR.orderBy,
            searchSTR.dossierNumber,
            searchSTR.applicationType,
            searchSTR.atcCode,
            searchSTR.status,
            searchSTR.keyword,
            searchSTR.startDate,
            searchSTR.endDate,
            searchSTR.day0StartDate,
            searchSTR.day0EndDate,
            searchSTR.reimbStartDate,
            searchSTR.reimbEndDate,
            searchSTR.lastMeetingStartDate,
            searchSTR.lastMeetingEndDate,
            searchSTR.indication,
            searchSTR.phase,
            searchSTR.contractAdvisor
        ).pipe(
            tap((response) => {
                this.HelpersService.downloadBlob(response, `CRM Budget Report ${moment().format('YYYY-MM-DD')}.xlsx`);
            })
        );
    }

    public exportDossiersExtra(searchSTR: any) {
        return this.DefaultService.dossiersExtraExportDossiers(
            searchSTR.orderBy,
            searchSTR.dossierNumber,
            searchSTR.applicationType,
            searchSTR.atcCode,
            searchSTR.status,
            searchSTR.keyword,
            searchSTR.startDate,
            searchSTR.endDate,
            searchSTR.day0StartDate,
            searchSTR.day0EndDate,
            searchSTR.reimbStartDate,
            searchSTR.reimbEndDate,
            searchSTR.lastMeetingStartDate,
            searchSTR.lastMeetingEndDate,
            searchSTR.indication,
            searchSTR.phase,
            searchSTR.contractAdvisor
        ).pipe(
            tap((response) => {
                this.HelpersService.downloadBlob(
                    response,
                    `Potential In discussion MEA ${moment().format('YYYY-MM-DD')}.xlsx`
                );
            })
        );
    }

    public getDossier(id: number) {
        return this.DefaultService.dossierGetDossier(id);
    }

    unarchiveDossier(id: number) {
        return this.DefaultService.dossierArchive(id, false).pipe(
            tap((next) => {
                this.toastr.success(
                    this.TranslatorService.getTranslation('crmCtgDossier_toastr_unarchived'),
                    this.TranslatorService.getTranslation('crmCtgDossier_toastr_unarchived_title')
                );
            })
        );
    }

    deleteDossier(id: number) {
        return this.DefaultService.dossierDelete(id).pipe(
            tap((next) => {
                this.toastr.success(
                    this.TranslatorService.getTranslation('crmCtgDossier_toastr_delete'),
                    this.TranslatorService.getTranslation('crmCtgDossier_toastr_delete_title')
                );
            })
        );
    }

    archiveDossier(id: number) {
        return this.DefaultService.dossierArchive(id, true).pipe(
            tap((next) => {
                this.toastr.success(
                    this.TranslatorService.getTranslation('crmCtgDossier_toastr_archived'),
                    this.TranslatorService.getTranslation('crmCtgDossier_toastr_archived_title')
                );
            })
        );
    }

    public updateInsertDossier(body: any, section: string, id?: number) {
        let result;
        switch (section) {
            case 'general':
                result = this.DefaultService.dossierCreateOrUpdateDossierGeneral(body).pipe(
                    tap((next) => {
                        this.showCreateUpdateToast(id);
                    })
                );
                break;
            case 'stats':
                result = this.DefaultService.dossierCreateOrUpdateDossierStats(body).pipe(
                    tap((next) => {
                        this.showCreateUpdateToast(id);
                    })
                );
                break;
            case 'budget':
                result = this.DefaultService.dossierCreateOrUpdateDossierBudget(body).pipe(
                    tap((next) => {
                        this.showCreateUpdateToast(id);
                    })
                );
                break;
        }

        return result;
    }

    showCreateUpdateToast(id) {
        if (id) {
            this.toastr.success(
                this.TranslatorService.getTranslation('crmCtgDossier_toastr_saved'),
                this.TranslatorService.getTranslation('crmCtgDossier_toastr_saved_title')
            );
        } else {
            this.toastr.success(
                this.TranslatorService.getTranslation('crmCtgDossier_toastr_created'),
                this.TranslatorService.getTranslation('crmCtgDossier_toastr_created_title')
            );
        }
    }
}
