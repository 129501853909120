<table class="table--detail" [class.readonly]="readOnly">
    <tr>
        <th style="width: 15%;">{{'contract_dossier_number' | translate}}</th>
        <th style="width: 30%;">{{'contract_dossier_product' | translate}}</th>
        <th style="width: 15%;">{{'contract_dossier_molecule' | translate}}</th>
        <th style="width: 15%;">{{'contract_dossier_advise' | translate}}</th>
        <th style="width: 15%;">{{'contract_dossier_advisor' | translate}}</th>
        <th style="width: auto"></th>
    </tr>
    <ng-container *ngIf="model">
        <tr *ngFor="let dossier of model; index as $i">
            <td>
                <a (click)="ActiveModal.dismiss()" class="format-link" [routerLink]="['/dossiers']" [queryParams]="{dossierId: dossier.id, activePage: 'general'}">{{getDossierNrs(dossier.dossier_nrs) | display}}</a>
                <ng-container *ngIf="getStampDossierNrs(dossier.dossier_nrs)">
                    <div class="d-inline-block" [class.app-tooltip-wrap]="getStampForTootltipDossierNrs(dossier.dossier_nrs)">
                        <span class="stamp mr-xxs ml-xxs stamp--small">
                            {{getStampDossierNrs(dossier.dossier_nrs)}}
                        </span>
                        <div *ngIf="getTooltipDossierNrs(dossier.dossier_nrs)" class="app-tooltip app-tooltip-default-centered">{{getTooltipDossierNrs(dossier.dossier_nrs)}}</div>
                    </div>
                </ng-container>
            </td>
            <td>
                {{dossier.name | display}}
                <ng-container *ngIf="dossier.package">
                    <button class="button-link underline" style="display: contents;" (click)="showHidePackages(dossier.id)"><span>({{getDossagesText(dossier.package)}})</span></button>
                    <ul class="app-list" [id]="dossier.id + '_package'" style="display: none;">
                        <li *ngFor="let p of dossier.package">
                            {{p}}
                        </li>
                    </ul>
                </ng-container>
            </td>
            <td>
                {{dossier.dci | display}}
            </td>
            <td>
                {{dossier.advice_crm | display}}
            </td>
            <td>
                {{dossier.crm_advisor_name | display}}
            </td>
            <td class="shrink" style="text-align: right;">
                <i *ngIf="!readOnly" (click)="deleteValue($i); error = null" class="im-icon im-icon-x-light delete-icon"></i>
            </td>
        </tr>
        <tr *ngIf="!model.length && readOnly" class="table-empty-line">
            <td>-</td>
            <td>-</td>
            <td>-</td>
            <td>-</td>
            <td>-</td>
            <td></td>
        </tr>
    </ng-container>
    <ng-container *ngIf="!readOnly">
        <tr>
            <td colspan="6" style="border-bottom: none;">
                <div class="field field-typeahead position-relative">
                    <input
                        style="max-width: 340px"
                        [class.error-field]="error && !HelpersService.isObj(error)"
                        (focus)="onFocus($event)"
                        (click)="onFocus($event)"
                        id="typeahead-prevent-manual-entry"  
                        type="text"
                        [(ngModel)]="selected"
                        placeholder="{{'dossiers_placeholder' | translate}}"
                        (selectItem)="onSelect($event); error = null"
                        [ngbTypeahead]="search"
                        [inputFormatter]="formatter"
                        [resultFormatter]="formatter"
                        [resultTemplate]="template"
                        editable=false />
                
                    <ng-template #template let-r="result" let-t="term">
                        <ngb-highlight [result]="r.value" [term]="t"></ngb-highlight>
                        <span *ngIf="r.dossier" class="typeahead-after">
                            {{getDossierNrs(r.dossier.dossier_nrs)}}
                            <ng-container *ngIf="getStampDossierNrs(r.dossier.dossier_nrs)">
                                <span class="stamp mr-xxs ml-xxs stamp--small">
                                    {{getStampDossierNrs(r.dossier.dossier_nrs)}}
                                </span>
                            </ng-container>
                        </span>
                    </ng-template>
                    <span *ngIf="error && !HelpersService.isObj(error)" class="error-field-display">{{error}}</span>
                </div>
            </td>
        </tr>
    </ng-container>
</table>