/**
 * pnr
 * pnr api
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent, HttpParameterCodec }       from '@angular/common/http';
import { CustomHttpParameterCodec }                          from '../encoder';
import { Observable }                                        from 'rxjs';

import { BulkDocumentValidationObj } from '../model/models';
import { BulkDocumentsResponse } from '../model/models';
import { BulkUploadUpdateDocumentIdsBody } from '../model/models';
import { CompaniesLinkCompaniesBody } from '../model/models';
import { Company } from '../model/models';
import { CompanySTR } from '../model/models';
import { Contract } from '../model/models';
import { ContractSTR } from '../model/models';
import { DocumentFileReadBody } from '../model/models';
import { DocumentLinkDocumentToDossiersBody } from '../model/models';
import { Dossier } from '../model/models';
import { DossierSTR } from '../model/models';
import { Estimated } from '../model/models';
import { EstimatedCopy } from '../model/models';
import { FinancialScheme } from '../model/models';
import { History } from '../model/models';
import { Indication } from '../model/models';
import { InlineResponse200 } from '../model/models';
import { InlineResponse2001 } from '../model/models';
import { InlineResponse2002 } from '../model/models';
import { InlineResponse2003 } from '../model/models';
import { InlineResponse2004 } from '../model/models';
import { InlineResponse2005 } from '../model/models';
import { InlineResponse2006 } from '../model/models';
import { InlineResponse2007 } from '../model/models';
import { LanguageAddNewStringBody } from '../model/models';
import { Payment } from '../model/models';
import { PaymentCopy } from '../model/models';
import { PickContractIdContractQuestionsProlongationTransitionYearPostContractFacialPriceDecreasePostContractPostContractComment } from '../model/models';
import { PriceDossier } from '../model/models';
import { PriceDossierFileReadBody } from '../model/models';
import { ResourceAddIndicationBody } from '../model/models';
import { User } from '../model/models';
import { UserSTR } from '../model/models';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';



@Injectable({
  providedIn: 'root'
})
export class DefaultService {

    protected basePath = 'http://localhost/api';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();
    public encoder: HttpParameterCodec;

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (configuration) {
            this.configuration = configuration;
        }
        if (typeof this.configuration.basePath !== 'string') {
            if (typeof basePath !== 'string') {
                basePath = this.basePath;
            }
            this.configuration.basePath = basePath;
        }
        this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    private addToHttpParams(httpParams: HttpParams, value: any, key?: string): HttpParams {
        if (typeof value === "object" && value instanceof Date === false) {
            httpParams = this.addToHttpParamsRecursive(httpParams, value);
        } else {
            httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
        }
        return httpParams;
    }

    private addToHttpParamsRecursive(httpParams: HttpParams, value?: any, key?: string): HttpParams {
        if (value == null) {
            return httpParams;
        }

        if (typeof value === "object") {
            if (Array.isArray(value)) {
                (value as any[]).forEach( elem => httpParams = this.addToHttpParamsRecursive(httpParams, elem, key));
            } else if (value instanceof Date) {
                if (key != null) {
                    httpParams = httpParams.append(key,
                        (value as Date).toISOString().substr(0, 10));
                } else {
                   throw Error("key may not be null if value is Date");
                }
            } else {
                Object.keys(value).forEach( k => httpParams = this.addToHttpParamsRecursive(
                    httpParams, value[k], key != null ? `${key}.${k}` : k));
            }
        } else if (key != null) {
            httpParams = httpParams.append(key, value);
        } else {
            throw Error("key may not be null if value is not object or array");
        }
        return httpParams;
    }

    /**
     * @param bulkUploadFile 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public bulkUploadBulkDocumentUpload(bulkUploadFile: Blob, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<BulkDocumentValidationObj>;
    public bulkUploadBulkDocumentUpload(bulkUploadFile: Blob, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<BulkDocumentValidationObj>>;
    public bulkUploadBulkDocumentUpload(bulkUploadFile: Blob, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<BulkDocumentValidationObj>>;
    public bulkUploadBulkDocumentUpload(bulkUploadFile: Blob, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (bulkUploadFile === null || bulkUploadFile === undefined) {
            throw new Error('Required parameter bulkUploadFile was null or undefined when calling bulkUploadBulkDocumentUpload.');
        }

        let headers = this.defaultHeaders;

        // authentication (default) required
        if (this.configuration.apiKeys) {
            const key: string | undefined = this.configuration.apiKeys["default"] || this.configuration.apiKeys["jwt"];
            if (key) {
            }
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'multipart/form-data'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any; };
        let useForm = false;
        let convertFormParamsToString = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        // see https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new HttpParams({encoder: this.encoder});
        }

        if (bulkUploadFile !== undefined) {
            formParams = formParams.append('bulkUploadFile', <any>bulkUploadFile) as any || formParams;
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<BulkDocumentValidationObj>(`${this.configuration.basePath}/bulk-upload/crm/save`,
            convertFormParamsToString ? formParams.toString() : formParams,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param notuleFile 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public bulkUploadBulkDocumentUploadNotule(notuleFile: Blob, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<BulkDocumentValidationObj>;
    public bulkUploadBulkDocumentUploadNotule(notuleFile: Blob, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<BulkDocumentValidationObj>>;
    public bulkUploadBulkDocumentUploadNotule(notuleFile: Blob, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<BulkDocumentValidationObj>>;
    public bulkUploadBulkDocumentUploadNotule(notuleFile: Blob, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (notuleFile === null || notuleFile === undefined) {
            throw new Error('Required parameter notuleFile was null or undefined when calling bulkUploadBulkDocumentUploadNotule.');
        }

        let headers = this.defaultHeaders;

        // authentication (default) required
        if (this.configuration.apiKeys) {
            const key: string | undefined = this.configuration.apiKeys["default"] || this.configuration.apiKeys["jwt"];
            if (key) {
            }
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'multipart/form-data'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any; };
        let useForm = false;
        let convertFormParamsToString = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        // see https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new HttpParams({encoder: this.encoder});
        }

        if (notuleFile !== undefined) {
            formParams = formParams.append('notuleFile', <any>notuleFile) as any || formParams;
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<BulkDocumentValidationObj>(`${this.configuration.basePath}/bulk-upload/notule/save`,
            convertFormParamsToString ? formParams.toString() : formParams,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param documentIds 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public bulkUploadConfirmCRMDocument(documentIds: Array<number>, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<any>;
    public bulkUploadConfirmCRMDocument(documentIds: Array<number>, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<HttpResponse<any>>;
    public bulkUploadConfirmCRMDocument(documentIds: Array<number>, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<HttpEvent<any>>;
    public bulkUploadConfirmCRMDocument(documentIds: Array<number>, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: undefined}): Observable<any> {
        if (documentIds === null || documentIds === undefined) {
            throw new Error('Required parameter documentIds was null or undefined when calling bulkUploadConfirmCRMDocument.');
        }

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (documentIds) {
            documentIds.forEach((element) => {
                queryParameters = this.addToHttpParams(queryParameters,
                  <any>element, 'documentIds[]');
            })
        }

        let headers = this.defaultHeaders;

        // authentication (default) required
        if (this.configuration.apiKeys) {
            const key: string | undefined = this.configuration.apiKeys["default"] || this.configuration.apiKeys["jwt"];
            if (key) {
            }
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.put<any>(`${this.configuration.basePath}/bulk-upload/crm/confirm`,
            null,
            {
                params: queryParameters,
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param documentIds 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public bulkUploadDeleteDocument(documentIds: Array<number>, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<any>;
    public bulkUploadDeleteDocument(documentIds: Array<number>, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<HttpResponse<any>>;
    public bulkUploadDeleteDocument(documentIds: Array<number>, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<HttpEvent<any>>;
    public bulkUploadDeleteDocument(documentIds: Array<number>, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: undefined}): Observable<any> {
        if (documentIds === null || documentIds === undefined) {
            throw new Error('Required parameter documentIds was null or undefined when calling bulkUploadDeleteDocument.');
        }

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (documentIds) {
            documentIds.forEach((element) => {
                queryParameters = this.addToHttpParams(queryParameters,
                  <any>element, 'documentIds[]');
            })
        }

        let headers = this.defaultHeaders;

        // authentication (default) required
        if (this.configuration.apiKeys) {
            const key: string | undefined = this.configuration.apiKeys["default"] || this.configuration.apiKeys["jwt"];
            if (key) {
            }
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.delete<any>(`${this.configuration.basePath}/bulk-upload/crm/invalid/delete`,
            {
                params: queryParameters,
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param documentId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public bulkUploadGetBulkDocuments(documentId?: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<BulkDocumentsResponse>;
    public bulkUploadGetBulkDocuments(documentId?: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<BulkDocumentsResponse>>;
    public bulkUploadGetBulkDocuments(documentId?: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<BulkDocumentsResponse>>;
    public bulkUploadGetBulkDocuments(documentId?: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (documentId !== undefined && documentId !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>documentId, 'documentId');
        }

        let headers = this.defaultHeaders;

        // authentication (default) required
        if (this.configuration.apiKeys) {
            const key: string | undefined = this.configuration.apiKeys["default"] || this.configuration.apiKeys["jwt"];
            if (key) {
            }
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<BulkDocumentsResponse>(`${this.configuration.basePath}/bulk-upload/documents`,
            {
                params: queryParameters,
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param bulkUploadUpdateDocumentIdsBody 
     * @param documentId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public bulkUploadUpdateDocumentIds(bulkUploadUpdateDocumentIdsBody: BulkUploadUpdateDocumentIdsBody, documentId: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<object>;
    public bulkUploadUpdateDocumentIds(bulkUploadUpdateDocumentIdsBody: BulkUploadUpdateDocumentIdsBody, documentId: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<object>>;
    public bulkUploadUpdateDocumentIds(bulkUploadUpdateDocumentIdsBody: BulkUploadUpdateDocumentIdsBody, documentId: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<object>>;
    public bulkUploadUpdateDocumentIds(bulkUploadUpdateDocumentIdsBody: BulkUploadUpdateDocumentIdsBody, documentId: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (bulkUploadUpdateDocumentIdsBody === null || bulkUploadUpdateDocumentIdsBody === undefined) {
            throw new Error('Required parameter bulkUploadUpdateDocumentIdsBody was null or undefined when calling bulkUploadUpdateDocumentIds.');
        }
        if (documentId === null || documentId === undefined) {
            throw new Error('Required parameter documentId was null or undefined when calling bulkUploadUpdateDocumentIds.');
        }

        let headers = this.defaultHeaders;

        // authentication (default) required
        if (this.configuration.apiKeys) {
            const key: string | undefined = this.configuration.apiKeys["default"] || this.configuration.apiKeys["jwt"];
            if (key) {
            }
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.put<object>(`${this.configuration.basePath}/bulk-upload/crm/${encodeURIComponent(String(documentId))}/update`,
            bulkUploadUpdateDocumentIdsBody,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param orderBy 
     * @param samCompanyName 
     * @param companyName 
     * @param linkStatus 
     * @param companyId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public companiesExportLinkedCompanies(orderBy?: Array<string>, samCompanyName?: string, companyName?: string, linkStatus?: 'linked' | 'open', companyId?: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/html'}): Observable<Blob>;
    public companiesExportLinkedCompanies(orderBy?: Array<string>, samCompanyName?: string, companyName?: string, linkStatus?: 'linked' | 'open', companyId?: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/html'}): Observable<HttpResponse<Blob>>;
    public companiesExportLinkedCompanies(orderBy?: Array<string>, samCompanyName?: string, companyName?: string, linkStatus?: 'linked' | 'open', companyId?: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/html'}): Observable<HttpEvent<Blob>>;
    public companiesExportLinkedCompanies(orderBy?: Array<string>, samCompanyName?: string, companyName?: string, linkStatus?: 'linked' | 'open', companyId?: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/html'}): Observable<any> {

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (orderBy) {
            orderBy.forEach((element) => {
                queryParameters = this.addToHttpParams(queryParameters,
                  <any>element, 'orderBy[]');
            })
        }
        if (samCompanyName !== undefined && samCompanyName !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>samCompanyName, 'samCompanyName');
        }
        if (companyName !== undefined && companyName !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>companyName, 'companyName');
        }
        if (linkStatus !== undefined && linkStatus !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>linkStatus, 'linkStatus');
        }
        if (companyId !== undefined && companyId !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>companyId, 'company_id');
        }

        let headers = this.defaultHeaders;

        // authentication (default) required
        if (this.configuration.apiKeys) {
            const key: string | undefined = this.configuration.apiKeys["default"] || this.configuration.apiKeys["jwt"];
            if (key) {
            }
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'text/html'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        return this.httpClient.get(`${this.configuration.basePath}/companies/export`,
            {
                params: queryParameters,
                responseType: "blob",
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param startRow 
     * @param rpp 
     * @param orderBy 
     * @param samCompanyName 
     * @param companyName 
     * @param linkStatus 
     * @param companyId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public companiesGetLinkedCompanies(startRow?: number, rpp?: number, orderBy?: Array<string>, samCompanyName?: string, companyName?: string, linkStatus?: 'linked' | 'open', companyId?: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<InlineResponse2007>;
    public companiesGetLinkedCompanies(startRow?: number, rpp?: number, orderBy?: Array<string>, samCompanyName?: string, companyName?: string, linkStatus?: 'linked' | 'open', companyId?: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<InlineResponse2007>>;
    public companiesGetLinkedCompanies(startRow?: number, rpp?: number, orderBy?: Array<string>, samCompanyName?: string, companyName?: string, linkStatus?: 'linked' | 'open', companyId?: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<InlineResponse2007>>;
    public companiesGetLinkedCompanies(startRow?: number, rpp?: number, orderBy?: Array<string>, samCompanyName?: string, companyName?: string, linkStatus?: 'linked' | 'open', companyId?: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (startRow !== undefined && startRow !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>startRow, 'startRow');
        }
        if (rpp !== undefined && rpp !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>rpp, 'rpp');
        }
        if (orderBy) {
            orderBy.forEach((element) => {
                queryParameters = this.addToHttpParams(queryParameters,
                  <any>element, 'orderBy[]');
            })
        }
        if (samCompanyName !== undefined && samCompanyName !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>samCompanyName, 'samCompanyName');
        }
        if (companyName !== undefined && companyName !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>companyName, 'companyName');
        }
        if (linkStatus !== undefined && linkStatus !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>linkStatus, 'linkStatus');
        }
        if (companyId !== undefined && companyId !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>companyId, 'company_id');
        }

        let headers = this.defaultHeaders;

        // authentication (default) required
        if (this.configuration.apiKeys) {
            const key: string | undefined = this.configuration.apiKeys["default"] || this.configuration.apiKeys["jwt"];
            if (key) {
            }
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<InlineResponse2007>(`${this.configuration.basePath}/companies/links`,
            {
                params: queryParameters,
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param startRow 
     * @param rpp 
     * @param orderBy 
     * @param term 
     * @param excludeIds 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public companiesGetPharmaCompanies(startRow?: number, rpp?: number, orderBy?: Array<string>, term?: string, excludeIds?: Array<string>, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<CompanySTR>;
    public companiesGetPharmaCompanies(startRow?: number, rpp?: number, orderBy?: Array<string>, term?: string, excludeIds?: Array<string>, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<CompanySTR>>;
    public companiesGetPharmaCompanies(startRow?: number, rpp?: number, orderBy?: Array<string>, term?: string, excludeIds?: Array<string>, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<CompanySTR>>;
    public companiesGetPharmaCompanies(startRow?: number, rpp?: number, orderBy?: Array<string>, term?: string, excludeIds?: Array<string>, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (startRow !== undefined && startRow !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>startRow, 'startRow');
        }
        if (rpp !== undefined && rpp !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>rpp, 'rpp');
        }
        if (orderBy) {
            orderBy.forEach((element) => {
                queryParameters = this.addToHttpParams(queryParameters,
                  <any>element, 'orderBy[]');
            })
        }
        if (term !== undefined && term !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>term, 'term');
        }
        if (excludeIds) {
            excludeIds.forEach((element) => {
                queryParameters = this.addToHttpParams(queryParameters,
                  <any>element, 'excludeIds[]');
            })
        }

        let headers = this.defaultHeaders;

        // authentication (default) required
        if (this.configuration.apiKeys) {
            const key: string | undefined = this.configuration.apiKeys["default"] || this.configuration.apiKeys["jwt"];
            if (key) {
            }
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<CompanySTR>(`${this.configuration.basePath}/companies/pharma`,
            {
                params: queryParameters,
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param startRow 
     * @param rpp 
     * @param orderBy 
     * @param term 
     * @param excludeUuids 
     * @param companyId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public companiesGetSamCompanies(startRow?: number, rpp?: number, orderBy?: Array<string>, term?: string, excludeUuids?: Array<string>, companyId?: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<CompanySTR>;
    public companiesGetSamCompanies(startRow?: number, rpp?: number, orderBy?: Array<string>, term?: string, excludeUuids?: Array<string>, companyId?: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<CompanySTR>>;
    public companiesGetSamCompanies(startRow?: number, rpp?: number, orderBy?: Array<string>, term?: string, excludeUuids?: Array<string>, companyId?: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<CompanySTR>>;
    public companiesGetSamCompanies(startRow?: number, rpp?: number, orderBy?: Array<string>, term?: string, excludeUuids?: Array<string>, companyId?: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (startRow !== undefined && startRow !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>startRow, 'startRow');
        }
        if (rpp !== undefined && rpp !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>rpp, 'rpp');
        }
        if (orderBy) {
            orderBy.forEach((element) => {
                queryParameters = this.addToHttpParams(queryParameters,
                  <any>element, 'orderBy[]');
            })
        }
        if (term !== undefined && term !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>term, 'term');
        }
        if (excludeUuids) {
            excludeUuids.forEach((element) => {
                queryParameters = this.addToHttpParams(queryParameters,
                  <any>element, 'excludeUuids[]');
            })
        }
        if (companyId !== undefined && companyId !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>companyId, 'company_id');
        }

        let headers = this.defaultHeaders;

        // authentication (default) required
        if (this.configuration.apiKeys) {
            const key: string | undefined = this.configuration.apiKeys["default"] || this.configuration.apiKeys["jwt"];
            if (key) {
            }
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<CompanySTR>(`${this.configuration.basePath}/companies/sam`,
            {
                params: queryParameters,
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param companiesLinkCompaniesBody 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public companiesLinkCompanies(companiesLinkCompaniesBody: CompaniesLinkCompaniesBody, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<Company>;
    public companiesLinkCompanies(companiesLinkCompaniesBody: CompaniesLinkCompaniesBody, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<Company>>;
    public companiesLinkCompanies(companiesLinkCompaniesBody: CompaniesLinkCompaniesBody, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<Company>>;
    public companiesLinkCompanies(companiesLinkCompaniesBody: CompaniesLinkCompaniesBody, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (companiesLinkCompaniesBody === null || companiesLinkCompaniesBody === undefined) {
            throw new Error('Required parameter companiesLinkCompaniesBody was null or undefined when calling companiesLinkCompanies.');
        }

        let headers = this.defaultHeaders;

        // authentication (default) required
        if (this.configuration.apiKeys) {
            const key: string | undefined = this.configuration.apiKeys["default"] || this.configuration.apiKeys["jwt"];
            if (key) {
            }
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<Company>(`${this.configuration.basePath}/companies/link`,
            companiesLinkCompaniesBody,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public configGetConfig(observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<object>;
    public configGetConfig(observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<object>>;
    public configGetConfig(observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<object>>;
    public configGetConfig(observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {

        let headers = this.defaultHeaders;

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<object>(`${this.configuration.basePath}/config`,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param contractId 
     * @param archive 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public contractArchive(contractId: string, archive?: boolean, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<any>;
    public contractArchive(contractId: string, archive?: boolean, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<HttpResponse<any>>;
    public contractArchive(contractId: string, archive?: boolean, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<HttpEvent<any>>;
    public contractArchive(contractId: string, archive?: boolean, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: undefined}): Observable<any> {
        if (contractId === null || contractId === undefined) {
            throw new Error('Required parameter contractId was null or undefined when calling contractArchive.');
        }

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (archive !== undefined && archive !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>archive, 'archive');
        }

        let headers = this.defaultHeaders;

        // authentication (default) required
        if (this.configuration.apiKeys) {
            const key: string | undefined = this.configuration.apiKeys["default"] || this.configuration.apiKeys["jwt"];
            if (key) {
            }
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.patch<any>(`${this.configuration.basePath}/contract/${encodeURIComponent(String(contractId))}/archive`,
            null,
            {
                params: queryParameters,
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param estimatedCopy 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public contractCopyEstimated(estimatedCopy: EstimatedCopy, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<any>;
    public contractCopyEstimated(estimatedCopy: EstimatedCopy, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<HttpResponse<any>>;
    public contractCopyEstimated(estimatedCopy: EstimatedCopy, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<HttpEvent<any>>;
    public contractCopyEstimated(estimatedCopy: EstimatedCopy, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: undefined}): Observable<any> {
        if (estimatedCopy === null || estimatedCopy === undefined) {
            throw new Error('Required parameter estimatedCopy was null or undefined when calling contractCopyEstimated.');
        }

        let headers = this.defaultHeaders;

        // authentication (default) required
        if (this.configuration.apiKeys) {
            const key: string | undefined = this.configuration.apiKeys["default"] || this.configuration.apiKeys["jwt"];
            if (key) {
            }
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<any>(`${this.configuration.basePath}/contract/estimated/copy`,
            estimatedCopy,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param paymentCopy 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public contractCopyPayment(paymentCopy: PaymentCopy, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<any>;
    public contractCopyPayment(paymentCopy: PaymentCopy, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<HttpResponse<any>>;
    public contractCopyPayment(paymentCopy: PaymentCopy, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<HttpEvent<any>>;
    public contractCopyPayment(paymentCopy: PaymentCopy, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: undefined}): Observable<any> {
        if (paymentCopy === null || paymentCopy === undefined) {
            throw new Error('Required parameter paymentCopy was null or undefined when calling contractCopyPayment.');
        }

        let headers = this.defaultHeaders;

        // authentication (default) required
        if (this.configuration.apiKeys) {
            const key: string | undefined = this.configuration.apiKeys["default"] || this.configuration.apiKeys["jwt"];
            if (key) {
            }
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<any>(`${this.configuration.basePath}/contract/payment/copy`,
            paymentCopy,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param estimated 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public contractCreateOrUpdateEstimated(estimated: Estimated, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<Estimated>;
    public contractCreateOrUpdateEstimated(estimated: Estimated, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<Estimated>>;
    public contractCreateOrUpdateEstimated(estimated: Estimated, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<Estimated>>;
    public contractCreateOrUpdateEstimated(estimated: Estimated, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (estimated === null || estimated === undefined) {
            throw new Error('Required parameter estimated was null or undefined when calling contractCreateOrUpdateEstimated.');
        }

        let headers = this.defaultHeaders;

        // authentication (default) required
        if (this.configuration.apiKeys) {
            const key: string | undefined = this.configuration.apiKeys["default"] || this.configuration.apiKeys["jwt"];
            if (key) {
            }
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<Estimated>(`${this.configuration.basePath}/contract/estimated`,
            estimated,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param financialScheme 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public contractCreateOrUpdateFinancial(financialScheme: FinancialScheme, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<FinancialScheme>;
    public contractCreateOrUpdateFinancial(financialScheme: FinancialScheme, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<FinancialScheme>>;
    public contractCreateOrUpdateFinancial(financialScheme: FinancialScheme, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<FinancialScheme>>;
    public contractCreateOrUpdateFinancial(financialScheme: FinancialScheme, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (financialScheme === null || financialScheme === undefined) {
            throw new Error('Required parameter financialScheme was null or undefined when calling contractCreateOrUpdateFinancial.');
        }

        let headers = this.defaultHeaders;

        // authentication (default) required
        if (this.configuration.apiKeys) {
            const key: string | undefined = this.configuration.apiKeys["default"] || this.configuration.apiKeys["jwt"];
            if (key) {
            }
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<FinancialScheme>(`${this.configuration.basePath}/contract/financial`,
            financialScheme,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param contract 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public contractCreateOrUpdateGeneral(contract: Contract, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<Contract>;
    public contractCreateOrUpdateGeneral(contract: Contract, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<Contract>>;
    public contractCreateOrUpdateGeneral(contract: Contract, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<Contract>>;
    public contractCreateOrUpdateGeneral(contract: Contract, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (contract === null || contract === undefined) {
            throw new Error('Required parameter contract was null or undefined when calling contractCreateOrUpdateGeneral.');
        }

        let headers = this.defaultHeaders;

        // authentication (default) required
        if (this.configuration.apiKeys) {
            const key: string | undefined = this.configuration.apiKeys["default"] || this.configuration.apiKeys["jwt"];
            if (key) {
            }
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<Contract>(`${this.configuration.basePath}/contract/general`,
            contract,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param payment 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public contractCreateOrUpdatePayment(payment: Payment, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<Payment>;
    public contractCreateOrUpdatePayment(payment: Payment, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<Payment>>;
    public contractCreateOrUpdatePayment(payment: Payment, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<Payment>>;
    public contractCreateOrUpdatePayment(payment: Payment, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (payment === null || payment === undefined) {
            throw new Error('Required parameter payment was null or undefined when calling contractCreateOrUpdatePayment.');
        }

        let headers = this.defaultHeaders;

        // authentication (default) required
        if (this.configuration.apiKeys) {
            const key: string | undefined = this.configuration.apiKeys["default"] || this.configuration.apiKeys["jwt"];
            if (key) {
            }
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<Payment>(`${this.configuration.basePath}/contract/payment`,
            payment,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param pickContractIdContractQuestionsProlongationTransitionYearPostContractFacialPriceDecreasePostContractPostContractComment 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public contractCreateOrUpdateSummary(pickContractIdContractQuestionsProlongationTransitionYearPostContractFacialPriceDecreasePostContractPostContractComment: PickContractIdContractQuestionsProlongationTransitionYearPostContractFacialPriceDecreasePostContractPostContractComment, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<any>;
    public contractCreateOrUpdateSummary(pickContractIdContractQuestionsProlongationTransitionYearPostContractFacialPriceDecreasePostContractPostContractComment: PickContractIdContractQuestionsProlongationTransitionYearPostContractFacialPriceDecreasePostContractPostContractComment, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<HttpResponse<any>>;
    public contractCreateOrUpdateSummary(pickContractIdContractQuestionsProlongationTransitionYearPostContractFacialPriceDecreasePostContractPostContractComment: PickContractIdContractQuestionsProlongationTransitionYearPostContractFacialPriceDecreasePostContractPostContractComment, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<HttpEvent<any>>;
    public contractCreateOrUpdateSummary(pickContractIdContractQuestionsProlongationTransitionYearPostContractFacialPriceDecreasePostContractPostContractComment: PickContractIdContractQuestionsProlongationTransitionYearPostContractFacialPriceDecreasePostContractPostContractComment, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: undefined}): Observable<any> {
        if (pickContractIdContractQuestionsProlongationTransitionYearPostContractFacialPriceDecreasePostContractPostContractComment === null || pickContractIdContractQuestionsProlongationTransitionYearPostContractFacialPriceDecreasePostContractPostContractComment === undefined) {
            throw new Error('Required parameter pickContractIdContractQuestionsProlongationTransitionYearPostContractFacialPriceDecreasePostContractPostContractComment was null or undefined when calling contractCreateOrUpdateSummary.');
        }

        let headers = this.defaultHeaders;

        // authentication (default) required
        if (this.configuration.apiKeys) {
            const key: string | undefined = this.configuration.apiKeys["default"] || this.configuration.apiKeys["jwt"];
            if (key) {
            }
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<any>(`${this.configuration.basePath}/contract/summary`,
            pickContractIdContractQuestionsProlongationTransitionYearPostContractFacialPriceDecreasePostContractPostContractComment,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param contractId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public contractDelete(contractId: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<any>;
    public contractDelete(contractId: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<HttpResponse<any>>;
    public contractDelete(contractId: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<HttpEvent<any>>;
    public contractDelete(contractId: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: undefined}): Observable<any> {
        if (contractId === null || contractId === undefined) {
            throw new Error('Required parameter contractId was null or undefined when calling contractDelete.');
        }

        let headers = this.defaultHeaders;

        // authentication (default) required
        if (this.configuration.apiKeys) {
            const key: string | undefined = this.configuration.apiKeys["default"] || this.configuration.apiKeys["jwt"];
            if (key) {
            }
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.delete<any>(`${this.configuration.basePath}/contract/${encodeURIComponent(String(contractId))}`,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param estimatedUuid 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public contractDeleteEstimated(estimatedUuid: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<any>;
    public contractDeleteEstimated(estimatedUuid: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<HttpResponse<any>>;
    public contractDeleteEstimated(estimatedUuid: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<HttpEvent<any>>;
    public contractDeleteEstimated(estimatedUuid: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: undefined}): Observable<any> {
        if (estimatedUuid === null || estimatedUuid === undefined) {
            throw new Error('Required parameter estimatedUuid was null or undefined when calling contractDeleteEstimated.');
        }

        let headers = this.defaultHeaders;

        // authentication (default) required
        if (this.configuration.apiKeys) {
            const key: string | undefined = this.configuration.apiKeys["default"] || this.configuration.apiKeys["jwt"];
            if (key) {
            }
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.delete<any>(`${this.configuration.basePath}/contract/estimated/${encodeURIComponent(String(estimatedUuid))}`,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param paymentUuid 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public contractDeletePayment(paymentUuid: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<any>;
    public contractDeletePayment(paymentUuid: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<HttpResponse<any>>;
    public contractDeletePayment(paymentUuid: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<HttpEvent<any>>;
    public contractDeletePayment(paymentUuid: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: undefined}): Observable<any> {
        if (paymentUuid === null || paymentUuid === undefined) {
            throw new Error('Required parameter paymentUuid was null or undefined when calling contractDeletePayment.');
        }

        let headers = this.defaultHeaders;

        // authentication (default) required
        if (this.configuration.apiKeys) {
            const key: string | undefined = this.configuration.apiKeys["default"] || this.configuration.apiKeys["jwt"];
            if (key) {
            }
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.delete<any>(`${this.configuration.basePath}/contract/payment/${encodeURIComponent(String(paymentUuid))}`,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param contractId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public contractGetContract(contractId: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<Contract>;
    public contractGetContract(contractId: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<Contract>>;
    public contractGetContract(contractId: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<Contract>>;
    public contractGetContract(contractId: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (contractId === null || contractId === undefined) {
            throw new Error('Required parameter contractId was null or undefined when calling contractGetContract.');
        }

        let headers = this.defaultHeaders;

        // authentication (default) required
        if (this.configuration.apiKeys) {
            const key: string | undefined = this.configuration.apiKeys["default"] || this.configuration.apiKeys["jwt"];
            if (key) {
            }
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<Contract>(`${this.configuration.basePath}/contract/detail/${encodeURIComponent(String(contractId))}`,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param companyId 
     * @param term 
     * @param excludeIds 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public contractGetContractDossiers(companyId?: number, term?: string, excludeIds?: Array<string>, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<DossierSTR>;
    public contractGetContractDossiers(companyId?: number, term?: string, excludeIds?: Array<string>, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<DossierSTR>>;
    public contractGetContractDossiers(companyId?: number, term?: string, excludeIds?: Array<string>, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<DossierSTR>>;
    public contractGetContractDossiers(companyId?: number, term?: string, excludeIds?: Array<string>, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (companyId !== undefined && companyId !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>companyId, 'companyId');
        }
        if (term !== undefined && term !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>term, 'term');
        }
        if (excludeIds) {
            excludeIds.forEach((element) => {
                queryParameters = this.addToHttpParams(queryParameters,
                  <any>element, 'excludeIds[]');
            })
        }

        let headers = this.defaultHeaders;

        // authentication (default) required
        if (this.configuration.apiKeys) {
            const key: string | undefined = this.configuration.apiKeys["default"] || this.configuration.apiKeys["jwt"];
            if (key) {
            }
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<DossierSTR>(`${this.configuration.basePath}/contract/dossiers`,
            {
                params: queryParameters,
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param orderBy 
     * @param contractNumber 
     * @param dossierNumber 
     * @param contractType 
     * @param stage 
     * @param status 
     * @param term 
     * @param initialContracts 
     * @param excludeIds 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public contractsExportContracts(orderBy?: Array<string>, contractNumber?: string, dossierNumber?: string, contractType?: string, stage?: string, status?: 'active' | 'archived', term?: string, initialContracts?: boolean, excludeIds?: Array<string>, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/html'}): Observable<Blob>;
    public contractsExportContracts(orderBy?: Array<string>, contractNumber?: string, dossierNumber?: string, contractType?: string, stage?: string, status?: 'active' | 'archived', term?: string, initialContracts?: boolean, excludeIds?: Array<string>, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/html'}): Observable<HttpResponse<Blob>>;
    public contractsExportContracts(orderBy?: Array<string>, contractNumber?: string, dossierNumber?: string, contractType?: string, stage?: string, status?: 'active' | 'archived', term?: string, initialContracts?: boolean, excludeIds?: Array<string>, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/html'}): Observable<HttpEvent<Blob>>;
    public contractsExportContracts(orderBy?: Array<string>, contractNumber?: string, dossierNumber?: string, contractType?: string, stage?: string, status?: 'active' | 'archived', term?: string, initialContracts?: boolean, excludeIds?: Array<string>, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/html'}): Observable<any> {

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (orderBy) {
            orderBy.forEach((element) => {
                queryParameters = this.addToHttpParams(queryParameters,
                  <any>element, 'orderBy[]');
            })
        }
        if (contractNumber !== undefined && contractNumber !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>contractNumber, 'contractNumber');
        }
        if (dossierNumber !== undefined && dossierNumber !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>dossierNumber, 'dossierNumber');
        }
        if (contractType !== undefined && contractType !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>contractType, 'contractType');
        }
        if (stage !== undefined && stage !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>stage, 'stage');
        }
        if (status !== undefined && status !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>status, 'status');
        }
        if (term !== undefined && term !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>term, 'term');
        }
        if (initialContracts !== undefined && initialContracts !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>initialContracts, 'initialContracts');
        }
        if (excludeIds) {
            excludeIds.forEach((element) => {
                queryParameters = this.addToHttpParams(queryParameters,
                  <any>element, 'excludeIds[]');
            })
        }

        let headers = this.defaultHeaders;

        // authentication (default) required
        if (this.configuration.apiKeys) {
            const key: string | undefined = this.configuration.apiKeys["default"] || this.configuration.apiKeys["jwt"];
            if (key) {
            }
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'text/html'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        return this.httpClient.get(`${this.configuration.basePath}/contracts/export`,
            {
                params: queryParameters,
                responseType: "blob",
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param startRow 
     * @param rpp 
     * @param orderBy 
     * @param contractNumber 
     * @param dossierNumber 
     * @param contractType 
     * @param stage 
     * @param status 
     * @param term 
     * @param initialContracts 
     * @param excludeIds 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public contractsGetContracts(startRow?: number, rpp?: number, orderBy?: Array<string>, contractNumber?: string, dossierNumber?: string, contractType?: string, stage?: string, status?: 'active' | 'archived', term?: string, initialContracts?: boolean, excludeIds?: Array<string>, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<ContractSTR>;
    public contractsGetContracts(startRow?: number, rpp?: number, orderBy?: Array<string>, contractNumber?: string, dossierNumber?: string, contractType?: string, stage?: string, status?: 'active' | 'archived', term?: string, initialContracts?: boolean, excludeIds?: Array<string>, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<ContractSTR>>;
    public contractsGetContracts(startRow?: number, rpp?: number, orderBy?: Array<string>, contractNumber?: string, dossierNumber?: string, contractType?: string, stage?: string, status?: 'active' | 'archived', term?: string, initialContracts?: boolean, excludeIds?: Array<string>, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<ContractSTR>>;
    public contractsGetContracts(startRow?: number, rpp?: number, orderBy?: Array<string>, contractNumber?: string, dossierNumber?: string, contractType?: string, stage?: string, status?: 'active' | 'archived', term?: string, initialContracts?: boolean, excludeIds?: Array<string>, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (startRow !== undefined && startRow !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>startRow, 'startRow');
        }
        if (rpp !== undefined && rpp !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>rpp, 'rpp');
        }
        if (orderBy) {
            orderBy.forEach((element) => {
                queryParameters = this.addToHttpParams(queryParameters,
                  <any>element, 'orderBy[]');
            })
        }
        if (contractNumber !== undefined && contractNumber !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>contractNumber, 'contractNumber');
        }
        if (dossierNumber !== undefined && dossierNumber !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>dossierNumber, 'dossierNumber');
        }
        if (contractType !== undefined && contractType !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>contractType, 'contractType');
        }
        if (stage !== undefined && stage !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>stage, 'stage');
        }
        if (status !== undefined && status !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>status, 'status');
        }
        if (term !== undefined && term !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>term, 'term');
        }
        if (initialContracts !== undefined && initialContracts !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>initialContracts, 'initialContracts');
        }
        if (excludeIds) {
            excludeIds.forEach((element) => {
                queryParameters = this.addToHttpParams(queryParameters,
                  <any>element, 'excludeIds[]');
            })
        }

        let headers = this.defaultHeaders;

        // authentication (default) required
        if (this.configuration.apiKeys) {
            const key: string | undefined = this.configuration.apiKeys["default"] || this.configuration.apiKeys["jwt"];
            if (key) {
            }
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<ContractSTR>(`${this.configuration.basePath}/contracts`,
            {
                params: queryParameters,
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param documentFileReadBody 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public documentFileRead(documentFileReadBody: DocumentFileReadBody, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<any>;
    public documentFileRead(documentFileReadBody: DocumentFileReadBody, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<HttpResponse<any>>;
    public documentFileRead(documentFileReadBody: DocumentFileReadBody, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<HttpEvent<any>>;
    public documentFileRead(documentFileReadBody: DocumentFileReadBody, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: undefined}): Observable<any> {
        if (documentFileReadBody === null || documentFileReadBody === undefined) {
            throw new Error('Required parameter documentFileReadBody was null or undefined when calling documentFileRead.');
        }

        let headers = this.defaultHeaders;

        // authentication (default) required
        if (this.configuration.apiKeys) {
            const key: string | undefined = this.configuration.apiKeys["default"] || this.configuration.apiKeys["jwt"];
            if (key) {
            }
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.patch<any>(`${this.configuration.basePath}/document/log`,
            documentFileReadBody,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param contractId 
     * @param documentId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public documentGetDocumentsForContract(contractId: string, documentId?: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<object>;
    public documentGetDocumentsForContract(contractId: string, documentId?: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<object>>;
    public documentGetDocumentsForContract(contractId: string, documentId?: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<object>>;
    public documentGetDocumentsForContract(contractId: string, documentId?: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (contractId === null || contractId === undefined) {
            throw new Error('Required parameter contractId was null or undefined when calling documentGetDocumentsForContract.');
        }

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (documentId !== undefined && documentId !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>documentId, 'documentId');
        }

        let headers = this.defaultHeaders;

        // authentication (default) required
        if (this.configuration.apiKeys) {
            const key: string | undefined = this.configuration.apiKeys["default"] || this.configuration.apiKeys["jwt"];
            if (key) {
            }
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<object>(`${this.configuration.basePath}/document/contract/${encodeURIComponent(String(contractId))}`,
            {
                params: queryParameters,
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param dossierId 
     * @param documentId 
     * @param folderId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public documentGetDocumentsForDossier(dossierId: number, documentId?: number, folderId?: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<object>;
    public documentGetDocumentsForDossier(dossierId: number, documentId?: number, folderId?: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<object>>;
    public documentGetDocumentsForDossier(dossierId: number, documentId?: number, folderId?: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<object>>;
    public documentGetDocumentsForDossier(dossierId: number, documentId?: number, folderId?: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (dossierId === null || dossierId === undefined) {
            throw new Error('Required parameter dossierId was null or undefined when calling documentGetDocumentsForDossier.');
        }

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (documentId !== undefined && documentId !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>documentId, 'documentId');
        }
        if (folderId !== undefined && folderId !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>folderId, 'folderId');
        }

        let headers = this.defaultHeaders;

        // authentication (default) required
        if (this.configuration.apiKeys) {
            const key: string | undefined = this.configuration.apiKeys["default"] || this.configuration.apiKeys["jwt"];
            if (key) {
            }
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<object>(`${this.configuration.basePath}/document/dossier/${encodeURIComponent(String(dossierId))}`,
            {
                params: queryParameters,
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param documentLinkDocumentToDossiersBody 
     * @param documentId 
     * @param folderId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public documentLinkDocumentToDossiers(documentLinkDocumentToDossiersBody: DocumentLinkDocumentToDossiersBody, documentId: number, folderId?: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<any>;
    public documentLinkDocumentToDossiers(documentLinkDocumentToDossiersBody: DocumentLinkDocumentToDossiersBody, documentId: number, folderId?: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<HttpResponse<any>>;
    public documentLinkDocumentToDossiers(documentLinkDocumentToDossiersBody: DocumentLinkDocumentToDossiersBody, documentId: number, folderId?: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<HttpEvent<any>>;
    public documentLinkDocumentToDossiers(documentLinkDocumentToDossiersBody: DocumentLinkDocumentToDossiersBody, documentId: number, folderId?: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: undefined}): Observable<any> {
        if (documentLinkDocumentToDossiersBody === null || documentLinkDocumentToDossiersBody === undefined) {
            throw new Error('Required parameter documentLinkDocumentToDossiersBody was null or undefined when calling documentLinkDocumentToDossiers.');
        }
        if (documentId === null || documentId === undefined) {
            throw new Error('Required parameter documentId was null or undefined when calling documentLinkDocumentToDossiers.');
        }

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (folderId !== undefined && folderId !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>folderId, 'folderId');
        }

        let headers = this.defaultHeaders;

        // authentication (default) required
        if (this.configuration.apiKeys) {
            const key: string | undefined = this.configuration.apiKeys["default"] || this.configuration.apiKeys["jwt"];
            if (key) {
            }
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.patch<any>(`${this.configuration.basePath}/document/link/${encodeURIComponent(String(documentId))}`,
            documentLinkDocumentToDossiersBody,
            {
                params: queryParameters,
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param documentId 
     * @param dossierIds 
     * @param folderId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public documentMoveDocument(documentId: number, dossierIds: Array<number>, folderId: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<any>;
    public documentMoveDocument(documentId: number, dossierIds: Array<number>, folderId: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<HttpResponse<any>>;
    public documentMoveDocument(documentId: number, dossierIds: Array<number>, folderId: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<HttpEvent<any>>;
    public documentMoveDocument(documentId: number, dossierIds: Array<number>, folderId: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: undefined}): Observable<any> {
        if (documentId === null || documentId === undefined) {
            throw new Error('Required parameter documentId was null or undefined when calling documentMoveDocument.');
        }
        if (dossierIds === null || dossierIds === undefined) {
            throw new Error('Required parameter dossierIds was null or undefined when calling documentMoveDocument.');
        }
        if (folderId === null || folderId === undefined) {
            throw new Error('Required parameter folderId was null or undefined when calling documentMoveDocument.');
        }

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (dossierIds) {
            dossierIds.forEach((element) => {
                queryParameters = this.addToHttpParams(queryParameters,
                  <any>element, 'dossierIds[]');
            })
        }
        if (folderId !== undefined && folderId !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>folderId, 'folderId');
        }

        let headers = this.defaultHeaders;

        // authentication (default) required
        if (this.configuration.apiKeys) {
            const key: string | undefined = this.configuration.apiKeys["default"] || this.configuration.apiKeys["jwt"];
            if (key) {
            }
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.put<any>(`${this.configuration.basePath}/document/move/${encodeURIComponent(String(documentId))}`,
            null,
            {
                params: queryParameters,
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param documentFile 
     * @param folderId 
     * @param dossierIds 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public documentSaveDocument(documentFile: Blob, folderId: number, dossierIds: Array<number>, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<InlineResponse200>;
    public documentSaveDocument(documentFile: Blob, folderId: number, dossierIds: Array<number>, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<InlineResponse200>>;
    public documentSaveDocument(documentFile: Blob, folderId: number, dossierIds: Array<number>, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<InlineResponse200>>;
    public documentSaveDocument(documentFile: Blob, folderId: number, dossierIds: Array<number>, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (documentFile === null || documentFile === undefined) {
            throw new Error('Required parameter documentFile was null or undefined when calling documentSaveDocument.');
        }
        if (folderId === null || folderId === undefined) {
            throw new Error('Required parameter folderId was null or undefined when calling documentSaveDocument.');
        }
        if (dossierIds === null || dossierIds === undefined) {
            throw new Error('Required parameter dossierIds was null or undefined when calling documentSaveDocument.');
        }

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (folderId !== undefined && folderId !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>folderId, 'folderId');
        }
        if (dossierIds) {
            dossierIds.forEach((element) => {
                queryParameters = this.addToHttpParams(queryParameters,
                  <any>element, 'dossierIds[]');
            })
        }

        let headers = this.defaultHeaders;

        // authentication (default) required
        if (this.configuration.apiKeys) {
            const key: string | undefined = this.configuration.apiKeys["default"] || this.configuration.apiKeys["jwt"];
            if (key) {
            }
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'multipart/form-data'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any; };
        let useForm = false;
        let convertFormParamsToString = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        // see https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new HttpParams({encoder: this.encoder});
        }

        if (documentFile !== undefined) {
            formParams = formParams.append('documentFile', <any>documentFile) as any || formParams;
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<InlineResponse200>(`${this.configuration.basePath}/document/dossier/save`,
            convertFormParamsToString ? formParams.toString() : formParams,
            {
                params: queryParameters,
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param dossierId 
     * @param archive 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public dossierArchive(dossierId: number, archive?: boolean, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<any>;
    public dossierArchive(dossierId: number, archive?: boolean, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<HttpResponse<any>>;
    public dossierArchive(dossierId: number, archive?: boolean, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<HttpEvent<any>>;
    public dossierArchive(dossierId: number, archive?: boolean, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: undefined}): Observable<any> {
        if (dossierId === null || dossierId === undefined) {
            throw new Error('Required parameter dossierId was null or undefined when calling dossierArchive.');
        }

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (archive !== undefined && archive !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>archive, 'archive');
        }

        let headers = this.defaultHeaders;

        // authentication (default) required
        if (this.configuration.apiKeys) {
            const key: string | undefined = this.configuration.apiKeys["default"] || this.configuration.apiKeys["jwt"];
            if (key) {
            }
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<any>(`${this.configuration.basePath}/dossier/${encodeURIComponent(String(dossierId))}/archive`,
            null,
            {
                params: queryParameters,
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param dossier 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public dossierCreateOrUpdateDossierBudget(dossier: Dossier, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<any>;
    public dossierCreateOrUpdateDossierBudget(dossier: Dossier, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<HttpResponse<any>>;
    public dossierCreateOrUpdateDossierBudget(dossier: Dossier, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<HttpEvent<any>>;
    public dossierCreateOrUpdateDossierBudget(dossier: Dossier, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: undefined}): Observable<any> {
        if (dossier === null || dossier === undefined) {
            throw new Error('Required parameter dossier was null or undefined when calling dossierCreateOrUpdateDossierBudget.');
        }

        let headers = this.defaultHeaders;

        // authentication (default) required
        if (this.configuration.apiKeys) {
            const key: string | undefined = this.configuration.apiKeys["default"] || this.configuration.apiKeys["jwt"];
            if (key) {
            }
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<any>(`${this.configuration.basePath}/dossier/budget`,
            dossier,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param dossier 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public dossierCreateOrUpdateDossierGeneral(dossier: Dossier, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<Dossier>;
    public dossierCreateOrUpdateDossierGeneral(dossier: Dossier, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<Dossier>>;
    public dossierCreateOrUpdateDossierGeneral(dossier: Dossier, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<Dossier>>;
    public dossierCreateOrUpdateDossierGeneral(dossier: Dossier, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (dossier === null || dossier === undefined) {
            throw new Error('Required parameter dossier was null or undefined when calling dossierCreateOrUpdateDossierGeneral.');
        }

        let headers = this.defaultHeaders;

        // authentication (default) required
        if (this.configuration.apiKeys) {
            const key: string | undefined = this.configuration.apiKeys["default"] || this.configuration.apiKeys["jwt"];
            if (key) {
            }
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<Dossier>(`${this.configuration.basePath}/dossier/general`,
            dossier,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param dossier 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public dossierCreateOrUpdateDossierStats(dossier: Dossier, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<any>;
    public dossierCreateOrUpdateDossierStats(dossier: Dossier, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<HttpResponse<any>>;
    public dossierCreateOrUpdateDossierStats(dossier: Dossier, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<HttpEvent<any>>;
    public dossierCreateOrUpdateDossierStats(dossier: Dossier, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: undefined}): Observable<any> {
        if (dossier === null || dossier === undefined) {
            throw new Error('Required parameter dossier was null or undefined when calling dossierCreateOrUpdateDossierStats.');
        }

        let headers = this.defaultHeaders;

        // authentication (default) required
        if (this.configuration.apiKeys) {
            const key: string | undefined = this.configuration.apiKeys["default"] || this.configuration.apiKeys["jwt"];
            if (key) {
            }
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<any>(`${this.configuration.basePath}/dossier/stats`,
            dossier,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param dossierId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public dossierDelete(dossierId: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<any>;
    public dossierDelete(dossierId: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<HttpResponse<any>>;
    public dossierDelete(dossierId: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<HttpEvent<any>>;
    public dossierDelete(dossierId: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: undefined}): Observable<any> {
        if (dossierId === null || dossierId === undefined) {
            throw new Error('Required parameter dossierId was null or undefined when calling dossierDelete.');
        }

        let headers = this.defaultHeaders;

        // authentication (default) required
        if (this.configuration.apiKeys) {
            const key: string | undefined = this.configuration.apiKeys["default"] || this.configuration.apiKeys["jwt"];
            if (key) {
            }
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.delete<any>(`${this.configuration.basePath}/dossier/${encodeURIComponent(String(dossierId))}`,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param dossierId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public dossierGetDossier(dossierId: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<Dossier>;
    public dossierGetDossier(dossierId: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<Dossier>>;
    public dossierGetDossier(dossierId: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<Dossier>>;
    public dossierGetDossier(dossierId: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (dossierId === null || dossierId === undefined) {
            throw new Error('Required parameter dossierId was null or undefined when calling dossierGetDossier.');
        }

        let headers = this.defaultHeaders;

        // authentication (default) required
        if (this.configuration.apiKeys) {
            const key: string | undefined = this.configuration.apiKeys["default"] || this.configuration.apiKeys["jwt"];
            if (key) {
            }
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<Dossier>(`${this.configuration.basePath}/dossier/${encodeURIComponent(String(dossierId))}`,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param dossierId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public dossierGetHistory(dossierId: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<Array<History>>;
    public dossierGetHistory(dossierId: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<Array<History>>>;
    public dossierGetHistory(dossierId: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<Array<History>>>;
    public dossierGetHistory(dossierId: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (dossierId === null || dossierId === undefined) {
            throw new Error('Required parameter dossierId was null or undefined when calling dossierGetHistory.');
        }

        let headers = this.defaultHeaders;

        // authentication (default) required
        if (this.configuration.apiKeys) {
            const key: string | undefined = this.configuration.apiKeys["default"] || this.configuration.apiKeys["jwt"];
            if (key) {
            }
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<Array<History>>(`${this.configuration.basePath}/dossier/${encodeURIComponent(String(dossierId))}/history`,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param orderBy 
     * @param dossierNr 
     * @param applicationType 
     * @param atcCode 
     * @param status 
     * @param term 
     * @param startDate 
     * @param endDate 
     * @param day0StartDate 
     * @param day0EndDate 
     * @param reimbStartDate 
     * @param reimbEndDate 
     * @param lastMeetingStartDate 
     * @param lastMeetingEndDate 
     * @param indication 
     * @param phases 
     * @param contractManagerIds 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public dossiersExportDossiers(orderBy?: Array<string>, dossierNr?: string, applicationType?: string, atcCode?: string, status?: 'active' | 'archived', term?: string, startDate?: string, endDate?: string, day0StartDate?: string, day0EndDate?: string, reimbStartDate?: string, reimbEndDate?: string, lastMeetingStartDate?: string, lastMeetingEndDate?: string, indication?: string, phases?: Array<string>, contractManagerIds?: Array<number>, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/html'}): Observable<Blob>;
    public dossiersExportDossiers(orderBy?: Array<string>, dossierNr?: string, applicationType?: string, atcCode?: string, status?: 'active' | 'archived', term?: string, startDate?: string, endDate?: string, day0StartDate?: string, day0EndDate?: string, reimbStartDate?: string, reimbEndDate?: string, lastMeetingStartDate?: string, lastMeetingEndDate?: string, indication?: string, phases?: Array<string>, contractManagerIds?: Array<number>, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/html'}): Observable<HttpResponse<Blob>>;
    public dossiersExportDossiers(orderBy?: Array<string>, dossierNr?: string, applicationType?: string, atcCode?: string, status?: 'active' | 'archived', term?: string, startDate?: string, endDate?: string, day0StartDate?: string, day0EndDate?: string, reimbStartDate?: string, reimbEndDate?: string, lastMeetingStartDate?: string, lastMeetingEndDate?: string, indication?: string, phases?: Array<string>, contractManagerIds?: Array<number>, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/html'}): Observable<HttpEvent<Blob>>;
    public dossiersExportDossiers(orderBy?: Array<string>, dossierNr?: string, applicationType?: string, atcCode?: string, status?: 'active' | 'archived', term?: string, startDate?: string, endDate?: string, day0StartDate?: string, day0EndDate?: string, reimbStartDate?: string, reimbEndDate?: string, lastMeetingStartDate?: string, lastMeetingEndDate?: string, indication?: string, phases?: Array<string>, contractManagerIds?: Array<number>, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/html'}): Observable<any> {

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (orderBy) {
            orderBy.forEach((element) => {
                queryParameters = this.addToHttpParams(queryParameters,
                  <any>element, 'orderBy[]');
            })
        }
        if (dossierNr !== undefined && dossierNr !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>dossierNr, 'dossier_nr');
        }
        if (applicationType !== undefined && applicationType !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>applicationType, 'application_type');
        }
        if (atcCode !== undefined && atcCode !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>atcCode, 'atc_code');
        }
        if (status !== undefined && status !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>status, 'status');
        }
        if (term !== undefined && term !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>term, 'term');
        }
        if (startDate !== undefined && startDate !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>startDate, 'startDate');
        }
        if (endDate !== undefined && endDate !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>endDate, 'endDate');
        }
        if (day0StartDate !== undefined && day0StartDate !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>day0StartDate, 'day0StartDate');
        }
        if (day0EndDate !== undefined && day0EndDate !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>day0EndDate, 'day0EndDate');
        }
        if (reimbStartDate !== undefined && reimbStartDate !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>reimbStartDate, 'reimbStartDate');
        }
        if (reimbEndDate !== undefined && reimbEndDate !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>reimbEndDate, 'reimbEndDate');
        }
        if (lastMeetingStartDate !== undefined && lastMeetingStartDate !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>lastMeetingStartDate, 'lastMeetingStartDate');
        }
        if (lastMeetingEndDate !== undefined && lastMeetingEndDate !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>lastMeetingEndDate, 'lastMeetingEndDate');
        }
        if (indication !== undefined && indication !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>indication, 'indication');
        }
        if (phases) {
            phases.forEach((element) => {
                queryParameters = this.addToHttpParams(queryParameters,
                  <any>element, 'phases[]');
            })
        }
        if (contractManagerIds) {
            contractManagerIds.forEach((element) => {
                queryParameters = this.addToHttpParams(queryParameters,
                  <any>element, 'contractManagerIds[]');
            })
        }

        let headers = this.defaultHeaders;

        // authentication (default) required
        if (this.configuration.apiKeys) {
            const key: string | undefined = this.configuration.apiKeys["default"] || this.configuration.apiKeys["jwt"];
            if (key) {
            }
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'text/html'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        return this.httpClient.get(`${this.configuration.basePath}/dossiers/export`,
            {
                params: queryParameters,
                responseType: "blob",
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param orderBy 
     * @param dossierNr 
     * @param applicationType 
     * @param atcCode 
     * @param status 
     * @param term 
     * @param startDate 
     * @param endDate 
     * @param day0StartDate 
     * @param day0EndDate 
     * @param reimbStartDate 
     * @param reimbEndDate 
     * @param lastMeetingStartDate 
     * @param lastMeetingEndDate 
     * @param indication 
     * @param phases 
     * @param contractManagerIds 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public dossiersExtraExportDossiers(orderBy?: Array<string>, dossierNr?: string, applicationType?: string, atcCode?: string, status?: 'active' | 'archived', term?: string, startDate?: string, endDate?: string, day0StartDate?: string, day0EndDate?: string, reimbStartDate?: string, reimbEndDate?: string, lastMeetingStartDate?: string, lastMeetingEndDate?: string, indication?: string, phases?: Array<string>, contractManagerIds?: Array<number>, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/html'}): Observable<Blob>;
    public dossiersExtraExportDossiers(orderBy?: Array<string>, dossierNr?: string, applicationType?: string, atcCode?: string, status?: 'active' | 'archived', term?: string, startDate?: string, endDate?: string, day0StartDate?: string, day0EndDate?: string, reimbStartDate?: string, reimbEndDate?: string, lastMeetingStartDate?: string, lastMeetingEndDate?: string, indication?: string, phases?: Array<string>, contractManagerIds?: Array<number>, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/html'}): Observable<HttpResponse<Blob>>;
    public dossiersExtraExportDossiers(orderBy?: Array<string>, dossierNr?: string, applicationType?: string, atcCode?: string, status?: 'active' | 'archived', term?: string, startDate?: string, endDate?: string, day0StartDate?: string, day0EndDate?: string, reimbStartDate?: string, reimbEndDate?: string, lastMeetingStartDate?: string, lastMeetingEndDate?: string, indication?: string, phases?: Array<string>, contractManagerIds?: Array<number>, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/html'}): Observable<HttpEvent<Blob>>;
    public dossiersExtraExportDossiers(orderBy?: Array<string>, dossierNr?: string, applicationType?: string, atcCode?: string, status?: 'active' | 'archived', term?: string, startDate?: string, endDate?: string, day0StartDate?: string, day0EndDate?: string, reimbStartDate?: string, reimbEndDate?: string, lastMeetingStartDate?: string, lastMeetingEndDate?: string, indication?: string, phases?: Array<string>, contractManagerIds?: Array<number>, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/html'}): Observable<any> {

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (orderBy) {
            orderBy.forEach((element) => {
                queryParameters = this.addToHttpParams(queryParameters,
                  <any>element, 'orderBy[]');
            })
        }
        if (dossierNr !== undefined && dossierNr !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>dossierNr, 'dossier_nr');
        }
        if (applicationType !== undefined && applicationType !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>applicationType, 'application_type');
        }
        if (atcCode !== undefined && atcCode !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>atcCode, 'atc_code');
        }
        if (status !== undefined && status !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>status, 'status');
        }
        if (term !== undefined && term !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>term, 'term');
        }
        if (startDate !== undefined && startDate !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>startDate, 'startDate');
        }
        if (endDate !== undefined && endDate !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>endDate, 'endDate');
        }
        if (day0StartDate !== undefined && day0StartDate !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>day0StartDate, 'day0StartDate');
        }
        if (day0EndDate !== undefined && day0EndDate !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>day0EndDate, 'day0EndDate');
        }
        if (reimbStartDate !== undefined && reimbStartDate !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>reimbStartDate, 'reimbStartDate');
        }
        if (reimbEndDate !== undefined && reimbEndDate !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>reimbEndDate, 'reimbEndDate');
        }
        if (lastMeetingStartDate !== undefined && lastMeetingStartDate !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>lastMeetingStartDate, 'lastMeetingStartDate');
        }
        if (lastMeetingEndDate !== undefined && lastMeetingEndDate !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>lastMeetingEndDate, 'lastMeetingEndDate');
        }
        if (indication !== undefined && indication !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>indication, 'indication');
        }
        if (phases) {
            phases.forEach((element) => {
                queryParameters = this.addToHttpParams(queryParameters,
                  <any>element, 'phases[]');
            })
        }
        if (contractManagerIds) {
            contractManagerIds.forEach((element) => {
                queryParameters = this.addToHttpParams(queryParameters,
                  <any>element, 'contractManagerIds[]');
            })
        }

        let headers = this.defaultHeaders;

        // authentication (default) required
        if (this.configuration.apiKeys) {
            const key: string | undefined = this.configuration.apiKeys["default"] || this.configuration.apiKeys["jwt"];
            if (key) {
            }
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'text/html'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        return this.httpClient.get(`${this.configuration.basePath}/dossiers/extra-export`,
            {
                params: queryParameters,
                responseType: "blob",
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param startRow 
     * @param rpp 
     * @param orderBy 
     * @param dossierNr 
     * @param applicationType 
     * @param atcCode 
     * @param status 
     * @param term 
     * @param startDate 
     * @param endDate 
     * @param day0StartDate 
     * @param day0EndDate 
     * @param reimbStartDate 
     * @param reimbEndDate 
     * @param lastMeetingStartDate 
     * @param lastMeetingEndDate 
     * @param indication 
     * @param isExport 
     * @param contractId 
     * @param phases 
     * @param contractManagerIds 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public dossiersGetDossiers(startRow?: number, rpp?: number, orderBy?: Array<string>, dossierNr?: string, applicationType?: string, atcCode?: string, status?: 'active' | 'archived', term?: string, startDate?: string, endDate?: string, day0StartDate?: string, day0EndDate?: string, reimbStartDate?: string, reimbEndDate?: string, lastMeetingStartDate?: string, lastMeetingEndDate?: string, indication?: string, isExport?: boolean, contractId?: string, phases?: Array<string>, contractManagerIds?: Array<number>, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<DossierSTR>;
    public dossiersGetDossiers(startRow?: number, rpp?: number, orderBy?: Array<string>, dossierNr?: string, applicationType?: string, atcCode?: string, status?: 'active' | 'archived', term?: string, startDate?: string, endDate?: string, day0StartDate?: string, day0EndDate?: string, reimbStartDate?: string, reimbEndDate?: string, lastMeetingStartDate?: string, lastMeetingEndDate?: string, indication?: string, isExport?: boolean, contractId?: string, phases?: Array<string>, contractManagerIds?: Array<number>, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<DossierSTR>>;
    public dossiersGetDossiers(startRow?: number, rpp?: number, orderBy?: Array<string>, dossierNr?: string, applicationType?: string, atcCode?: string, status?: 'active' | 'archived', term?: string, startDate?: string, endDate?: string, day0StartDate?: string, day0EndDate?: string, reimbStartDate?: string, reimbEndDate?: string, lastMeetingStartDate?: string, lastMeetingEndDate?: string, indication?: string, isExport?: boolean, contractId?: string, phases?: Array<string>, contractManagerIds?: Array<number>, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<DossierSTR>>;
    public dossiersGetDossiers(startRow?: number, rpp?: number, orderBy?: Array<string>, dossierNr?: string, applicationType?: string, atcCode?: string, status?: 'active' | 'archived', term?: string, startDate?: string, endDate?: string, day0StartDate?: string, day0EndDate?: string, reimbStartDate?: string, reimbEndDate?: string, lastMeetingStartDate?: string, lastMeetingEndDate?: string, indication?: string, isExport?: boolean, contractId?: string, phases?: Array<string>, contractManagerIds?: Array<number>, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (startRow !== undefined && startRow !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>startRow, 'startRow');
        }
        if (rpp !== undefined && rpp !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>rpp, 'rpp');
        }
        if (orderBy) {
            orderBy.forEach((element) => {
                queryParameters = this.addToHttpParams(queryParameters,
                  <any>element, 'orderBy[]');
            })
        }
        if (dossierNr !== undefined && dossierNr !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>dossierNr, 'dossier_nr');
        }
        if (applicationType !== undefined && applicationType !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>applicationType, 'application_type');
        }
        if (atcCode !== undefined && atcCode !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>atcCode, 'atc_code');
        }
        if (status !== undefined && status !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>status, 'status');
        }
        if (term !== undefined && term !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>term, 'term');
        }
        if (startDate !== undefined && startDate !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>startDate, 'startDate');
        }
        if (endDate !== undefined && endDate !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>endDate, 'endDate');
        }
        if (day0StartDate !== undefined && day0StartDate !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>day0StartDate, 'day0StartDate');
        }
        if (day0EndDate !== undefined && day0EndDate !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>day0EndDate, 'day0EndDate');
        }
        if (reimbStartDate !== undefined && reimbStartDate !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>reimbStartDate, 'reimbStartDate');
        }
        if (reimbEndDate !== undefined && reimbEndDate !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>reimbEndDate, 'reimbEndDate');
        }
        if (lastMeetingStartDate !== undefined && lastMeetingStartDate !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>lastMeetingStartDate, 'lastMeetingStartDate');
        }
        if (lastMeetingEndDate !== undefined && lastMeetingEndDate !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>lastMeetingEndDate, 'lastMeetingEndDate');
        }
        if (indication !== undefined && indication !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>indication, 'indication');
        }
        if (isExport !== undefined && isExport !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>isExport, 'isExport');
        }
        if (contractId !== undefined && contractId !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>contractId, 'contractId');
        }
        if (phases) {
            phases.forEach((element) => {
                queryParameters = this.addToHttpParams(queryParameters,
                  <any>element, 'phases[]');
            })
        }
        if (contractManagerIds) {
            contractManagerIds.forEach((element) => {
                queryParameters = this.addToHttpParams(queryParameters,
                  <any>element, 'contractManagerIds[]');
            })
        }

        let headers = this.defaultHeaders;

        // authentication (default) required
        if (this.configuration.apiKeys) {
            const key: string | undefined = this.configuration.apiKeys["default"] || this.configuration.apiKeys["jwt"];
            if (key) {
            }
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<DossierSTR>(`${this.configuration.basePath}/dossiers`,
            {
                params: queryParameters,
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param languageAddNewStringBody 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public languageAddNewString(languageAddNewStringBody: LanguageAddNewStringBody, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<object>;
    public languageAddNewString(languageAddNewStringBody: LanguageAddNewStringBody, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<object>>;
    public languageAddNewString(languageAddNewStringBody: LanguageAddNewStringBody, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<object>>;
    public languageAddNewString(languageAddNewStringBody: LanguageAddNewStringBody, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (languageAddNewStringBody === null || languageAddNewStringBody === undefined) {
            throw new Error('Required parameter languageAddNewStringBody was null or undefined when calling languageAddNewString.');
        }

        let headers = this.defaultHeaders;

        // authentication (default) required
        if (this.configuration.apiKeys) {
            const key: string | undefined = this.configuration.apiKeys["default"] || this.configuration.apiKeys["jwt"];
            if (key) {
            }
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<object>(`${this.configuration.basePath}/strings`,
            languageAddNewStringBody,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public languageGetAllStringForCurrentLanguage(observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<object>;
    public languageGetAllStringForCurrentLanguage(observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<object>>;
    public languageGetAllStringForCurrentLanguage(observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<object>>;
    public languageGetAllStringForCurrentLanguage(observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {

        let headers = this.defaultHeaders;

        // authentication (default) required
        if (this.configuration.apiKeys) {
            const key: string | undefined = this.configuration.apiKeys["default"] || this.configuration.apiKeys["jwt"];
            if (key) {
            }
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<object>(`${this.configuration.basePath}/strings`,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param notuleId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public notulesDelete(notuleId: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<any>;
    public notulesDelete(notuleId: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<HttpResponse<any>>;
    public notulesDelete(notuleId: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<HttpEvent<any>>;
    public notulesDelete(notuleId: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: undefined}): Observable<any> {
        if (notuleId === null || notuleId === undefined) {
            throw new Error('Required parameter notuleId was null or undefined when calling notulesDelete.');
        }

        let headers = this.defaultHeaders;

        // authentication (default) required
        if (this.configuration.apiKeys) {
            const key: string | undefined = this.configuration.apiKeys["default"] || this.configuration.apiKeys["jwt"];
            if (key) {
            }
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.delete<any>(`${this.configuration.basePath}/notules/${encodeURIComponent(String(notuleId))}`,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param startRow 
     * @param rpp 
     * @param orderBy 
     * @param term 
     * @param startDate 
     * @param endDate 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public notulesGetAll(startRow?: number, rpp?: number, orderBy?: Array<string>, term?: string, startDate?: string, endDate?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<InlineResponse2003>;
    public notulesGetAll(startRow?: number, rpp?: number, orderBy?: Array<string>, term?: string, startDate?: string, endDate?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<InlineResponse2003>>;
    public notulesGetAll(startRow?: number, rpp?: number, orderBy?: Array<string>, term?: string, startDate?: string, endDate?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<InlineResponse2003>>;
    public notulesGetAll(startRow?: number, rpp?: number, orderBy?: Array<string>, term?: string, startDate?: string, endDate?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (startRow !== undefined && startRow !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>startRow, 'startRow');
        }
        if (rpp !== undefined && rpp !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>rpp, 'rpp');
        }
        if (orderBy) {
            orderBy.forEach((element) => {
                queryParameters = this.addToHttpParams(queryParameters,
                  <any>element, 'orderBy[]');
            })
        }
        if (term !== undefined && term !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>term, 'term');
        }
        if (startDate !== undefined && startDate !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>startDate, 'startDate');
        }
        if (endDate !== undefined && endDate !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>endDate, 'endDate');
        }

        let headers = this.defaultHeaders;

        // authentication (default) required
        if (this.configuration.apiKeys) {
            const key: string | undefined = this.configuration.apiKeys["default"] || this.configuration.apiKeys["jwt"];
            if (key) {
            }
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<InlineResponse2003>(`${this.configuration.basePath}/notules`,
            {
                params: queryParameters,
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param priceDossierId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public priceDossierDelete(priceDossierId: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<any>;
    public priceDossierDelete(priceDossierId: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<HttpResponse<any>>;
    public priceDossierDelete(priceDossierId: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<HttpEvent<any>>;
    public priceDossierDelete(priceDossierId: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: undefined}): Observable<any> {
        if (priceDossierId === null || priceDossierId === undefined) {
            throw new Error('Required parameter priceDossierId was null or undefined when calling priceDossierDelete.');
        }

        let headers = this.defaultHeaders;

        // authentication (default) required
        if (this.configuration.apiKeys) {
            const key: string | undefined = this.configuration.apiKeys["default"] || this.configuration.apiKeys["jwt"];
            if (key) {
            }
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.delete<any>(`${this.configuration.basePath}/price-dossier/${encodeURIComponent(String(priceDossierId))}`,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param priceDossierUuid 
     * @param folderId 
     * @param documentId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public priceDossierDeleteDocument(priceDossierUuid: string, folderId: number, documentId: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<any>;
    public priceDossierDeleteDocument(priceDossierUuid: string, folderId: number, documentId: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<HttpResponse<any>>;
    public priceDossierDeleteDocument(priceDossierUuid: string, folderId: number, documentId: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<HttpEvent<any>>;
    public priceDossierDeleteDocument(priceDossierUuid: string, folderId: number, documentId: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: undefined}): Observable<any> {
        if (priceDossierUuid === null || priceDossierUuid === undefined) {
            throw new Error('Required parameter priceDossierUuid was null or undefined when calling priceDossierDeleteDocument.');
        }
        if (folderId === null || folderId === undefined) {
            throw new Error('Required parameter folderId was null or undefined when calling priceDossierDeleteDocument.');
        }
        if (documentId === null || documentId === undefined) {
            throw new Error('Required parameter documentId was null or undefined when calling priceDossierDeleteDocument.');
        }

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (folderId !== undefined && folderId !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>folderId, 'folderId');
        }
        if (documentId !== undefined && documentId !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>documentId, 'documentId');
        }

        let headers = this.defaultHeaders;

        // authentication (default) required
        if (this.configuration.apiKeys) {
            const key: string | undefined = this.configuration.apiKeys["default"] || this.configuration.apiKeys["jwt"];
            if (key) {
            }
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.delete<any>(`${this.configuration.basePath}/price-dossier/documents/delete/${encodeURIComponent(String(priceDossierUuid))}`,
            {
                params: queryParameters,
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param priceDossierFileReadBody 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public priceDossierFileRead(priceDossierFileReadBody: PriceDossierFileReadBody, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<any>;
    public priceDossierFileRead(priceDossierFileReadBody: PriceDossierFileReadBody, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<HttpResponse<any>>;
    public priceDossierFileRead(priceDossierFileReadBody: PriceDossierFileReadBody, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<HttpEvent<any>>;
    public priceDossierFileRead(priceDossierFileReadBody: PriceDossierFileReadBody, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: undefined}): Observable<any> {
        if (priceDossierFileReadBody === null || priceDossierFileReadBody === undefined) {
            throw new Error('Required parameter priceDossierFileReadBody was null or undefined when calling priceDossierFileRead.');
        }

        let headers = this.defaultHeaders;

        // authentication (default) required
        if (this.configuration.apiKeys) {
            const key: string | undefined = this.configuration.apiKeys["default"] || this.configuration.apiKeys["jwt"];
            if (key) {
            }
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.patch<any>(`${this.configuration.basePath}/price-dossier/documents/read`,
            priceDossierFileReadBody,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param priceDossierId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public priceDossierGetById(priceDossierId: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<PriceDossier>;
    public priceDossierGetById(priceDossierId: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<PriceDossier>>;
    public priceDossierGetById(priceDossierId: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<PriceDossier>>;
    public priceDossierGetById(priceDossierId: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (priceDossierId === null || priceDossierId === undefined) {
            throw new Error('Required parameter priceDossierId was null or undefined when calling priceDossierGetById.');
        }

        let headers = this.defaultHeaders;

        // authentication (default) required
        if (this.configuration.apiKeys) {
            const key: string | undefined = this.configuration.apiKeys["default"] || this.configuration.apiKeys["jwt"];
            if (key) {
            }
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<PriceDossier>(`${this.configuration.basePath}/price-dossier/${encodeURIComponent(String(priceDossierId))}`,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param priceDossierUuid 
     * @param documentId 
     * @param folderId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public priceDossierGetDocuments(priceDossierUuid: string, documentId?: number, folderId?: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<object>;
    public priceDossierGetDocuments(priceDossierUuid: string, documentId?: number, folderId?: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<object>>;
    public priceDossierGetDocuments(priceDossierUuid: string, documentId?: number, folderId?: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<object>>;
    public priceDossierGetDocuments(priceDossierUuid: string, documentId?: number, folderId?: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (priceDossierUuid === null || priceDossierUuid === undefined) {
            throw new Error('Required parameter priceDossierUuid was null or undefined when calling priceDossierGetDocuments.');
        }

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (documentId !== undefined && documentId !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>documentId, 'documentId');
        }
        if (folderId !== undefined && folderId !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>folderId, 'folderId');
        }

        let headers = this.defaultHeaders;

        // authentication (default) required
        if (this.configuration.apiKeys) {
            const key: string | undefined = this.configuration.apiKeys["default"] || this.configuration.apiKeys["jwt"];
            if (key) {
            }
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<object>(`${this.configuration.basePath}/price-dossier/documents/folders/${encodeURIComponent(String(priceDossierUuid))}`,
            {
                params: queryParameters,
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param documentId 
     * @param priceDossierUuid 
     * @param folderId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public priceDossierMoveDocument(documentId: number, priceDossierUuid: string, folderId: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<any>;
    public priceDossierMoveDocument(documentId: number, priceDossierUuid: string, folderId: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<HttpResponse<any>>;
    public priceDossierMoveDocument(documentId: number, priceDossierUuid: string, folderId: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<HttpEvent<any>>;
    public priceDossierMoveDocument(documentId: number, priceDossierUuid: string, folderId: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: undefined}): Observable<any> {
        if (documentId === null || documentId === undefined) {
            throw new Error('Required parameter documentId was null or undefined when calling priceDossierMoveDocument.');
        }
        if (priceDossierUuid === null || priceDossierUuid === undefined) {
            throw new Error('Required parameter priceDossierUuid was null or undefined when calling priceDossierMoveDocument.');
        }
        if (folderId === null || folderId === undefined) {
            throw new Error('Required parameter folderId was null or undefined when calling priceDossierMoveDocument.');
        }

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (priceDossierUuid !== undefined && priceDossierUuid !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>priceDossierUuid, 'priceDossierUuid');
        }
        if (folderId !== undefined && folderId !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>folderId, 'folderId');
        }

        let headers = this.defaultHeaders;

        // authentication (default) required
        if (this.configuration.apiKeys) {
            const key: string | undefined = this.configuration.apiKeys["default"] || this.configuration.apiKeys["jwt"];
            if (key) {
            }
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.put<any>(`${this.configuration.basePath}/price-dossier/documents/move/${encodeURIComponent(String(documentId))}`,
            null,
            {
                params: queryParameters,
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param priceDossierDocumentFile 
     * @param folderId 
     * @param priceDossierUuid 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public priceDossierSaveDocument(priceDossierDocumentFile: Blob, folderId: number, priceDossierUuid: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<InlineResponse200>;
    public priceDossierSaveDocument(priceDossierDocumentFile: Blob, folderId: number, priceDossierUuid: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<InlineResponse200>>;
    public priceDossierSaveDocument(priceDossierDocumentFile: Blob, folderId: number, priceDossierUuid: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<InlineResponse200>>;
    public priceDossierSaveDocument(priceDossierDocumentFile: Blob, folderId: number, priceDossierUuid: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (priceDossierDocumentFile === null || priceDossierDocumentFile === undefined) {
            throw new Error('Required parameter priceDossierDocumentFile was null or undefined when calling priceDossierSaveDocument.');
        }
        if (folderId === null || folderId === undefined) {
            throw new Error('Required parameter folderId was null or undefined when calling priceDossierSaveDocument.');
        }
        if (priceDossierUuid === null || priceDossierUuid === undefined) {
            throw new Error('Required parameter priceDossierUuid was null or undefined when calling priceDossierSaveDocument.');
        }

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (folderId !== undefined && folderId !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>folderId, 'folderId');
        }
        if (priceDossierUuid !== undefined && priceDossierUuid !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>priceDossierUuid, 'priceDossierUuid');
        }

        let headers = this.defaultHeaders;

        // authentication (default) required
        if (this.configuration.apiKeys) {
            const key: string | undefined = this.configuration.apiKeys["default"] || this.configuration.apiKeys["jwt"];
            if (key) {
            }
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'multipart/form-data'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any; };
        let useForm = false;
        let convertFormParamsToString = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        // see https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new HttpParams({encoder: this.encoder});
        }

        if (priceDossierDocumentFile !== undefined) {
            formParams = formParams.append('priceDossierDocumentFile', <any>priceDossierDocumentFile) as any || formParams;
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<InlineResponse200>(`${this.configuration.basePath}/price-dossier/documents/save`,
            convertFormParamsToString ? formParams.toString() : formParams,
            {
                params: queryParameters,
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param priceDossier 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public priceDossierUpdateOrCreate(priceDossier: PriceDossier, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<PriceDossier>;
    public priceDossierUpdateOrCreate(priceDossier: PriceDossier, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<PriceDossier>>;
    public priceDossierUpdateOrCreate(priceDossier: PriceDossier, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<PriceDossier>>;
    public priceDossierUpdateOrCreate(priceDossier: PriceDossier, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (priceDossier === null || priceDossier === undefined) {
            throw new Error('Required parameter priceDossier was null or undefined when calling priceDossierUpdateOrCreate.');
        }

        let headers = this.defaultHeaders;

        // authentication (default) required
        if (this.configuration.apiKeys) {
            const key: string | undefined = this.configuration.apiKeys["default"] || this.configuration.apiKeys["jwt"];
            if (key) {
            }
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<PriceDossier>(`${this.configuration.basePath}/price-dossier`,
            priceDossier,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param startRow 
     * @param rpp 
     * @param orderBy 
     * @param number 
     * @param term 
     * @param company 
     * @param statusCode 
     * @param dateMeetingFrom 
     * @param dateMeetingTo 
     * @param day0 
     * @param dateEndPriceProcedureFrom 
     * @param dateEndPriceProcedureTo 
     * @param interuptionDate 
     * @param yearOfDecision 
     * @param crpCpsp 
     * @param type 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public priceDossiersExportPriceDossiers(startRow?: number, rpp?: number, orderBy?: Array<string>, number?: string, term?: string, company?: string, statusCode?: string, dateMeetingFrom?: string, dateMeetingTo?: string, day0?: string, dateEndPriceProcedureFrom?: string, dateEndPriceProcedureTo?: string, interuptionDate?: string, yearOfDecision?: number, crpCpsp?: string, type?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/html'}): Observable<Blob>;
    public priceDossiersExportPriceDossiers(startRow?: number, rpp?: number, orderBy?: Array<string>, number?: string, term?: string, company?: string, statusCode?: string, dateMeetingFrom?: string, dateMeetingTo?: string, day0?: string, dateEndPriceProcedureFrom?: string, dateEndPriceProcedureTo?: string, interuptionDate?: string, yearOfDecision?: number, crpCpsp?: string, type?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/html'}): Observable<HttpResponse<Blob>>;
    public priceDossiersExportPriceDossiers(startRow?: number, rpp?: number, orderBy?: Array<string>, number?: string, term?: string, company?: string, statusCode?: string, dateMeetingFrom?: string, dateMeetingTo?: string, day0?: string, dateEndPriceProcedureFrom?: string, dateEndPriceProcedureTo?: string, interuptionDate?: string, yearOfDecision?: number, crpCpsp?: string, type?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'text/html'}): Observable<HttpEvent<Blob>>;
    public priceDossiersExportPriceDossiers(startRow?: number, rpp?: number, orderBy?: Array<string>, number?: string, term?: string, company?: string, statusCode?: string, dateMeetingFrom?: string, dateMeetingTo?: string, day0?: string, dateEndPriceProcedureFrom?: string, dateEndPriceProcedureTo?: string, interuptionDate?: string, yearOfDecision?: number, crpCpsp?: string, type?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'text/html'}): Observable<any> {

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (startRow !== undefined && startRow !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>startRow, 'startRow');
        }
        if (rpp !== undefined && rpp !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>rpp, 'rpp');
        }
        if (orderBy) {
            orderBy.forEach((element) => {
                queryParameters = this.addToHttpParams(queryParameters,
                  <any>element, 'orderBy[]');
            })
        }
        if (number !== undefined && number !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>number, 'number');
        }
        if (term !== undefined && term !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>term, 'term');
        }
        if (company !== undefined && company !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>company, 'company');
        }
        if (statusCode !== undefined && statusCode !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>statusCode, 'status_code');
        }
        if (dateMeetingFrom !== undefined && dateMeetingFrom !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>dateMeetingFrom, 'date_meeting_from');
        }
        if (dateMeetingTo !== undefined && dateMeetingTo !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>dateMeetingTo, 'date_meeting_to');
        }
        if (day0 !== undefined && day0 !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>day0, 'day0');
        }
        if (dateEndPriceProcedureFrom !== undefined && dateEndPriceProcedureFrom !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>dateEndPriceProcedureFrom, 'date_end_price_procedure_from');
        }
        if (dateEndPriceProcedureTo !== undefined && dateEndPriceProcedureTo !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>dateEndPriceProcedureTo, 'date_end_price_procedure_to');
        }
        if (interuptionDate !== undefined && interuptionDate !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>interuptionDate, 'interuption_date');
        }
        if (yearOfDecision !== undefined && yearOfDecision !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>yearOfDecision, 'year_of_decision');
        }
        if (crpCpsp !== undefined && crpCpsp !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>crpCpsp, 'crp_cpsp');
        }
        if (type !== undefined && type !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>type, 'type');
        }

        let headers = this.defaultHeaders;

        // authentication (default) required
        if (this.configuration.apiKeys) {
            const key: string | undefined = this.configuration.apiKeys["default"] || this.configuration.apiKeys["jwt"];
            if (key) {
            }
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'text/html'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        return this.httpClient.get(`${this.configuration.basePath}/price-dossiers/export`,
            {
                params: queryParameters,
                responseType: "blob",
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param startRow 
     * @param rpp 
     * @param orderBy 
     * @param number 
     * @param term 
     * @param company 
     * @param statusCode 
     * @param dateMeetingFrom 
     * @param dateMeetingTo 
     * @param day0 
     * @param dateEndPriceProcedureFrom 
     * @param dateEndPriceProcedureTo 
     * @param interuptionDate 
     * @param yearOfDecision 
     * @param crpCpsp 
     * @param type 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public priceDossiersGetAll(startRow?: number, rpp?: number, orderBy?: Array<string>, number?: string, term?: string, company?: string, statusCode?: string, dateMeetingFrom?: string, dateMeetingTo?: string, day0?: string, dateEndPriceProcedureFrom?: string, dateEndPriceProcedureTo?: string, interuptionDate?: string, yearOfDecision?: number, crpCpsp?: string, type?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<InlineResponse2004>;
    public priceDossiersGetAll(startRow?: number, rpp?: number, orderBy?: Array<string>, number?: string, term?: string, company?: string, statusCode?: string, dateMeetingFrom?: string, dateMeetingTo?: string, day0?: string, dateEndPriceProcedureFrom?: string, dateEndPriceProcedureTo?: string, interuptionDate?: string, yearOfDecision?: number, crpCpsp?: string, type?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<InlineResponse2004>>;
    public priceDossiersGetAll(startRow?: number, rpp?: number, orderBy?: Array<string>, number?: string, term?: string, company?: string, statusCode?: string, dateMeetingFrom?: string, dateMeetingTo?: string, day0?: string, dateEndPriceProcedureFrom?: string, dateEndPriceProcedureTo?: string, interuptionDate?: string, yearOfDecision?: number, crpCpsp?: string, type?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<InlineResponse2004>>;
    public priceDossiersGetAll(startRow?: number, rpp?: number, orderBy?: Array<string>, number?: string, term?: string, company?: string, statusCode?: string, dateMeetingFrom?: string, dateMeetingTo?: string, day0?: string, dateEndPriceProcedureFrom?: string, dateEndPriceProcedureTo?: string, interuptionDate?: string, yearOfDecision?: number, crpCpsp?: string, type?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (startRow !== undefined && startRow !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>startRow, 'startRow');
        }
        if (rpp !== undefined && rpp !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>rpp, 'rpp');
        }
        if (orderBy) {
            orderBy.forEach((element) => {
                queryParameters = this.addToHttpParams(queryParameters,
                  <any>element, 'orderBy[]');
            })
        }
        if (number !== undefined && number !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>number, 'number');
        }
        if (term !== undefined && term !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>term, 'term');
        }
        if (company !== undefined && company !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>company, 'company');
        }
        if (statusCode !== undefined && statusCode !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>statusCode, 'status_code');
        }
        if (dateMeetingFrom !== undefined && dateMeetingFrom !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>dateMeetingFrom, 'date_meeting_from');
        }
        if (dateMeetingTo !== undefined && dateMeetingTo !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>dateMeetingTo, 'date_meeting_to');
        }
        if (day0 !== undefined && day0 !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>day0, 'day0');
        }
        if (dateEndPriceProcedureFrom !== undefined && dateEndPriceProcedureFrom !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>dateEndPriceProcedureFrom, 'date_end_price_procedure_from');
        }
        if (dateEndPriceProcedureTo !== undefined && dateEndPriceProcedureTo !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>dateEndPriceProcedureTo, 'date_end_price_procedure_to');
        }
        if (interuptionDate !== undefined && interuptionDate !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>interuptionDate, 'interuption_date');
        }
        if (yearOfDecision !== undefined && yearOfDecision !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>yearOfDecision, 'year_of_decision');
        }
        if (crpCpsp !== undefined && crpCpsp !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>crpCpsp, 'crp_cpsp');
        }
        if (type !== undefined && type !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>type, 'type');
        }

        let headers = this.defaultHeaders;

        // authentication (default) required
        if (this.configuration.apiKeys) {
            const key: string | undefined = this.configuration.apiKeys["default"] || this.configuration.apiKeys["jwt"];
            if (key) {
            }
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<InlineResponse2004>(`${this.configuration.basePath}/price-dossiers`,
            {
                params: queryParameters,
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param startRow 
     * @param rpp 
     * @param orderBy 
     * @param samCompanyUuid 
     * @param companyId 
     * @param atcCode 
     * @param term 
     * @param excludeUuids 
     * @param dossierId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public productGetProducts(startRow?: number, rpp?: number, orderBy?: Array<string>, samCompanyUuid?: string, companyId?: string, atcCode?: string, term?: string, excludeUuids?: Array<string>, dossierId?: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<InlineResponse2001>;
    public productGetProducts(startRow?: number, rpp?: number, orderBy?: Array<string>, samCompanyUuid?: string, companyId?: string, atcCode?: string, term?: string, excludeUuids?: Array<string>, dossierId?: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<InlineResponse2001>>;
    public productGetProducts(startRow?: number, rpp?: number, orderBy?: Array<string>, samCompanyUuid?: string, companyId?: string, atcCode?: string, term?: string, excludeUuids?: Array<string>, dossierId?: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<InlineResponse2001>>;
    public productGetProducts(startRow?: number, rpp?: number, orderBy?: Array<string>, samCompanyUuid?: string, companyId?: string, atcCode?: string, term?: string, excludeUuids?: Array<string>, dossierId?: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (startRow !== undefined && startRow !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>startRow, 'startRow');
        }
        if (rpp !== undefined && rpp !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>rpp, 'rpp');
        }
        if (orderBy) {
            orderBy.forEach((element) => {
                queryParameters = this.addToHttpParams(queryParameters,
                  <any>element, 'orderBy[]');
            })
        }
        if (samCompanyUuid !== undefined && samCompanyUuid !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>samCompanyUuid, 'samCompanyUuid');
        }
        if (companyId !== undefined && companyId !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>companyId, 'company_id');
        }
        if (atcCode !== undefined && atcCode !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>atcCode, 'atc_code');
        }
        if (term !== undefined && term !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>term, 'term');
        }
        if (excludeUuids) {
            excludeUuids.forEach((element) => {
                queryParameters = this.addToHttpParams(queryParameters,
                  <any>element, 'excludeUuids[]');
            })
        }
        if (dossierId !== undefined && dossierId !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>dossierId, 'dossierId');
        }

        let headers = this.defaultHeaders;

        // authentication (default) required
        if (this.configuration.apiKeys) {
            const key: string | undefined = this.configuration.apiKeys["default"] || this.configuration.apiKeys["jwt"];
            if (key) {
            }
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<InlineResponse2001>(`${this.configuration.basePath}/product/`,
            {
                params: queryParameters,
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param resourceAddIndicationBody 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public resourceAddIndication(resourceAddIndicationBody: ResourceAddIndicationBody, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<Indication>;
    public resourceAddIndication(resourceAddIndicationBody: ResourceAddIndicationBody, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<Indication>>;
    public resourceAddIndication(resourceAddIndicationBody: ResourceAddIndicationBody, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<Indication>>;
    public resourceAddIndication(resourceAddIndicationBody: ResourceAddIndicationBody, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (resourceAddIndicationBody === null || resourceAddIndicationBody === undefined) {
            throw new Error('Required parameter resourceAddIndicationBody was null or undefined when calling resourceAddIndication.');
        }

        let headers = this.defaultHeaders;

        // authentication (default) required
        if (this.configuration.apiKeys) {
            const key: string | undefined = this.configuration.apiKeys["default"] || this.configuration.apiKeys["jwt"];
            if (key) {
            }
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.put<Indication>(`${this.configuration.basePath}/resources/indication`,
            resourceAddIndicationBody,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public resourceGetFolders(observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<Array<InlineResponse2006>>;
    public resourceGetFolders(observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<Array<InlineResponse2006>>>;
    public resourceGetFolders(observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<Array<InlineResponse2006>>>;
    public resourceGetFolders(observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {

        let headers = this.defaultHeaders;

        // authentication (default) required
        if (this.configuration.apiKeys) {
            const key: string | undefined = this.configuration.apiKeys["default"] || this.configuration.apiKeys["jwt"];
            if (key) {
            }
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<Array<InlineResponse2006>>(`${this.configuration.basePath}/resources/folder`,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param startRow 
     * @param rpp 
     * @param orderBy 
     * @param excludeIds 
     * @param term 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public resourceGetIndications(startRow?: number, rpp?: number, orderBy?: Array<string>, excludeIds?: Array<number>, term?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<InlineResponse2005>;
    public resourceGetIndications(startRow?: number, rpp?: number, orderBy?: Array<string>, excludeIds?: Array<number>, term?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<InlineResponse2005>>;
    public resourceGetIndications(startRow?: number, rpp?: number, orderBy?: Array<string>, excludeIds?: Array<number>, term?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<InlineResponse2005>>;
    public resourceGetIndications(startRow?: number, rpp?: number, orderBy?: Array<string>, excludeIds?: Array<number>, term?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (startRow !== undefined && startRow !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>startRow, 'startRow');
        }
        if (rpp !== undefined && rpp !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>rpp, 'rpp');
        }
        if (orderBy) {
            orderBy.forEach((element) => {
                queryParameters = this.addToHttpParams(queryParameters,
                  <any>element, 'orderBy[]');
            })
        }
        if (excludeIds) {
            excludeIds.forEach((element) => {
                queryParameters = this.addToHttpParams(queryParameters,
                  <any>element, 'excludeIds[]');
            })
        }
        if (term !== undefined && term !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>term, 'term');
        }

        let headers = this.defaultHeaders;

        // authentication (default) required
        if (this.configuration.apiKeys) {
            const key: string | undefined = this.configuration.apiKeys["default"] || this.configuration.apiKeys["jwt"];
            if (key) {
            }
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<InlineResponse2005>(`${this.configuration.basePath}/resources/indication`,
            {
                params: queryParameters,
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param type 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public resourceGetResources(type?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<object>;
    public resourceGetResources(type?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<object>>;
    public resourceGetResources(type?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<object>>;
    public resourceGetResources(type?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (type !== undefined && type !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>type, 'type');
        }

        let headers = this.defaultHeaders;

        // authentication (default) required
        if (this.configuration.apiKeys) {
            const key: string | undefined = this.configuration.apiKeys["default"] || this.configuration.apiKeys["jwt"];
            if (key) {
            }
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<object>(`${this.configuration.basePath}/resources`,
            {
                params: queryParameters,
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public resourcesGetLanguages(observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<Array<InlineResponse2002>>;
    public resourcesGetLanguages(observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<Array<InlineResponse2002>>>;
    public resourcesGetLanguages(observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<Array<InlineResponse2002>>>;
    public resourcesGetLanguages(observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {

        let headers = this.defaultHeaders;

        // authentication (default) required
        if (this.configuration.apiKeys) {
            const key: string | undefined = this.configuration.apiKeys["default"] || this.configuration.apiKeys["jwt"];
            if (key) {
            }
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<Array<InlineResponse2002>>(`${this.configuration.basePath}/resources/languages`,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public userLogin(observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<object>;
    public userLogin(observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<object>>;
    public userLogin(observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<object>>;
    public userLogin(observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {

        let headers = this.defaultHeaders;

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<object>(`${this.configuration.basePath}/user/login`,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public userLogout(observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<any>;
    public userLogout(observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<HttpResponse<any>>;
    public userLogout(observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<HttpEvent<any>>;
    public userLogout(observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: undefined}): Observable<any> {

        let headers = this.defaultHeaders;

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<any>(`${this.configuration.basePath}/user/logout`,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public userMe(observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<User>;
    public userMe(observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<User>>;
    public userMe(observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<User>>;
    public userMe(observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {

        let headers = this.defaultHeaders;

        // authentication (default) required
        if (this.configuration.apiKeys) {
            const key: string | undefined = this.configuration.apiKeys["default"] || this.configuration.apiKeys["jwt"];
            if (key) {
            }
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<User>(`${this.configuration.basePath}/user/me`,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param startRow 
     * @param rpp 
     * @param orderBy 
     * @param dossierId 
     * @param term 
     * @param companyIds 
     * @param samCompanyIds 
     * @param contractManager 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public usersGetUsers(startRow?: number, rpp?: number, orderBy?: Array<string>, dossierId?: number, term?: string, companyIds?: Array<number>, samCompanyIds?: Array<string>, contractManager?: boolean, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<UserSTR>;
    public usersGetUsers(startRow?: number, rpp?: number, orderBy?: Array<string>, dossierId?: number, term?: string, companyIds?: Array<number>, samCompanyIds?: Array<string>, contractManager?: boolean, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<UserSTR>>;
    public usersGetUsers(startRow?: number, rpp?: number, orderBy?: Array<string>, dossierId?: number, term?: string, companyIds?: Array<number>, samCompanyIds?: Array<string>, contractManager?: boolean, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<UserSTR>>;
    public usersGetUsers(startRow?: number, rpp?: number, orderBy?: Array<string>, dossierId?: number, term?: string, companyIds?: Array<number>, samCompanyIds?: Array<string>, contractManager?: boolean, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (startRow !== undefined && startRow !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>startRow, 'startRow');
        }
        if (rpp !== undefined && rpp !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>rpp, 'rpp');
        }
        if (orderBy) {
            orderBy.forEach((element) => {
                queryParameters = this.addToHttpParams(queryParameters,
                  <any>element, 'orderBy[]');
            })
        }
        if (dossierId !== undefined && dossierId !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>dossierId, 'dossierId');
        }
        if (term !== undefined && term !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>term, 'term');
        }
        if (companyIds) {
            companyIds.forEach((element) => {
                queryParameters = this.addToHttpParams(queryParameters,
                  <any>element, 'company_ids[]');
            })
        }
        if (samCompanyIds) {
            samCompanyIds.forEach((element) => {
                queryParameters = this.addToHttpParams(queryParameters,
                  <any>element, 'sam_company_ids[]');
            })
        }
        if (contractManager !== undefined && contractManager !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>contractManager, 'contractManager');
        }

        let headers = this.defaultHeaders;

        // authentication (default) required
        if (this.configuration.apiKeys) {
            const key: string | undefined = this.configuration.apiKeys["default"] || this.configuration.apiKeys["jwt"];
            if (key) {
            }
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<UserSTR>(`${this.configuration.basePath}/users/`,
            {
                params: queryParameters,
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
