<div class="pagination d-inline-flex align-items-center">
    <button [class.disabled]="startRow <= 0" (click)="changePage('prev')" class="prev d-flex"><i class="im-icon im-icon-chev-left"></i></button>
    <!-- start pages -->
    <button (click)="changePage(1)" [class.active]="getPage() === 1">{{getPages()[0]}}</button>
    <span class="fill" *ngIf="showFill(true)">...</span>
    <ng-container *ngFor="let i of getPages()">
        <button (click)="changePage(i)" [class.active]="getPage() === i" *ngIf="i != 1 && i != getPages().length && truncate(i)">{{i}}</button>
    </ng-container>
    <span class="fill" *ngIf="showFill(false)">...</span>
    <button (click)="changePage(getPages().length)" [class.active]="getPage() === getPages().length" *ngIf="getPages().length > 1">{{getPages()[getPages().length - 1]}}</button>
    <!-- end pages -->
    <button [class.disabled]="startRow >= totalRows - RPP" (click)="changePage('next')" class="next d-flex"><i class="im-icon im-icon-chev-right"></i></button>
</div>