<header class="d-flex">
    <div class="container d-flex flex-row align-items-center justify-content-between">
        <nav class="d-flex h-100 left">
            <ul class="d-flex flex-row m-0">
                <li>
                    <a [routerLink]="['/']" exact="true" routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}" class="logo"><img src="/assets/img/misc/logo.svg" alt="{{appName}}"></a>
                </li>
                <!-- CRM/CTG dossiers -->
                <ng-container *ngIf="AuthenticationService.hasAccessCTGDossiers()">
                    <li>
                        <a [routerLink]="['/dossiers']" exact="true" routerLinkActive="active" [routerLinkActiveOptions]="{exact:false}">{{'crmCtgDossiers_header_link' | translate}}</a>
                    </li>
                </ng-container>
                <!-- Price dossiers -->
                <ng-container *ngIf="AuthenticationService.hasAccessPriceDossiers()">
                    <li>
                        <a [routerLink]="['/price-dossiers']" exact="true" routerLinkActive="active" [routerLinkActiveOptions]="{exact:false}">{{'priceDossiers_header_link' | translate}}</a>
                    </li>
                </ng-container>
                <!-- CRM notules -->
                <ng-container *ngIf="AuthenticationService.hasAccessNotules()">
                    <li>
                        <a [routerLink]="['/notules']" exact="true" routerLinkActive="active" [routerLinkActiveOptions]="{exact:false}">{{'notules_header_link' | translate}}</a>
                    </li>
                </ng-container>
                <!-- contracts -->
                <ng-container *ngIf="AuthenticationService.hasAccessContracts()">
                    <li>
                        <a [routerLink]="['/contracts']" exact="true" routerLinkActive="active" [routerLinkActiveOptions]="{exact:false}">{{'contracts_header_link' | translate}}</a>
                    </li>
                </ng-container>
                <!-- tools -->
                <ng-container *ngIf="AuthenticationService.hasAccessBulkUploadTool() || AuthenticationService.hasAccessCompanyMapping()">
                    <li>
                        <div style="cursor: pointer;" class="d-flex position-relative" (click)="settingsOpen = true;" appClickOutside (clickOutside)="settingsOpen = null;"> 
                            <a (click)="toggleSettingsOpen(); $event.stopPropagation();" [class.active]="menuActive('tools/') || settingsOpen">{{'tools_header_link' | translate}}<i class="im-icon im-icon-chev-down"></i></a>
                            <app-popover [open]="settingsOpen" positionClass="center">
                                <div class="settings-popover">
                                    <div class="options">
                                        <ul>
                                            <!-- companies -->
                                            <ng-container *ngIf="AuthenticationService.hasAccessCompanyMapping()">
                                                <li>
                                                    <a [routerLink]="['/tools/companies']" exact="true" (click)="toggleSettingsOpen(); $event.stopPropagation();">
                                                        <div class="flex-grow-1">{{'companies_header_link' | translate}}</div>
                                                    </a>
                                                </li>
                                            </ng-container>
                                            <!-- bulk_upload_documents -->
                                            <ng-container *ngIf="AuthenticationService.hasAccessBulkUploadTool()">
                                                <li>
                                                    <a [routerLink]="['/tools/bulk-upload-documents']" exact="true" (click)="toggleSettingsOpen(); $event.stopPropagation();">
                                                        <div class="flex-grow-1">{{'bulk_upload_documents_header_link' | translate}}</div>
                                                    </a>
                                                </li>
                                            </ng-container>
                                        </ul>
                                    </div>
                                </div>
                            </app-popover>
                        </div>
                    </li>
                </ng-container>
            </ul>
        </nav>
        
        <div class="d-flex h-100 align-items-center">
            <div style="cursor: pointer;" class="p-0 ml-xxs position-relative d-flex align-items-center" (click)="profileOpen = true;" appClickOutside (clickOutside)="profileOpen = null;">
                <app-avatar (click)="toggleProfileOpen(); $event.stopPropagation();" [class.hightlight-hover]="!profileOpen" style="transition: .2s ease;" [user]="user" size="30" fontSize="12"></app-avatar>
                <app-popover [open]="profileOpen" positionClass="left" style="transform: translatey(30px);">
                    <div class="profile-popover d-flex flex-column">
                        <div class="options">
                            <ul>
                                <li class="d-flex logout" (click)="AuthenticationService.logout()">
                                    <a class="d-flex align-items-center">
                                        <span><i class="im-icon im-icon-logout" style="transform: translateX(1px);"></i></span>
                                        <div class="flex-grow-1">{{'header_logout' | translate}}</div>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </app-popover>
            </div>
        </div>
    </div>
</header>