import { Injectable } from '@angular/core';
import { DefaultService, Indication } from '../utils/api';
import { HelpersService } from './helpers.service';
import { TranslatorService } from './translator.service';

@Injectable({
    providedIn: 'root'
})
export class IndicationService {
    constructor(
        private DefaultService: DefaultService,
        public HelpersService: HelpersService,
        public TranslatorService: TranslatorService
    ) {}

    public searchIndications(term: string, selectedIndications: Indication[]) {
        let excludeIds: Array<number> = [];

        if (selectedIndications && selectedIndications.length > 0) {
            for (const indication of selectedIndications) {
                excludeIds.push(indication.id);
            }
        }

        return this.DefaultService.resourceGetIndications(0, 50, null, excludeIds, term);
    }

    public addIndication(FORM: any) {
        return this.DefaultService.resourceAddIndication(FORM);
    }
}
