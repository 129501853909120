<div class="field field-typeahead position-relative" [class.valid]="model">
    <span *ngIf="label" class="label">{{label}} <span *ngIf="optionalString">({{optionalString}})</span></span>
    <input id="typeahead-prevent-manual-entry"  
        type="text"
        [class.error-field]="error"
        [placeholder]="placeholder"
        [(ngModel)]="model"
        (blur)="onBlur()"
        (selectItem)="onSelect($event)"
        [ngbTypeahead]="search"
        [inputFormatter]="formatter"
        [resultFormatter]="formatter"
        [resultTemplate]="template"
        (change)="change($event)"
        [editable]="editable" />
    <span *ngIf="error" class="error-field-display">{{error}}</span>

    <ng-template #template let-r="result" let-t="term">
        <ngb-highlight [result]="r.value" [term]="t"></ngb-highlight>
        <span class="typeahead-after" *ngIf="afterARR && afterARR.length">
            <ng-container *ngFor="let item of afterARR">
                <ng-container *ngIf="r[item]">
                    - {{r[item]}}
                </ng-container>
            </ng-container>
        </span>
    </ng-template>
</div>