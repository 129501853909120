<div class="options-modal-head d-flex flex-row justify-content-between align-items-center">
    <h1>{{'add_umbrella_contract_title' | translate}}</h1>
    <div class="h-100 d-flex align-items-center">
        <button (click)="cancel()" class="button tertiary"><span>{{'misc_cancel' | translate}}</span></button>
        <button (click)="submit()" [disabled]="!FORM" class="button default-sm ml-xs">{{'misc_save' | translate}}</button>
    </div>
</div>
<div class="options-modal-body">
    <div class="row display-row">
        <div class="col-6">
            <app-field-text 
                [model]="FORM" 
                [instant]="true"
                [error]="false"
                (modelChange)="FORM = $event;" 
                [label]="'add_umbrella_contract' | translate"
            ></app-field-text>
        </div>
    </div>
</div>