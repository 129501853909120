<div class="container page">
    <div class="d-flex ">

        <!-- side panel -->
        <div class="side-panel-wrap">
            <div class="side-panel d-flex flex-column justify-content-between">
                <div>
                    <!-- switch html -->
                    <!-- <app-field-switch 
                        class="d-block"
                        style="margin-bottom: 25px; margin-top: 2px;"
                        [isTitle]="true"
                        [model]="searchType" 
                        (modelChange)="searchType = $event"
                        [options]="switchOptions">
                    </app-field-switch> -->
                    <h2 class="search-title">{{'search_title' | translate}}</h2>
                    <div class="position-relative">
                        <!-- ADVANCED -->
                        <div class="w-100">
                            <div class="row row-form">
                                <div class="col-md-12">
                                    <app-field-text
                                        [model]="searchSTR.companyName" 
                                        (modelChange)="searchSTR.companyName = $event" 
                                        (enter)="search()" 
                                        label="{{'companies_searchForm_companyName' | translate}}"
                                        placeholder="{{'companies_searchForm_companyName_placeholder' | translate}}">
                                    </app-field-text>
                                </div>
                            </div>
                            <div class="row row-form">
                                <div class="col-md-12">
                                    <app-field-text
                                        [model]="searchSTR.samCopanyName" 
                                        (modelChange)="searchSTR.samCopanyName = $event" 
                                        (enter)="search()" 
                                        label="{{'companies_searchForm_samCopanyName' | translate}}"
                                        placeholder="{{'companies_searchForm_samCopanyName_placeholder' | translate}}">
                                    </app-field-text>
                                </div>
                            </div>
                            <div class="row row-form">
                                <div class="col-md-12">
                                    <app-field-radios 
                                        class="d-block"
                                        label="{{'companies_searchForm_status' | translate}}"
                                        [canUncheck]="false"
                                        [model]="searchSTR.status" 
                                        (modelChange)="searchSTR.status = $event"
                                        [options]="statusOptions"
                                        [optionWidth]=""
                                        [optionColumnWidth]="4">
                                    </app-field-radios>
                                </div>
                            </div>
                            <div class="mt-s d-flex flex-column align-items-center search-button-container">
                                <button (click)="search()" class="button default dark w-100 text-center d-block">{{'companies_searchForm_search_btn' | translate}}</button>
                                <button (click)="search(true)" class="button-link underline mt-s fontsize-s underline"><span>{{'companies_searchForm_clear_btn' | translate}}</span></button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- main content -->
        <div class="flex-grow-1 side-panel-main pt-s pb-l">
            <div class="d-flex justify-content-between pl-xs pr-xs">
                <h1 class="mb-l">{{'companies_table_title' | translate}} {{((!tableLoading && totalRows) || '') && '(' + totalRows + ')'}}</h1>
            </div>
            <div class="pl-xs pr-xs">
                <div class= "scroll-xx">
                    <table class="table">
                        <thead>
                            <tr>
                                <ng-container *ngFor="let head of tableHeads">
                                    <th [ngStyle]="{'width' : head.width ? head.width : 'auto' }" 
                                        [class.sortable]="head.sortable" 
                                        (click)="head.sortable && clickSort(head.code)"
                                    >
                                        <div class="position-relative" [ngClass]="getSortingClass(head)">{{head.name}}</div>
                                    </th>
                                </ng-container>
                            </tr>
                        </thead>
                        <tbody>
                            <div *ngIf="!companies || !companies.length" class="pl-xs pr-xs mt-s emptystate-wrap">
                                <app-empty-state value="{{'companies_table_empty' | translate}}"></app-empty-state>
                            </div>
                            <ng-container *ngFor="let item of companies; index as $i">
                                <tr>
                                    <td>
                                        {{item.companyName | display}}
                                    </td>
                                    <td *ngIf="!item.edit">
                                        <ng-container *ngIf="item.sam_companies && item.sam_companies.length > 0">
                                            <ul class="app-list app-list--table" [class.app-list--single]="item.sam_companies.length == 1">
                                                <li *ngFor="let company of item.sam_companies">
                                                    {{company.name}} <span class="sam-id" *ngIf="company?.sam_id">#{{company.sam_id}}</span>
                                                </li>
                                            </ul>
                                        </ng-container>
                                    </td>
                                    <td *ngIf="item.edit">
                                        <app-field-sam-companies 
                                            [company_id]="item.company_id"
                                            [model]="item.sam_companies" 
                                            (modelChange)="item.sam_companies = $event;"
                                            placeholder="{{'companies_table_search_samCompany' | translate}}">
                                        </app-field-sam-companies>
                                    </td>
                                    <td>
                                        {{item.companyEditTS | formatTs: 'prettyDateTime'}}
                                    </td>
                                    <td>
                                        <ng-container *ngIf="!item.edit">
                                            <div class="d-flex align-items-center">
                                                <button class="button-link" (click)="item.edit=true;"><span>{{'companies_change_btn' | translate}}</span></button>
                                            </div>
                                        </ng-container>
                                        <ng-container *ngIf="item.edit">
                                            <div class="d-flex align-items-center">
                                                <button (click)="confirmChange(item)" class="button default-xsm"><span>{{'companies_confirm_btn' | translate}}</span></button>
                                                <button (click)="cancel(item)" class="button-link cancel"><span>{{'companies_cancel_btn' | translate}}</span></button>
                                            </div>
                                        </ng-container>
                                    </td>
                                </tr>
                            </ng-container>
                        </tbody>
                    </table>
                    <div *ngIf="companies && companies.length" class="row mt-l">
                        <div class="col-md-4">
                            <div class="d-flex justify-content-end pl-s">
                                <!--  -->
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="d-flex justify-content-center" *ngIf="totalRows > RPP">
                                <app-pagination 
                                    [RPP]="RPP"
                                    [startRow]="startRow"
                                    [totalRows]="totalRows"
                                    (startRowChange)="changeStartRow($event)">
                                </app-pagination>
                            </div>
                        </div>
                        <div class="col-md-4 d-flex flex-row align-items-center">
                            <div class="d-flex w-100 justify-content-end pr-s">
                                <button (click)="export()" [class.disabled]="exportBusy" class="button-link fontsize-s export-btn">
                                    <i *ngIf="!exportBusy" class="im-icon im-icon-download"></i>
                                    <app-inline-spinner *ngIf="exportBusy" class="mr-xxxs"></app-inline-spinner>
                                    <span>{{'companies_export' | translate}}</span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>