import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfigService } from '../../../services/config.service';
import { AuthenticationService } from '../../../services/authentication.service';
@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
    appName: string;
    routes: any;
    user: any;
    user$: any;
    profileOpen: boolean = null;
    settingsOpen: boolean = null;
    darkMode: boolean;

    constructor(
        public ConfigService: ConfigService,
        public route: ActivatedRoute,
        public router: Router,
        public AuthenticationService: AuthenticationService
    ) {}

    setProfileOpen(value: boolean) {
        setTimeout(() => {
            this.profileOpen = value;
        }, 1);
    }

    setSettingsOpen(value: boolean) {
        setTimeout(() => {
            this.settingsOpen = value;
        }, 1);
    }

    ngOnInit(): void {
        this.appName = this.ConfigService.getConfig().appName;
        this.AuthenticationService.getUser().subscribe((next: any) => {
            this.user = { ...next };
        });
    }

    toggleProfileOpen() {
        if (this.profileOpen) {
            this.profileOpen = null;
        } else this.profileOpen = true;
    }

    toggleSettingsOpen() {
        if (this.settingsOpen) {
            this.settingsOpen = null;
        } else this.settingsOpen = true;
    }

    isActive(url) {
        return window.location.href.includes(url);
    }

    menuActive(menu) {
        return this.router.url.includes(menu);
    }
}
