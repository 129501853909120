import { Component, OnDestroy, OnInit } from '@angular/core';
import { allowedNotuleExtensions } from 'backend/src/models/bulk-upload';
import { allowedExtensions } from 'backend/src/models/dossier-model';
import { ToastrService } from 'ngx-toastr';
import { lastValueFrom, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { BulkUploadService } from 'src/app/services/bulk-upload.service';
import { ResourceService } from 'src/app/services/resource.service';
import { BulkDocument } from 'src/app/utils/api';
import { BulkDocumentValidationObj } from 'src/app/utils/api/model/bulkDocumentValidationObj';
import { TranslatePipe } from 'src/app/utils/pipes';
//import * as JSZip from 'jszip';

declare var JSZip: any;

@Component({
    selector: 'app-bulk-upload',
    templateUrl: './bulk-upload.component.html',
    styleUrls: ['./bulk-upload.component.scss']
})
export class BulkUploadComponent implements OnInit, OnDestroy {
    onDestroy$: Subject<void> = new Subject<void>();
    helper: any = {};
    popoverHelper: any = null;

    invalid_docs: any[] = [];
    attention_docs: any[] = [];
    attention_docexchange_docs: any[] = [];
    original_attention_docs: any[] = [];
    original_attention_docexchange_docs: any[] = [];
    confirm_docs: any[] = [];
    processing_docs: any[] = [];

    folderOptions: any[] = [];

    loadFiles: boolean = false;

    constructor(
        private bulkUploadService: BulkUploadService,
        private resourceService: ResourceService,
        private translatePipe: TranslatePipe,
        private toastr: ToastrService
    ) {}

    ngOnInit(): void {
        this.getDocuments();
        this.getFolders();
    }

    getDocuments() {
        this.bulkUploadService
            .getDocuments()
            .pipe(takeUntil(this.onDestroy$))
            .subscribe((next) => {
                this.invalid_docs = next.invalid.map((doc) => {
                    const companies = doc.companies.map((company) => company.name);
                    return {
                        ...doc,
                        companies: {
                            value: this.getTitleMultiValues(companies, 1),
                            tooltip: this.getTooltipValue(companies, 1),
                            stamps: [
                                ...this.getStampForCompany(companies[0]),
                                ...this.getStampForTootltip(companies, 1)
                            ]
                        }
                    };
                });
                this.confirm_docs = next.confirm.map((doc) => {
                    const companies = doc.companies.map((company) => company.name);
                    return {
                        ...doc,
                        companies: {
                            value: this.getTitleMultiValues(companies, 1),
                            tooltip: this.getTooltipValue(companies, 1),
                            stamps: [
                                ...this.getStampForCompany(companies[0]),
                                ...this.getStampForTootltip(companies, 1)
                            ]
                        }
                    };
                });
                this.setAttentionDocs(next.attention);
            });
    }

    getTitleMultiValues(values: any[], numberValues) {
        if (values && values.length > numberValues) {
            return values.slice(0, numberValues).join(', ');
        } else {
            return values ? values.join(', ') : '';
        }
    }

    getTooltipValue(values: any[], numberValues) {
        if (values && values.length > numberValues) {
            return values.slice(numberValues).join(', ');
        }
    }

    getStampForTootltip(values: any[], numberValues) {
        if (values && values.length > numberValues) {
            return [{ value: '+' + (values.length - numberValues), classList: 'stamp--small' }];
        }
        return [];
    }

    getStampForCompany(company) {
        if (company?.name && company?.id) {
            return [{ value: this.translatePipe.transform('crmCtgDossiers_table_member'), classList: 'stamp--gray' }];
        }
        return [];
    }

    getFolders() {
        this.folderOptions = this.resourceService.getFolders();
    }

    getFolderTitle(folderId) {
        if (folderId) {
            return this.folderOptions.filter((item) => item.key == folderId)[0].value;
        }
    }

    setAttentionDocs(docs) {
        for (const document of docs) {
            this.addAttentionDocument(document);
        }
    }

    addAttentionDocument(document) {
        document.edit_dossier_id = document.dossierId ? false : true;
        document.edit_folder_id = document.folderId ? false : true;

        if (document.docExchangeFlag) {
            this.attention_docexchange_docs.push(document);
            this.original_attention_docexchange_docs.push({ ...document });
        } else {
            this.attention_docs.push(document);
            this.original_attention_docs.push({ ...document });
        }
    }

    toAnchor(id) {
        document.getElementById(id).scrollIntoView({
            behavior: 'smooth',
            block: 'start',
            inline: 'nearest'
        });
    }

    getStatusTitle(status) {
        switch (status) {
            case 'attention':
                return this.translatePipe.transform('bulkupload_nav_attention');
            case 'confirm':
                return this.translatePipe.transform('bulkupload_nav_ready');
            case 'confirmed':
                return this.translatePipe.transform('bulkupload_confirmed');
            case 'invalid':
                return this.translatePipe.transform('bulkupload_nav_invalid');
            case 'processing':
                return this.translatePipe.transform('bulkupload_nav_processed');
            case 'processed':
                return this.translatePipe.transform('bulkupload_processed');
            case 'deleted':
                return this.translatePipe.transform('bulkupload_deleted');
            default:
                return status;
        }
    }

    getProcessingFilesTitle() {
        if (this.getNumberOfProcessingFiles() == this.getNumberOfProcessedFiles()) {
            return this.translatePipe.transform('bulkupload_processed_files_title');
        } else {
            return this.translatePipe.transform('bulkupload_processing_files_title');
        }
    }

    getProcessingTitle() {
        if (this.getNumberOfProcessingFiles() == this.getNumberOfProcessedFiles()) {
            return this.translatePipe.transform('bulkupload_processed');
        } else {
            return this.translatePipe.transform('bulkupload_processing');
        }
    }

    getTitleDossierNrs(values: any[]) {
        if (values && values.length > 1) {
            return values.slice(0, 1).join(', ');
        } else {
            return values ? values.join(', ') : '';
        }
    }

    getTooltipDossierNrs(values: any[]) {
        if (values && values.length > 1) {
            return values.slice(1).join(', ');
        }
    }

    getStampDossierNrs(values: any[]) {
        if (values && values.length > 1) {
            return '+' + (values.length - 1);
        }
    }

    getNumberOfProcessedFiles() {
        return this.processing_docs.filter((item) => item.status != 'processing').length;
    }

    getPerCentNumberOfProcessedFiles() {
        if (this.processing_docs.length > 0) {
            return Math.round((this.getNumberOfProcessedFiles() / this.processing_docs.length) * 100);
        } else {
            return 0;
        }
    }

    getNumberOfProcessingFiles() {
        return this.processing_docs.length;
    }

    getConfirmCRMDocumentsLabel(crmDocuments) {
        return this.translatePipe.transform('bulkupload_confirm_files_btn').replace('#', crmDocuments.length);
    }

    addToProcessingDocs(addedFiles: any) {
        for (const file of addedFiles) {
            this.processing_docs.push({ fileName: file.name, status: 'processing' });
        }
    }

    selectDossier(item, $event) {
        this.cancelAttention(item);
        if ($event.dossierId) {
            const companies = ($event.companies ?? []).map((company) => company.name);
            item.companies = {
                value: this.getTitleMultiValues(companies, 1),
                tooltip: this.getTooltipValue(companies, 1),
                stamps: [...this.getStampForCompany(companies[0]), ...this.getStampForTootltip(companies, 1)]
            };
            item.dossierId = $event.dossierId;
            item.dossier = $event.dossier;
            if (item.edit_folder_id || (!item.docExchangeFlag && !item.folderId)) {
                item.folderId = $event.folderId;
            }
            if (!item.docExchangeFlag) {
                if (item.folderId) {
                    item.edit_folder_id = false;
                } else {
                    item.edit_folder_id = true;
                }
            }
        }
    }

    changeStatusOfProcessingDoc(fileName, oldStatus, newStatus) {
        for (const doc of this.processing_docs.filter((item) => item.status == oldStatus)) {
            if (doc.fileName == fileName) {
                doc.status = newStatus;
            }
        }
    }

    isZipFile(file) {
        if (file.name.toLowerCase().lastIndexOf('.app.zip') == -1) {
            return file.name.toLowerCase().lastIndexOf('.zip') > 0;
        }
        return false;
    }

    async getFilesFromZip(zipFile) {
        const jsZip = new JSZip();
        var flist = [];
        var zip = await jsZip.loadAsync(zipFile);
        if (Object.keys(zip.files).length < 800) {
            for (const filename in zip.files) {
                var match = !filename.match(/^__MACOSX\//);
                if (match) {
                    if (!zip.file(filename).dir) {
                        var blob = await zip.file(filename).async('blob');
                        var file = new File([blob], filename);
                        flist.push(file);
                    }
                }
            }
        } else {
            this.addToProcessingDocs([zipFile]);
            this.changeStatusOfProcessingDoc(zipFile.name, 'processing', 'invalid');
            this.invalid_docs.push(this.convertToBulkDocument(zipFile.name, 'Maximum 400 files in zip'));
        }
        return flist;
        return [];
    }

    async uploadDocuments(event, type) {
        this.loadFiles = true;
        if (event.rejectedFiles && event.rejectedFiles.length > 0) {
            for (const file of event.rejectedFiles) {
                this.invalid_docs.push(this.convertToBulkDocument(file.name, file.reason));
            }
            this.loadFiles = false;
        } else if (event.addedFiles) {
            let addedFiles = [];
            for (const file of event.addedFiles) {
                if (this.isZipFile(file)) {
                    const files = await this.getFilesFromZip(file);
                    if (files.length > 400) {
                        this.addToProcessingDocs([file]);
                        this.changeStatusOfProcessingDoc(file.name, 'processing', 'invalid');
                        this.invalid_docs.push(this.convertToBulkDocument(file.name, 'Maximum 400 files in zip'));
                    } else {
                        for (const file of files) {
                            addedFiles.push(file);
                        }
                    }
                } else {
                    addedFiles.push(file);
                }
            }
            this.loadFiles = false;

            addedFiles = this.getSortedFiles(addedFiles);

            this.addToProcessingDocs(addedFiles);
            await this.processDocuments(addedFiles, type);

            this.toastr.success(
                this.translatePipe.transform('crm_documents_processed'),
                this.translatePipe.transform('crm_documents_processed_title')
            );
        }
    }

    getSortedFiles(files) {
        let sortedFiles = [];
        for (const folder of this.folderOptions) {
            const regex = folder.regex;
            files.forEach(function (file, i) {
                if (file.name.match(new RegExp(regex, 'gi'))) {
                    sortedFiles.push(file);
                    files.splice(i, 1);
                }
            });
        }
        sortedFiles = sortedFiles.concat(files);
        return sortedFiles;
    }

    async processDocuments(addedFiles, type) {
        for (const file of addedFiles) {
            await this.processDocument(file, type);
        }
    }

    async processDocument(file, type) {
        if (!this.hasAllowedExtension(file, type) || !this.hasAllowedFileSize(file)) {
            return;
        } else {
            if (file.name.lastIndexOf('.') > 0) {
                await lastValueFrom(this.bulkUploadService.upload(file, type)).then(
                    (validationObj: BulkDocumentValidationObj) => {
                        this.changeStatusOfProcessingDoc(validationObj.fileName, 'processing', validationObj.status);
                        switch (validationObj.status) {
                            case 'attention':
                                this.addAttentionDocument(validationObj);
                                break;
                            case 'confirm':
                                this.confirm_docs.push(validationObj);
                                break;
                            case 'invalid':
                                this.invalid_docs.push(validationObj);
                                break;
                        }
                    }
                );
            } else {
                this.changeStatusOfProcessingDoc(file.name, 'processing', 'invalid');
                this.invalid_docs.push(this.convertToBulkDocument(file.name, 'Not a file'));
            }
        }
    }

    hasAllowedExtension(file, type) {
        const splitFileName = file.name.split('.').reverse();
        const fileExtention = splitFileName[0];
        if (type == 'notulen') {
            if (fileExtention != 'pdf') {
                this.changeStatusOfProcessingDoc(file.name, 'processing', 'invalid');
                this.invalid_docs.push(
                    this.convertToBulkDocument(file.name, 'Document does not have an .pdf extension')
                );
                return false;
            }
        } else {
            if (!allowedExtensions.includes(fileExtention)) {
                this.changeStatusOfProcessingDoc(file.name, 'processing', 'invalid');
                this.invalid_docs.push(this.convertToBulkDocument(file.name, 'Extension is not allowed'));
                return false;
            }
        }

        return true;
    }

    hasAllowedFileSize(file) {
        if (file.size > 152428800) {
            this.changeStatusOfProcessingDoc(file.name, 'processing', 'invalid');
            this.invalid_docs.push(this.convertToBulkDocument(file.name, 'Can be maximum 150 MB in size'));
            return false;
        }
        return true;
    }

    confirmCRMDocument(document: BulkDocument) {
        this.confirmCRMDocuments([document]);
    }

    confirmCRMDocuments(documents: BulkDocument[]) {
        const documentIds = [];
        for (const doc of documents) {
            documentIds.push(doc.documentId);
        }

        this.bulkUploadService.confirmCRMDocument(documentIds).subscribe((next) => {
            this.confirm_docs = this.confirm_docs.filter(function (item) {
                return documentIds.indexOf(item.documentId) === -1;
            });

            if (this.processing_docs.length > 0) {
                for (const doc of documents) {
                    this.changeStatusOfProcessingDoc(doc.fileName, 'confirm', 'confirmed');
                }
            }

            this.toastr.success(
                this.translatePipe.transform('crm_documents_confirmed'),
                this.translatePipe.transform('crm_documents_confirmed_title')
            );
        });
    }

    confirmAttention(document: BulkDocument) {
        this.bulkUploadService
            .updateDocumentIds(document.documentId, document.dossierId, document.folderId)
            .subscribe((result: BulkDocument) => {
                this.attention_docexchange_docs = this.attention_docexchange_docs.filter(function (item) {
                    return item.documentId != document.documentId;
                });

                this.attention_docs = this.attention_docs.filter(function (item) {
                    return item.documentId != document.documentId;
                });

                if (result) {
                    this.confirm_docs.push(result);
                    if (this.processing_docs.length > 0) {
                        this.changeStatusOfProcessingDoc(result.fileName, 'attention', 'confirm');
                    }
                } else {
                    if (this.processing_docs.length > 0) {
                        this.changeStatusOfProcessingDoc(document.fileName, 'attention', 'processed');
                    }
                }

                this.toastr.success(
                    this.translatePipe.transform('crm_documents_attention_confirmed'),
                    this.translatePipe.transform('crm_documents_attention_confirmed_title')
                );
            });
    }

    cancelAttention(document) {
        for (const original_attention_doc of this.original_attention_docs) {
            if (original_attention_doc.documentId == document.documentId) {
                for (const attention_doc of this.attention_docs) {
                    if (attention_doc.documentId == original_attention_doc.documentId) {
                        attention_doc.dossierId = original_attention_doc.dossierId;
                        attention_doc.folderId = original_attention_doc.folderId;
                        attention_doc.companyName = original_attention_doc.companyName;
                        return;
                    }
                }
            }
        }

        for (const original_attention_docexchange_doc of this.original_attention_docexchange_docs) {
            if (original_attention_docexchange_doc.documentId == document.documentId) {
                for (const attention_doc of this.attention_docs) {
                    if (attention_doc.documentId == original_attention_docexchange_doc.documentId) {
                        attention_doc.dossierId = original_attention_docexchange_doc.dossierId;
                        attention_doc.folderId = original_attention_docexchange_doc.folderId;
                        attention_doc.companyName = original_attention_docexchange_doc.companyName;
                        return;
                    }
                }
            }
        }
    }

    deleteDocument(document: BulkDocument, status) {
        this.deleteDocuments([document], status);
    }

    deleteDocuments(documents: BulkDocument[], status) {
        const documentIds = [];
        for (const doc of documents) {
            documentIds.push(doc.documentId);
        }

        this.bulkUploadService.deleteDocument(documentIds).subscribe((next) => {
            this.invalid_docs = this.invalid_docs.filter(function (item) {
                return documentIds.indexOf(item.documentId) === -1;
            });

            this.attention_docs = this.attention_docs.filter(function (item) {
                return documentIds.indexOf(item.documentId) === -1;
            });

            this.attention_docexchange_docs = this.attention_docexchange_docs.filter(function (item) {
                return documentIds.indexOf(item.documentId) === -1;
            });

            this.confirm_docs = this.confirm_docs.filter(function (item) {
                return documentIds.indexOf(item.documentId) === -1;
            });

            if (this.processing_docs.length > 0) {
                for (const doc of documents) {
                    this.changeStatusOfProcessingDoc(doc.fileName, status, 'deleted');
                }
            }

            this.toastr.success(
                this.translatePipe.transform('documents_deleted'),
                this.translatePipe.transform('documents_deleted_title')
            );
        });
    }

    convertToBulkDocument(fileName: string, reason: string) {
        return {
            documentId: null,
            fileName: fileName,
            create_ts: new Date().toISOString().slice(0, 19).replace('T', ' '),
            error: reason,
            blobUrl: null,
            dossierId: null,
            folderId: null,
            alias: null,
            dossierNumbers: null,
            uploadSource: 'upload',
            companyName: null
        };
    }

    setDossier(item, $event) {
        item.documentId = $event.id;
    }

    dismissPopover() {
        setTimeout(() => {
            this.popoverHelper = null;
        }, 1);
    }

    canExit(): boolean {
        if (
            this.getNumberOfProcessingFiles() == this.getNumberOfProcessedFiles() ||
            confirm(this.translatePipe.transform('exit'))
        ) {
            return true;
        } else {
            return false;
        }
    }

    ngOnDestroy(): void {
        this.onDestroy$.next();
    }
}
