import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'contract-estimated-copy',
    templateUrl: './contract-estimated-copy.component.html',
    styleUrls: ['./contract-estimated-copy.component.scss']
})
export class ContractModalEstimatedCopyComponent implements OnInit {
    @Input() options: any = {};
    @Input() multiple: boolean = false;
    @Output() confirmed = new EventEmitter();

    formSTR: any = {};
    validation: any = {};

    constructor(private ActiveModal: NgbActiveModal) {}

    ngOnInit(): void {}

    submit() {
        this.confirmed.emit({
            formSTR: this.formSTR
        });

        this.ActiveModal.dismiss();
    }

    cancel() {
        this.ActiveModal.dismiss();
    }
}
