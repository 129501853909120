import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';

@Component({
    selector: 'app-field-multiselect',
    templateUrl: './field-multiselect.component.html',
    styleUrls: ['./field-multiselect.component.scss']
})
export class FieldMultiselectComponent implements OnInit, OnChanges {
    @Input() label?: string;
    @Input() error?: string;
    @Input() optionalString?: string;
    @Input() placeholder: string;
    @Input() suffSingular: string;
    @Input() suffPlural: string;
    @Input() options: any[];
    @Input() model: any[];
    @Input() disabled: boolean = false;
    @Output() modelChange = new EventEmitter();
    @Input() isActive: boolean = false;
    @Output() setActive = new EventEmitter();
    @Output() setInactive = new EventEmitter();

    localOptions: any[] = [];
    open: boolean = false;
    @Input() hasActiveControl: boolean = false;
    formattedModel: string = '';

    constructor() {}

    ngOnInit(): void {}

    ngOnChanges(changes: SimpleChanges) {
        if (changes.model) {
            this.modelUpdated();
        }
        if (changes.model && changes.model.currentValue === null) {
            this.model = undefined;
        }
        if (changes.options && changes.options.currentValue) {
            this.localOptions = changes.options.currentValue;
        }
    }

    selectOption(option) {
        if (this.optionIsSelected(option.value)) {
            const index = this.model.findIndex((item) => {
                return item === option.value;
            });
            this.model.splice(index, 1);
        } else this.model.push(option.value);
        this.modelChange.emit(this.model);
        this.modelUpdated();
    }

    modelUpdated() {
        let result = [];
        for (let i = 0; i < this.model.length; i++) {
            const val = this.options.find((item) => {
                return item.value === this.model[i];
            })?.title;
            if (val) result.push(val);
        }
        this.formattedModel = result.join(', ');
    }

    setOpen($event) {
        if ($event) {
            $event.stopPropagation();
        }
        if (this.open) {
            return;
        }
        if (this.hasActiveControl) {
            this.setActive.emit();
        } else this.open = true;
    }

    setClosed() {
        if (this.open) {
            return;
        }
        if (this.hasActiveControl) {
            this.setInactive.emit();
        } else this.open = false;
    }

    optionIsSelected(value) {
        const index = this.model.findIndex((item) => {
            return item === value;
        });
        return index !== -1;
    }
}
