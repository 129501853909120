<div class="field field-typeahead position-relative" [class.valid]="model">
    <span *ngIf="label" class="label">{{label}}</span>

    <input
        id="typeahead-prevent-manual-entry"  
        type="text"
        [(ngModel)]="model"
        [class.error-field]="error"
        [placeholder]="placeholder"
        (blur)="onBlur()"
        (selectItem)="onSelect($event);"
        [ngbTypeahead]="search"
        [inputFormatter]="formatter"
        [resultFormatter]="formatter"
        [resultTemplate]="template"
        (change)="change($event)"
        [editable]="false" />
    <span *ngIf="error" class="error-field-display">{{error}}</span>

    <ng-template #template let-r="result" let-t="term">
        <ngb-highlight [result]="r.value" [term]="t"></ngb-highlight>
        <span *ngIf="r.product" class="typeahead-after">{{r.product.authorisationNr}}</span>
    </ng-template>
</div>