import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationStart } from '@angular/router';
import { Router } from '@angular/router';
import { Event as NavigationEvent } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { FieldSwitchOptionsModel } from 'src/app/components/fields/field-switch/field-switch.component';
import { HelpersService } from 'src/app/services/helpers.service';
import { ResourceService } from 'src/app/services/resource.service';
import { TranslatePipe } from 'src/app/utils/pipes';
import { HttpParams } from '@angular/common/http';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { LocalStorageService } from 'src/app/services/local-storage.service';
import { ContractsService } from 'src/app/services/contracts.service';
import { ContractDetailComponent } from 'src/app/components/modals/contract-detail/contract-detail.component';
import { DefaultService } from 'src/app/utils/api';

@Component({
    selector: 'app-contracts',
    templateUrl: './contracts.component.html',
    styleUrls: ['./contracts.component.scss']
})
export class ContractsComponent implements OnInit, OnDestroy {
    onDestroy$: Subject<void> = new Subject<void>();
    popoverHelper: any = null;
    searchSTR: any = {};
    exportBusy: boolean = false;
    switchOptions: FieldSwitchOptionsModel;
    statusOptions;
    displayMoreOptions: boolean = false;
    createdOrUpdated: boolean = false;
    modalOpen: boolean = false;

    contractTypes: any[];
    stageOptions: any[];

    // table
    tableHeads: any;
    tableSort: { code: string; dir: string };
    tableSortName = 'contract_tableSort';

    contracts: any[] = null;
    startRow: number = 0;
    RPP: number = 20;
    totalRows: number = null;
    tableLoading: boolean = true;
    searchType: string = 'advanced';

    previousParams: any = null;

    constructor(
        private router: Router,
        private ModalService: NgbModal,
        private contractsService: ContractsService,
        private helpersService: HelpersService,
        private resourceService: ResourceService,
        private translatePipe: TranslatePipe,
        private authenticationService: AuthenticationService,
        private localStorageService: LocalStorageService,
        private route: ActivatedRoute,
        private DefaultService: DefaultService
    ) {
        // router.events
        //     .pipe(
        //         filter((event: NavigationEvent) => {
        //             return event instanceof NavigationStart;
        //         })
        //     )
        //     .subscribe((event: NavigationStart) => {
        //         if (event.restoredState) {
        //             window.location.href = event.url;
        //         }
        //     });
        // route.queryParams.subscribe((params) => {
        //     const parameters = Object.assign({}, params);
        //     if (parameters.contractId) delete parameters['contractId'];
        //     if (parameters.activePage) delete parameters['activePage'];
        //     if (JSON.stringify(parameters) === JSON.stringify(this.previousParams)) {
        //         return;
        //     }
        //     this.startRow = (parameters.page || 1) * this.RPP - this.RPP;
        //     this.searchSTR = params.query ? JSON.parse(params.query) : this.getDefaultSearchSTR();
        //     this.getContracts();
        //     this.previousParams = parameters;
        // });
    }

    ngOnInit(): void {
        const activeContractId = this.helpersService.getParam('contractId');
        const mode = this.helpersService.getParam('mode');
        const activePage = this.helpersService.getParam('activePage');
        if (activeContractId) this.openCreateUpdateModal(activeContractId, mode, activePage);

        this.switchOptions = [
            {
                title: this.translatePipe.transform('contract_searchForm_title_'),
                value: 'advanced'
            }
        ];

        this.statusOptions = [
            { title: this.translatePipe.transform('contract_searchForm_status_active'), value: 'active' },
            { title: this.translatePipe.transform('contract_searchForm_status_archived'), value: 'archived' }
        ];

        this.tableHeads = [
            {
                name: this.translatePipe.transform('contract_table_contract_number'),
                code: 'number',
                sortable: true,
                width: '9%'
            },
            {
                name: this.translatePipe.transform('contract_table_contract_alias'),
                code: 'alias',
                sortable: true,
                hideable: true,
                width: '11%'
            },
            {
                name: this.translatePipe.transform('contract_table_contract_version'),
                code: 'name',
                sortable: true,
                hideable: true,
                width: '14%'
            },
            {
                name: this.translatePipe.transform('contract_table_stage'),
                code: 'stage',
                sortable: true,
                hideable: true,
                width: '11%'
            },
            {
                name: this.translatePipe.transform('contract_table_advisor'),
                code: 'advisor',
                sortable: true,
                hideable: true,
                width: '11%'
            },
            {
                name: this.translatePipe.transform('contract_table_contractType'),
                code: 'type',
                sortable: true,
                hideable: true,
                width: '11%'
            },
            {
                name: this.translatePipe.transform('contract_convention_type'),
                code: 'convention_type',
                sortable: true,
                hideable: true,
                width: '11%'
            },
            {
                name: this.translatePipe.transform('contract_table_startDate'),
                code: 'start_date',
                sortable: true,
                hideable: true,
                width: '11%'
            },
            {
                name: this.translatePipe.transform('contract_table_endDate'),
                code: 'end_date',
                sortable: true,
                hideable: true,
                width: '11%'
            },
            {
                name: '',
                code: 'actions',
                settings: true
            }
        ];

        this.tableSort = this.getTableSort();
        if (!this.tableSort) {
            this.tableSort = { code: 'name', dir: 'asc' };
        }

        this.router.events
            .pipe(
                filter((event: NavigationEvent) => {
                    return event instanceof NavigationStart;
                })
            )
            .subscribe((event: NavigationStart) => {
                if (event.restoredState) {
                    window.location.href = event.url;
                }
            });
        this.route.queryParams.subscribe((params) => {
            const parameters = Object.assign({}, params);
            if (parameters.contractId) delete parameters['contractId'];
            if (parameters.activePage) delete parameters['activePage'];
            if (JSON.stringify(parameters) === JSON.stringify(this.previousParams)) {
                return;
            }
            this.startRow = (parameters.page || 1) * this.RPP - this.RPP;
            this.searchSTR = params.query ? JSON.parse(params.query) : this.getDefaultSearchSTR();
            this.getContracts();
            this.previousParams = parameters;
        });

        this.getContractTypes();
        this.getStageOptions();
    }

    private getTableSort() {
        const tableSortLS = this.localStorageService.getItem(this.tableSortName);
        if (
            tableSortLS &&
            this.tableHeads.filter((item) => item.code == tableSortLS.code && item.sortable == true).length == 1
        ) {
            return tableSortLS;
        }

        return null;
    }

    private getContractTypes() {
        this.contractTypes = this.resourceService.getResourceByType('contract_type');
    }
    private getStageOptions() {
        this.stageOptions = this.resourceService.getResourceByType('stage');
    }

    dismissPopover() {
        setTimeout(() => {
            this.popoverHelper = null;
        }, 1);
    }

    getDefaultSearchSTR() {
        return { status: 'all' };
    }

    getSearchQuery() {
        return {
            ...this.searchSTR,
            startRow: this.startRow,
            RRP: this.RPP,
            orderBy: this.getSortARR()
        };
    }

    getSortARR() {
        if (!this.tableSort || !this.tableSort.code) {
            return [];
        }
        return [`${this.tableSort.code} ${this.tableSort.dir}`];
    }

    search(reset?: boolean) {
        this.startRow = 0;
        if (reset) {
            this.router.navigate([], {
                queryParams: {
                    query: null,
                    page: null,
                    contractId: null
                },
                queryParamsHandling: 'merge'
            });
        } else {
            this.router.navigate([], {
                queryParams: {
                    query: JSON.stringify(this.searchSTR),
                    page: null
                },
                queryParamsHandling: 'merge'
            });
        }
    }

    getContracts() {
        const searchSTR = this.getSearchQuery();
        this.tableLoading = true;
        this.contractsService
            .getContracts(searchSTR)
            .pipe(takeUntil(this.onDestroy$))
            .subscribe((next) => {
                if (this.startRow && this.startRow >= next.rows) {
                    this.resetStartRow();
                    return;
                }
                this.contracts = next.data.map((item: any) => {
                    if (item.convention_type) {
                        item.convention_type = item.convention_type.map((conv_type) => {
                            return this.resourceService.getResourceTitleByTypeAndKey('convention_type', conv_type);
                        });
                    }
                    const contract = {
                        ...item,
                        name: {
                            type: 'default',
                            value: item.name
                        },
                        // number: {
                        //     type: 'titleSubtitle',
                        //     title: this.getContractTitle(item),
                        //     stamps: this.getStampForContract(item.status),
                        //     subtitle: this.getTitleMultiValuesForProduct(item.products, 1),
                        //     tooltipSubtitle: this.getTooltipValueForProduct(item.products, 1),
                        //     stampsSubtitle: this.getStampForTootltipForProduct(item.products, 1)
                        // },
                        stage: {
                            type: 'default',
                            value: this.resourceService.getResourceTitleByTypeAndKey('stage', item.stage)
                        },
                        advisor: {
                            type: 'default',
                            value: item.advisor
                        },
                        type: {
                            type: 'default',
                            value: item.type
                        },
                        // <ng-container *ngIf="contract.convention_type && contract.convention_type.length > 0">
                        //     <ul class="app-list" [class.app-list--single]="contract.convention_type.length == 1">
                        //         <li *ngFor="let conv_type of contract.convention_type">
                        //             {{resourceService.getResourceTitleByTypeAndKey('convention_type', conv_type) | display}}
                        //         </li>
                        //     </ul>
                        // </ng-container>
                        // <ng-container *ngIf="!contract.convention_type || contract.convention_type.length == 0">
                        //     <span>-</span>
                        // </ng-container>
                        // convention_type: {
                        //     type: 'default',
                        //     value: item.convention_type
                        // },
                        convention_type: {
                            type: 'default',
                            value: this.getTitleMultiValues(item.convention_type, 1),
                            tooltip: this.getTooltipValue(item.convention_type, 1),
                            stamps: this.getStampForTootltip(item.convention_type, 1)
                        },
                        startDate: {
                            type: 'default',
                            value: item.start_date
                        },
                        endDate: {
                            type: 'default',
                            value: item.end_date
                        },
                        actions: {
                            type: 'actions',
                            actions: this.getActions(item)
                        }
                    };
                    return contract;
                });
                this.tableLoading = false;
                this.totalRows = next.rows;
                document.body.scrollTop = 0;
                document.documentElement.scrollTop = 0;
            });
    }

    getTitleMultiValues(values: any[], numberValues) {
        if (values && values.length > numberValues) {
            return values.slice(0, numberValues).join(', ');
        } else {
            return values ? values.join(', ') : '';
        }
    }

    getTooltipValue(values: any[], numberValues) {
        if (values && values.length > numberValues) {
            return values.slice(numberValues).join(', ');
        }
    }

    getStampForTootltip(values: any[], numberValues) {
        if (values && values.length > numberValues) {
            return [{ value: '+' + (values.length - numberValues), classList: 'stamp--small' }];
        }
    }

    getContractTitle(item) {
        if (item.name) {
            return item.number + ' ' + item.name;
        } else {
            return item.number;
        }
    }

    getProductTitle(item) {
        if (item.product) {
            return 'product: ' + item.product;
        }
    }

    getActions(item) {
        const actions = [];
        const editAction = {
            name: this.translatePipe.transform('contract_table_edit'),
            code: 'edit',
            icon: 'pencil'
        };
        actions.push(editAction);
        if (item.status == '1') {
            const archiveAction = {
                name: this.translatePipe.transform('contract_table_archive'),
                code: 'archive',
                icon: 'folder',
                confirm: true,
                confirm_type: 'archive'
            };
            actions.push(archiveAction);
        }
        if (item.status == '3') {
            const unarchiveAction = {
                name: this.translatePipe.transform('contract_table_unarchive'),
                code: 'unarchive',
                icon: 'folder',
                confirm: true,
                confirm_type: 'unarchive'
            };
            actions.push(unarchiveAction);
        }
        if (this.authenticationService.hasAccessDeleteContracts()) {
            const deleteAction = {
                name: this.translatePipe.transform('contract_table_delete'),
                code: 'delete',
                icon: 'trash',
                class: 'delete-red',
                confirm: true,
                confirm_type: 'delete'
            };
            actions.push(deleteAction);
        }
        return actions;
    }

    getStampForContract(status) {
        if (status == '3') {
            return [{ value: this.translatePipe.transform('contract_table_archived'), classList: 'stamp--gray' }];
        }
    }

    getTitleMultiValuesForProduct(products: any[], numberValues) {
        if (products) {
            const values = [];
            for (const product of products) {
                values.push(product.product);
            }
            if (values && values.length > numberValues) {
                return 'product: ' + values.slice(0, numberValues).join(', ');
            } else {
                return values ? 'product: ' + values.join(', ') : '';
            }
        }
    }

    getTooltipValueForProduct(products: any[], numberValues) {
        if (products) {
            const values = [];
            for (const product of products) {
                values.push(product.product);
            }
            if (values && values.length > numberValues) {
                return values.slice(numberValues).join(', ');
            }
        }
    }

    getStampForTootltipForProduct(products: any[], numberValues) {
        if (products) {
            const values = [];
            for (const product of products) {
                values.push(product.product);
            }
            if (values && values.length > numberValues) {
                return [{ value: '+' + (values.length - numberValues), classList: 'stamp--small' }];
            }
        }
    }

    archiveContract(id: string) {
        this.dismissPopover();
        this.contractsService.archiveContract(id).subscribe((next) => {
            if (this.searchSTR.status) {
                this.contracts = this.contracts.filter((item) => item.id != id);
            } else {
                for (let contract of this.contracts) {
                    if (contract.id == id) {
                        contract.status = 3;
                        contract.number.stamps = this.getStampForContract(contract.status);
                        contract.actions.actions = this.getActions(contract);
                    }
                }
            }
        });
    }

    unarchiveContract(id: string) {
        this.dismissPopover();
        this.contractsService.unarchiveContract(id).subscribe((next) => {
            if (this.searchSTR.status) {
                this.contracts = this.contracts.filter((item) => item.id != id);
            } else {
                for (let contract of this.contracts) {
                    if (contract.id == id) {
                        contract.status = 1;
                        contract.number.stamps = this.getStampForContract(contract.status);
                        contract.actions.actions = this.getActions(contract);
                    }
                }
            }
        });
    }

    deleteContract(id: string) {
        this.dismissPopover();
        this.contractsService.deleteContract(id).subscribe((next) => {
            this.contracts = this.contracts.filter((item) => item.id != id);
        });
    }

    resetStartRow() {
        this.router.navigate([], {
            queryParams: {
                page: 1
            },
            queryParamsHandling: 'merge'
        });
    }

    changeStartRow(startRow: number) {
        this.router.navigate([], {
            queryParams: {
                page: Math.ceil(startRow / this.RPP) + 1
            },
            queryParamsHandling: 'merge'
        });
    }

    tableClick(item: any) {
        switch (item.type) {
            case 'actions':
                break;
            default:
                this.openCreateUpdateModal(item.id, 'show');
                break;
        }
    }

    actionClick(item: any, action: string) {
        switch (action) {
            case 'edit':
                this.openCreateUpdateModal(item.id, 'edit');
                break;
            case 'archive':
                this.archiveContract(item.id);
                break;
            case 'unarchive':
                this.unarchiveContract(item.id);
                break;
            case 'delete':
                this.deleteContract(item.id);
                break;
        }
    }

    setSort(code: string, dir: string) {
        this.tableSort = { code: code, dir: dir };
        this.localStorageService.setItem(this.tableSortName, this.tableSort);
        this.getContracts();
    }

    openCreateUpdateModal(id?: number, mode?: string, activePage?: string) {
        if (!activePage) {
            activePage = id ? 'summary' : 'overview';
        }
        let windowClass = 'main-modal detail-modal ';
        if (!id) windowClass += 'detail-modal--create';
        const modalRef = this.ModalService.open(ContractDetailComponent, {
            windowClass: windowClass,
            beforeDismiss: () => {
                return modalRef.componentInstance.canExit && modalRef.componentInstance.canExit();
            }
        });

        this.router.navigate([], {
            queryParams: {
                contractId: id,
                activePage: activePage
            },
            queryParamsHandling: 'merge'
        });

        this.modalOpen = true;
        modalRef.componentInstance.id = id;
        modalRef.componentInstance.mode = mode;
        modalRef.componentInstance.activePage = activePage;
        modalRef.result
            .then((returnValue) => {
                switch (returnValue) {
                }
            })
            .finally(() => {
                setTimeout(() => {
                    this.modalOpen = false;
                    this.router.navigate([], {
                        queryParams: {
                            contractId: null,
                            activePage: null,
                            mode: null
                        },
                        queryParamsHandling: 'merge'
                    });
                    if (this.createdOrUpdated) {
                        this.search(true);
                        this.getContracts();
                        this.createdOrUpdated = false;
                    }
                }, 1);
            });
        // on create
        modalRef.componentInstance.created.subscribe((contract: any) => {
            // setTimeout(() => {
            this.router.navigate([], {
                queryParams: {
                    contractId: contract.id,
                    activePage: activePage
                },
                queryParamsHandling: 'merge'
            });
            // }, 1);
            // this.search(true);
            // this.getContracts();
            this.createdOrUpdated = true;
        });
        // on update
        modalRef.componentInstance.updated.subscribe((contract: any) => {
            this.createdOrUpdated = true;
        });
    }

    export() {
        const searchSTR = this.getSearchQuery();
        let params = new HttpParams();
        for (const key in searchSTR) {
            params = params.append(key, searchSTR[key]);
        }
        this.exportBusy = true;
        this.contractsService.exportContracts(searchSTR).subscribe((data) => {
            setTimeout(() => {
                this.exportBusy = false;
            }, 500);
        });
    }

    ngOnDestroy(): void {
        this.onDestroy$.next();
    }
}
