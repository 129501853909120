import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationStart } from '@angular/router';
import { Router } from '@angular/router';
import { Event as NavigationEvent } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { FieldSwitchOptionsModel } from 'src/app/components/fields/field-switch/field-switch.component';
import { HelpersService } from 'src/app/services/helpers.service';
import { ResourceService } from 'src/app/services/resource.service';
import { TranslatePipe } from 'src/app/utils/pipes';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { LocalStorageService } from 'src/app/services/local-storage.service';
import { PriceDossiersService } from 'src/app/services/price-dossiers.service';
import { PriceDossierDetailComponent } from 'src/app/components/modals/price-dossier-detail/price-dossier-detail.component';
import { HttpParams } from '@angular/common/http';
import sanitize from 'sanitize-html';

@Component({
    selector: 'app-price-dossiers',
    templateUrl: './price-dossiers.component.html',
    styleUrls: ['./price-dossiers.component.scss']
})
export class PriceDossiersComponent implements OnInit, OnDestroy {
    onDestroy$: Subject<void> = new Subject<void>();
    popoverHelper: any = null;
    searchSTR: any = {};
    exportBusy: boolean = false;
    switchOptions: FieldSwitchOptionsModel;
    statusOptions;
    displayMoreOptions: boolean = false;
    options: any = {};

    // table
    tableHeads: any;
    tableSort: { code: string; dir: string };
    tableSortName = 'pricedossier_tableSort';

    dossiers: any[] = null;
    startRow: number = 0;
    RPP: number = 20;
    totalRows: number = null;
    tableLoading: boolean = true;
    searchType: string = 'advanced';
    modalOpen: boolean = false;

    constructor(
        private router: Router,
        private ModalService: NgbModal,
        private priceDossiersService: PriceDossiersService,
        private helpersService: HelpersService,
        private resourceService: ResourceService,
        private translatePipe: TranslatePipe,
        private authenticationService: AuthenticationService,
        private localStorageService: LocalStorageService,
        private route: ActivatedRoute
    ) {
        // router.events
        //     .pipe(
        //         filter((event: NavigationEvent) => {
        //             return event instanceof NavigationStart;
        //         })
        //     )
        //     .subscribe((event: NavigationStart) => {
        //         if (event.restoredState) {
        //             window.location.href = event.url;
        //         }
        //     });
        // route.queryParams.subscribe((params) => {
        //     this.startRow = (params.page || 1) * this.RPP - this.RPP;
        //     this.searchSTR = params.query ? JSON.parse(params.query) : this.getDefaultSearchSTR();
        //     this.getDossiers();
        // });
    }

    ngOnInit(): void {
        // this.initSearchSTR();
        const activeDossierId = this.helpersService.getParam('dossierId');
        const mode = this.helpersService.getParam('mode');
        const activePage = this.helpersService.getParam('activePage');
        if (activeDossierId) this.openCreateUpdateModal(activeDossierId, mode, activePage);

        this.switchOptions = [
            {
                title: this.translatePipe.transform('pricedossier_searchForm_title_'),
                value: 'advanced'
            }
        ];

        this.options = {
            status_code: this.resourceService.getResourceByType('status_code'),
            year_of_decision: [...Array(2050 - 2015 + 1).keys()]
                .map((x) => x + 2015)
                .map((x) => {
                    return { key: x, value: x };
                }),
            crp_cpsp: this.resourceService.getResourceByType('crp_cpsp'),
            type: this.resourceService.getResourceByType('type')
        };

        this.tableHeads = [
            {
                name: this.translatePipe.transform('pricedossier_table_productname'),
                code: 'products',
                sortable: true,
                width: '25%'
            },
            {
                name: this.translatePipe.transform('pricedossier_table_company'),
                code: 'company',
                sortable: true,
                width: '15%'
            },
            {
                name: this.translatePipe.transform('pricedossier_table_date_meeting'),
                code: 'date_meeting',
                sortable: true,
                width: '15%'
            },
            {
                name: this.translatePipe.transform('pricedossier_table_type'),
                code: 'type',
                sortable: true,
                width: '15%'
            },
            {
                name: this.translatePipe.transform('pricedossier_table_reimbursement'),
                code: 'reimbursement',
                sortable: true,
                width: '15%'
            },
            {
                name: this.translatePipe.transform('pricedossier_table_lastModifiedTS'),
                code: 'lastModifiedTS',
                sortable: true,
                width: '15%'
            },
            {
                name: '',
                code: 'actions'
            }
        ];

        this.tableSort = this.getTableSort();
        if (!this.tableSort) {
            this.tableSort = { code: 'lastModifiedTS', dir: 'desc' };
        }

        this.router.events
            .pipe(
                filter((event: NavigationEvent) => {
                    return event instanceof NavigationStart;
                })
            )
            .subscribe((event: NavigationStart) => {
                if (event.restoredState) {
                    window.location.href = event.url;
                }
            });
        this.route.queryParams.subscribe((params) => {
            this.startRow = (params.page || 1) * this.RPP - this.RPP;
            this.searchSTR = params.query ? JSON.parse(params.query) : this.getDefaultSearchSTR();
            this.getDossiers();
        });
    }

    private getTableSort() {
        const tableSortLS = this.localStorageService.getItem(this.tableSortName);
        if (
            tableSortLS &&
            this.tableHeads.filter((item) => item.code == tableSortLS.code && item.sortable == true).length == 1
        ) {
            return tableSortLS;
        }

        return null;
    }

    dismissPopover() {
        setTimeout(() => {
            this.popoverHelper = null;
        }, 1);
    }

    // initSearchSTR() {
    //     this.searchSTR = { status: 'active' };
    // }

    getDefaultSearchSTR() {
        // return { status: 'active' };
        return {};
    }

    getSearchQuery() {
        return {
            ...this.searchSTR,
            startRow: this.startRow,
            RRP: this.RPP,
            orderBy: this.getSortARR()
        };
    }

    getSortARR() {
        if (!this.tableSort || !this.tableSort.code) {
            return [];
        }
        return [`${this.tableSort.code} ${this.tableSort.dir}`];
    }

    search(reset?: boolean) {
        this.startRow = 0;
        if (reset) {
            this.searchSTR = this.getDefaultSearchSTR();
            this.router.navigate([], {
                queryParams: {
                    query: null,
                    page: null
                },
                queryParamsHandling: 'merge'
            });
        }
        if (this.helpersService.objectIsEmpty(this.searchSTR)) {
            this.router.navigate([], {
                queryParams: {
                    query: null,
                    page: null
                },
                queryParamsHandling: 'merge'
            });
        } else {
            // const query = escape(JSON.stringify(this.searchSTR));
            // this.router.navigate([], {
            //     queryParams: {
            //         query: query,
            //         page: null
            //     },
            //     queryParamsHandling: 'merge'
            // });
            this.router.navigate([], {
                queryParams: {
                    query: JSON.stringify(this.searchSTR),
                    page: null
                },
                queryParamsHandling: 'merge'
            });
        }
        // this.getDossiers();
    }

    getDossiers() {
        const searchSTR = this.getSearchQuery();
        this.tableLoading = true;
        this.priceDossiersService
            .getDossiers(searchSTR)
            .pipe(takeUntil(this.onDestroy$))
            .subscribe((next) => {
                if (this.startRow && this.startRow >= next.rows) {
                    this.resetStartRow();
                    return;
                }
                this.dossiers = next.data.map((item: any) => {
                    let productName: string;
                    if (item.products?.length) {
                        productName =
                            item.products[0]?.package ??
                            sanitize(item.products[0]?.comments, {
                                allowedTags: []
                            });
                    }

                    const dossier = {
                        ...item,
                        products: {
                            type: 'default',
                            value: productName,
                            stamps: this.getStampForTootltip(
                                item.products?.map((item) => {
                                    return (
                                        item.package ??
                                        sanitize(item.comments, {
                                            allowedTags: []
                                        }) ??
                                        '-'
                                    );
                                }),
                                1
                            ),
                            tooltip: this.getTooltipValue(
                                item.products?.map((item) => {
                                    return (
                                        item.package ??
                                        sanitize(item.comments, {
                                            allowedTags: []
                                        }) ??
                                        '-'
                                    );
                                }),
                                1
                            )
                        },
                        reimbursement: this.resourceService.getResourceTitleByTypeAndKey(
                            'reimbursement',
                            item.reimbursement
                        ),
                        type: this.resourceService.getResourceTitleByTypeAndKey('type', item.type),
                        status_code: this.resourceService.getResourceTitleByTypeAndKey('status_code', item.status_code),
                        advisor: {
                            type: 'default',
                            value: item.advisor
                        },
                        lastModifiedTS: {
                            type: 'ts',
                            ts: item.lastModifiedTS,
                            format: 'prettyDateTime'
                        },
                        actions: {
                            type: 'actions',
                            actions: this.getActions(item)
                        }
                    };
                    return dossier;
                });
                this.tableLoading = false;
                this.totalRows = next.rows;
                document.body.scrollTop = 0;
                document.documentElement.scrollTop = 0;
            });
    }

    getDossierTitle(item) {
        return item.doc_number ? item.doc_number : '-';
        // return item.dos_number ? item.dos_number : '-';
    }

    export() {
        const searchSTR = this.getSearchQuery();
        let params = new HttpParams();
        for (const key in searchSTR) {
            params = params.append(key, searchSTR[key]);
        }
        this.exportBusy = true;
        this.priceDossiersService.exportDossiers(searchSTR).subscribe((data) => {
            setTimeout(() => {
                this.exportBusy = false;
            }, 500);
        });
    }

    getActions(item) {
        const actions = [];
        const editAction = {
            name: this.translatePipe.transform('pricedossier_table_edit'),
            code: 'edit',
            icon: 'pencil'
        };
        actions.push(editAction);
        if (item.status == '1') {
            const archiveAction = {
                name: this.translatePipe.transform('pricedossier_table_archive'),
                code: 'archive',
                icon: 'folder',
                confirm: true,
                confirm_type: 'archive'
            };
            actions.push(archiveAction);
        }
        if (item.status == '3') {
            const unarchiveAction = {
                name: this.translatePipe.transform('pricedossier_table_unarchive'),
                code: 'unarchive',
                icon: 'folder',
                confirm: true,
                confirm_type: 'unarchive'
            };
            actions.push(unarchiveAction);
        }
        if (this.authenticationService.hasAccessDeletePriceDossiers()) {
            const deleteAction = {
                name: this.translatePipe.transform('pricedossier_table_delete'),
                code: 'delete',
                icon: 'trash',
                class: 'delete-red',
                confirm: true,
                confirm_type: 'delete'
            };
            actions.push(deleteAction);
        }
        return actions;
    }

    getStampForDossier(status) {
        if (status == '3') {
            return [{ value: this.translatePipe.transform('pricedossier_table_archived'), classList: 'stamp--gray' }];
        }
    }

    getTitleMultiValues(values: any[], numberValues) {
        if (values && values.length > numberValues) {
            return values.slice(0, numberValues).join(', ');
        } else {
            return values ? values.join(', ') : '';
        }
    }

    getTooltipValue(values: any[], numberValues) {
        if (values && values.length > numberValues) {
            return values.slice(numberValues).join(', ');
        }
    }

    getStampForTootltip(values: any[], numberValues) {
        if (values && values.length > numberValues) {
            return [{ value: '+' + (values.length - numberValues), classList: 'stamp--small' }];
        }
    }

    archiveDossier(id: string) {
        this.dismissPopover();
        this.priceDossiersService.archiveDossier(id).subscribe((next) => {
            if (this.searchSTR.status) {
                this.dossiers = this.dossiers.filter((item) => item.id != id);
            } else {
                for (let dossier of this.dossiers) {
                    if (dossier.id == id) {
                        dossier.status = 3;
                        dossier.number.stamps = this.getStampForDossier(dossier.status);
                        dossier.actions.actions = this.getActions(dossier);
                    }
                }
            }
        });
    }

    unarchiveDossier(id: string) {
        this.dismissPopover();
        this.priceDossiersService.unarchiveDossier(id).subscribe((next) => {
            if (this.searchSTR.status) {
                this.dossiers = this.dossiers.filter((item) => item.id != id);
            } else {
                for (let dossier of this.dossiers) {
                    if (dossier.id == id) {
                        dossier.status = 1;
                        dossier.number.stamps = this.getStampForDossier(dossier.status);
                        dossier.actions.actions = this.getActions(dossier);
                    }
                }
            }
        });
    }

    deleteDossier(id: string) {
        this.dismissPopover();
        this.priceDossiersService.deleteDossier(id).subscribe((next) => {
            this.dossiers = this.dossiers.filter((item) => item.id != id);
        });
    }

    resetStartRow() {
        this.router.navigate([], {
            queryParams: {
                page: 1
            },
            queryParamsHandling: 'merge'
        });
    }

    changeStartRow(startRow: number) {
        this.router.navigate([], {
            queryParams: {
                page: Math.ceil(startRow / this.RPP) + 1
            },
            queryParamsHandling: 'merge'
        });
    }

    tableClick(item: any) {
        switch (item.type) {
            case 'actions':
                break;
            default:
                this.openCreateUpdateModal(item.id, 'show');
                break;
        }
    }

    actionClick(item: any, action: string) {
        switch (action) {
            case 'edit':
                this.openCreateUpdateModal(item.id, 'edit');
                break;
            case 'archive':
                this.archiveDossier(item.id);
                break;
            case 'unarchive':
                this.unarchiveDossier(item.id);
                break;
            case 'delete':
                this.deleteDossier(item.id);
                break;
        }
    }

    setSort(code: string, dir: string) {
        this.tableSort = { code: code, dir: dir };
        this.localStorageService.setItem(this.tableSortName, this.tableSort);
        this.getDossiers();
    }

    openCreateUpdateModal(id?: number, mode?: string, activePage?: string) {
        if (!activePage) {
            activePage = 'overview';
        }

        const modalRef = this.ModalService.open(PriceDossierDetailComponent, {
            windowClass: 'main-modal detail-modal',
            beforeDismiss: () => {
                return modalRef.componentInstance.canExit && modalRef.componentInstance.canExit();
            }
        });

        this.router.navigate([], {
            queryParams: {
                dossierId: id,
                activePage: activePage
            },
            queryParamsHandling: 'merge'
        });

        this.modalOpen = true;
        modalRef.componentInstance.id = id;
        modalRef.componentInstance.mode = mode;
        modalRef.componentInstance.activePage = activePage;
        modalRef.result
            .then((returnValue) => {
                switch (returnValue) {
                }
            })
            .finally(() => {
                this.modalOpen = false;
                this.router.navigate([], {
                    queryParams: {
                        dossierId: null,
                        activePage: null
                    },
                    queryParamsHandling: 'merge'
                });
            });
        // on create
        modalRef.componentInstance.created.subscribe((dossier: any) => {
            setTimeout(() => {
                this.router.navigate([], {
                    queryParams: {
                        dossierId: dossier.id,
                        activePage: activePage
                    },
                    queryParamsHandling: 'merge'
                });
            }, 1);
            this.search(true);
        });
        // on update
        modalRef.componentInstance.updated.subscribe((dossier: any) => {
            this.getDossiers();
        });
    }

    ngOnDestroy(): void {
        this.onDestroy$.next();
    }
}
