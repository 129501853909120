import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, switchMap, map } from 'rxjs/operators';
import { ContractsService } from 'src/app/services/contracts.service';
import { HelpersService } from 'src/app/services/helpers.service';

@Component({
    selector: 'app-contract-dossiers',
    templateUrl: './contract-dossiers.component.html',
    styleUrls: ['./contract-dossiers.component.scss']
})
export class ContractDossiersComponent implements OnInit {
    @Input() label?: string;
    @Input() error?: string | string[];
    @Input() placeholder?: string;
    @Input() model: any[] = [];
    @Input() readOnly: boolean = false;
    @Output() modelChange = new EventEmitter();

    selected = '';

    constructor(
        private contractsService: ContractsService,
        public HelpersService: HelpersService,
        public ActiveModal: NgbActiveModal
    ) {}

    ngOnInit(): void {
        if (this.model == null || this.model == undefined) {
            this.model = [];
        }
    }

    formatter = (item: { key: any; value: string }) => item.value;

    public onFocus(e: Event): void {
        e.stopPropagation();
        setTimeout(() => {
            const inputEvent: Event = new Event('input');
            e.target.dispatchEvent(inputEvent);
        }, 0);
    }

    search = (text$: Observable<string>) =>
        text$.pipe(
            debounceTime(200),
            distinctUntilChanged(),
            filter((term) => {
                if (this.model && this.model.length > 0) {
                    return true;
                } else {
                    return term.length > 2;
                }
            }),
            switchMap((term) => this.contractsService.searchDossiers(term, this.model)),
            map((val) => {
                let dossiers = [];
                for (const dossier of val.data) {
                    dossiers.push({
                        key: dossier.id,
                        value: this.getDossierInfo(dossier),
                        dossier: dossier
                    });
                }

                if (dossiers.length == 0) {
                    dossiers.push({
                        key: '',
                        value: 'No dossiers found.',
                        dossier: null
                    });
                }
                return dossiers;
            })
        );

    onSelect($event) {
        if ($event.item.dossier) {
            this.model.push($event.item.dossier);
            this.modelChange.emit(this.model);
        }

        $event.preventDefault();

        setTimeout(() => {
            this.selected = '';
        }, 200);
    }

    deleteValue(index) {
        this.model.splice(index, 1);
        if (this.error && this.error[index]) {
            (this.error as any).splice(index, 1);
        }
        this.modelChange.emit(this.model);
    }

    getDossierInfo(dossier) {
        if (dossier.alias) {
            return dossier.alias;
        } else {
            return '[No dossier name]';
        }
    }

    getDossierNrs(dossier_nrs: string[]) {
        if (dossier_nrs && dossier_nrs.length > 1) {
            return dossier_nrs.slice(0, 1);
        } else {
            return dossier_nrs ? dossier_nrs : '';
        }
    }

    getStampDossierNrs(values: any[]) {
        if (values && values.length > 1) {
            return '+' + (values.length - 1);
        }
    }

    getTooltipDossierNrs(values: any[]) {
        if (values && values.length > 1) {
            return values.slice(1).join(', ');
        }
    }

    getStampForTootltipDossierNrs(values: any[]) {
        if (values && values.length > 1) {
            return [{ value: '+' + (values.length - 1), classList: 'stamp--small' }];
        }
    }

    showHidePackages(dossierId) {
        let x = document.getElementById(dossierId + '_package');
        if (x.style.display === 'none') {
            x.style.display = 'block';
        } else {
            x.style.display = 'none';
        }
    }

    getDossagesText(packages) {
        if (packages.length == 1) {
            return '1 dosage';
        } else {
            return packages.length + ' dosages';
        }
    }

    isArray(val) {
        if (val && val instanceof Array) {
            return true;
        }
        return false;
    }
}
