import { Injectable } from '@angular/core';
import { DefaultService } from '../utils/api';
import { HelpersService } from './helpers.service';
import { TranslatorService } from './translator.service';

@Injectable({
    providedIn: 'root'
})
export class UsersService {
    constructor(
        private DefaultService: DefaultService,
        public HelpersService: HelpersService,
        public TranslatorService: TranslatorService
    ) {}

    public getUsers({ startRow, RPP, orderBy, dossierId, term, company_ids, sam_company_ids }) {
        return this.DefaultService.usersGetUsers(startRow, RPP, orderBy, dossierId, term, company_ids, sam_company_ids);
    }
}
