<div class="field field-typeahead position-relative" [class.valid]="model">
    <span *ngIf="label" class="label">{{label}}</span>
    <input
        (focus)="onFocus($event)"
        (click)="onFocus($event)"
        id="typeahead-prevent-manual-entry"  
        type="text"
        [(ngModel)]="selected"
        [class.error-field]="error"
        [placeholder]="placeholder"
        (selectItem)="onSelect($event);"
        (keyup.enter)="enter.emit()"
        (blur)="blur($event)"
        [ngbTypeahead]="search"
        [inputFormatter]="formatter"
        [resultFormatter]="formatter"
        [resultTemplate]="template"
        editable=false />
    <span *ngIf="error" class="error-field-display">{{error}}</span>

    <ng-template #template let-r="result" let-t="term">
        <ngb-highlight [result]="r.value" [term]="t"></ngb-highlight>
        <span *ngIf="r.dossier" class="typeahead-after">
            {{getDossierNrs(r.dossier.dossier_nrs)}}
            <ng-container *ngIf="getStampDossierNrs(r.dossier.dossier_nrs)">
                <span class="stamp mr-xxs ml-xxs stamp--small">
                    {{getStampDossierNrs(r.dossier.dossier_nrs)}}
                </span>
            </ng-container>
        </span>
    </ng-template>
</div>