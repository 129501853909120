import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, switchMap, map } from 'rxjs/operators';
import { IndicationService } from 'src/app/services/indication.service';
import { TranslatePipe } from 'src/app/utils/pipes';
import { ModalAddIndicationComponent } from '../../modals/modal-add-indication/modal-add-indication.component';

@Component({
    selector: 'app-field-indications',
    templateUrl: './field-indications.component.html',
    styleUrls: ['./field-indications.component.scss']
})
export class FieldIndicationsComponent implements OnInit {
    @Input() label?: string;
    @Input() error?: string | string[];
    @Input() placeholder?: string;
    @Input() model: any[] = [];
    @Input() optionalInputPlaceholder: string;
    @Output() modelChange = new EventEmitter();

    optionalInputModelName = 'comment';
    selected = '';

    constructor(
        private indicationService: IndicationService,
        private TranslatePipe: TranslatePipe,
        private ModalService: NgbModal
    ) {}

    ngOnInit(): void {
        if (this.model == null || this.model == undefined) {
            this.model = [];
        }
    }

    formatter = (item: { key: any; value: string }) => item.value;

    public onFocus(e: Event): void {
        e.stopPropagation();
        setTimeout(() => {
            const inputEvent: Event = new Event('input');
            e.target.dispatchEvent(inputEvent);
        }, 0);
    }

    search = (text$: Observable<string>) =>
        text$.pipe(
            debounceTime(200),
            distinctUntilChanged(),
            filter((term) => {
                return term.length > 1;
            }),
            switchMap((term) => this.indicationService.searchIndications(term, this.model)),
            map((val) => {
                let indications = [];
                for (const indication of val.data) {
                    indications.push({
                        key: indication.id,
                        value: indication.label,
                        indication: indication
                    });
                }

                if (indications.length == 0) {
                    indications.push({
                        key: 'add_new',
                        value: this.TranslatePipe.transform('indications_placeholder_add'),
                        indication: null
                    });
                    // indications.push({
                    //     key: '',
                    //     value: 'No indications found.',
                    //     indication: null
                    // });
                }
                return indications;
            })
        );

    onSelect($event) {
        // add new
        if ($event?.item?.key === 'add_new') {
            const modalRef = this.ModalService.open(ModalAddIndicationComponent, {
                windowClass: 'main-modal options-modal'
            });
            modalRef.componentInstance.label = this.selected || '';
            modalRef.componentInstance.confirmed.subscribe((next) => {
                this.model.push(next);
            });
            $event.preventDefault();
            setTimeout(() => {
                this.selected = '';
            }, 1);
            return;
        }
        // add existing
        if ($event.item.indication) {
            this.model.push({
                id: $event.item.indication.id,
                label: $event.item.indication.label
            });
            this.modelChange.emit(this.model);
        }
        $event.preventDefault();

        setTimeout(() => {
            this.selected = '';
        }, 200);
    }

    deleteValue(index) {
        this.model.splice(index, 1);
        this.modelChange.emit(this.model);
    }

    isArray(val) {
        if (val && val instanceof Array) {
            return true;
        }
        return false;
    }
}
