<ng-container *ngIf="mode != 'globalError'">
    <ng-container *ngIf="!ready">
        <app-spinner [centerVertically]="true"></app-spinner>
    </ng-container>
    <div *ngIf="ready" class="d-flex detail-modal">
        <!-- side panel -->
        <app-modal-side-panel *ngIf="mode != 'create'" [open]="true">
            <div class="d-flex flex-column w-100 flex-grow-1 scroll-y pb-5 saving--pre" [class.saving]="saving">
                <div class="p-l pb-s pr-s panel-head">
                    <button (click)="activeModal.dismiss()" class="mb-xl fontsize-xs"> <i class="im-icon im-icon-arrow-left mr-0" style="font-size: 18px;"></i> <span class="fontsize-s d-inline-block" style="margin-left: 11px;line-height: 21px;transform: translateY(-2px);">{{'contract_detail_back_btn' | translate}}</span></button>  
                    <div class="">
                        <div class="d-flex flex-column titles">
                            <span class="title">
                                {{dossier.doc_number | display}}
                            </span>
                        </div>
                    </div>
                </div>
                <div class="d-flex flex-grow-1 scroll-y opacity-hide" [class.opacity-show]="true">
                    <ng-container>
                        <app-side-navigation
                            class="d-block w-100"
                            [pageARR]="pages"
                            [model]="activePage"
                            (modelChange)="pageChange($event)">
                        </app-side-navigation>
                    </ng-container>
                </div>
            </div>
        </app-modal-side-panel>
    
        <div class="d-flex flex-column w-100 flex-grow-1 scroll-y pb-5">
            <div class="">
                <!-- header -->
                <div class="detail-modal-header d-flex flex-row justify-content-between align-items-center" style="min-height: 36px;">
                    <ng-container *ngIf="mode == 'create'">
                        <h1 style="min-height: 36px;">{{'pricedossier_detail_create_title' | translate}}</h1>
                        <div class="h-100 d-flex align-items-center">
                            <button class="button tertiary cancel--" (click)="canExit(); activeModal.dismiss();" [class.disabled]="saving"><span>{{'contract_detail_cancel_btn' | translate}}</span></button>
                            <button class="button default-sm ml-xs" (click)="createUpdate()" [class.disabled]="saving">{{'contract_detail_save_btn' | translate}}</button>
                        </div>
                    </ng-container>
                    <ng-container *ngIf="mode != 'create'">
                        <h1 style="min-height: 36px;" class="d-flex align-items-center">
                            <i class="active-page-icon" *ngIf="getActivePage()?.icon" class="im-icon im-icon-{{getActivePage().icon}}"></i>
                            <span>{{'contract_detail_menu_' + activePage | translate}}</span>
                        </h1>
                        <ng-container *ngIf="activePage != 'documents' && activePage != 'payments'">
                            <div *ngIf="mode == 'show'" class="h-100 d-flex align-items-center">
                                <!-- edit -->
                                <button class="button tertiary ml-m" (click)="mode='edit';">
                                    <i class="im-icon im-icon-pencil icon-l mr-xxxs"></i><span>{{'contract_detail_edit_btn' | translate}}</span>
                                </button>
                            </div>
                            <div *ngIf="mode == 'edit'" class="h-100 d-flex align-items-center">
                                <button *ngIf="id" class="button tertiary cancel--" (click)="cancelEdit()" [class.disabled]="saving"><span>{{'contract_detail_cancel_btn' | translate}}</span></button>
                                <button class="button default-sm ml-xs" (click)="createUpdate()" [class.disabled]="saving">{{'contract_detail_save_btn' | translate}}</button>
                            </div>
                        </ng-container>
                    </ng-container>
                </div>
                <div class="detail-modal-body">
                    <!-- Overview -->
                    <ng-container *ngIf="activePage == 'overview'">
                        <ng-container *ngIf="mode == 'show'">
                            <div class="row display-row">
                                <div class="col-3">
                                    <div class="display-content">                            
                                        <div class="display-label">{{'price_dossier_status_code' | translate}}</div>
                                        <div class="display-value d-flex flex-row">
                                            <span>{{resourceService.getResourceTitleByTypeAndKey('status_code', dossier.status_code) | display}}</span>
                                        </div>                     
                                    </div>
                                </div>
                                <div class="col-3">
                                    <div class="display-content">                            
                                        <div class="display-label">{{'price_dossier_year_of_treatment' | translate}}</div>
                                        <div class="display-value d-flex flex-row">
                                            <span>{{dossier.year_of_treatment | display}}</span>
                                        </div>                     
                                    </div>
                                </div>
                                <div class="col-3">
                                    <div class="display-content">                            
                                        <div class="display-label">{{'price_dossier_year_of_decision' | translate}}</div>
                                        <div class="display-value d-flex flex-row">
                                            <span>{{dossier.year_of_decision | display}}</span>
                                        </div>                     
                                    </div>
                                </div>
                            </div>
                            <div class="row display-row">
                                <div class="col-3">
                                    <div class="display-content">                            
                                        <div class="display-label">{{'price_dossier_crp_cpsp' | translate}}</div>
                                        <div class="display-value d-flex flex-row">
                                            <span>{{resourceService.getResourceTitleByTypeAndKey('crp_cpsp', dossier.crp_cpsp) | display}}</span>
                                        </div>                     
                                    </div>
                                </div>
                                <div class="col-3">
                                    <div class="display-content">                            
                                        <div class="display-label">{{'price_dossier_type_of_price_dossier' | translate}}</div>
                                        <div class="display-value d-flex flex-row">
                                            <span>{{resourceService.getResourceTitleByTypeAndKey('type', dossier.type) | display}}</span>
                                        </div>                     
                                    </div>
                                </div>
                                <div class="col-3">
                                    <div class="display-content">                            
                                        <div class="display-label">{{'price_dossier_final_decision' | translate}}</div>
                                        <div class="display-value d-flex flex-row">
                                            <span>{{resourceService.getResourceTitleByTypeAndKey('final_decision', dossier.final_decision) | display}}</span>
                                        </div>                     
                                    </div>
                                </div>
                            </div>
                            <div class="row display-row">
                                <div class="col-3">
                                    <div class="display-content">                            
                                        <div class="display-label">{{'price_dossier_doc_number' | translate}}</div>
                                        <div class="display-value d-flex flex-row">
                                            <span>{{dossier.doc_number | display}}</span>
                                        </div>                     
                                    </div>
                                </div>
                                <div class="col-3">
                                    <div class="display-content">                            
                                        <div class="display-label">{{'price_dossier_dos_number' | translate}}</div>
                                        <div class="display-value d-flex flex-row">
                                            <span>{{dossier.dos_number | display}}</span>
                                        </div>                     
                                    </div>
                                </div>
                                <div class="col-3">
                                    <div class="display-content">                            
                                        <div class="display-label">{{'price_dossier_reimbursement' | translate}}</div>
                                        <div class="display-value d-flex flex-row">
                                            <span>{{resourceService.getResourceTitleByTypeAndKey('reimbursement', dossier.reimbursement) | display}}</span>
                                        </div>                     
                                    </div>
                                </div>
                            </div>
                            <div class="display-label">{{'price_dossier_product' | translate}}</div>
                            <div class="row display-row" *ngIf="!dossier?.products?.length">
                                <div class="col-12">
                                    <div class="display-content">
                                        <div class="display-value">-</div>
                                    </div>
                                </div>
                            </div>
                            <div class="row display-row" *ngFor="let product of dossier.products; index as $i">
                                <div class="col-12">
                                    <div class="products">
                                        <div class="row display-row">
                                            <div class="col-7">
                                                <div class="display-content">                            
                                                    <div class="display-value">
                                                        {{product.productPackage}}
                                                        <div class="subProduct">{{product.authorisationNr}}</div>
                                                    </div>                     
                                                </div>
                                            </div>
                                            <div class="col-4">
                                                <div class="display-content">                            
                                                    <div class="display-label">{{'price_dossier_atc' | translate}}</div>
                                                    <div class="display-value d-flex flex-row">
                                                        <span>{{product.atc | display}}</span>
                                                    </div>                  
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row display-row"> 
                                            <div class="col-3">
                                                <div class="display-content">                            
                                                    <div class="display-label">{{'price_dossier_price' | translate}}</div>
                                                    <div class="display-value d-flex flex-row">
                                                        <span>{{product.price | customCurrency: false}}</span>
                                                    </div>                     
                                                </div>
                                            </div>
                                            <div class="col-4">
                                                <div class="display-content">                            
                                                    <div class="display-label">{{'price_dossier_recommended' | translate}}</div>
                                                    <div class="display-value d-flex flex-row">
                                                        <span>
                                                            {{product.recommended | booleanDisplay}}
                                                            <ng-container *ngIf="product.recommended_price">
                                                                , {{product.recommended_price | customCurrency: false}}
                                                            </ng-container>
                                                        </span>
                                                    </div>  

                                                </div>
                                            </div>
                                            <div class="col-3">
                                                <div class="display-content">                            
                                                    <div class="display-label">{{'price_dossier_minister' | translate}}</div>
                                                    <div class="display-value d-flex flex-row">
                                                        <span>
                                                            {{product.minister | booleanDisplay}}
                                                            <ng-container *ngIf="product.minister_price">
                                                                , {{product.minister_price | customCurrency: false}}
                                                            </ng-container>
                                                        </span>
                                                    </div>  
                                                                        
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row display-row">
                                            <div class="col-6">
                                                <div class="display-content">                            
                                                    <div class="display-label">{{'price_dossier_comments' | translate}}</div>
                                                    <div class="display-value d-flex flex-row">
                                                        <app-field-richtext
                                                            [model]="product.comments"
                                                            [readOnly]="true"
                                                        ></app-field-richtext>
                                                    </div>                     
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row display-row">
                                <div class="col-3">
                                    <div class="display-content">                            
                                        <div class="display-label">{{'price_dossier_company' | translate}}</div>
                                        <div class="display-value d-flex flex-row">
                                            <span>{{dossier.company_name | display}}</span>
                                            <ng-container *ngIf="dossier.company_name">
                                                <div class="pl-xxs" *ngIf="dossier.lnk_company_id">
                                                    <span class="stamp mr-xxs stamp--gray">{{'pricedossier_table_member' | translate}} <ng-container *ngIf="!dossier.company_status">({{'misc_inactive' | translate}})</ng-container></span>
                                                </div>
                                            </ng-container>
                                        </div>                     
                                    </div>
                                </div>
                                <div class="col-3">
                                    <div class="display-content">                            
                                        <div class="display-label">{{'price_dossier_lnk_contact_id' | translate}}</div>
                                        <div class="display-value d-flex flex-row">
                                            <span>{{dossier.contact | display}}</span>
                                        </div>                     
                                    </div>
                                </div>
                            </div>
                            <div class="row display-row">
                                <div class="col-4">
                                    <div class="display-content">                            
                                        <div class="display-label">{{'price_dossier_date_meeting' | translate}}</div>
                                        <div class="display-value d-flex flex-row">
                                            <span>{{dossier.date_meeting | formatTs: 'DD-MM-YYYY'}}</span>
                                        </div>                     
                                    </div>
                                </div>
                                <div class="col-4">
                                    <div class="display-content">                            
                                        <div class="display-label">{{'price_dossier_date_day0' | translate}}</div>
                                        <div class="display-value d-flex flex-row">
                                            <span>{{dossier.date_day0 | formatTs: 'DD-MM-YYYY'}}</span>
                                        </div>                     
                                    </div>
                                </div>
                                <div class="col-4">
                                    <div class="display-content">                            
                                        <div class="display-label">{{'price_dossier_date_end_price_procedure' | translate}}</div>
                                        <div class="display-value d-flex flex-row">
                                            <span>{{dossier.date_end_price_procedure | formatTs: 'DD-MM-YYYY'}}</span>
                                        </div>                     
                                    </div>
                                </div>
                            </div>
                            <div class="row display-row">
                                <div class="col-2">
                                    <div class="display-content">                            
                                        <div class="display-label">{{'price_dossier_interuption_dates' | translate}}</div>
                                        <div class="display-value d-flex flex-row">
                                            <ng-container *ngIf="dossier.interuption_dates && dossier.interuption_dates.length > 0">
                                                <ul class="app-list" [class.app-list--single]="dossier.interuption_dates.length == 1">
                                                    <ng-container *ngFor="let date of dossier.interuption_dates">
                                                        <li>
                                                            {{date | formatTs:'DD-MM-YYYY'}}
                                                        </li>
                                                    </ng-container>
                                                </ul>
                                            </ng-container>
                                            <ng-container *ngIf="!dossier.interuption_dates || dossier.interuption_dates.length == 0">
                                                <span>-</span>
                                            </ng-container>
                                        </div>                    
                                    </div>
                                </div>
                            </div>
                            <div class="row display-row">
                                <div class="col-3">
                                    <div class="display-content">                            
                                        <div class="display-label">{{'price_dossier_price_available_d90' | translate}}</div>
                                        <div class="display-value d-flex flex-row">
                                            <span>{{dossier.price_available_d90| booleanDisplay}}</span>
                                        </div>                     
                                    </div>
                                </div>
                                <div class="col-3" *ngIf="show_why_price_not_available_d90 == false">
                                    <div class="display-content">                            
                                        <div class="display-label">{{'price_dossier_why_price_not_available_d90' | translate}}</div>
                                        <div class="display-value d-flex flex-row">
                                            <span>{{resourceService.getResourceTitleByTypeAndKey('why_price_not_available_d90', dossier.why_price_not_available_d90) | display}}</span>
                                        </div>                     
                                    </div>
                                </div>
                            </div>
                            <div class="row display-row">
                                <div class="col-3">
                                    <div class="display-content">                            
                                        <div class="display-label">{{'price_dossier_supplementary_questions' | translate}}</div>
                                        <div class="display-value d-flex flex-row">
                                            <span>{{dossier.supplementary_questions | booleanDisplay}}</span>
                                        </div>                     
                                    </div>
                                </div>
                            </div>
                            <div class="row display-row">
                                <div class="col-6">
                                    <div class="display-content">                            
                                        <div class="display-label">{{'price_dossier_comments_price_committee' | translate}}</div>
                                        <div class="display-value d-flex flex-row">
                                            <app-field-richtext
                                                [model]="dossier.comments_price_committee"
                                                [readOnly]="true"
                                            ></app-field-richtext>
                                        </div>                     
                                    </div>
                                </div>
                            </div>
                            <div class="row display-row">
                                <div class="col-6">
                                    <div class="display-content">                            
                                        <div class="display-label">{{'price_dossier_degree_of_deviation' | translate}}</div>
                                        <div class="display-value d-flex flex-row">
                                            <app-field-richtext
                                                [model]="dossier.degree_of_deviation"
                                                [readOnly]="true"
                                            ></app-field-richtext>
                                        </div>                     
                                    </div>
                                </div>
                            </div>
                            <div class="row display-row">
                                <div class="col-6">
                                    <div class="display-content">                            
                                        <div class="display-label">{{'price_dossier_summary' | translate}}</div>
                                        <div class="display-value d-flex flex-row">
                                            <app-field-richtext
                                                [model]="dossier.summary"
                                                [readOnly]="true"
                                            ></app-field-richtext>
                                        </div>                     
                                    </div>
                                </div>
                            </div>
                        </ng-container>
                        <ng-container *ngIf="mode == 'create' || mode == 'edit'">
                            <div class="row display-row">
                                <div class="col-4">
                                    <div class="d-flex align-items-end">
                                        <app-field-radios 
                                            class="d-block"
                                            label="{{'price_dossier_status_code' | translate}}"
                                            [canUncheck]="false"
                                            [model]="formSTR.status_code"
                                            [error]="validation.status_code"
                                            (modelChange)="formSTR.status_code = $event; formChange();"
                                            [options]="options.status_code"
                                            [optionWidth]="120">
                                        </app-field-radios>
                                    </div>
                                </div>
                                <div class="col-4">
                                    <div class="d-flex align-items-end">
                                        <div class="display-content">                            
                                            <div class="display-label">{{'price_dossier_year_of_treatment' | translate}}</div>
                                            <div class="display-value d-flex flex-row">
                                                <span>{{dossier.year_of_treatment | display}}</span>
                                            </div>                     
                                        </div>
                                    </div>
                                </div>
                                <div class="col-2">
                                    <div class="d-flex align-items-end">
                                        <app-field-select 
                                        [model]="formSTR.year_of_decision" 
                                        [options]="options.year_of_decision"
                                        [error]="validation.year_of_decision"
                                        (modelChange)="formSTR.year_of_decision = $event; formChange();"
                                        label="{{'price_dossier_year_of_decision' | translate}}"
                                        placeholder="{{'price_dossier_year_of_decision_ph' | translate}}">
                                    </app-field-select>
                                    </div>
                                </div>
                            </div>
                            <div class="row display-row">
                                <div class="col-4">
                                    <div class="d-flex align-items-end">
                                        <app-field-radios 
                                            class="d-block"
                                            label="{{'price_dossier_crp_cpsp' | translate}}"
                                            [canUncheck]="false"
                                            [model]="formSTR.crp_cpsp"
                                            [error]="validation.crp_cpsp"
                                            (modelChange)="formSTR.crp_cpsp = $event; formChange();"
                                            [options]="options.crp_cpsp"
                                            [optionWidth]="120">
                                        </app-field-radios>
                                    </div>
                                </div>
                                <div class="col-3">
                                    <app-field-select 
                                        [model]="formSTR.type" 
                                        [options]="options.type"
                                        [error]="validation.type"
                                        (modelChange)="formSTR.type = $event; formChange();"
                                        label="{{'price_dossier_type_of_price_dossier' | translate}}"
                                        placeholder="{{'price_dossier_type_of_price_dossier_ph' | translate}}">
                                    </app-field-select>
                                </div>
                                <div class="col-1"></div>
                                <div class="col-3">
                                    <app-field-select 
                                        [model]="formSTR.final_decision" 
                                        [options]="options.final_decision"
                                        [error]="validation.final_decision"
                                        (modelChange)="formSTR.final_decision = $event; formChange();"
                                        label="{{'price_dossier_final_decision' | translate}}"
                                        placeholder="{{'price_dossier_final_decision_ph' | translate}}">
                                    </app-field-select>
                                </div>
                            </div>
                            <div class="row display-row">
                                <div class="col-3">
                                    <app-field-text 
                                        [model]="formSTR.doc_number" 
                                        [error]="validation.doc_number"
                                        (modelChange)="formSTR.doc_number = $event; formChange();" 
                                        [label]="'price_dossier_doc_number' | translate"
                                    ></app-field-text>
                                </div>
                                <div class="col-1"></div>
                                <div class="col-3">
                                    <app-field-text 
                                        [model]="formSTR.dos_number" 
                                        [error]="validation.dos_number"
                                        (modelChange)="formSTR.dos_number = $event; formChange();" 
                                        [label]="'price_dossier_dos_number' | translate"
                                    ></app-field-text>
                                </div>
                                <div class="col-1"></div>
                                <div class="col-3">
                                    <app-field-select 
                                        [model]="formSTR.reimbursement" 
                                        [options]="options.reimbursement"
                                        [error]="validation.reimbursement"
                                        (modelChange)="formSTR.reimbursement = $event; formChange();"
                                        label="{{'price_dossier_reimbursement' | translate}}"
                                        placeholder="{{'price_dossier_reimbursement_ph' | translate}}">
                                    </app-field-select>
                                </div>
                            </div>
                            <div class="display-label">{{'price_dossier_product' | translate}}</div>

                            <div class="row display-row mb-s" *ngFor="let product of formSTR.products; index as $i">
                                <div class="col-12">
                                    <div class="products">
                                        <i (click)="deleteProduct($i)" class="im-icon im-icon-trash delete-icon"></i>
                                        <div class="row display-row">
                                            <div class="col-3">
                                                <!-- <div class="display-content">                            
                                                    <div class="display-value">
                                                        {{product.productPackage}}
                                                        <div class="subProduct">{{product.authorisationNr}}</div>
                                                    </div>                     
                                                </div> -->
                                                <app-field-product
                                                    [model]="product.product" 
                                                    
                                                    (modelChange)="onSelectProduct($event, $i); formChange();"
                                                    [error]="validation.products"
                                                    [placeholder]="'price_dossier_product_placeholder' | translate">
                                                </app-field-product>
                                            </div>
                                            <div class="col-4"></div>
                                            <div class="col-3">
                                                <div class="display-content">                            
                                                    <div class="display-label">{{'price_dossier_atc' | translate}}</div>
                                                    <div class="display-value d-flex flex-row">
                                                        <span>{{product.atc}}</span>
                                                    </div>                  
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row display-row"> 
                                            <div class="col-3">
                                                <app-field-text 
                                                    [model]="product.price" 
                                                    [error]="validation.price"
                                                    (modelChange)="product.price = $event; setMinisterPrice($i); formChange();" 
                                                    [label]="'price_dossier_price' | translate"
                                                    type="currency"
                                                ></app-field-text>
                                            </div>
                                            <div class="col-4">
                                                <div class="d-flex align-items-end">
                                                    <app-field-radios 
                                                        class="d-block"
                                                        label="{{'price_dossier_recommended' | translate}}"
                                                        [canUncheck]="true"
                                                        [model]="product.recommended"
                                                        [error]="validation.recommended"
                                                        (modelChange)="product.recommended = $event; formChange();"
                                                        [options]="options.recommended"
                                                        [optionWidth]="2"
                                                        [optionalInput]="true"
                                                        [optionalInputModel]="product.recommended_price"
                                                        (optionalInputModelChange)="product.recommended_price = $event; formChange();"
                                                        optionalInputType="currency"
                                                        optionalInputWidth="4">
                                                    </app-field-radios>
                                                </div>
                                            </div>
                                            <div class="col-3">
                                                <div class="d-flex align-items-end">
                                                    <app-field-radios 
                                                        class="d-block"
                                                        label="{{'price_dossier_minister' | translate}}"
                                                        [canUncheck]="true"
                                                        [model]="product.minister"
                                                        [error]="validation.minister"
                                                        (modelChange)="product.minister = $event; setMinisterPrice($i); formChange();"
                                                        [options]="options.minister"
                                                        [optionWidth]="2"
                                                        [optionalInput]="true"
                                                        [optionalInputOnModelValue]="false"
                                                        [optionalInputModel]="product.minister_price"
                                                        (optionalInputModelChange)="product.minister_price = $event; formChange();"
                                                        optionalInputType="currency"
                                                        optionalInputWidth="4"
                                                        [optionalReadOnlyOnOtherModelValue]="true"
                                                        [optionalReadOnlyValue]="product.price">
                                                    </app-field-radios>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row display-row">
                                            <div class="col-6">
                                                <app-field-richtext 
                                                    [model]="product.comments" 
                                                    [error]="validation.comments"
                                                    (modelChange)="product.comments = $event; formChange();" 
                                                    [label]="'price_dossier_comments' | translate"
                                                ></app-field-richtext>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row display-row">
                                <div class="col-12">
                                    <button class="button-link h-auto" (click)="addProduct()" style="margin-top: 0px;"><span class="d-inline-block" style="margin-right: 4px;text-decoration: none;">+</span><span class="underline d-inline-block"> {{'pricedossier_detail_add_product_btn' | translate}}</span></button>
                                </div>
                            </div>
                            <!-- PHARMA-1593 -->
                            <!-- <div class="row display-row">
                                <div class="col-3">
                                    <div *ngIf="formSTR.products?.length" class="display-label">{{'price_dossier_add_product' | translate}}</div>
                                    <app-field-products
                                        [model]="formSTR.products" 
                                        (modelChange)="setProducts($event); hideProductField = true; $event?.length ? this.formSTR.initial_company_name = {value: $event[0].companyName} : ''; formChange();"
                                        [hideList]="true"
                                        [error]="validation.products"
                                        placeholder="{{'price_dossier_product_placeholder' | translate}}">
                                    </app-field-products>
                                </div>
                            </div> -->
                            <div class="row display-row">
                                <div class="col-3">
                                    <!-- <div class="display-content">                            
                                        <div class="display-label">{{'price_dossier_company' | translate}}</div>
                                        <div class="display-value d-flex flex-row">
                                            <span>{{formSTR.company_name | display}}</span>
                                        </div>                     
                                    </div> -->
                                    <!-- <ng-container *ngIf="formSTR.id">
                                        <div class="display-content">
                                            <div class="display-label">{{'price_dossier_company' | translate}}</div>
                                            <div class="display-value d-flex flex-row">
                                                <span>{{dossier.company_name | display}}</span>
                                                <ng-container *ngIf="dossier.company_name">
                                                    <div class="pl-xxs" *ngIf="dossier.lnk_company_id">
                                                        <span class="stamp mr-xxs stamp--gray">{{'pricedossier_table_member' | translate}} <ng-container *ngIf="!dossier.company_status">({{'misc_inactive' | translate}})</ng-container></span>
                                                    </div>
                                                </ng-container>
                                            </div>                     
                                        </div>
                                    </ng-container>
                                    <ng-container *ngIf="!formSTR.id"> -->
                                        <div class="d-flex w-100 align-items-end">
                                            <app-field-company-all
                                                style="max-width: 800px;"
                                                class="w-100"
                                                [multiple]="false"
                                                [detectModelChange]="formSTR.company_name"
                                                [model]="formSTR.initial_company_name" 
                                                (modelChange)="companyChange($event)"
                                                label="{{'pricedossier_detail_company' | translate}}"
                                                [error]="validation.company_name"
                                                placeholder="{{'pricedossier_detail_company_placeholder' | translate}}"
                                            ></app-field-company-all>
                                        </div>
                                    <!-- </ng-container> -->
                                </div>
                                <div class="col-1"></div>
                                <div class="col-3">
                                    <app-field-select 
                                        [model]="formSTR.lnk_contact_id" 
                                        [options]="dossierManagers"
                                        [error]="validation.lnk_contact_id"
                                        (modelChange)="formSTR.lnk_contact_id = $event; addNewContactPerson($event); formChange();"
                                        label="{{'price_dossier_lnk_contact_id' | translate}}"
                                        placeholder="{{'price_dossier_lnk_contact_id_ph' | translate}}">
                                    </app-field-select>
                                </div>
                            </div>
                            <div class="row display-row">
                                <div class="col-4">
                                    <app-field-date 
                                        id="date_meeting"
                                        [model]="formSTR.date_meeting" 
                                        (modelChange)="formSTR.date_meeting = $event; changeYearOfTreatment($event); formChange();" 
                                        placeholder="dd-mm-yyyy"
                                        [error]="validation.date_meeting"
                                        label="{{'price_dossier_date_meeting' | translate}}"
                                        positionDateSelector="top"
                                    ></app-field-date>
                                </div>
                                <div class="col-4">
                                    <app-field-date 
                                        id="date_day0"
                                        [model]="formSTR.date_day0" 
                                        (modelChange)="formSTR.date_day0 = $event; formChange();" 
                                        placeholder="dd-mm-yyyy"
                                        [error]="validation.date_day0"
                                        label="{{'price_dossier_date_day0' | translate}}"
                                        positionDateSelector="top"
                                    ></app-field-date>
                                </div>
                                <div class="col-4">
                                    <app-field-date 
                                        id="date_end_price_procedure"
                                        [model]="formSTR.date_end_price_procedure" 
                                        (modelChange)="formSTR.date_end_price_procedure = $event; formChange();" 
                                        placeholder="dd-mm-yyyy"
                                        [error]="validation.date_end_price_procedure"
                                        label="{{'price_dossier_date_end_price_procedure' | translate}}"
                                        positionDateSelector="top"
                                    ></app-field-date>
                                </div>
                            </div>
                            <div class="row display-row">
                                <div class="col-2">
                                    <app-field-multi-input
                                        type="date"
                                        id="interuption_dates"
                                        [model]="formSTR.interuption_dates" 
                                        (modelChange)="formSTR.interuption_dates = $event; formChange();" 
                                        label="{{'price_dossier_interuption_dates' | translate}}"
                                        [error]="validation.interuption_dates"
                                        placeholder="dd-mm-yyyy"
                                        positionDateSelector="top"
                                        >
                                    </app-field-multi-input>
                                </div>
                            </div>
                            <div class="row display-row">
                                <div class="col-3">
                                    <div class="d-flex align-items-end">
                                        <app-field-radios 
                                            class="d-block"
                                            label="{{'price_dossier_price_available_d90' | translate}}"
                                            [canUncheck]="true"
                                            [model]="formSTR.price_available_d90"
                                            [error]="validation.price_available_d90"
                                            (modelChange)="formSTR.price_available_d90 = $event; showHideWhyPriceNotAvailableD90(); formChange();"
                                            [options]="options.price_available_d90"
                                            [optionWidth]="120">
                                        </app-field-radios>
                                    </div>
                                </div>
                                <div class="col-3" *ngIf="show_why_price_not_available_d90 == false">
                                    <app-field-select 
                                        [model]="formSTR.why_price_not_available_d90" 
                                        [options]="options.why_price_not_available_d90"
                                        [error]="validation.why_price_not_available_d90"
                                        (modelChange)="formSTR.why_price_not_available_d90 = $event; formChange();"
                                        label="{{'price_dossier_why_price_not_available_d90' | translate}}"
                                        placeholder="{{'price_dossier_why_price_not_available_d90_ph' | translate}}">
                                    </app-field-select>
                                </div>
                            </div>
                            <div class="row display-row">
                                <div class="col-3">
                                    <div class="d-flex align-items-end">
                                        <app-field-radios 
                                            class="d-block"
                                            label="{{'price_dossier_supplementary_questions' | translate}}"
                                            [canUncheck]="true"
                                            [model]="formSTR.supplementary_questions"
                                            [error]="validation.supplementary_questions"
                                            (modelChange)="formSTR.supplementary_questions = $event; formChange();"
                                            [options]="options.supplementary_questions"
                                            [optionWidth]="120">
                                        </app-field-radios>
                                    </div>
                                </div>
                            </div>
                            <div class="row display-row">
                                <div class="col-6">
                                    <app-field-richtext 
                                        [model]="formSTR.comments_price_committee" 
                                        [error]="validation.comments_price_committee"
                                        (modelChange)="formSTR.comments_price_committee = $event; formChange();" 
                                        [label]="'price_dossier_comments_price_committee' | translate"
                                    ></app-field-richtext>
                                </div>
                            </div>
                            <div class="row display-row">
                                <div class="col-6">
                                    <app-field-richtext 
                                        [model]="formSTR.degree_of_deviation" 
                                        [error]="validation.degree_of_deviation"
                                        (modelChange)="formSTR.degree_of_deviation = $event; formChange();" 
                                        [label]="'price_dossier_degree_of_deviation' | translate"
                                    ></app-field-richtext>
                                </div>
                            </div>
                            <div class="row display-row">
                                <div class="col-6">
                                    <app-field-richtext 
                                        [model]="formSTR.summary" 
                                        [error]="validation.summary"
                                        (modelChange)="formSTR.summary = $event; formChange();" 
                                        [label]="'price_dossier_summary' | translate"
                                    ></app-field-richtext>
                                </div>
                            </div>
                        </ng-container>
                    </ng-container>
                    <ng-container *ngIf="activePage == 'documents'">
                            <ng-container *ngIf="!documentsReady">
                                <app-spinner [centerVertically]="true"></app-spinner>
                            </ng-container>
                            <ng-container *ngIf="documentsReady">
                                <app-documents
                                    [foldersInput]="this.folders"
                                    [priceDossierId]="dossier.id"
                                    [dossiers]="this.dossier.folders"
                                ></app-documents>
                        </ng-container>
                    </ng-container>
                </div>
            </div>
        </div>
    </div>
</ng-container>
