import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
    selector: 'app-side-navigation',
    templateUrl: './side-navigation.component.html',
    styleUrls: ['./side-navigation.component.scss']
})
export class SideNavigationComponent implements OnInit {
    @Input() pageARR: {
        code: any;
        name: any;
        notify?: boolean;
        disabled?: boolean;
        indent?: boolean;
        customContent?: boolean;
    }[];
    @Input() model?: string;
    @Input() inactive?: boolean;
    @Output() modelChange = new EventEmitter();

    constructor() {}

    ngOnInit(): void {}

    pageChange(page): void {
        if (!page?.disabled) {
            this.modelChange.emit(page.code);
        }
    }
}
