import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';

@Component({
    selector: 'app-field-select',
    templateUrl: './field-select.component.html',
    styleUrls: ['./field-select.component.scss']
})
export class FieldSelectComponent implements OnInit, OnChanges {
    @Input() label?: string;
    @Input() tooltip?: string;
    @Input() hideLabel?: boolean;
    @Input() error?: string;
    @Input() disabled?: boolean;
    @Input() optionalString?: string;
    @Input() placeholder?: string;
    @Input() maxWidth?: string;
    @Input() options: { value: string; key: any }[];
    @Input() localOptions: { value: string; key: any }[];
    @Input() optionsToExclude: any[] = [];
    @Input() model: string;
    @Output() modelChange = new EventEmitter();

    constructor() {}

    ngOnInit(): void {
        if (this.placeholder && this.options) {
            this.localOptions = [{ value: this.placeholder, key: undefined }, ...this.options];
        }
        setTimeout(() => {
            if (this.options?.length && this.model && !this.options.some((option) => option.key === this.model)) {
                this.modelChange.emit(undefined);
            }
        }, 1);
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.options && changes.options.currentValue) {
            if (this.placeholder) {
                this.localOptions = [{ value: this.placeholder, key: undefined }, ...changes.options.currentValue];
            } else this.localOptions = changes.options.currentValue;
        }
        if (changes.optionsToExclude && changes.optionsToExclude.currentValue) {
            if (changes.optionsToExclude.currentValue.indexOf(this.model) != -1) {
                setTimeout(() => {
                    this.modelChange.emit(undefined);
                }, 0);
            }
        }
    }

    getOptionsToExclude() {
        if (this.optionsToExclude == null || this.optionsToExclude == undefined) {
            return [];
        }
        return this.optionsToExclude;
    }

    hasParent(option) {
        return option.lnk_parent_id;
    }

    isParent(option) {
        return (
            option &&
            option.id &&
            this.localOptions.find((item: any) => {
                return item.lnk_parent_id == option.id;
            })
        );
    }

    getChildren(option) {
        return this.localOptions.filter((item: any) => {
            return item.lnk_parent_id == option.id;
        });
    }
}
