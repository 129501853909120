import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, switchMap, map } from 'rxjs/operators';
import { CompanyService } from 'src/app/services/company.service';

@Component({
    selector: 'app-field-pharma-companies',
    templateUrl: './field-pharma-companies.component.html',
    styleUrls: ['./field-pharma-companies.component.scss']
})
export class FieldPharmaCompaniesComponent implements OnInit, OnChanges {
    @Input() label?: string;
    @Input() error?: string | string[];
    @Input() placeholder?: string;
    @Input() initialModel: string;
    @Input() detectModelChange?: any;
    @Output() modelChange = new EventEmitter();
    @Output() enter = new EventEmitter();
    model: any;

    constructor(private companyService: CompanyService) {}

    ngOnInit(): void {
        this.updateModel();
    }

    ngOnChanges(changes: SimpleChanges) {
        this.updateModel();
    }

    updateModel() {
        if (this.initialModel) {
            this.model = this.initialModel;
        } else {
            this.model = '';
        }
    }

    formatter = (item: { key: any; value: string }) => item.value;

    search = (text$: Observable<string>) =>
        text$.pipe(
            debounceTime(200),
            distinctUntilChanged(),
            filter((term) => {
                return term.length > 1;
            }),
            switchMap((term) => this.companyService.searchPharmaCompanies(term)),
            map((val) => {
                let companies = [];
                for (const company of val.data) {
                    companies.push({
                        key: company.id,
                        value: company.name,
                        company: company
                    });
                }

                if (companies.length == 0) {
                    companies.push({
                        key: '',
                        value: 'No companies found.',
                        company: null
                    });
                }
                return companies;
            })
        );

    onSelect($event) {
        if ($event) {
            this.modelChange.emit($event);
        }
    }

    change($event) {
        if (!this.model) {
            this.modelChange.emit(undefined);
        }
    }

    onBlur($event?) {
        if (!this.model) {
            this.model = '';
        }
    }
}
