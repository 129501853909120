import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { Config } from '../models/common';

@Injectable({
    providedIn: 'root'
})
export class ConfigService {
    private config: Config = {
        appName: 'pnr',
        apiUrl: 'api/v1',
        isIE: false
    };

    constructor(private TitleService: Title, private Router: Router, public Route: ActivatedRoute) {
        var ua = window.navigator.userAgent;
        if (ua.indexOf('MSIE ') != -1 || ua.indexOf('Trident/') != -1) {
            this.config.isIE = true;
        }
    }

    public getConfig(): Config {
        return this.config;
    }

    public setConfig(config): any {
        this.config = { ...this.config, ...config };
    }
}
