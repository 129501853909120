import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { TranslatePipe } from 'src/app/utils/pipes';

@Component({
    selector: 'app-contract-version',
    templateUrl: './contract-version.component.html',
    styleUrls: ['./contract-version.component.scss']
})
export class ContractVersionComponent implements OnInit {
    @Input() options: any[];
    @Input() model: string = null;
    @Input() disabled: boolean = false;
    @Output() modelChange = new EventEmitter();
    @Output() add = new EventEmitter();

    open: boolean = false;

    constructor(public TranslatePipe: TranslatePipe) {}

    ngOnInit(): void {}

    getOption(id) {
        return this.options?.find((option) => {
            return option.id === id;
        });
    }

    selectOption(option) {
        this.modelChange.emit(option.id);
        this.open = false;
    }

    clickAdd() {
        this.add.emit();
        this.open = false;
    }
}
