<ng-container>
    <nav>
        <ul class="d-flex flex-column">
            <li *ngFor="let page of pageARR;" class="d-block" [class.indent]="page.indent" [class.custom-content]="page.customContent" [class.active]="model === page.code && !inactive" [class.disabled]="page.disabled" (click)="!page.customContent && pageChange(page)">
                <div class="d-flex justify-content-between" *ngIf="!page.customContent">
                    <div class="d-flex align-items-center"><span class="icon-container"><i class="im-icon im-icon-{{page.icon || 'arrow-right'}}"></i></span> {{page.name}}</div> 
                    <i *ngIf="page.notify" class="icon icon-notif"></i>
                </div>
                <ng-content *ngIf="page.customContent"></ng-content>
            </li>
        </ul>
    </nav>
</ng-container>