<div class="container page">
    <div class="d-flex">
        <!-- side panel -->
        <div class="side-panel-wrap">
            <div class="side-panel d-flex flex-column justify-content-between">
                <div>
                    <h2 class="search-title">{{'search_title' | translate}}</h2>
                    <div class="position-relative">
                        <!-- ADVANCED -->
                        <div class="w-100" *ngIf="searchType === 'advanced'">
                            <div class="row row-form">
                                <div class="col-md-12">
                                    <app-field-text
                                        [model]="searchSTR.term" 
                                        (modelChange)="searchSTR.term = $event" 
                                        (enter)="search()" 
                                        label="{{'pricedossier_searchForm_keyword' | translate}}"
                                        placeholder="{{'pricedossier_searchForm_keyword_placeholder' | translate}}">
                                    </app-field-text>
                                </div>
                            </div>
                            <div class="row row-form">
                                <div class="col-md-12">
                                    <app-field-text
                                        [model]="searchSTR.dos_number" 
                                        (modelChange)="searchSTR.dos_number = $event" 
                                        (enter)="search()" 
                                        label="{{'pricedossier_searchForm_dos_number' | translate}}"
                                        placeholder="{{'pricedossier_searchForm_dos_number_placeholder' | translate}}">
                                    </app-field-text>
                                </div>
                            </div>
                            <div class="row row-form">
                                <div class="col-md-12">
                                    <app-field-pharma-companies
                                        [detectModelChange]="searchSTR.company_name"
                                        [initialModel]="searchSTR.initial_company_name"  
                                        (modelChange)="searchSTR.company_name = $event?.item?.value;searchSTR.initial_company_name = $event ? $event?.item : '';"
                                        label="{{'pricedossier_searchForm_company' | translate}}"
                                        placeholder="{{'pricedossier_searchForm_company_placeholder' | translate}}">
                                    </app-field-pharma-companies>
                                </div>
                            </div>
                            <div class="row row-form">
                                <div class="col-md-12">
                                    <app-field-select 
                                        [model]="searchSTR.status_code" 
                                        [options]="options.status_code"
                                        (modelChange)="searchSTR.status_code = $event;"
                                        label="{{'pricedossier_searchForm_status_code' | translate}}"
                                        placeholder="{{'pricedossier_searchForm_status_code_placeholder' | translate}}">
                                    </app-field-select>
                                </div>
                            </div>
                            <div class="row row-form">
                                <div class="col-6">
                                    <app-field-date 
                                        [model]="searchSTR.date_meeting_from" 
                                        (modelChange)="searchSTR.date_meeting_from = $event;" 
                                        label="{{'pricedossier_searchForm_date_meeting_from' | translate}}"
                                        placeholder="dd-mm-yyyy"
                                        positionDateSelector="bottom">
                                    </app-field-date>
                                </div>
                                <div class="col-6">
                                    <app-field-date 
                                        [model]="searchSTR.date_meeting_to" 
                                        (modelChange)="searchSTR.date_meeting_to = $event;" 
                                        label="{{'pricedossier_searchForm_date_meeting_to' | translate}}"
                                        placeholder="dd-mm-yyyy"
                                        positionDateSelector="bottom">
                                    </app-field-date>
                                </div>
                            </div>
                            <div class="row row-form">
                                <div class="col-md-6">
                                    <app-field-select 
                                        [model]="searchSTR.year_of_decision" 
                                        [options]="options.year_of_decision"
                                        (modelChange)="searchSTR.year_of_decision = $event;"
                                        label="{{'pricedossier_searchForm_year_of_decision' | translate}}"
                                        placeholder="{{'pricedossier_searchForm_year_of_decision_placeholder' | translate}}">
                                    </app-field-select>
                                </div>
                                <div class="col-md-6">
                                    <app-field-select 
                                        [model]="searchSTR.crp_cpsp" 
                                        [options]="options.crp_cpsp"
                                        (modelChange)="searchSTR.crp_cpsp = $event;"
                                        label="{{'pricedossier_searchForm_crp_cpsp' | translate}}"
                                        placeholder="{{'pricedossier_searchForm_crp_cpsp_placeholder' | translate}}">
                                    </app-field-select>
                                </div>
                            </div>
                            <div class="row row-form">
                                <div class="col-md-12">
                                    <app-field-select 
                                        [model]="searchSTR.type" 
                                        [options]="options.type"
                                        (modelChange)="searchSTR.type = $event;"
                                        label="{{'pricedossier_searchForm_type' | translate}}"
                                        placeholder="{{'pricedossier_searchForm_type_placeholder' | translate}}">
                                    </app-field-select>
                                </div>
                            </div>
                            <div class="mt-s d-flex flex-column align-items-center search-button-container">
                                <button (click)="search()" class="button default dark w-100 text-center d-block">{{'pricedossier_searchForm_search_btn' | translate}}</button>
                                <button (click)="search(true)" class="button-link underline mt-s fontsize-s"><span>{{'pricedossier_searchForm_clear_btn' | translate}}</span></button>
                            </div>
                        </div>
                        
                    </div>
                </div>
            </div>
        </div>

        <!-- main content -->
        <div class="flex-grow-1 side-panel-main pt-s pb-l">
            <div class="d-flex justify-content-between pl-xs pr-xs">
                <h1 class="mb-l">{{'pricedossier_table_title' | translate}} {{((!tableLoading && totalRows) || '') && '(' + totalRows + ')'}}</h1>
                <button (click)="openCreateUpdateModal()" class="button default"><i class="im-icon im-icon-plus-round"></i>{{'pricedossier_new_btn' | translate}}</button>
            </div>
            <div class="pl-xs pr-xs">
                
                <app-table
                    id="priceDossiers"
                    [heads]="tableHeads" 
                    [data]="dossiers"
                    [sort]="tableSort"
                    [RPP]="RPP"
                    [startRow]="startRow"
                    [totalRows]="totalRows"
                    [loading]="tableLoading"
                    [componentIsAttached]="!modalOpen"
                    [shimmerTypes]="['text-m','text-m','text-m','text-m', 'actions']"
                    emptyState="{{'pricedossier_table_empty' | translate}}"
                    (sortChange)="setSort($event.code, $event.dir)"
                    (clickData)="tableClick($event.item)"
                    (clickAction)="actionClick($event.item, $event.action)"
                    (startRowChange)="changeStartRow($event)"
                    (clickExport)="export()"
                    exportLabel="{{'pricedossier_table_export' | translate}}"
                    [exportBusy]="exportBusy"
                    >
                </app-table>
            </div>
        </div>
    </div>
</div>