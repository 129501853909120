<!-- head -->
<div class="options-modal-head d-flex flex-row justify-content-between align-items-center">
    <h1>{{(file ? 'documentcontractoptions_title_edit' : 'documentcontractoptions_title') | translate}}</h1>
    <div class="h-100 d-flex align-items-center">
        <button (click)="cancel()" class="button tertiary"><span>{{'documentcontractoptions_cancel' | translate}}</span></button>
        <button *ngIf="files?.length" [class.disabled]="!FORM?.length" (click)="FORM?.length && submit()" class="button default-sm ml-xs">{{'documentcontractoptions_save' | translate}}</button>
    </div>
</div>

<!-- body -->
<div class="options-modal-body">

    <!-- files -->
    <div class="row display-row">
        <div class="col-12 mw-600">
            <div class="display-content">                            
                <div class="display-label">{{(files?.length == 1 ? 'documentcontractoptions_file' : 'documentcontractoptions_files') | translate}}</div>
                <div class="display-value d-flex flex-row">
                    <ul class="app-list mb-0">
                        <!-- rejected -->
                        <li class="error-red" *ngFor="let file of rejected">{{getRejectedReason(file)}}</li>
                        <!-- accepted -->
                        <li class="" *ngFor="let file of files">{{file.name}}</li>
                    </ul>
                </div>       
            </div>
        </div>
    </div>

    <!-- link dossiers -->
    <div class="row display-row" *ngIf="files?.length">
        <div class="col-12 mw-600">
            <div class="display-content">                            
                <div class="display-label">{{'documentcontractoptions_link_dossiers' | translate}}</div>
                <div class="display-value d-flex flex-row">
                    <ul class="app-list">
                        <!-- rejected -->
                        <li *ngFor="let dossier of dossiers">
                            <div (click)="check(dossier.id)" [class.active]="isChecked(dossier.id)" class="field-checkbox-wrap" style="min-height: 0; transform: translatey(1px)">
                                <span class="fake-checkbox"></span> <span>{{dossier.alias}} ({{dossier.dossier_nrs | array}})</span>
                            </div>
                        </li>
                    </ul>
                </div>       
            </div>
        </div>
    </div>

</div>